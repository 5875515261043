import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDisclosure } from '@chakra-ui/react';

import { useGetAgreementMutation } from '@/store/leadagreement.sclice';

export function useViewAgreementServices() {
  const { agreementData, attachments } = useSelector(
    (state: any) => state.leadagreement
  );

  const [reqget, resget] = useGetAgreementMutation();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  useEffect(() => {
    reqget({ id: agreementData?.id });
  }, [onEditAgreementClose]);

  return {
    agreementData,
    attachments,
    onEditAgreementOpen,
    isEditAgreementOpen,
    onEditAgreementClose,
  };
}
