/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  usePostCreateSequenceMutation,
  usePostSequenceListMutation,
} from '@/store/outreach.slice';
import { SEQUENCE_SCHEMA } from './constant';

export const useSequenceModalServices = ({ isOpen, onClose, isEdit }: any) => {
  const toast = useToast();

  const [reqPostSequenceList] = usePostSequenceListMutation();
  const [reqPostCreateSequence, resPostCreateSequence] =
    usePostCreateSequenceMutation();

  const {
    sequencesPgBtn,
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
  } = useSelector((state: any) => state.outreach);

  const { jobs } = useSelector((state: any) => state.jobs);

  const initialValues = {
    primary: false,
    job_id: '',
    name: '',
    sender_email: [] as any,
    email_template: '',
  };

  const validationSchema = SEQUENCE_SCHEMA;

  const handleOnSubmit = (data: any) => {
    const params = {
      primary: data.primary,
      job_id: data.job_id,
      name: data.name,
      sender_email:
        (data.sender_email &&
          data.sender_email?.length > 0 &&
          data.sender_email?.map((val: any) => {
            return val.value;
          })) ||
        [],
      email_template: data.email_template,
    };
    reqPostCreateSequence(params);

    console.log(params, 'xx submit');
  };

  useEffect(() => {
    if (resPostCreateSequence.isSuccess) {
      reqPostSequenceList(sequencesPgBtn);
      onClose();

      toast({
        title: 'New sequence added.',
        description: "We've added your new sequence.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostCreateSequence.isSuccess]);

  return {
    jobs,
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
    initialValues,
    validationSchema,
    isOpen,
    onClose,
    handleOnSubmit,
    isLoading: resPostCreateSequence.isLoading,
  };
};
