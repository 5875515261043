import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const skillSlice = createSlice({
  name: 'skills',
  initialState: {
    skills: [],
    skillListing: [],
    skillPageBtn: {
      page: 1,
      take: 10,
      query: '',
    },
  },

  reducers: {
    listSkills: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listSkills } = skillSlice.actions;
export default skillSlice.reducer;

const skillApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    skillList: builder.mutation({
      query: (data) => ({
        url: `/skills`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSkills({ skills: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    Searchskill: builder.mutation({
      query: (data) => ({
        url: `/skills/search`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSkills({ skillListing: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useSkillListMutation, useSearchskillMutation } = skillApiSlice;
