import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const leadNotesSlice = createSlice({
  name: 'leadAttachment',
  initialState: {
    attachments: [],
  },
  reducers: {
    getAttachment: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getAttachment } = leadNotesSlice.actions;
export default leadNotesSlice.reducer;

export const leadNotesApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateNotes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-notes/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateNotes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-notes/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelNotes: builder.mutation({
      query: ({ id }) => ({
        url: `/lead-notes/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: [],
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateNotesMutation,
  useDelNotesMutation,
  useUpdateNotesMutation,
} = leadNotesApiSplice;
