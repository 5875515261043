import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  useToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from '@/components/app/ScrollError';
import 'react-quill/dist/quill.snow.css';
import '@/assets/css/minHquill.css';
import {
  useListSignatureMutation,
  useUpdateSignatureMutation,
} from '@/store/signature.slice';
import AtsEmailBody from '@/components/app/EmailBody';

interface SignatureEdit {
  isOpen: any;
  onClose: any;
  signature: any;
}

export default function SignatureEditModal({
  isOpen,
  onClose,
  signature,
}: SignatureEdit) {
  const [reqUpdateSignature, resUpdateSignature] = useUpdateSignatureMutation();
  const [quilValue, setquilValue] = useState(signature?.body || '');
  const [reqListSignature] = useListSignatureMutation();
  const { signaturePageBtn } = useSelector((state: any) => state.signature);
  const dispatch = useDispatch();
  const onSubmit = async (data: any) => {
    reqUpdateSignature({ data, id: signature?.id });
  };
  const toast = useToast();

  const initialValues = {
    title: signature?.title,
    body: signature?.body,
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Title is required.'),
      body: Yup.string().required('Body is required.'),
    })
  );

  useEffect(() => {
    if (resUpdateSignature.isSuccess) {
      onClose();
      reqListSignature(signaturePageBtn);
    }
  }, [resUpdateSignature.isSuccess]);

  useEffect(() => {
    setquilValue(signature?.body);
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Signature
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box width="100%">
                      <Flex mb="20px" gap="32px">
                        <FormControl
                          isInvalid={Boolean(!!errors.title && touched.title)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Signature Name
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            name="title"
                            type="text"
                            placeholder="Signature Name"
                            variant="outline"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.title)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Box mb="20px">
                        <FormControl
                          isInvalid={Boolean(!!errors.body && touched.body)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Body
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Box
                            sx={{
                              '& .ql-editor': {
                                minHeight: '100vh !important',
                              },
                            }}
                          >
                            <AtsEmailBody
                              value={quilValue}
                              onChange={(e: any) => {
                                setquilValue(e);
                                setFieldValue('body', e);
                              }}
                              height={175}
                            />
                          </Box>
                          <FormErrorMessage>
                            {String(errors.body)}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      // disabled={uploading}
                      isLoading={resUpdateSignature.isLoading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
