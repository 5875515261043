import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateInbox,
  useGetTaskViewMutation,
  usePostTaskListMutation,
  usePostUpdateStatusMutation,
} from '@/store/inbox.slice';
import { updateOutreach } from '@/store/outreach.slice';

interface useEmailsTableServicesProps {
  active: number;
}

export const useEmailsTableServices = ({
  active,
}: useEmailsTableServicesProps) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { inboxList, inboxData, inboxPage, inboxPgBtn } = useSelector(
    (state: any) => state.inbox
  );

  const [reqPostTaskList, resPostTaskList] = usePostTaskListMutation();
  const [reqGetTaskView] = useGetTaskViewMutation();
  const [reqPostUpdateStatus, resPostUpdateStatus] =
    usePostUpdateStatusMutation();

  const [rowId, setRowId] = useState(inboxData?.rowId);
  const [totalInboxList, setTotalInboxList] = useState(inboxList.length);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        take: inboxPgBtn.take,
        query: '',
        page: inboxData.page,
      };
      dispatch(
        updateOutreach({
          inboxPgBtn: params,
        })
      );
    }
  };

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(inboxList.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...inboxPgBtn,
      page: page,
      take: inboxPgBtn.take,
      query: inboxPgBtn.query,
    };
    dispatch(
      updateInbox({
        inboxPgBtn: newParam,
      })
    );
    reqPostTaskList(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...inboxPgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      updateInbox({
        inboxPgBtn: newParam,
      })
    );
    reqPostTaskList(newParam);
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < inboxList.length) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === inboxList.length) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, inboxList]);

  const handleBulkDoneInbox = () => {
    const payload = {
      status: active === 0 ? '2' : '1',
      ids: isCheck,
    };
    reqPostUpdateStatus(payload);
  };

  useEffect(() => {
    setTotalInboxList(inboxList.length);
  }, [inboxList]);

  useEffect(() => {
    if (inboxData?.rowId) {
      setRowId(inboxData?.rowId);
    }
  }, [inboxData?.rowId]);

  useEffect(() => {
    if (resPostUpdateStatus.isSuccess) {
      reqPostTaskList(inboxPgBtn);

      toast({
        title: 'Task status updated.',
        description: "We've updated your task status.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostUpdateStatus.isSuccess]);

  return {
    rowId,
    totalInboxList,
    inboxList,
    inboxData,
    inboxPage,
    inboxPgBtn,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    clearSearch,
    handleBulkDoneInbox,
    pageChange,
    entryChange,
    reqPostTaskList,
    resPostTaskList,
    reqGetTaskView,
  };
};
