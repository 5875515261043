import { Text, Stack, Button, Flex, Image, Box } from '@chakra-ui/react';
import { TAB_TYPE } from '@/modules/Engage/constant';

import CommandDefault from '@/assets/images/engage/MdCommandDefault.svg';
import CommandActive from '@/assets/images/engage/MdCommandActive.svg';
import SequenceDefault from '@/assets/images/engage/MdSequenceDefault.svg';
import SequenceActive from '@/assets/images/engage/MdSequenceActive.svg';
import SmsDefault from '@/assets/images/engage/MdSmsDefault.svg';
import SmsActive from '@/assets/images/engage/MdSmsActive.svg';
import SettingDefault from '@/assets/images/engage/MdSettingDefault.svg';
import SettingActive from '@/assets/images/engage/MdSettingActive.svg';

import Highlight from '@/assets/images/inboxes/MdHighlight.svg';

import { useLocation, useNavigate, useParams } from 'react-router';
import { SearchBar } from '@/components/app/Global/SearchBar';

import { useSideBarServices } from './useSideBarServices';
import { useMemo } from 'react';
import { CANDIDATE_FIL, CANDIDATE_STAT, FILTER_STAT } from './constant';
import { CheckboxLayout } from '@/components/app/Global/Checkbox';

const EngageSideBar = ({ tab, setTab }: any) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    search,
    setSearch,
    handleSearch,
    searchCandidate,
    setSearchCandidate,
    handleSearchCandidate,
    searchSMSHistory,
    setSearchSMSHistory,
    handleSearchSMSHistory,
    searchEmailTemplate,
    setSearchEmailTemplate,
    handleSearchEmailTemplate,
    searchEmailConnection,
    setSearchEmailConnection,
    handleSearchEmailConnection,
    setStatusEnrichPayload,
    setStatusCandidatePayload,
    setStatusSequencePayload,
  } = useSideBarServices();

  const SideBarItem = [
    {
      id: 0,
      label: 'Command Center',
      image: CommandDefault,
      imageActive: CommandActive,
      to: 'command-center',
    },
    {
      id: 1,
      label: 'Sequences',
      image: SequenceDefault,
      imageActive: SequenceActive,
      to: 'sequences',
    },
    {
      id: 2,
      label: 'SMS',
      image: SmsDefault,
      imageActive: SmsActive,
      to: 'sms',
    },
    {
      id: 3,
      label: 'Settings',
      image: SettingDefault,
      imageActive: SettingActive,
      to: 'settings',
    },
  ];

  const handleFilters = useMemo(() => {
    if (
      params.settingNav === 'sequences' &&
      params.settingType === 'candidates'
    ) {
      return (
        <>
          <Text
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>

          <SearchBar
            search={searchCandidate}
            setSearch={setSearchCandidate}
            placeholder="Search Recipients"
            handleOnChange={handleSearchCandidate}
          />

          <Stack
            spacing="10px"
            sx={{
              mt: '16px',
              bgColor: '#fff',
              borderRadius: '10px',
            }}
          >
            {params?.settingSubType === 'all' && (
              <>
                <Text
                  color="#718096"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19.1px"
                >
                  Status
                </Text>

                <Stack
                  spacing="10px"
                  sx={{
                    label: {
                      fontWeight: 400,
                    },
                  }}
                >
                  <CheckboxLayout
                    data={CANDIDATE_STAT}
                    setStatusPayload={setStatusCandidatePayload}
                  />
                </Stack>
              </>
            )}

            <Text
              color="#718096"
              fontSize="14px"
              fontWeight="600"
              lineHeight="19.1px"
            >
              Enriched
            </Text>

            <Stack
              spacing="10px"
              sx={{
                label: {
                  fontWeight: 400,
                },
              }}
            >
              <CheckboxLayout
                data={CANDIDATE_FIL}
                setStatusPayload={setStatusEnrichPayload}
              />
            </Stack>
          </Stack>
        </>
      );
    } else if (location.pathname === '/engage/sequences/recruitment') {
      return (
        <>
          <Text
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>

          <SearchBar
            search={search}
            setSearch={setSearch}
            placeholder="Search job, client, recruiters..."
            handleOnChange={handleSearch}
          />

          <Stack
            spacing="10px"
            sx={{
              mt: '16px',
              bgColor: '#fff',
              borderRadius: '10px',
            }}
          >
            <Text
              color="#718096"
              fontSize="14px"
              fontWeight="600"
              lineHeight="19.1px"
            >
              Status
            </Text>

            <CheckboxLayout
              data={FILTER_STAT}
              setStatusPayload={setStatusSequencePayload}
            />
          </Stack>
        </>
      );
    } else if (params.settingType === 'sms-history') {
      return (
        <>
          <Text
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>

          <SearchBar
            search={searchSMSHistory}
            setSearch={setSearchSMSHistory}
            placeholder="Search recipients"
            handleOnChange={handleSearchSMSHistory}
          />
        </>
      );
    } else if (params.settingType === 'email-template') {
      return (
        <>
          <Text
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>

          <SearchBar
            search={searchEmailTemplate}
            setSearch={setSearchEmailTemplate}
            placeholder="Search template title"
            handleOnChange={handleSearchEmailTemplate}
          />
        </>
      );
    } else if (params.settingType === 'email-connection') {
      return (
        <>
          <Text
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>

          <SearchBar
            search={searchEmailConnection}
            setSearch={setSearchEmailConnection}
            placeholder="Search by name"
            handleOnChange={handleSearchEmailConnection}
          />
        </>
      );
    }
  }, [
    params,
    searchCandidate,
    search,
    searchSMSHistory,
    searchEmailTemplate,
    searchEmailConnection,
  ]);

  return (
    <Stack
      spacing="9px"
      sx={{
        borderRadius: '10px',
        width: '20%',
        height: '100vh',
        bgColor: '#F9F9FB',
        p: '24px 12px',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px 8px',
        }}
      >
        <Flex w="100%" bg="#FAF6FF" p="4px" borderRadius="10px" color="#718096">
          <Button
            variant="ghost"
            sx={{
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 700,
              py: tab === TAB_TYPE.recruitment && '14px',
              borderRadius: tab === TAB_TYPE.recruitment && '10px',
              bg: tab === TAB_TYPE.recruitment ? '#FFF' : '#FAF6FF',
              color: tab === TAB_TYPE.recruitment && 'primary.800',
            }}
            _focus={{ border: 'none' }}
            w="100%"
            onClick={() => setTab(TAB_TYPE.recruitment)}
          >
            Recruitment
          </Button>
          <Button
            variant="ghost"
            sx={{
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 700,
              py: tab === TAB_TYPE.business && '14px',
              borderRadius: tab === TAB_TYPE.business && '10px',
              bg: tab === TAB_TYPE.business ? '#FFF' : '#FAF6FF',
              color: tab === TAB_TYPE.business && 'primary.800',
            }}
            _focus={{ border: 'none' }}
            w="100%"
            onClick={() => setTab(TAB_TYPE.business)}
          >
            BD
          </Button>
        </Flex>
      </Stack>

      <Stack
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
        }}
      >
        <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
          {SideBarItem.map((val) => {
            const isActive = params.settingNav === val.to;
            return (
              <Flex
                gap="10px"
                sx={{
                  alignItems: 'center',
                  p: '4px 8px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  background: isActive && '#FAF6FF',
                  color: isActive ? '#6930CA' : '#2B2D42',
                }}
                _hover={{
                  borderRadius: '4px',
                  background: '#FAF6FF',
                  color: '#6930CA',
                  p: {
                    color: '#6930CA',
                  },
                  svg: {
                    fill: '#6930CA',
                  },
                }}
                onClick={() => {
                  if (val.to === 'sms') {
                    navigate(
                      `/engage/${val.to}/${params.settingTab}/sms-automation`
                    );
                  } else if (val.to === 'settings') {
                    navigate(
                      `/engage/${val.to}/${params.settingTab}/default-schedule`
                    );
                  } else {
                    navigate(`/engage/${val.to}/${params.settingTab}`);
                  }
                }}
              >
                {isActive && (
                  <Image
                    src={Highlight}
                    height="35px"
                    width="4px"
                    sx={{ position: 'absolute', left: 0 }}
                  />
                )}
                <Image
                  src={isActive ? val.imageActive : val.image}
                  height="24px"
                  width="24px"
                />
                <Text sx={{ fontSize: '16px', fontWeight: 400 }}>
                  {val.label}
                </Text>
              </Flex>
            );
          })}
        </Stack>
      </Stack>

      <Box
        sx={{
          bgColor: handleFilters && '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
      >
        {handleFilters}
      </Box>
    </Stack>
  );
};
export default EngageSideBar;
