import { Formik } from 'formik';
import moment from 'moment';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Input,
  Link,
  Image,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Menu,
  MenuButton,
} from '@chakra-ui/react';

import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';

import ScrollToFieldError from '@/components/app/ScrollError';

import GoTo from '@/assets/images/goto.svg';

import FAIcon from '@/components/lib/FAIcon';
import { tableHeader } from './constants';

import { useAttachmentsServices } from './useAttachmentsServices';
import { LeadsAddAttachmentModal } from './Add';
import { StreamAttachmentModal } from './Stream';

export const LeadsAttachments = () => {
  const {
    initialValue,
    onSubmit,
    clearSearch,
    LAlist,
    onStreamOpen,
    setFileData,
    delAttachment,
    LAPage,
    pageChange,
    entryChange,
    LAPgBtn,
    onAddAttachmentOpen,
    isAddAttachmentOpen,
    onAddAttachmentClose,
    isStreamOpen,
    onStreamClose,
    fileData,
  } = useAttachmentsServices();
  return (
    <Box>
      <Flex justifyContent="space-between" py={6}>
        <Box pr={2}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                    placeholder="Search File Name"
                  />
                </InputGroup>
              </form>
            )}
          </Formik>
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={onAddAttachmentOpen}>
          Add Attachments
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {LAlist.length > 0 &&
                LAlist.map((attachments: any) => (
                  <Tr key={attachments.id} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Flex alignItems="center" color="primary.800">
                        <Link
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                          onClick={() => {
                            onStreamOpen();
                            setFileData(attachments);
                          }}
                        >
                          {attachments.original_file_name}
                          <span>
                            <Image src={GoTo} alt="logo" />
                          </span>
                        </Link>
                      </Flex>
                    </Td>
                    <Td>
                      {attachments.user?.first_name +
                        ' ' +
                        attachments.user?.last_name}
                    </Td>
                    <Td>
                      {moment
                        .utc(attachments.created_at)
                        .format('MM/DD/YYYY hh:mm A')}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          onClick={() => delAttachment(attachments.id)}
                        >
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="trash"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          totalPages={LAPage.lastPage}
          currentPage={LAPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={LAPage.count}
          currentCount={LAlist?.length}
          targetCount={LAPgBtn.take}
        />
        {isAddAttachmentOpen && (
          <LeadsAddAttachmentModal
            isOpen={isAddAttachmentOpen}
            onClose={onAddAttachmentClose}
          />
        )}

        {isStreamOpen && (
          <StreamAttachmentModal
            isOpen={isStreamOpen}
            onClose={onStreamClose}
            file={fileData}
          />
        )}
      </Box>
    </Box>
  );
};
