import { createSlice } from '@reduxjs/toolkit';
import authService from '@/services//auth.service';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const candidateResumetSlice = createSlice({
  name: 'candidateResume',
  initialState: {
    resumes: [],
    latest: [],
    candidateResPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidateResPgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    getResume: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getResume } = candidateResumetSlice.actions;
export default candidateResumetSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const candidateResumeApiSplice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    DownloadCandidateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-resume/download/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
        }
      },
    }),

    ListResumeCandidate: builder.mutation({
      query: ({ data, id, signal }) => ({
        url: `/candidate-resume/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getResume({ resumes: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateResumePrimaryStatus: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-resume/set-primary/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    LatestResumeCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-resume/latest/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getResume({ latest: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    DelCandidateResume: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-resume/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: [],
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    UnparsedResumeByJob: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-resume/unparsed/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        // body: [],
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('data', data);
          dispatch(getResume({ resumes: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useDownloadCandidateAttachmentMutation,
  useLatestResumeCandidateMutation,
  useUpdateResumePrimaryStatusMutation,
  useListResumeCandidateMutation,
  useDelCandidateResumeMutation,
  useUnparsedResumeByJobMutation,
} = candidateResumeApiSplice;
