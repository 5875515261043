import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TrLoading from '@/components/app/TrLoading';
import TeamTR from './TeamTr';
import { TeamDataInt, TeamsTableProps } from './types';
import useTableTeamsServices from './useTableTeamsServices';

export default function TeamsTable({
  handleEdit,
  teams,
  handleViewTeam,
  handleDelete,
  resTeams,
}: TeamsTableProps) {
  const { tableHeaders } = useTableTeamsServices({ resTeams, teams });
  return (
    <Box>
      <TableContainer
        boxSizing="border-box"
        borderX="1px solid"
        borderTop="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr>
              {tableHeaders.map((header: string, index: number) => {
                return (
                  <Th key={index}>
                    <Box color="default.gray.600">{header}</Box>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {resTeams.isLoading ? (
              <TrLoading rows={5} columns={tableHeaders.length} />
            ) : (
              resTeams.isSuccess &&
              teams?.map((team: TeamDataInt, key: number) => {
                return (
                  <TeamTR
                    team={team}
                    index={key}
                    handleViewTeam={handleViewTeam}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
