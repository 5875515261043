import apiSlice from '@/store/api.slice';
import { JobByIdData } from './job.type';
import { ApiResponse } from '@/services//type';

export const jobsQuery = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchJobById: builder.query<
      JobByIdData | null,
      { id: number; signal?: AbortSignal }
    >({
      query: ({ id, signal }) => {
        return {
          url: `/jobs/view/${id}`,
          method: 'GET',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          signal,
        };
      },
      providesTags: (result, error, { id }) =>
        result && error
          ? [
              {
                type: 'Job-Detail',
                id,
              },
              { type: 'Job-Detail', id: 'List' },
            ]
          : [],
      transformResponse: (response: ApiResponse<JobByIdData>) => {
        return response.success ? response.data : null;
      },
    }),
  }),
});

export const { useFetchJobByIdQuery } = jobsQuery;
