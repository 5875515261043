/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import MdError from '@/assets/images/engage/MdError1.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  usePostSMSAutomationDeleteMutation,
  usePostSMSAutomationListMutation,
} from '@/store/outreach.slice';

interface ConfirmationModalProps {
  id: any;
  isOpen: any;
  onClose: any;
}

export default function ConfirmationModal({
  id,
  isOpen,
  onClose,
}: ConfirmationModalProps) {
  const toast = useToast();

  const [reqPostSMSAutomationList] = usePostSMSAutomationListMutation();
  const [reqPostSMSAutomationDelete, resPostSMSAutomationDelete] =
    usePostSMSAutomationDeleteMutation();

  const { smsAutomationPgBtn } = useSelector((state: any) => state.outreach);

  const handleSubmit = () => {
    reqPostSMSAutomationDelete(id);
  };

  useEffect(() => {
    if (resPostSMSAutomationDelete.isSuccess) {
      reqPostSMSAutomationList(smsAutomationPgBtn);
      onClose();

      toast({
        title: 'SMS Automation deleted.',
        description: "We've deleted your SMS Automation.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostSMSAutomationDelete.isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalBody borderRadius="5px" p="16px 24px">
          <Stack m="16px auto" textAlign="center" w="400px">
            <Image src={MdError} width="100px" height="100px" m="0 auto" />
            <Text sx={{ fontSize: '20px', fontWeight: 600, color: '#2B2D42' }}>
              You are about to{' '}
              <Text as="span" sx={{ color: '#EC7272' }}>
                delete an automation.
              </Text>
            </Text>
            <Text sx={{ fontSize: '16px', fontWeight: 300, color: '#2B2D42' }}>
              Are you sure you want to continue?
            </Text>
          </Stack>
          <Flex gap="10px" mt="40px">
            <Button
              onClick={() => onClose()}
              w="100%"
              sx={{
                borderColor: '#EC7272',
                color: '#EC7272',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#EC7272',
                color: '#EC7272',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={handleSubmit}
              w="100%"
              sx={{
                borderColor: '#EC7272',
                bgColor: '#EC7272',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#EC7272',
                bgColor: '#EC7272',
              }}
            >
              Yes
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
