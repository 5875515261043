import parse from 'html-react-parser';

import { Box, Image } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import Loading from '@/assets/images/talie/loading.gif';

const TypingEffect = ({ text }: any) => {
  const speed = 15;
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (text !== displayedText) {
      setIsComplete(false);
    }
    if (index < text.length) {
      const typeTimeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, speed);

      return () => clearTimeout(typeTimeout);
    } else if (index === text.length) {
      setIsComplete(true);
    }
  }, [index, text, displayedText, speed]);

  useEffect(() => {
    if (text !== displayedText) {
      setDisplayedText('');
      setIndex(0);
      setIsComplete(false);
    }
  }, [text]);

  return (
    <Box
      sx={{
        '& img': {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      }}
    >
      {parse(displayedText)}{' '}
      {!isComplete && (
        <span style={{ display: 'inline-block' }}>
          <Image src={Loading} height="12px" width="12px" />
        </span>
      )}
    </Box>
  );
};

export default TypingEffect;
