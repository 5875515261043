import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import moment from 'moment';
import parse from 'html-react-parser';
import { SuppressionModalProps } from '../types';
import { useSuppressionTableServices } from '../Table/useSuppressionTableServices';
import FAIcon from '@/components/lib/FAIcon';
import { useDispatch } from 'react-redux';
import { updateOutreach } from '@/store/outreach.slice';
import { useNavigate } from 'react-router';

export const SuppressionDrawer = ({
  isOpen,
  onOpen,
  onClose,
}: SuppressionModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    rowId,
    suppressionData,
    totalSuppressionList,
    handlePrevData,
    handleNextData,
  } = useSuppressionTableServices();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/engage/settings/recruitment/suppression-list');
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B' }}>
            Showing <b>{rowId}</b> of {totalSuppressionList} entries
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() =>
                  rowId !== totalSuppressionList && handleNextData()
                }
                disabled={rowId === totalSuppressionList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="24px 36px">
          <Stack>
            <Stack spacing="16px" mb="32px">
              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Company Name
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {suppressionData?.company}
                  </Text>

                  <Menu closeOnSelect={false}>
                    <MenuButton>
                      <Button
                        htmlType="menu"
                        variant="ghost"
                        iconName="ellipsis-h"
                        iconSize="xl"
                      />
                    </MenuButton>
                    <MenuList
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight="600"
                    >
                      <MenuItem
                        icon={<FAIcon iconName="pencil" />}
                        onClick={() => {
                          dispatch(
                            updateOutreach({
                              suppressionData: suppressionData,
                            })
                          );
                          onOpen();
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<FAIcon iconName="trash" />}
                        onClick={() => {}}
                        sx={{ color: '#CB2C2C' }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Suppression Type
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {suppressionData.type}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Email / Domain
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {suppressionData?.domain}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Date Created
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {moment(suppressionData?.date_created).format(
                    'MMMM DD, YYYY'
                  )}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Expiration Date
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#2B2D42',
                  }}
                >
                  {suppressionData?.duration}
                </Text>
              </Stack>
            </Stack>

            <Stack spacing="16px">
              <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>
                Description
              </Text>
              <Box
                sx={{
                  py: '8px',
                  fontWeight: 300,
                }}
              >
                {parse(
                  suppressionData.description || 'No description available'
                )}
              </Box>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
