import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Icon,
  Stack,
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import PdfEditor from './pdfEditor';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import AtsSelect from '@/components/app/AtsSelect';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface RedactResumeProps {
  isOpen: boolean;
  onClose: () => void;
  selectedVersion: any;
  candidate: any;
}

const RedactResume: React.FC<RedactResumeProps> = ({
  isOpen,
  onClose,
  selectedVersion,
  candidate,
}) => {
  const pdfEditorRef = useRef<any>(null);
  const [isOpenState, setIsOpen] = useState(true); // To control modal visibility
  const { hasSwitchedAccount } = useSelector((state: any) => state.app);

  const [watermark, setWatermark] = useState(true);
  const [header, setHeader] = useState(true);

  const onCloseState = () => {
    setIsOpen(false); // Close the modal when called
  };
  const handleApplyClick = () => {
    if (pdfEditorRef.current) {
      // Call the handleApply method from the PdfEditor component
      pdfEditorRef.current.changeAnnotationColor();
    }
  };

  const saveResume = () => {
    if (pdfEditorRef.current) {
      // Call the handleApply method from the PdfEditor component
      pdfEditorRef.current.downloadPdf();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent background="#F9F9FB" h="100vh">
        <ModalHeader fontSize="medium">Redact Resume</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0px 16px">
          <Grid
            gridTemplateColumns="minmax(250px,400px) 1fr"
            columnGap="10px"
            height="100%"
            pt="8px"
          >
            <GridItem colSpan={1}>
              {/* Accordion with both sections open by default */}
              <Accordion allowMultiple defaultIndex={[0, 1]}>
                {/* Header Template */}
                <AccordionItem
                  bgColor="#fff"
                  border={0}
                  p="16px"
                  borderRadius="10px"
                  mb="4px"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        _expanded={{ color: 'primary.800', pb: '0px' }}
                        _focus={{ border: 'none' }}
                        fontWeight="600"
                        _hover={{ bg: 'none' }}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Text
                            fontSize="medium"
                            fontWeight={600}
                            color="#2B2D42"
                          >
                            Templates
                          </Text>
                          <Box>
                            {isExpanded ? (
                              <Icon
                                as={FaAngleDown}
                                w="16px"
                                h="16px"
                                color="#6930CA"
                              />
                            ) : (
                              <Icon
                                as={FaAngleUp}
                                w="16px"
                                h="16px"
                                color="#6930CA"
                              />
                            )}
                          </Box>
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pt="16px">
                        <Stack columnGap="12px">
                          <Stack columnGap="8px">
                            <Text fontSize="14px" color="#2B2D42">
                              Header Template
                            </Text>
                            <AtsSelect
                              defaultValue={{
                                label: 'Talently Logo ',
                                value: '1',
                              }} // Pass the full option object
                              placeholder="Select Header Template"
                              options={[
                                { label: 'Talently Logo ', value: '1' },
                              ]}
                              isClearable
                              onChange={(data: any) => {
                                if (data == null) {
                                  setHeader(false);
                                }
                              }}
                            />
                          </Stack>
                          <Stack columnGap="8px">
                            <Text fontSize="14px" color="#2B2D42">
                              Watermark Template
                            </Text>
                            <AtsSelect
                              defaultValue={{
                                label: 'Talently Logo ',
                                value: '1',
                              }} // Pass the full option object
                              placeholder="Select Header Template"
                              options={[
                                { label: 'Talently Logo ', value: '1' },
                              ]}
                              isClearable
                              onChange={(data: any) => {
                                if (data == null) {
                                  setWatermark(false);
                                }
                              }}
                            />
                          </Stack>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                {/* Text Redaction */}
                <AccordionItem
                  bgColor="#fff"
                  border={0}
                  p="16px"
                  borderRadius="10px"
                  mt="4px"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        _expanded={{ color: 'primary.800', pb: '0px' }}
                        _focus={{ border: 'none' }}
                        fontWeight="600"
                        _hover={{ bg: 'none' }}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Text
                            fontSize="medium"
                            fontWeight={600}
                            color="#2B2D42"
                          >
                            Text Redaction
                          </Text>
                          <Box>
                            {isExpanded ? (
                              <Icon
                                as={FaAngleDown}
                                w="16px"
                                h="16px"
                                color="#6930CA"
                              />
                            ) : (
                              <Icon
                                as={FaAngleUp}
                                w="16px"
                                h="16px"
                                color="#6930CA"
                              />
                            )}
                          </Box>
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pt="16px">
                        <Stack columnGap="12px">
                          <Text fontSize="14px">
                            Once you've finished creating redaction annotations,
                            apply the redaction to permanently remove the
                            redacted content from the document.
                          </Text>
                          <Button variant="solid" onClick={handleApplyClick}>
                            Apply Redaction
                          </Button>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} bgColor="#fff">
              <Box
                height="100%"
                px="8px"
                pt="8px"
                sx={{ mx: 'auto', minWidth: '400px', maxWidth: '800px' }}
              >
                <PdfEditor
                  selectedVersion={selectedVersion}
                  ref={pdfEditorRef}
                  candidate={candidate}
                  onClose={onClose}
                  watermark={watermark}
                  header={header}
                />
              </Box>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter bgColor="#fff" px="16px" py="9px">
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="purple" onClick={saveResume}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RedactResume;
