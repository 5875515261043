import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Text,
  Link,
  Button,
  Badge,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import moment from 'moment';
import { boolean } from 'yup';

interface props {
  interview: any;
  cancelInterView: (interview: any) => void;
  editInterview: (interview: any) => void;
}

const InterviewAccordion = ({
  interview,
  cancelInterView,
  editInterview,
}: props) => {
  const status = {
    1: {
      scheme: 'purple',
      color: '#6930CA',
      label: 'Scheduled',
    },
    2: {
      scheme: 'green',
      color: '#38B17A',
      label: 'Completed',
    },
  } as any;

  const InterviewPanels = () => {
    return (
      <>
        {interview?.panels.map((panel: any) => {
          return (
            <Badge
              sx={{
                p: '4px 8px',
                textTransform: 'none',
                fontSize: '14px',
                borderRadius: '2px',
                color: '#2B2D42',
              }}
            >
              {panel?.email}
            </Badge>
          );
        })}
      </>
    );
  };

  return (
    <AccordionItem
      style={{ border: '1px solid #E7E9ED' }}
      borderRadius="md"
      _expanded={{
        borderColor: '#9B9B9B',
      }}
    >
      <h2>
        <AccordionButton
          sx={{ p: '10px 8px' }}
          _expanded={{
            bgColor: '#F9F9FB',
            borderBottom: '0.5px solid #9B9B9B',
          }}
        >
          <Box flex="1" textAlign="left">
            <Flex justify="flex-start" gap="8px">
              <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>
                {interview?.meeting_type} # {interview?.counter}
              </Text>

              <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>
                {moment.utc(interview?.meeting_date).format('MM/DD/YYYY')}{' '}
                {moment
                  .utc(interview?.meeting_start, 'HH:mm:ss')
                  .format('hh:mmA')}{' '}
                -{' '}
                {moment
                  .utc(interview?.meeting_end, 'HH:mm:ss')
                  .format('hh:mmA')}
              </Text>
            </Flex>
          </Box>
          <Badge
            ml="8px"
            mr="12px"
            colorScheme={status[interview?.status]?.scheme || 'green'}
            sx={{
              textTransform: 'capitalize',
              p: '4px 8px',
              fontSize: '14px',
              borderRadius: '2px',
              color: status[interview?.status]?.color,
            }}
          >
            {status[interview?.status]?.label || 'Undefined'}
          </Badge>
          <AccordionIcon color="#9B9B9B" />
        </AccordionButton>
      </h2>
      <AccordionPanel p="12px">
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing="10px"
          align="stretch"
        >
          <Flex justify="flex-start" gap="8px" pt="8px">
            <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>
              Interview Name / Type:{' '}
            </Text>
            <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>
              {interview?.subject}
            </Text>
          </Flex>
          <Flex justify="flex-start" gap="8px" pt="8px" alignItems="center">
            <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>Job: </Text>
            <Text>
              <Link
                href={`/jobs/${interview?.job_id}/details`}
                sx={{ fontSize: '14px' }}
              >
                {[interview?.job?.title, interview?.job?.client?.name]
                  .filter(boolean)
                  .join(' - ')}
              </Link>
            </Text>
          </Flex>
          <Flex justify="flex-start" gap="8px" pt="8px" alignItems="center">
            <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>Panels: </Text>
            <Text>
              <InterviewPanels />
            </Text>
          </Flex>
          <Flex justify="flex-start" gap="8px" pt="8px" alignItems="center">
            <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>
              Meeting Link:{' '}
            </Text>
            <Text>
              <Link
                color="teal.500"
                href={`${interview?.teams_meating_url}`}
                isExternal
              >
                <FAIcon iconName="link" />
                {interview?.meeting_type}
              </Link>
            </Text>
          </Flex>

          <Flex justifyContent="flex-end" mt="2px">
            <Button
              variant="delete"
              mr={3}
              onClick={() => cancelInterView(interview)}
            >
              Cancel Interview
            </Button>
            <Button colorScheme="blue" onClick={() => editInterview(interview)}>
              Edit
            </Button>
          </Flex>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default InterviewAccordion;
