import { Flex, Image, Text, Th, Thead, Tr } from '@chakra-ui/react';
import MdFilter from '@/assets/images/MdFilter.svg';

interface TableHeaderProps {
  handleFilter: (e: string) => void;
}

export const TableHeader = ({ handleFilter }: TableHeaderProps) => {
  const sequenceTableHeader = [
    {
      content: '',
      sx: {
        width: '50px',
      },
    },
    {
      content: (
        <Flex gap="10px">
          <Text
            sx={{
              fontSize: '10px',
              color: '#9B9B9B',
              letterSpacing: '1.5px',
            }}
          >
            Job Title
          </Text>

          <Image
            height="14px"
            width="30px"
            src={MdFilter}
            onClick={() => handleFilter('jobs.title')}
            sx={{ cursor: 'pointer' }}
          />
        </Flex>
      ),
      sx: {
        width: '50px',
      },
    },
    {
      content: (
        <Flex gap="10px">
          <Text
            sx={{
              fontSize: '10px',
              color: '#9B9B9B',
              letterSpacing: '1.5px',
            }}
          >
            Client Name
          </Text>
          <Image
            height="14px"
            width="30px"
            src={MdFilter}
            onClick={() => handleFilter('client.name')}
            sx={{ cursor: 'pointer' }}
          />
        </Flex>
      ),
      sx: {
        width: '50px',
      },
    },
    {
      content: 'Recruiters',
      sx: {
        width: '50px',
        fontSize: '10px',
        color: '#9B9B9B',
        letterSpacing: '1.5px',
      },
    },
    {
      content: (
        <Flex gap="10px">
          <Text
            sx={{
              fontSize: '10px',
              color: '#9B9B9B',
              letterSpacing: '1.5px',
            }}
          >
            Sequences
          </Text>
          <Image
            height="14px"
            width="30px"
            src={MdFilter}
            onClick={() => handleFilter('outreachsequence.title')}
            sx={{ cursor: 'pointer' }}
          />
        </Flex>
      ),
      sx: {
        width: '50px',
      },
    },
    {
      content: (
        <Flex gap="10px">
          <Text
            sx={{
              fontSize: '10px',
              color: '#9B9B9B',
              letterSpacing: '1.5px',
            }}
          >
            Candidates
          </Text>
          <Image
            height="14px"
            width="30px"
            src={MdFilter}
            onClick={() => handleFilter('jobs.job_outreach_candidate_count')}
            sx={{ cursor: 'pointer' }}
          />
        </Flex>
      ),
      sx: {
        width: '50px',
      },
    },
    {
      content: '',
      sx: {
        width: '50px',
      },
    },
  ];
  return (
    <Thead>
      <Tr bg="default.white.600">
        {sequenceTableHeader.map((val, index) => (
          <Th key={index} sx={val.sx}>
            {val.content}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};
