import { placeholderUrl } from '../utils/util';
import { CANDIDATE_PLACEHOLDER_PAIR } from '../constants/defaults';
import { useLazyFetchPlaceholderPairQuery } from '@/services//common/constants.query';
import { CAREER_PAGE_URL } from '@/constants/values';
import { IntialValueType } from '../utils/validationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { setPairTemplate } from '@/store/template.slice';
import { debounce } from 'lodash';
import { replacePlaceholders } from '@/utils/placeholders';

export default function usePlaceholderPair(jobId?: number) {
  const dispatch = useDispatch();
  const [reqPlaceholderPair, { data: pairPlaceholderData }] =
    useLazyFetchPlaceholderPairQuery();
  const placeholderPair = useSelector(
    (state: any) => state.template.placehoderPair
  );

  //fetch placeholder pair on - Contact change, JobId edit/duplicate
  function fetchPlaceholderPair(
    contactId: number,
    formValues: IntialValueType
  ) {
    const params: Record<string, number> = { contact_id: contactId };
    if (jobId) {
      params.job_id = jobId;
    }
    const existingPair = extractPlaceholderPair(formValues); //extract form values into key-pair
    reqPlaceholderPair(params)
      .unwrap()
      .then((data) => {
        dispatch(
          setPairTemplate({ placehoderPair: { ...data, ...existingPair } })
        );
      });
  }

  //submitted form values, update redux
  const updatePlaceholderPair = debounce((formValues: IntialValueType) => {
    let formPlaceholderPair = extractPlaceholderPair(formValues);
    if (
      formValues?.client_job_description?.includes('{{') &&
      formValues?.client_job_description?.includes('}}')
    ) {
      formPlaceholderPair['internal_job_description'] = replacePlaceholders(
        formPlaceholderPair['internal_job_description'],
        formPlaceholderPair
      ); //replace any placeholder with value for client job description
    }
    dispatch(
      setPairTemplate({
        placehoderPair: { ...pairPlaceholderData, ...formPlaceholderPair },
      })
    );
  }, 500);

  return {
    fetchPlaceholderPair,
    updatePlaceholderPair,
    replacePlaceholder: (text: string) =>
      replacePlaceholders(text, placeholderPair),
  };
}

function extractPlaceholderPair(
  values: IntialValueType
): Record<string, string> {
  return {
    ...CANDIDATE_PLACEHOLDER_PAIR,
    client_job_title: values?.client_job_title ?? '',
    job_location: values?.location?.label ?? '',
    internal_job_description: values?.client_job_description ?? '',
    job_contact_name: values?.contact?.label ?? '',
    job_contact_first_name: values?.contact?.label?.split(' ')?.[0] ?? '',
    job_contact_title: values?.contact?.title ?? '',
    job_company_name: values?.client?.label ?? '',
    job_primary_recruiter_name: values?.primary_recruiter?.label ?? '',
    job_url: placeholderUrl(
      `${CAREER_PAGE_URL}/job-details/`,
      `${CAREER_PAGE_URL}/job-details/`
    ),
    job_name_url: placeholderUrl(
      `${CAREER_PAGE_URL}/job-details/`,
      `${values?.posting_title}`
    ),
    job_ad_description: values?.job_description ?? '',
    job_posting_title: values?.posting_title ?? '',
    job_company_website: values?.company_website ?? '',
  };
}
