import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import AtsSelect from '@/components/app/AtsSelect';
import { useEffect, useState } from 'react';
import '../../../../assets/css/autocomplete.css';
import Button from '@/Library/Button';

interface jobInterface {
  jobs: any;
  isOpen: any;
  onClose: any;
  onSelect: any;
  type: string;
}

const CandidateJobList = ({
  jobs,
  isOpen,
  onClose,
  onSelect,
  type,
}: jobInterface) => {
  const { isOpen: isOpenAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: false,
  });
  const [options, setOptions] = useState([]);
  const [minHeight, setMinHeight] = useState('10vh');
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (isOpen) {
      let data = [] as any;
      jobs?.map((item: any) => {
        data.push({
          value: item?.job?.job_id || item?.job_id,
          label: item?.job?.title || item?.title,
          serial: item?.job?.serial_number,
          assoc: item?.job?.assoc_id || item?.id,
          target: item?.job?.sub?.target,
        });
      });
      setOptions(data);
      console.log({ jobs });
    }
  }, [isOpen]);

  const onFocusSelect = () => {
    setMinHeight('45vh');
  };

  const onBlurSelect = () => {
    setMinHeight('10vh');
  };

  const selectJob = () => {
    onSelect({
      id: Number(selected?.value),
      type,
      assoc_id: Number(selected?.assoc),
      reject: selected?.target,
    });
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Select a Job from the list
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{''}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Box
              overflow="automatic"
              p="30px"
              background="#fff"
              borderRadius="8px"
              minHeight={minHeight}
            >
              <FormControl>
                <AtsSelect
                  placeholder="Select a Job"
                  options={options}
                  onMenuOpen={() => onFocusSelect()}
                  onMenuClose={() => onBlurSelect()}
                  onChange={(e: any) => {
                    setSelected(e);
                  }}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
            gap="16px"
          >
            <Button variant="ghost" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="solid"
              loadingText="Submitting"
              onClick={() => selectJob()}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidateJobList;
