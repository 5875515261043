import apiSlice from '@/store/api.slice';
import { appError } from '@/store/app.slice';
import { getJob } from '@/store/jobs.slice';

export const jobMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createFullJob: builder.mutation<any, any>({
      query: (data) => ({
        url: '/jobs/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const hasPaidJob =
            data?.data?.['jobAds']?.at?.(0)?.paid_job_board_publish;
          dispatch(
            getJob({
              jobData: { ...data.data, ...data.data.data },
              hasPaidJob,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    createQuickJob: builder.mutation<any, any>({
      query: (data) => ({
        url: '/jobs/quick',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const hasPaidJob =
            data?.data?.['jobAds']?.at?.(0)?.paid_job_board_publish;
          dispatch(
            getJob({
              jobData: { ...data.data, ...data.data.data },
              hasPaidJob,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    updateFullJob: builder.mutation<any, { data: any; id: number }>({
      query: ({ data, id }) => ({
        url: `/jobs/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Job-Detail', id: 'List' },
              { type: 'Job-Detail', id },
            ]
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const hasPaidJob =
            data?.data?.['jobAds']?.at?.(0)?.paid_job_board_publish;
          dispatch(
            getJob({
              jobData: { ...data.data, ...data.data.data },
              hasPaidJob,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    updateQuickJob: builder.mutation<any, { data: any; id: number }>({
      query: ({ data, id }) => ({
        url: `/jobs/update-quick/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Job-Detail', id: 'List' },
              { type: 'Job-Detail', id },
            ]
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const hasPaidJob =
            data?.data?.['jobAds']?.at?.(0)?.paid_job_board_publish;
          dispatch(
            getJob({
              jobData: { ...data.data, ...data.data },
              hasPaidJob,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateFullJobMutation,
  useCreateQuickJobMutation,
  useUpdateFullJobMutation,
  useUpdateQuickJobMutation,
} = jobMutation;
