// React and related libraries
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Chakra UI components
import { useDisclosure } from '@chakra-ui/react';

// Custom hooks
import {
  useRejectCandidateMutation,
  useBulkRejectCandidateMutation,
  useListCandidatesMutation,
} from '@/store/candidates.slice';
import { useGetSubStatusMutation } from '@/store/constant.slice';
import { useGetJobsMutation } from '@/store/jobs.slice';

// Utilities
import * as Yup from 'yup';

interface useRejectServicesProps {
  rejectType: string;
  id: number;
  isBulkReject: boolean;
  idList: number[];
  jobsId: number;
  onClose: () => void;
  callback: () => void;
}

export const useRejectServices = ({
  id,
  isBulkReject,
  idList,
  jobsId,
  onClose,
  callback,
  rejectType,
}: useRejectServicesProps) => {
  const initialValues = {
    reason: {
      value: 14,
      label: 'Recruiter Reject',
    },
  };

  const params = useParams();

  // const dispatch = useDispatch();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const [reSub, resSub] = useGetSubStatusMutation();
  const [status, setStatus] = useState([]);
  const [reqReject, resReject] = useRejectCandidateMutation();
  const [reqBulkReject, resBulkReject] = useBulkRejectCandidateMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      reason: Yup.object()
        .shape({
          value: Yup.number().required(),
          label: Yup.string().required(),
        })
        .required('Reason is required'),
    })
  );

  // console.log('params', params.jobsTabId);

  useEffect(() => {
    reSub({ id: 6 });
  }, [id]);

  useEffect(() => {
    const data: any = resSub.data;
    const target =
      (params.jobsTabId || rejectType) == 'hired' ? 'hired' : 'other';
    let options = [] as any;
    if (data?.data) {
      data?.data.map((item: any) => {
        if (target == item.target) {
          options.push({
            value: item.id,
            label: item.sub_status,
          });
        }
      });
    }
    setStatus(options);
  }, [resSub.data, resSub.isSuccess]);

  const handleSubmit = (data: any) => {
    data.reason = data.reason.value;
    if (isBulkReject) {
      data.idList = idList;
      reqBulkReject({ data });
    } else {
      reqReject({ id, data });
    }
  };

  useEffect(() => {
    if (resReject.isSuccess || resBulkReject.isSuccess) {
      let Newparams = {
        ...candidatePgBtn,
        job_id: params?.jobId || jobsId,
      };

      reqCandidates({ data: Newparams });
      reqGetJob({ id: params?.jobId || jobsId });
      onClose();
      if (callback) {
        callback();
      }
    }
  }, [resReject.isSuccess, resBulkReject.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resReject.isError || resBulkReject.isError) {
      onOpenAlert();
    }
  }, [resReject.isError, resBulkReject.isError]);

  return {
    isOpenAlert,
    onCloseAlert,
    initialValues,
    handleSubmit,
    validationSchema,
    isLoading: resReject.isLoading || resBulkReject.isLoading,
    status,
  };
};
