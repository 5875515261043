import {
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AI_LIST, TONE_OPTIONS } from './constants';
import { useDispatch } from 'react-redux';
import { updateAI } from '@/store/ai.slice';

import MdDiamond from '@/assets/images/ai-generator/MdDiamond.svg';
import MdClose from '@/assets/images/ai-generator/MdClose.svg';
import MdMaximize from '@/assets/images/ai-generator/MdMaximize.svg';
import MdMinimize from '@/assets/images/ai-generator/MdMinimize.svg';
import MdGenerate from '@/assets/images/ai-generator/MdGenerate.svg';

interface AIGeneratorRewriteProps {
  hasTone: string;
  setHasTone: any;
  hasPrompt: string;
  setHasPrompt: any;
  setHasAIGenerate: any;
  setIsGenerate: any;
  handleGenerate: () => void;
  isMaximize: boolean;
  setIsMaximize: any;
}

export const AIGeneratorRewrite = ({
  hasTone,
  setHasTone,
  hasPrompt,
  setHasPrompt,
  setHasAIGenerate,
  setIsGenerate,
  handleGenerate,
  isMaximize,
  setIsMaximize,
}: AIGeneratorRewriteProps) => {
  const dispatch = useDispatch();

  return (
    <Stack
      id="ai"
      justifyContent="space-between"
      sx={{
        width: !isMaximize ? '35%' : '100%',
        height: !isMaximize ? '60vh !important' : '70vh',
        bgColor: '#fff',
        borderRadius: '6px',
        m: '8px 16px',
        ml: !isMaximize && 0,
        p: '16px',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack spacing="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}>
            Select a tone
          </Text>
          <Flex gap="10px">
            <Image
              src={!isMaximize ? MdMaximize : MdMinimize}
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsMaximize(!isMaximize)}
            />
            <Image
              src={MdClose}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(updateAI({ aiList: AI_LIST }));
                setHasAIGenerate('');
                setHasTone('');
                setHasPrompt('');
                setIsGenerate(false);
              }}
            />
          </Flex>
        </Flex>
        <Flex gap="8px" mb="16px">
          {TONE_OPTIONS.map((val) => (
            <Button
              variant="skeleton"
              key={val.tone}
              sx={{
                height: 'max-content',
                bgColor: hasTone === val.tone ? '#E9D8FD' : '#FFF',
                border:
                  hasTone === val.tone
                    ? '1px solid #6930CA'
                    : '1px solid #E7EDF4',
                borderRadius: '2px',
                py: '4px',
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 400,
                color: '#718096',
              }}
              _hover={{
                border: '1px solid #6930CA',
              }}
              onClick={() => setHasTone(val.tone)}
            >
              {val.label}
            </Button>
          ))}
        </Flex>
      </Stack>

      <InputGroup>
        <InputLeftElement>
          <Image src={MdDiamond} />
        </InputLeftElement>
        <Input
          value={hasPrompt}
          placeholder="Write a prompt"
          onChange={(e) => {
            setHasPrompt(e.target.value);
            e.preventDefault();
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleGenerate();
            }
          }}
          sx={{
            border: '1px solid #E7EDF4',
            borderRadius: '4px',
            boxShadow: '0px 2px 8px 0px #0000000A',
            color: '#718096',
          }}
          _hover={{
            border: '1px solid #E7EDF4',
            borderRadius: '4px',
            boxShadow: '0px 2px 8px 0px #0000000A',
          }}
          _focus={{
            border: '1px solid #E7EDF4',
            borderRadius: '4px',
            boxShadow: '0px 2px 8px 0px #0000000A',
          }}
        />
        <InputRightElement onClick={handleGenerate}>
          <Image src={MdGenerate} />
        </InputRightElement>
      </InputGroup>
    </Stack>
  );
};
