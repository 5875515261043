import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@/Library/Button';
import { ConnectEmail } from './Modal/ConnectEmail';
import { useEmailConnectionOutlookServices } from './useEmailConnectionOutlookServices';
import EmailTemplateConnectionTable from './Table';
import { LoginEmail } from './Modal/LoginEmail';
import { useEmailConnectionGmailServices } from './useEmailConnectionGmailServices';

const EmailConnection = () => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isOpenSMTP,
    onClose: onCloseSMTP,
    onOpen: onOpenSMTP,
  } = useDisclosure({ defaultIsOpen: false });

  const { handleConnectToOutlook, isLoading: isLoadingOutlook } =
    useEmailConnectionOutlookServices({
      onOpenSMTP,
    });

  const { handleConnectToGmail, isLoading: isLoadingGmail } =
    useEmailConnectionGmailServices({
      onOpenSMTP,
    });

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end" mb="16px" px={1}>
        <Flex gap={5}>
          <Button leftIcon="repeat" variant="outline" onClick={onOpen}>
            Connect New Inbox
          </Button>

          {isOpen && (
            <ConnectEmail
              isOpen={isOpen}
              onClose={onClose}
              handleConnectToOutlook={handleConnectToOutlook}
              handleConnectToGmail={handleConnectToGmail}
              isLoadingOutlook={isLoadingOutlook}
              isLoadingGmail={isLoadingGmail}
            />
          )}

          {isOpenSMTP && (
            <LoginEmail isOpen={isOpenSMTP} onClose={onCloseSMTP} />
          )}
        </Flex>
      </Flex>
      <EmailTemplateConnectionTable />
    </Box>
  );
};

export default EmailConnection;
