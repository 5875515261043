import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import { useNavigate, useParams } from 'react-router';
import { CandidatesTabList } from './CandidateTabList';
import { SEQUENCE_STATUS } from '../constant';
import ChangeStatusModal from '../Modal/ChangeStatus';
import { useCandidatesDrawerServices } from './useCandidatesDrawerServices';

interface CandidatesDrawerProps {
  isOpen?: boolean;
  onClose?: () => void;
  rowId: number;
  sequenceCandidateData: any;
  totalCandidatesList: any;
  handlePrevData: () => void;
  handleNextData: () => void;
}

export const CandidatesDrawer = ({
  isOpen,
  onClose,
  rowId,
  sequenceCandidateData,
  totalCandidatesList,
  handlePrevData,
  handleNextData,
}: CandidatesDrawerProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    candidateIds,
    setCandidateIds,
    isOpenChangeStatusModal,
    onOpenChangeStatusModal,
    onCloseChangeStatusModal,
    handleViewDetails,
  } = useCandidatesDrawerServices();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate(
          `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}`
        );
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B', fontWeight: 700 }}>
            Showing{' '}
            <Box as="span" color="#545454">
              {rowId}
            </Box>{' '}
            of {totalCandidatesList} candidates
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() =>
                  rowId !== totalCandidatesList && handleNextData()
                }
                disabled={rowId === totalCandidatesList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="0">
          <Box
            bg="#F9FAFB"
            borderBottom="1px solid"
            borderColor="default.white.400"
            p="32px"
            pb="60px"
          >
            <Flex justifyContent="space-between" mb="16px">
              <Stack spacing={0} minH="62px" justifyContent="center">
                <Text
                  sx={{
                    mb: '8px',
                    fontSize: '20px',
                    fontWeight: 700,
                  }}
                >
                  {[
                    sequenceCandidateData.first_name,
                    sequenceCandidateData.last_name,
                  ].join(' ')}
                </Text>
                <Flex gap="8px" color="#545454" sx={{ minHeight: '24px' }}>
                  {sequenceCandidateData?.latest_job_title && (
                    <Text>{sequenceCandidateData?.latest_job_title}</Text>
                  )}
                  {sequenceCandidateData?.latest_job_title &&
                    sequenceCandidateData?.latest_employer &&
                    '|'}
                  {sequenceCandidateData?.latest_employer && (
                    <Text>{sequenceCandidateData?.latest_employer}</Text>
                  )}
                </Flex>
              </Stack>
              <Button leftIcon="eye" onClick={handleViewDetails}>
                View All Details
              </Button>
            </Flex>
            <Flex alignItems="center">
              <Badge
                sx={{
                  borderRadius: '2px',
                  height: '26px',
                  p: '4px 8px',
                  fontSize: '14px',
                  fontWeight: 700,
                  textTransform: 'capitalize',
                  bgColor:
                    sequenceCandidateData?.sequence &&
                    SEQUENCE_STATUS[
                      sequenceCandidateData?.sequence[0]
                        ?.sequence_candidate_status?.status
                    ]?.bgColor,
                  color:
                    sequenceCandidateData?.sequence &&
                    SEQUENCE_STATUS[
                      sequenceCandidateData?.sequence[0]
                        ?.sequence_candidate_status?.status
                    ]?.color,
                }}
              >
                {sequenceCandidateData?.sequence &&
                  SEQUENCE_STATUS[
                    sequenceCandidateData?.sequence[0]
                      ?.sequence_candidate_status?.status
                  ]?.label}
              </Badge>

              <Button
                variant="borderless"
                leftIcon="refresh"
                size="md"
                onClick={() => {
                  onOpenChangeStatusModal();
                  setCandidateIds(
                    sequenceCandidateData?.sequence
                      ? [sequenceCandidateData?.sequence[0]?.id]
                      : []
                  );
                }}
              >
                Change Status
              </Button>
            </Flex>
          </Box>
          <Box mt="-39px" p="0 32px">
            <CandidatesTabList />
          </Box>

          {isOpenChangeStatusModal && (
            <ChangeStatusModal
              id={candidateIds}
              isOpen={isOpenChangeStatusModal}
              onClose={onCloseChangeStatusModal}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
