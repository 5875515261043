import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  Text,
  useToast,
  Grid,
  RadioGroup,
  Stack,
  Radio,
  GridItem,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from '@/components/app/ScrollError';
import 'react-quill/dist/quill.snow.css';
import '@/assets/css/minHquill.css';
import {
  useCreateTemplateMutation,
  useListTemplateMutation,
  usePlaceHoldersMutation,
} from '@/store/template.slice';
import { useGroupsMutation } from '@/store/email_group.slice';
import { cleanUpload } from '@/store/uploads.slice';
import AtsSelect from '@/components/app/AtsSelect';
import { useUserListMutation } from '@/store/user.slice';
import '@/assets/css/custom-modal.css';
import { meetingModeInt, meetingTypeInt } from '@/types';
import AtsEmailBody from '@/components/app/Global/Email/AIBody';
import { useAIGeneratorServices } from '@/components/app/AIGenerator/useAIGeneratorServices';
import FAIcon from '@/components/lib/FAIcon';
import replacePlaceholders from '@/utils/textPlaceholders';

interface TemplateAdd {
  isOpen: any;
  onClose: any;
  templateCallback?: any;
}

interface typeInt {
  value: number;
  label: string;
  mode: meetingModeInt[] | null;
}

interface modeInt {
  value: number;
  label: string;
}

export default function TemplateAddModal({
  isOpen,
  onClose,
  templateCallback,
}: TemplateAdd) {
  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const [reqGroups, resGroups] = useGroupsMutation();
  const [reqCreateTemplate, resCreateTemplate] = useCreateTemplateMutation();
  const [reqListTemplate, resListTemplate] = useListTemplateMutation();
  const [reqUser, resUser] = useUserListMutation();

  const { templatePageBtn } = useSelector((state: any) => state.template);
  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { placehodlers, placehoderPair } = useSelector(
    (state: any) => state.template
  );
  const { groups } = useSelector((state: any) => state.email_group);
  const { meeting_types } = useSelector((state: any) => state.constants);

  const toast = useToast();

  const [groupList, setGroupList] = useState([]);
  const [allowedCategory, setAllowedCategory] = useState([
    'Candidates',
    'Jobs',
    'Client',
    'Contact',
    'Interview',
    'Follow-up',
    'Signatures',
  ]);
  const [typeList, setTypeList] = useState<typeInt[]>([]);
  const [modeList, setModeList] = useState<modeInt[]>([]);
  const [users, setUsers] = useState();
  const [modeValue, setModeValue] = useState<any>(null);
  const [modeType, setModeType] = useState<boolean>(false);
  const [modeVisible, setModeVisible] = useState<boolean>(true);
  const [attachments, setAttachments] = useState([]);
  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [previewOpen, setPreviewOpen] = useState(false);

  const dispatch = useDispatch();
  const submitAgreement = async (data: any) => {
    let form = {
      ...data,
      attachments: uploaded,
      type: data?.type?.value || null,
      mode: data?.mode?.value || null,
    };
    // data['attachments'] = uploaded;

    reqCreateTemplate(form);
  };

  const initialValues = {
    title: '',
    remarks: '',
    group: {} as any,
    subject: '',
    body: '',
    share: 'only me',
    users: [] as any,
    type: {} as any,
    mode: {} as any,
  };

  useEffect(() => {
    let types = [] as typeInt[];
    meeting_types.map((item: meetingTypeInt) => {
      types.push({
        value: item.id,
        label: item.type,
        mode: item.mode,
      });
    });
    setTypeList(types);
  }, [meeting_types]);

  const typeChange = (event: typeInt) => {
    const modes = [] as modeInt[];
    event.mode.map((item: meetingModeInt) => {
      modes.push({
        value: item.id,
        label: item.mode,
      });
    });
    setModeList(modes);
    setModeValue(null);
    setModeVisible(event.mode.length > 0 ? true : false);
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Subject is required.'),
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      group: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      type: Yup.array().nullable(),
      mode: Yup.array().nullable(),
    })
  );

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    if (resCreateTemplate.isSuccess) {
      onClose();
      reqListTemplate(templatePageBtn);
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));

      if (templateCallback) templateCallback();
    }
  }, [resCreateTemplate.isSuccess]);

  useEffect(() => {
    if (isOpen) {
      const load = async () => {
        await reqPlaceHolder({});
        await reqGroups({});
        await dispatch(cleanUpload({ attachments: [] }));
        await dispatch(cleanUpload({ uploaded: [] }));
        await reqUser({});
      };

      load();
    }
  }, [isOpen]);

  useEffect(() => {
    if (resGroups.isSuccess) {
      const data: any = resGroups.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
        });
      });
      setGroupList(option);
    }
  }, [resGroups.isSuccess]);

  useEffect(() => {
    let users: any = [];
    if (resUser.isSuccess) {
      if (resUser.data?.data.length > 0) {
        Promise.all(
          resUser.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setUsers(users);
  }, [resUser.data?.data, resUser.isSuccess]);

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Candidate' });

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="6xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          fontFamily="NunitoSans Regular"
          maxW={{
            '1280px': '1200px', // 1280 x 832
            '1366px': '1200px', // 1366 x 768
            '1400px': '1300px', // 1400 x 900
            '1512px': '1400px', // 1512 x 982
            '1728px': '1500px', // 1728 x 1117
            '1920px': '1500px', // 1920 x 1280
          }}
          w={{
            '1280px': '1200px', // 1280 x 832
            '1366px': '1200px', // 1366 x 768
            '1400px': '1300px', // 1400 x 900
            '1512px': '1400px', // 1512 x 982
            '1728px': '1500px', // 1728 x 1117
            '1920px': '1500px', // 1920 x 1280
          }}
          maxH={{
            '1280px': '550px', // 1280 x 832
            '1366px': '550px', // 1366 x 768
            '1400px': '700px', // 1400 x 900
            '1512px': '700px', // 1512 x 982
            '1728px': '800px', // 1728 x 1117
            '1920px': '800px', // 1920 x 1280
          }}
          h={{
            '1280px': '550px', // 1280 x 832
            '1366px': '550px', // 1366 x 768
            '1400px': '700px', // 1400 x 900
            '1512px': '700px', // 1512 x 982
            '1728px': '800px', // 1728 x 1117
            '1920px': '800px', // 1920 x 1280
          }}
        >
          <ModalHeader
            sx={{
              background: '#F9FAFB',
              borderRadius: '4px 4px 0',
              p: '16px',
              fontSize: '18px',
              lineHeight: '18px',
              fontWeight: 700,
              color: '#2B2D42',
              textAlign: 'left',
            }}
          >
            Add New Template
          </ModalHeader>

          <ModalBody
            sx={{
              bgColor: '#F9FAFB',
              borderRadius: '0 0 4px 4px',
              p: 0,
              h: '100%',
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={submitAgreement}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                    <ScrollToFieldError />

                    <Stack
                      spacing={0}
                      justifyContent="space-between"
                      h="inherit"
                    >
                      <Flex
                        justifyContent="center"
                        gap="8px"
                        sx={{
                          h: 'inherit',
                          overflow: 'hidden',
                          '& #ai': {
                            overflowY: 'scroll',
                            h: 'inherit',
                          },
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }}
                      >
                        <Stack
                          h="inherit"
                          spacing="8px"
                          sx={{
                            display:
                              hasAIGenerate && isMaximize ? 'none' : 'flex',
                            w: hasAIGenerate !== '' ? '65%' : '100%',
                            m: '0 8px',
                            mr: hasAIGenerate && 0,
                          }}
                        >
                          <Stack
                            spacing="16px"
                            sx={{
                              background: '#fff',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <Grid templateColumns="repeat(2, 1fr)" gap="32px">
                              <GridItem w="100%">
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.group && touched.group
                                  )}
                                >
                                  <FormLabel fontSize="14px" lineHeight="18px">
                                    Template Group
                                    <Box as="span" color="caution.800">
                                      *
                                    </Box>
                                  </FormLabel>
                                  <AtsSelect
                                    id="group"
                                    name="group"
                                    defaultValue={values.group}
                                    placeholder="Template Group"
                                    onChange={(event: any) => {
                                      setFieldValue('group', event);
                                      setModeType(
                                        event.value === 5 || event.value === 4
                                          ? true
                                          : false
                                      );
                                      if (event.value !== 5) {
                                        setFieldValue('type', {});
                                        setFieldValue('mode', {});
                                      }
                                      if (event.value == 3) {
                                        setAllowedCategory([
                                          'Client',
                                          'Contact',
                                          'Signatures',
                                        ]);
                                      } else {
                                        setAllowedCategory([
                                          'Candidates',
                                          'Jobs',
                                          'Client',
                                          'Contact',
                                          'Interview',
                                          'Follow-up',
                                          'Signatures',
                                        ]);
                                      }
                                    }}
                                    // onInputChange={() => {}}
                                    isLoading={resGroups.isLoading}
                                    options={groupList}
                                    styles={{
                                      control: (styles: any) => ({
                                        ...styles,
                                        fontSize: '14px',
                                        border: 'none',
                                        borderBottom: '1px solid #E7E9ED',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        boxShadow: 'none',
                                        '&:hover': {},
                                      }),
                                      dropdownIndicator: (styles: any) => ({
                                        ...styles,
                                        color: '#676767',
                                      }),
                                      indicatorSeparator: () => ({
                                        display: 'none',
                                      }),
                                      placeholder: (styles: any) => ({
                                        ...styles,
                                        fontSize: '14px',
                                        color: '#9B9B9B',
                                      }),
                                      multiValue: (styles: any) => ({
                                        ...styles,
                                        background: '#F7FAFC',
                                        border: '1px solid #E2E8F0',
                                        borderRadius: '6px',
                                        '&& div:first-of-type': {
                                          color: '#6930CA',
                                          fontWeight: 600,
                                        },
                                      }),
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {String(errors.group)}
                                  </FormErrorMessage>
                                </FormControl>
                              </GridItem>
                              {modeType && (
                                <>
                                  <GridItem w="100%">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.type && touched.type
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                      >
                                        Meeting Type
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <AtsSelect
                                        id="type"
                                        name="type"
                                        defaultValue={values.type}
                                        placeholder="Meeting Type"
                                        onChange={(event: any) => {
                                          setFieldValue('type', event);
                                          setFieldValue('mode', {});

                                          typeChange(event);
                                        }}
                                        options={typeList}
                                        styles={{
                                          control: (styles: any) => ({
                                            ...styles,
                                            fontSize: '14px',
                                            border: 'none',
                                            borderBottom: '1px solid #E7E9ED',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            boxShadow: 'none',
                                            '&:hover': {},
                                          }),
                                          dropdownIndicator: (styles: any) => ({
                                            ...styles,
                                            color: '#676767',
                                          }),
                                          indicatorSeparator: () => ({
                                            display: 'none',
                                          }),
                                          placeholder: (styles: any) => ({
                                            ...styles,
                                            fontSize: '14px',
                                            color: '#9B9B9B',
                                          }),
                                          multiValue: (styles: any) => ({
                                            ...styles,
                                            background: '#F7FAFC',
                                            border: '1px solid #E2E8F0',
                                            borderRadius: '6px',
                                            '&& div:first-of-type': {
                                              color: '#6930CA',
                                              fontWeight: 600,
                                            },
                                          }),
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {String(errors.type)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </GridItem>
                                  {modeVisible && (
                                    <GridItem w="100%">
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.title && touched.title
                                        )}
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                        >
                                          Meeting Mode
                                        </FormLabel>
                                        <AtsSelect
                                          id="mode"
                                          name="mode"
                                          defaultValue={values.mode}
                                          placeholder="Meeting Mode"
                                          onChange={(event: any) => {
                                            setFieldValue('mode', event);
                                            setModeValue(event);
                                          }}
                                          options={modeList}
                                          isClearable={true}
                                          value={modeValue}
                                          styles={{
                                            control: (styles: any) => ({
                                              ...styles,
                                              fontSize: '14px',
                                              border: 'none',
                                              borderBottom: '1px solid #E7E9ED',
                                              borderRadius: '4px',
                                              cursor: 'pointer',
                                              boxShadow: 'none',
                                              '&:hover': {},
                                            }),
                                            dropdownIndicator: (
                                              styles: any
                                            ) => ({
                                              ...styles,
                                              color: '#676767',
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                            placeholder: (styles: any) => ({
                                              ...styles,
                                              fontSize: '14px',
                                              color: '#9B9B9B',
                                            }),
                                            multiValue: (styles: any) => ({
                                              ...styles,
                                              background: '#F7FAFC',
                                              border: '1px solid #E2E8F0',
                                              borderRadius: '6px',
                                              '&& div:first-of-type': {
                                                color: '#6930CA',
                                                fontWeight: 600,
                                              },
                                            }),
                                          }}
                                        />
                                        <FormErrorMessage>
                                          {String(errors.mode)}
                                        </FormErrorMessage>
                                      </FormControl>
                                    </GridItem>
                                  )}
                                </>
                              )}
                              <GridItem w="100%">
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.title && touched.title
                                  )}
                                >
                                  <FormLabel fontSize="14px" lineHeight="18px">
                                    Template Name
                                    <Box as="span" color="caution.800">
                                      *
                                    </Box>
                                  </FormLabel>
                                  <Input
                                    name="title"
                                    type="text"
                                    placeholder="Template Name"
                                    variant="outline"
                                    value={values.title}
                                    onChange={handleChange}
                                    sx={{
                                      height: '38px',
                                      border: 'none',
                                      borderBottom: '1px solid',
                                      borderColor: '#E7E9ED',
                                      p: '8px 12px',
                                      fontSize: '14px',
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {String(errors.title)}
                                  </FormErrorMessage>
                                </FormControl>
                              </GridItem>
                            </Grid>

                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Subject{' '}
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                              </Flex>

                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={placholders(values.subject)}
                                onChange={(e) =>
                                  setFieldValue('subject', e.target.value)
                                }
                                sx={{
                                  height: '38px',
                                  border: 'none',
                                  borderBottom: '1px solid',
                                  borderColor: '#E7E9ED',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Stack>

                          <Stack
                            h="inherit"
                            sx={{
                              position: 'relative',
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                              overflowY: 'hidden',
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }}
                          >
                            <AtsEmailBody
                              value={values.body}
                              onChange={(e: any) => {
                                setFieldValue('body', e);
                              }}
                              height={220}
                              hasAIGenerate={hasAIGenerate}
                              setHasAIGenerate={setHasAIGenerate}
                              setIsGenerate={setIsGenerate}
                              handleValues={handleValues}
                              setIsError={setIsError}
                              subject={values.subject}
                              body={values.body}
                              attachments={attachments}
                              setAttachments={setAttachments}
                              allowedCategory={allowedCategory}
                            />
                            <FormErrorMessage>
                              {String(errors.body)}
                            </FormErrorMessage>
                          </Stack>

                          <Box
                            sx={{
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <Text
                              sx={{
                                mb: '10px',
                                fontSize: '16px',
                                lineHeight: '18px',
                              }}
                            >
                              Share this with:
                            </Text>

                            <RadioGroup defaultValue={values.share}>
                              <Stack spacing={2} direction="column">
                                <Radio
                                  colorScheme="purple"
                                  name="share"
                                  onChange={handleChange}
                                  value="only me"
                                >
                                  Only me
                                </Radio>
                                <Radio
                                  colorScheme="purple"
                                  name="share"
                                  onChange={handleChange}
                                  value="everyone"
                                >
                                  Organization
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </Box>
                        </Stack>

                        {handleAIGenerator(setFieldValue)}
                      </Flex>

                      <ModalFooter
                        position="sticky"
                        bottom="0"
                        background="#F9F9FB"
                        p="8px"
                        zIndex={2}
                      >
                        <Flex gap="8px">
                          <Button onClick={onClose}>Cancel</Button>
                          <Button
                            variant="solid"
                            type="submit"
                            disabled={uploading}
                            isLoading={resCreateTemplate.isLoading}
                          >
                            Save
                          </Button>
                        </Flex>
                      </ModalFooter>
                    </Stack>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
