import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  InputGroup,
  InputRightElement,
  Image,
  useToast,
  useDisclosure,
  AlertIcon,
  Alert,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  CloseButton,
  ListItem,
  Checkbox,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from '@/components/app/ScrollError';
import FAIcon from '@/components/lib/FAIcon';

import randomstring from '@/utils/randomstring';

import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserListPaginateMutation,
} from '@/store/user.slice';
import {
  cleanUpload,
  uploadList,
  removeAttachment,
  setAttachment,
  setProgress,
  setUploaded,
} from '@/store/uploads.slice';
import { changeUploadName } from '@/utils/newFilename';
import axios from 'axios';
import { BASE_URL } from '@/constants/values';
import { UserTypes } from '@/types';

import User from '@/assets/images/user.png';
import { authStorage } from '@/utils/localStorage';
import { loginAuth } from '@/store/auth.slice';

interface userModal {
  isOpen: any;
  onClose: any;
  userData: UserTypes;
}

export default function UserModal({ isOpen, onClose, userData }: userModal) {
  const toast = useToast();
  const dispatch = useDispatch();

  const auth = authStorage();
  // phone_number
  // designation
  const { UserPgBtn } = useSelector((state: any) => state.user);
  const [reqCreateUser, resCreateUser] = useCreateUserMutation();
  const [reqUpdateUser, resUpdateUser] = useUpdateUserMutation();
  const [reqUser] = useUserListPaginateMutation();

  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const [selectedImage, setSelectedImage] = useState(
    userData?.profile_picture ? userData.signedUrl : null
  );
  const [show, setShow] = useState(false);
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [hasRemovePicture, setHasRemovePicture] = useState(false);
  const [errorList, setErrorList] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const url = BASE_URL + '/upload/file/temp';
  const default_pic = User;
  const isEditModal = userData !== null;
  const modalTitle = isEditModal ? 'Edit User' : 'Add User';
  const submitAgreement = async (data: any) => {
    if (!isEditModal) {
      let newForm = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        designation: data.designation,
        linkedin_url: data.linkedin_url,
        password: data.password,
        linkedin_name: data.linkedin_name,
        profile_picture: uploaded,
      };
      newForm['profile_picture'] = await changeUploadName(uploaded, prefix);
      newForm['profile_picture'] =
        newForm['profile_picture'][newForm['profile_picture'].length - 1];
      if (newForm.profile_picture === undefined)
        newForm.profile_picture = {
          file_name: null,
          file_size: null,
          file_type: null,
          location: null,
          original_file_name: null,
        };
      reqCreateUser(newForm);
    } else {
      let newForm = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        linkedin_url: data.linkedin_url,
        password: data.password,
        linkedin_name: data.linkedin_name,
        phone_number: data.phone_number,
        designation: data.designation,
        profile_picture: uploaded,
        password_update: isChangePassword, // change logic once okay
      };
      if (uploaded.length <= 0 && !hasRemovePicture) {
        // console.log('Nothing uploaded');
        newForm.profile_picture = {
          file_name: null,
          file_size: null,
          file_type: null,
          location: userData?.profile_picture,
          original_file_name: null,
        };
      } else if (!hasRemovePicture) {
        // console.log('Changed Profile Picture to New');
        newForm['profile_picture'] = await changeUploadName(uploaded, prefix);
        newForm['profile_picture'] =
          newForm['profile_picture'][newForm['profile_picture'].length - 1];
      } else {
        // console.log('Removed Profile Picture');
        newForm.profile_picture = {
          file_name: null,
          file_size: null,
          file_type: null,
          location: null,
          original_file_name: null,
        };
      }
      reqUpdateUser({ data: newForm, id: userData.id });
      if (userData.id == auth?.id) {
        let currentUser = auth;
        currentUser['email'] = newForm.email;
        currentUser['linkedin_url'] = newForm.linkedin_url;
        currentUser['first_name'] = newForm.first_name;
        currentUser['last_name'] = newForm.last_name;
        currentUser['linkedin_name'] = newForm.linkedin_name;
        currentUser['profile_picture'] = newForm.profile_picture;
        currentUser['phone_number'] = newForm.phone_number;
        currentUser['designation'] = newForm.designation;
        currentUser['signedUrl'] = selectedImage;
        dispatch(loginAuth(currentUser));
        localStorage.setItem('auth', JSON.stringify(currentUser));
      }
    }
  };

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    // console.log('file ', file);
    setHasRemovePicture(false);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(String(reader.result));
      };
      reader.readAsDataURL(file);
    }
    let prefix = event.target.value;
    dispatch(uploadList({ prefix: prefix }));

    let id = randomstring();
    let file_name = file.name;
    dispatch(uploadList({ uploading: true }));
    dispatch(
      setAttachment({
        id: id,
        name: file_name,
        progress: 0,
        uploading: true,
        file: file,
      })
    );

    const config = {
      withCredentials: true,
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(
          setProgress({
            id: id,
            name: file_name,
            progress: percentCompleted,
            uploading: percentCompleted < 100 ? true : false,
            file: file,
          })
        );
      },
    };

    let formData = new FormData();
    formData.append('file', file, file.name);
    await axios
      .post(url, formData, config)
      .then((res) => {
        dispatch(setUploaded(res.data?.data));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleRemoveImage = () => {
    cleanAttachment();
    setHasRemovePicture(true);
    setSelectedImage(null);
  };

  const cleanAttachment = () => {
    dispatch(removeAttachment(0));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    dispatch(cleanUpload({ withPrefix: false }));
  };

  useEffect(() => {
    if (isOpen) {
      cleanAttachment();
    }
  }, [isOpen]);

  const initialValues = isEditModal
    ? {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        designation: userData.designation,
        phone_number: userData.phone_number,
        linkedin_url: userData.linkedin_url,
        linkedin_name: userData.linkedin_name || '',
        password: '',
        confirm_password: '',
      }
    : {
        first_name: '',
        last_name: '',
        email: '',
        designation: '',
        phone_number: '',
        linkedin_url: '',
        linkedin_name: '',
        password: '',
        confirm_password: '',
      };

  const validationSchema = Yup.lazy(() =>
    isEditModal
      ? Yup.object().shape({
          first_name: Yup.string()
            .required('First name is required.')
            .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
          last_name: Yup.string()
            .required('Last name is required.')
            .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
          email: Yup.string()
            .email('Invalid email.')
            .required('Email is required.'),
          designation: Yup.string().nullable(true),
          phone_number: Yup.string().nullable(true),
          linkedin_url: Yup.string().nullable(true),
          linkedin_name: Yup.string().nullable(true),
          password: Yup.string().when('password_update', {
            is: true,
            then: Yup.string()
              .required('No password provided.')
              .min(5, 'Password is too short - should be 8 chars minimum.')
              .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
            otherwise: Yup.string(),
          }),
          confirm_password: Yup.string().when('password_update', {
            is: true,
            then: Yup.string()
              .required('Confirm password is required.')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            otherwise: Yup.string(),
          }),
        })
      : Yup.object().shape({
          first_name: Yup.string()
            .required('First name is required.')
            .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
          last_name: Yup.string()
            .required('Last name is required.')
            .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
          designation: Yup.string().nullable(true),
          phone_number: Yup.string().nullable(true),
          email: Yup.string()
            .email('Invalid email.')
            .required('Email is required.'),
          linkedin_url: Yup.string().nullable(true),
          linkedin_name: Yup.string().nullable(true),
          password: Yup.string()
            .required('No password provided.')
            .min(5, 'Password is too short - should be 8 chars minimum.')
            .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
          confirm_password: Yup.string()
            .required('Confirm password is required.')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        })
  );

  useEffect(() => {
    dispatch(uploadList({ uploading: false }));
  }, [uploaded]);

  useEffect(() => {
    if (resCreateUser.isSuccess || resUpdateUser.isSuccess) {
      onClose();
      onCloseAlert();
      handleRemoveImage();
      setHasRemovePicture(false);
      reqUser(UserPgBtn);
      if (resCreateUser.isSuccess) {
        // dispatch(getUser({ userList: {} }))
      }
      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateUser.isSuccess) {
          title = 'Added User';
          description = 'User successfully added';
        } else if (resUpdateUser.isSuccess) {
          title = 'Updated User';
          description = 'User has been updated';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [onClose, resCreateUser.isSuccess, resUpdateUser.isSuccess]);

  useEffect(() => {
    if (resCreateUser.isError) {
      let errorMessage: any = resCreateUser.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [resCreateUser.isError]);

  const tooglePassword = () => setShow(!show);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          console.log('closing...');
          onCloseAlert();
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert
                status="error"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Flex gap="20px" position="relative" left="20px">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Oh snap!</AlertTitle>
                    <AlertDescription>
                      {errMsg || (
                        <>
                          There were some problems in you input. Change a few
                          things up and try again.
                          <UnorderedList
                            mt="10px"
                            fontSize="13px"
                            lineHeight="1.5"
                            color="var(--chakra-colors-red-500)"
                          >
                            {Object.values(errorList).map((key: any) => (
                              <ListItem key={key}>{key}</ListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}
                    </AlertDescription>
                  </Box>
                </Flex>

                <CloseButton
                  position="absolute"
                  right="20px"
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={submitAgreement}
              validationSchema={validationSchema}
            >
              {({ values, handleSubmit, handleChange, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <Flex gap="32px" mb="34px">
                        <Flex
                          width="50%"
                          gap="32px"
                          mb="34px"
                          direction="column"
                        >
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.first_name && touched.first_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              First Name
                            </FormLabel>
                            <Input
                              name="first_name"
                              type="text"
                              placeholder="First Name"
                              variant="outline"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.first_name)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.last_name && touched.last_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Last Name
                            </FormLabel>
                            <Input
                              name="last_name"
                              type="text"
                              placeholder="Last Name"
                              variant="outline"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.last_name)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(!!errors.email && touched.email)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Email
                            </FormLabel>
                            <Input
                              name="email"
                              type="email"
                              placeholder="Email"
                              variant="outline"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.email)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.designation && touched.designation
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Designation
                            </FormLabel>
                            <Input
                              name="designation"
                              type="text"
                              placeholder="Designation"
                              variant="outline"
                              value={values.designation}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.email)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.phone_number && touched.phone_number
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Phone Number
                            </FormLabel>
                            <Input
                              name="phone_number"
                              type="text"
                              placeholder="Phone number"
                              variant="outline"
                              value={values.phone_number}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.email)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.linkedin_url && touched.linkedin_url
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Linkedin Url
                            </FormLabel>
                            <Input
                              name="linkedin_url"
                              type="linkedin_url"
                              placeholder="Linkedin Url"
                              variant="outline"
                              value={values.linkedin_url}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.linkedin_url)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.linkedin_name && touched.linkedin_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Linkedin Name
                            </FormLabel>
                            <Input
                              name="linkedin_name"
                              type="linkedin_name"
                              placeholder="Linkedin Name"
                              variant="outline"
                              value={values.linkedin_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.linkedin_name)}
                            </FormErrorMessage>
                          </FormControl>
                          {isEditModal && (
                            <Checkbox
                              name="password_update"
                              isChecked={isChangePassword}
                              onChange={(e) => {
                                setIsChangePassword(e.target.checked);
                                handleChange(e);
                              }}
                            >
                              Change Password?
                            </Checkbox>
                          )}
                          {(!isEditModal || isChangePassword) && (
                            <>
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.password && touched.password
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Password
                                </FormLabel>
                                <InputGroup>
                                  <Input
                                    name="password"
                                    type={show ? 'text' : 'password'}
                                    placeholder="Password"
                                    variant="outline"
                                    value={values.password}
                                    onChange={handleChange}
                                  />
                                  <InputRightElement>
                                    <Box
                                      onClick={tooglePassword}
                                      cursor="pointer"
                                    >
                                      <FAIcon
                                        iconName={show ? 'eye' : 'eye-slash'}
                                      />
                                    </Box>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {String(errors.password)}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.confirm_password &&
                                    touched.confirm_password
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Confirm Password
                                </FormLabel>
                                <InputGroup>
                                  <Input
                                    name="confirm_password"
                                    type={show ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    variant="outline"
                                    value={values.confirm_password}
                                    onChange={handleChange}
                                  />
                                  <InputRightElement>
                                    <Box
                                      onClick={tooglePassword}
                                      cursor="pointer"
                                    >
                                      <FAIcon
                                        iconName={show ? 'eye' : 'eye-slash'}
                                      />
                                    </Box>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {String(errors.confirm_password)}
                                </FormErrorMessage>
                              </FormControl>
                            </>
                          )}
                        </Flex>
                        <Box
                          display="flex"
                          flexDirection="column"
                          mb="34px"
                          width="50%"
                        >
                          {selectedImage !== null ? (
                            <Image
                              src={selectedImage}
                              alt="Selected"
                              borderRadius="full"
                              boxSize="250px"
                              placeSelf="center"
                              objectFit="contain"
                            />
                          ) : (
                            <Image
                              src={default_pic}
                              alt="Selected"
                              borderRadius="full"
                              boxSize="250px"
                              placeSelf="center"
                            />
                          )}
                          {selectedImage ? (
                            <Box textAlign="center" mt="4">
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={handleRemoveImage}
                              >
                                Remove Image
                              </Button>
                            </Box>
                          ) : (
                            <Box textAlign="center" mt="4">
                              <Button
                                as="label"
                                htmlFor="file-upload"
                                size="sm"
                              >
                                Upload File
                                <input
                                  id="file-upload"
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={handleImageChange}
                                  // value={prefixStr}
                                  style={{
                                    display: 'none',
                                    width: 0,
                                    height: 0,
                                  }}
                                />
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    zIndex="2"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={
                        uploading ||
                        isOpenAlert ||
                        resUpdateUser.isLoading ||
                        resCreateUser.isLoading
                      }
                      isLoading={
                        resUpdateUser.isLoading || resCreateUser.isLoading
                      }
                      onClick={() => {
                        setErrorList(errors);
                      }}
                    >
                      {modalTitle === 'Edit User' ? 'Update' : 'Add'}
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
