import { useDispatch } from 'react-redux';

import { Tabs, Tab, TabPanels, TabPanel, TabList } from '@chakra-ui/react';

import { updateOutreach } from '@/store/outreach.slice';

import CandidateTabPanel from './TabPanels/Candidates';
import SchedulesList from './TabPanels/Schedules';
import SettingsList from './TabPanels/Settings';
import StepsList from './TabPanels/Steps';

import { SIDE_BAR_ITEMS } from '../../constants';

import { useSequenceTabsServices } from './useSequenceTabsServices';

export const SequenceTabs = () => {
  const dispatch = useDispatch();

  const { selectedTab, handleChangeTab } = useSequenceTabsServices();

  return (
    <Tabs
      colorScheme="purple"
      index={selectedTab}
      onChange={(index) => {
        handleChangeTab(index);
        dispatch(updateOutreach({ stepsData: {} }));
      }}
    >
      <TabList sx={{ mb: '10px' }}>
        {SIDE_BAR_ITEMS.map((barItem) => {
          return (
            <Tab
              sx={{
                color: '#2B2D42',
                fontWeight: 700,
              }}
            >
              {barItem.label}
            </Tab>
          );
        })}
      </TabList>

      <TabPanels>
        <TabPanel sx={{ p: 0 }}>
          {selectedTab === 0 && <CandidateTabPanel />}
        </TabPanel>
        <TabPanel sx={{ p: 0 }}>{selectedTab === 1 && <StepsList />}</TabPanel>
        <TabPanel sx={{ p: 0 }}>
          {selectedTab === 2 && <SchedulesList />}
        </TabPanel>
        <TabPanel sx={{ p: 0 }}>
          {selectedTab === 3 && <SettingsList />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
