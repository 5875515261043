/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  Checkbox,
  Flex,
  Tag,
  Link,
} from '@chakra-ui/react';
import TrLoading from '@/components/app/TrLoading';
import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useEmailsTableServices } from './useEmailsTableServices';

interface EmailsTableProps {
  active: number;
  isLoading: boolean;
}

const EmailsTable = ({ active, isLoading }: EmailsTableProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    inboxList,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    handleBulkDoneInbox,
    inboxPage,
    inboxPgBtn,
    pageChange,
    entryChange,
    reqGetTaskView,
  } = useEmailsTableServices({ active });

  useEffect(() => {
    if (params.settingTab === 'emails' && params.settingTabId) {
      reqGetTaskView(params.settingTabId);
    }
  }, [params.settingTab, params.settingTabId]);

  return (
    <Box px={1} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button
            variant="basic"
            leftIcon="check"
            size="sm"
            onClick={handleBulkDoneInbox}
          >
            Mark as {active === 0 ? `Done` : `Pending`}
          </Button>
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th width="50px">
                <Checkbox
                  onChange={handleSelectAll}
                  isIndeterminate={isIndeterminate}
                  checked={isCheckAll}
                  defaultChecked={false}
                  colorScheme="purple"
                />
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Candidate Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Job Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Client Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Date
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <TrLoading rows={3} columns={6} />
          ) : (
            <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
              {inboxList?.length > 0 &&
                inboxList?.map((item: any, index: number) => {
                  return (
                    <Tr cursor="pointer">
                      <Td>
                        <Checkbox
                          onChange={handleCheck}
                          isChecked={isCheck.includes(item.id)}
                          id={item?.id?.toString()}
                          key={item.id}
                          colorScheme="purple"
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          navigate(`${params.settingTab}/${item.id}`);
                        }}
                      >
                        {[
                          item?.candidate?.first_name,
                          item?.candidate?.last_name,
                        ].join(' ')}
                      </Td>
                      <Td
                        onClick={() => {
                          navigate(`${params.settingTab}/${item.id}`);
                        }}
                      >
                        <Link
                          href={`/jobs/${item?.suquence?.job?.id}/details`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.suquence?.title}
                        </Link>
                      </Td>
                      <Td
                        onClick={() => {
                          navigate(`${params.settingTab}/${item.id}`);
                        }}
                      >
                        <Link
                          href={`/clients/my-clients/${item?.suquence?.job?.client?.id}/overview`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.suquence?.job?.client?.name}
                        </Link>
                      </Td>
                      <Td
                        onClick={() => {
                          navigate(`${params.settingTab}/${item.id}`);
                        }}
                      >
                        {moment(item.created_at).format('MMM DD')}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          )}
        </Table>
      </TableContainer>

      <Box sx={{ boxShadow: '0px -4px 8px 0px #0000000A', mt: '32px' }}>
        <Pagination
          totalPages={inboxPage.lastPage}
          currentPage={inboxPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={inboxPage.count}
          onEntryChange={(entries) => entryChange(entries)}
          currentCount={inboxList.length}
          targetCount={inboxPgBtn.take}
        />
      </Box>
    </Box>
  );
};
export default EmailsTable;
