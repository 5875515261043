import { createSlice } from '@reduxjs/toolkit';
import authService from '@/services//auth.service';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const candidateAttachmentSlice = createSlice({
  name: 'candidateAttachment',
  initialState: {
    attachments: [],
    resumes: [],
    latestResume: {},
    resumeVersions: [],
  },
  reducers: {
    getAttachment: (state, { payload }) => ({ ...state, ...payload }),
    listResumes: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getAttachment, listResumes } = candidateAttachmentSlice.actions;
export default candidateAttachmentSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const candidateAttachmentApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    DownloadCandidateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-attachment/download/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAttachment({ candidates: data.data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    CreateCandidateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-attachment/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    ExtractResume: builder.mutation({
      query: ({ data, id }) => ({
        url: `/resume-parser/extract/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    ListResume: builder.mutation({
      query: () => ({
        url: `/resume-parser/list/batch`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('data ', data);
          dispatch(listResumes({ resumes: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    LatestResume: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-resume/latest/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getAttachment({ latestResume: { ...data.data, ...data.data.data } })
          );
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    ExtractResumeVersions: builder.mutation({
      query: ({ id }) => ({
        url: `/resume-parser/versions/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listResumes({ resumeVersions: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelCandidateAttachment: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-attachment/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: [],
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useDownloadCandidateAttachmentMutation,
  useCreateCandidateAttachmentMutation,
  useExtractResumeMutation,
  useListResumeMutation,
  useLatestResumeMutation,
  useExtractResumeVersionsMutation,
  useDelCandidateAttachmentMutation,
} = candidateAttachmentApiSplice;
