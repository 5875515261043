/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';

import {
  useGetSequenceCandidateDataMutation,
  useGetSequenceDataMutation,
  usePostPrimaryEmailMutation,
  usePostPrimaryPhoneMutation,
  usePostValidEmailMutation,
  usePostValidPhoneMutation,
} from '@/store/outreach.slice';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useDetailsServices = () => {
  const params = useParams();

  const { sequenceCandidateData } = useSelector((state: any) => state.outreach);

  const [reqPostPrimaryEmail, resPostPrimaryEmail] =
    usePostPrimaryEmailMutation();
  const [reqPostPrimaryPhone, resPostPrimaryPhone] =
    usePostPrimaryPhoneMutation();
  const [reqPostValidEmail, resPostValidEmail] = usePostValidEmailMutation();
  const [reqPostValidPhone, resPostValidPhone] = usePostValidPhoneMutation();

  const [reqGetSequenceCandidateData] = useGetSequenceCandidateDataMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();

  const handlePrimaryEmail = ({ e, val }: any) => {
    const checked = e.target.checked;
    const params = {
      primary: checked === true ? 1 : 0,
    };
    reqPostPrimaryEmail({
      id: val.id,
      data: params,
    });
  };

  const handleValidEmail = ({ val, valid }: any) => {
    const params = {
      valid: valid,
    };
    reqPostValidEmail({
      id: val.id,
      data: params,
    });
  };

  const handlePrimaryPhone = ({ e, val }: any) => {
    const checked = e.target.checked;
    const params = {
      primary: checked === true ? 1 : 0,
    };
    reqPostPrimaryPhone({
      id: val.id,
      data: params,
    });
  };

  const handleValidPhone = ({ val, valid }: any) => {
    const params = {
      valid: valid,
    };
    reqPostValidPhone({
      id: val.id,
      data: params,
    });
  };

  useEffect(() => {
    if (
      resPostPrimaryEmail.isSuccess ||
      resPostPrimaryPhone.isSuccess ||
      resPostValidEmail.isSuccess ||
      resPostValidPhone.isSuccess
    ) {
      reqGetSequenceData({ id: params.settingTypeId });
      reqGetSequenceCandidateData(params.settingSubId);
    }
  }, [
    resPostPrimaryEmail.isSuccess,
    resPostPrimaryPhone.isSuccess,
    resPostValidEmail.isSuccess,
    resPostValidPhone.isSuccess,
  ]);

  const isLoading =
    resPostPrimaryEmail.isLoading ||
    resPostPrimaryPhone.isLoading ||
    resPostValidEmail.isLoading ||
    resPostValidPhone.isLoading;

  return {
    isLoading,
    sequenceCandidateData,
    handlePrimaryEmail,
    handleValidEmail,
    handlePrimaryPhone,
    handleValidPhone,
  };
};
