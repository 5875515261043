import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePostBuzzCheckMutation } from '@/store/outreach.slice';

export const useBuzzConnectionServices = () => {
  const { buzzData } = useSelector((state: any) => state.outreach);

  const [reqPostBuzzCheck, resPostBuzzCheck] = usePostBuzzCheckMutation();

  const [defaultSetup, setDefaultSetup] = useState(
    (buzzData?.campaigns?.some(
      (val: any) => val._id === buzzData?.buzz_connection?.campaign_id
    ) &&
      buzzData?.credentials?.some(
        (val: any) => val._id === buzzData?.buzz_connection?.credentials_id
      )) ||
      false
  );

  const [isLoggedin, setIsLoggedin] = useState(defaultSetup);
  const [hasSetup, setHasSetup] = useState(defaultSetup);

  const handleLogin = () => {
    setIsLoggedin(!isLoggedin);
  };

  useEffect(() => {
    if (!buzzData?.buzz_connection) {
      reqPostBuzzCheck({});
    }
  }, []);

  useEffect(() => {
    setDefaultSetup(
      (buzzData?.campaigns?.some(
        (val: any) => val._id === buzzData?.buzz_connection?.campaign_id
      ) &&
        buzzData?.credentials?.some(
          (val: any) => val._id === buzzData?.buzz_connection?.credentials_id
        )) ||
        false
    );
  }, [buzzData]);

  useEffect(() => {
    if (defaultSetup && true) {
      setHasSetup(true);
      setIsLoggedin(true);
    }
  }, [defaultSetup]);

  return {
    isLoggedin,
    handleLogin,
    hasSetup,
    setHasSetup,
    isLoading: resPostBuzzCheck.isLoading,
  };
};
