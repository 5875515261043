import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import moment from 'moment';
import parse from 'html-react-parser';
import FAIcon from '@/components/lib/FAIcon';
import { useDispatch, useSelector } from 'react-redux';
import { updateOutreach } from '@/store/outreach.slice';
import { useEmailTemplateDrawerServices } from './useEmailTemplateDrawerServices';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import { useState } from 'react';

export interface EmailTemplateDrawerProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  rowId: any;
  emailTemplateData: any;
  totalTemplateList: any;
  handlePrevData: any;
  handleNextData: any;
  handleSendEmail: any;
  isLoading: boolean;
  initialValueEmail: any;
  validationSchemaEmail: any;
}

export const EmailTemplateDrawer = ({
  isOpen,
  onOpen,
  onClose,
  rowId,
  emailTemplateData,
  totalTemplateList,
  handlePrevData,
  handleNextData,
  handleSendEmail,
  isLoading,
  initialValueEmail,
  validationSchemaEmail,
}: EmailTemplateDrawerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleDeleteTemplate, previewData, handlePreview, isSpinner } =
    useEmailTemplateDrawerServices({ onClose });
  const { hasAlert } = useSelector((state: any) => state.outreach);

  const [openIndex, setOpenIndex] = useState(null); // State to track which accordion is open

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/engage/settings/recruitment/email-template');
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B' }}>
            Showing <b>{rowId}</b> of {totalTemplateList} entries
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() => rowId !== totalTemplateList && handleNextData()}
                disabled={rowId === totalTemplateList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="24px 36px">
          <Stack>
            <Stack spacing="16px" mb="32px">
              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Template Title
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {emailTemplateData?.title}
                  </Text>

                  <Menu closeOnSelect={false}>
                    <MenuButton>
                      <Button
                        htmlType="menu"
                        variant="ghost"
                        iconName="ellipsis-h"
                        iconSize="xl"
                      />
                    </MenuButton>
                    <MenuList
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight="600"
                    >
                      <MenuItem
                        icon={<FAIcon iconName="pencil" />}
                        onClick={() => {
                          dispatch(
                            updateOutreach({
                              emailTemplateData: emailTemplateData,
                            })
                          );
                          onOpen();
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<FAIcon iconName="trash" />}
                        onClick={() => {
                          handleDeleteTemplate();
                        }}
                        sx={{ color: '#CB2C2C' }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Created by
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {[
                    emailTemplateData?.userCreated?.first_name,
                    emailTemplateData?.userCreated?.last_name,
                  ].join(' ')}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Role
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                    textTransform: 'capitalize',
                  }}
                >
                  {emailTemplateData?.role}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Date Added
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {moment(emailTemplateData?.created_at).format(
                    'MMMM DD, YYYY'
                  )}
                </Text>
              </Stack>
            </Stack>

            <Stack spacing="16px">
              <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>Steps</Text>
              <Stack spacing="16px">
                {emailTemplateData?.touches &&
                  emailTemplateData?.touches?.length > 0 &&
                  emailTemplateData?.touches.map(
                    (touch: any, index: number) => {
                      const isOpen = openIndex === index; // Check if this accordion is open

                      return (
                        <Accordion
                          allowMultiple={false}
                          allowToggle={false}
                          sx={{ position: 'relative' }}
                        >
                          <AccordionItem
                            sx={{
                              border: '0.5px solid #9B9B9B',
                              borderRadius: '4px',
                            }}
                          >
                            <AccordionButton
                              _expanded={{
                                bg: '#F9F9FB',
                                borderBottom: '0.5px solid #9B9B9B',
                              }}
                              sx={{
                                borderRadius: '4px 4px 0px 0px',
                                p: '10px 12px',
                              }}
                              onClick={() => {
                                setOpenIndex(index);
                                dispatch(updateOutreach({ previewData: {} }));
                                handlePreview(touch);
                              }}
                            >
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                sx={{
                                  fontSize: '14px',
                                  color: '#9B9B9B',
                                }}
                              >
                                Step # {index + 1}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                              p="12px"
                              pb={4}
                              sx={{ display: isOpen ? 'block' : 'none' }}
                            >
                              <Formik
                                initialValues={initialValueEmail}
                                validationSchema={validationSchemaEmail}
                                onSubmit={(e) => {
                                  const params = {
                                    to: e.to,
                                    subject: touch.subject,
                                    content: touch.content,
                                  };
                                  handleSendEmail(params);
                                }}
                                enableReinitialize={true}
                              >
                                {({
                                  values: valuesE,
                                  handleSubmit,
                                  handleChange,
                                  errors,
                                  touched,
                                }) => {
                                  return (
                                    <form onSubmit={handleSubmit}>
                                      <Stack
                                        spacing="10px"
                                        alignItems={isSpinner && 'center'}
                                      >
                                        {isSpinner ? (
                                          <Box
                                            sx={{
                                              color: 'primary.800',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Spinner size="sm" speed="0.65s" />
                                          </Box>
                                        ) : (
                                          <>
                                            <FormControl
                                              isInvalid={Boolean(
                                                !!errors.to && touched.to
                                              )}
                                              sx={{
                                                borderBottom:
                                                  '1px solid #EEEEEE',
                                                py: '8px',
                                              }}
                                            >
                                              <FormLabel
                                                sx={{
                                                  fontSize: '14px',
                                                  color: '#9B9B9B',
                                                }}
                                              >
                                                To:{' '}
                                                <Box
                                                  as="span"
                                                  color="caution.800"
                                                >
                                                  *
                                                </Box>
                                              </FormLabel>
                                              <Input
                                                name="to"
                                                value={valuesE.to}
                                                onChange={handleChange}
                                                color="primary.800"
                                                fontWeight={700}
                                              />
                                              <FormErrorMessage>
                                                {String(errors.to)}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <Text
                                              sx={{
                                                borderBottom:
                                                  '1px solid #EEEEEE',
                                                py: '8px',
                                                fontSize: '14px',
                                              }}
                                            >
                                              {previewData?.subject}
                                            </Text>
                                            <Box
                                              sx={{
                                                borderBottom:
                                                  '1px solid #EEEEEE',
                                                py: '8px',
                                              }}
                                            >
                                              {parse(previewData?.body || '')}
                                            </Box>
                                          </>
                                        )}

                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            top: '-4px',
                                            right: '50px',
                                          }}
                                        >
                                          <Button
                                            leftIcon="caret-right"
                                            variant="solid"
                                            type="submit"
                                            loading={isLoading}
                                            disabled={hasAlert || !!errors.to}
                                            onClick={handleSubmit}
                                            size="sm"
                                          >
                                            Send Email Test
                                          </Button>
                                        </Box>
                                      </Stack>
                                    </form>
                                  );
                                }}
                              </Formik>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      );
                    }
                  )}
              </Stack>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
