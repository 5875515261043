import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { ApiResponse, PaginatedResponse } from '@/services/type';
import apiSlice from '@/store/api.slice';
import {
  ClientLeadList,
  ClientLeadListResponse,
  ExistingClientProspectResType,
  LeadCountsAPIResponseType,
  LeadCountWithAllType,
  LeadsApiPayload,
  LeadsAPIResponseType,
  LeadsDetailAPIResponseType,
  LeadsDetailType,
  LeadsListResponsetype,
} from '../../modules/Leads/types';
import { LeadsJobinterface, LeadsSubmitsInterface } from './leads.type';

export const leadsAPI = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchLeadsCounts: builder.query<LeadCountWithAllType, any>({
      query: () => ({
        url: '/leads/count',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Leads-Count'],
      transformResponse: (response: { data: LeadCountsAPIResponseType }) => {
        const totalNewProspects = Object.values(
          response.data.prospect_status_count
        ).reduce((acc, cur) => acc + cur);
        const totalExistingClientProspects = Object.values(
          response.data.prospect_repeat_status_count
        ).reduce((acc, cur) => acc + cur);

        let data = {
          ...response.data,
          new_prospects: totalNewProspects,
          existing_client_prospects: totalExistingClientProspects,
          prospect_status_count: {
            All: totalNewProspects,
            ...response.data.prospect_status_count,
          },
          prospect_repeat_status_count: {
            All: totalExistingClientProspects,
            ...response.data.prospect_repeat_status_count,
          },
        };

        return data;
      },
    }),
    fetchLeads: builder.query<LeadsListResponsetype, LeadsApiPayload>({
      query: (payload, signal?: AbortSignal) => ({
        url: '/leads/',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: payload,
        signal,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                (data) =>
                  ({
                    type: 'Leads',
                    id: data.id,
                  } as const)
              ),
              { type: 'Leads', id: 'Leads List' },
            ]
          : [{ type: 'Leads', id: 'Leads List' }],
      transformResponse: (result: { data: LeadsListResponsetype }) => {
        return result.data;
      },
    }),
    fetchExistingClientProspects: builder.query<
      ExistingClientProspectResType,
      LeadsApiPayload
    >({
      query: (payload, signal?: AbortSignal) => ({
        url: '/leads',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: payload,
        signal,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                (data) =>
                  ({
                    type: 'Existing-Client-Prospects',
                    id: data?.client_id,
                  } as const)
              ),
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
            ]
          : [{ type: 'Existing-Client-Prospects', id: 'New Prospect list' }],
      transformResponse: (result: { data: ExistingClientProspectResType }) => {
        return result.data;
      },
    }),
    fetchLeadDetail: builder.query<LeadsDetailType, { id: number }>({
      query: ({ id }) => ({
        url: `/leads/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Details', id },
        { type: 'Leads-Details', id: 'Detail' },
      ],
      transformResponse: (result: LeadsDetailAPIResponseType) => {
        return result.data;
      },
    }),
    fetchLeadStatus: builder.query({
      query: () => ({
        url: `/constants/lead-status`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Lead-Status'],
      transformResponse: (response: { data: any }) => {
        return response.data;
      },
    }),
    fetchLeadTypes: builder.query({
      query: () => ({
        url: `/constants/lead-types/filtered`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Lead-Types'],
      transformResponse: (response: { data: any }) => {
        return response.data;
      },
    }),
    fetchClient: builder.query<ClientLeadList[], { id: number }>({
      query: ({ id }) => ({
        url: `/leads/client/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Client', id },
      ],
      transformResponse: (result: ClientLeadListResponse) => {
        return result.success ? result?.data ?? [] : [];
      },
    }),
    fetchAsContact: builder.query({
      query: ({ id }) => ({
        url: `/leads/as-contact/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-as-Contact', id },
      ],
    }),
    fetchLeadJobs: builder.query<
      LeadsJobinterface[],
      {
        data: {
          page: number;
          take: number;
          query: string;
          closed: boolean;
          open: boolean;
          quickJobs: boolean;
          job_assigned: boolean;
          job_ads: boolean;
        };
        id: number;
      }
    >({
      query: ({ data, id }) => ({
        url: `/jobs/lead/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Job', id },
        { type: 'Leads-Job', id: 'LIST' },
      ],
      transformResponse: (
        response: ApiResponse<PaginatedResponse<LeadsJobinterface>>
      ) => {
        return response.success ? response.data.data : [];
      },
    }),
    fetchLeadSubmits: builder.query<
      LeadsSubmitsInterface[],
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `/leads/submits/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Submits', id },
        { type: 'Leads-Submits', id: 'LIST' },
      ],
      transformResponse: (response: ApiResponse<LeadsSubmitsInterface[]>) => {
        return response.success ? response.data : [];
      },
    }),
    fetchLeadKanban: builder.query<
      { id: number; stage: string; leads: LeadsAPIResponseType[] }[],
      {}
    >({
      query: () => ({
        url: `/leads/kanban`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any) => [
        { type: 'Leads-Kanban' },
        { type: 'Leads-Kanban' },
      ],
      transformResponse: (
        response: ApiResponse<
          { id: number; stage: string; leads: LeadsAPIResponseType[] }[]
        >
      ) => {
        return response.success ? response.data : [];
      },
    }),
  }),
});

export const {
  useFetchLeadsCountsQuery,
  useFetchExistingClientProspectsQuery,
  useFetchLeadsQuery,
  useLazyFetchLeadsQuery,
  useFetchLeadDetailQuery,
  useFetchAsContactQuery,
  useFetchClientQuery,
  useFetchLeadStatusQuery,
  useFetchLeadTypesQuery,
  useFetchLeadJobsQuery,
  useFetchLeadSubmitsQuery,
  useFetchLeadKanbanQuery,
} = leadsAPI;
