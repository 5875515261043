import {
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import { Formik } from 'formik';
import Button from '@/Library/Button';
import moment from 'moment';
import { MembersDataInt, ViewTeamDrawerProps } from './types';
import useDrawerTeamsServices from './useDrawerTeamsServices';

export default function TeamDrawer({
  isOpen,
  onClose,
  handleEdit,
  handleDelete,
  values,
  rowId,
  total,
}: ViewTeamDrawerProps) {
  const { manager, members } = useDrawerTeamsServices({ values });
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerHeader p="0">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #E6E9EE"
          >
            <Flex
              px="6"
              py="2"
              color="default.secondarytext"
              fontSize="md"
              gap="3"
            >
              <Text>Showing</Text>
              <Text fontWeight="bold">{rowId}</Text>
              <Text>of</Text>
              <Text fontWeight="bold">{total}</Text>
              <Text>entries</Text>
            </Flex>
            <Flex h="100%">
              <Box h="100%" borderLeft="1px solid #E6E9EE" borderRadius="unset">
                <Button
                  iconName="chevron-left"
                  variant="ghost"
                  onClick={() =>
                    // rowId !== 1 &&  //temporary commenting for the candidate groups
                    {
                      // handlePrevData(candidateInfo);
                      // setPrevJobDetails(drawerJobDetails);
                      // setPrevCandidatesTab(params.candidatesTab);
                    }
                  }
                  disabled={rowId === 1}
                />
              </Box>

              <Box h="100%" borderLeft="1px solid #E6E9EE" borderRadius="unset">
                <Button
                  h="100%"
                  iconName="chevron-right"
                  variant="ghost"
                  onClick={() =>
                    // rowId !== totalCandidates && //temporary commenting for the candidate groups
                    {
                      // handleNextData();
                      // setPrevJobDetails(drawerJobDetails);
                      // setPrevCandidatesTab(params.candidatesTab);
                    }
                  }
                  disabled={rowId === total}
                  borderLeft="1px solid #6B6C7B"
                  borderRadius="unset"
                />
              </Box>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody p="0">
          <Flex direction="column" px="8" py="6" gap="8">
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                fontSize: 'md',
                fontWeight: 'bold',
              }}
            >
              <Flex direction="column" gap="4" w="100%">
                <Flex w="100%">
                  <Text color="default.gray.600" w="25%">
                    Team Name
                  </Text>
                  <Text color="default.primarytext">{values.name}</Text>
                </Flex>
                <Flex w="100%">
                  <Text color="default.gray.600" w="25%">
                    Manager
                  </Text>
                  <Text color="default.primarytext">
                    {[manager.first_name, manager.last_name]
                      .filter(Boolean)
                      .join(' ')}
                  </Text>
                </Flex>
                <Flex w="100%">
                  <Text color="default.gray.600" w="25%">
                    Team Members
                  </Text>
                  <Flex
                    color="default.primarytext"
                    fontSize="sm"
                    gap="3"
                    alignItems="center"
                    w="75%"
                    flexWrap="wrap"
                  >
                    {members.map((member: MembersDataInt) => {
                      return (
                        <Flex
                          bg="#2B2D421A"
                          px="3"
                          py="1"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text>
                            {[member.user?.first_name, member.user?.last_name]
                              .filter(Boolean)
                              .join(' ')}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Text color="default.gray.600" w="25%">
                    Date Added
                  </Text>
                  <Text color="default.primarytext">
                    {String(moment(values.created_at).format('MMMM DD, YYYY'))}
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Menu>
                  <MenuButton>
                    <Button
                      htmlType="menu"
                      variant="ghost"
                      iconName="ellipsis-h"
                      iconSize="xl"
                    />
                  </MenuButton>

                  <MenuList fontSize="sm">
                    <MenuItem onClick={() => handleEdit(values)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="pencil" />
                        <Box ml={12}>Edit</Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDelete(values)}
                      color="caution.800"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="trash" />
                        <Box ml={12}>Delete</Box>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            {/* <Flex
                            sx={{
                                justifyContent: 'flex-start',
                                fontSize: 'md',
                                fontWeight: 'bold',
                            }}
                            direction="column"
                            gap="5"
                        >
                            <Text>Description</Text>
                            <Flex>
                                {values.description !== "" && values.description
                                    ? <Text fontWeight="normal">{values.description}</Text>
                                    : <Text fontWeight="normal">No description available</Text>
                                }
                            </Flex>
                        </Flex> */}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
