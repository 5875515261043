import ReactPlayer from 'react-player';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import { Image } from '@chakra-ui/react';

import ZipImage from '@/assets/images/icons/zip.svg';
import ExcelImage from '@/assets/images/icons/excel.svg';
import CsvImage from '@/assets/images/icons/csv.svg';
import TxtImage from '@/assets/images/icons/txt.svg';
import UnknownImage from '@/assets/images/icons/unkown.svg';

import PDFViewer from '@/components/app/PDFViewer';

import { fileApplication } from '@/utils/fileApplication';
import { StreamModalProps } from './constants';

export function useStreamAttachmentServices({ file }: StreamModalProps) {
  const StreamComponent = () => {
    switch (fileApplication(file.file_name)) {
      case 'jpeg':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'jpg':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'png':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'zip':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ZipImage}
          />
        );
      case 'doc':
        const doc = [{ uri: file.signedUrl, fileType: 'doc' }];

        return (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={doc}
            style={{ width: '100%', height: 700 }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        );
      case 'docx':
        const docx = [{ uri: file.signedUrl, fileType: 'docx' }];

        return (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docx}
            style={{ width: '100%', height: 700 }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        );
      case 'csv':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={CsvImage}
          />
        );
      case 'xls':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ExcelImage}
          />
        );
      case 'xlsx':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ExcelImage}
          />
        );

      case 'txt':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={TxtImage}
          />
        );
      case 'pdf':
        return (
          // <Document file={file.signedUrl} onLoadSuccess={onDocumentLoadSuccess}>
          //   <Page pageNumber={pageNumber} />
          // </Document>
          <PDFViewer
            pdfUrl={file.signedUrl}
            // setResumeUrl={setResumeUrl}
            height="80vh"
          />
        );
      case 'mp4':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );
      case 'mov':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );
      case 'mpeg':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );

      case 'mp3':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );

      default:
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            objectFit="contain"
            src={UnknownImage}
          />
        );
    }
  };
  return { StreamComponent };
}
