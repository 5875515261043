import { useEffect, useRef, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import List from './List';
import {
  CANDIDATE_STATUS_ALL,
  CANDIDATE_STATUS_HIRED,
  CANDIDATE_STATUS_REJECTED,
  CANDIDATE_STATUS_SCREEN,
  status,
} from './constants';
import { useParams } from 'react-router';
import {
  listCandidates,
  useListCandidatesMutation,
  usePostJobViewCountMutation,
} from '@/store/candidates.slice';
import { useDispatch, useSelector } from 'react-redux';

export const JobCandidateStatus = () => {
  const { jobId, jobsTabNav } = useParams();

  const { candidatesCount, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  const candidatedFilterData: string | null =
    localStorage.getItem('candidatesFilter');
  const filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  const [items, setItems]: any = useState(CANDIDATE_STATUS_ALL);

  const [reqCandidates] = useListCandidatesMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    let data: any = CANDIDATE_STATUS_ALL;
    switch (jobsTabNav) {
      case 'screen':
        data = CANDIDATE_STATUS_SCREEN;
        break;
      case 'hired':
        data = CANDIDATE_STATUS_HIRED;
        break;
      case 'rejected':
        data = CANDIDATE_STATUS_REJECTED;
        break;
      default:
        data = data;
        break;
    }

    const updateCounts = (items: any, candidatesCount: any) => {
      return items.map((item: any) => {
        let matchingCandidate = candidatesCount.find(
          (candidate: any) => candidate.id === item.stat_id
        );

        if (matchingCandidate) {
          item.count = matchingCandidate.count;
        }

        if (item.items) {
          item.items = item.items.map((subItem: any) => {
            if (matchingCandidate) {
              let matchingSubCandidate = matchingCandidate.sub.find(
                (candidate: any) => candidate.id === subItem.stat_id
              );
              if (matchingSubCandidate) {
                subItem.count = matchingSubCandidate.count;
              }
            }
            return subItem;
          });
        }

        return item;
      });
    };

    updateCounts(data[0].items, candidatesCount);

    switch (jobsTabNav) {
      case 'screen':
        data = [CANDIDATE_STATUS_SCREEN[0].items[0]];
        break;
      case 'hired':
        data = [CANDIDATE_STATUS_HIRED[0].items[0]];
        break;
      case 'rejected':
        data = [CANDIDATE_STATUS_REJECTED[0].items[0]];
        break;
      default:
        data = data;
        break;
    }

    setItems(data);
  }, [jobsTabNav, candidatesCount]);

  const setStatus = (root: any, status: any) => {
    root.status = status;
    if (Array.isArray(root.items)) {
      return root.items.forEach((item: any) => {
        setStatus(item, status);
      });
    }
  };

  const computeStatus = (items: any) => {
    let checked = 0;
    let indeterminate = 0;

    items.forEach((item: any) => {
      if (item.status && item.status === status.checked) checked++;
      if (item.status && item.status === status.indeterminate) indeterminate++;
    });

    if (checked === items.length) {
      return status.checked;
    } else if (checked > 0 || indeterminate > 0) {
      return status.indeterminate;
    }
  };

  // Depth-first traversal
  const traverse = (root: any, needle: any, status: any) => {
    let id;
    let items;

    if (Array.isArray(root)) {
      items = root;
    } else {
      id = root.id;
      items = root.items;
    }

    // return if needle is found
    // we don't have to compute the status of the items if root.id === needle
    if (id === needle) {
      return setStatus(root, status);
    }

    if (!items) {
      return root;
    } else {
      items.forEach((item: any) => traverse(item, needle, status));
      root.status = computeStatus(items);
    }
  };

  const compute = (checkboxId: any, status: any) => {
    traverse(items, checkboxId, status);
    setItems(items.slice());
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    let status: any = [];
    let job_status: any = [];

    items.forEach((item: any) => {
      if (item.status === 1 || item.status === -1) {
        if (item.number_order !== undefined) {
          status.push(item.stat_id);
        }
      }

      if (item.items) {
        item.items.forEach((subItem: any) => {
          if (subItem.status === 1 || subItem.status === -1) {
            if (subItem.number_order !== undefined) {
              status.push(subItem.stat_id);
            } else {
              job_status.push(subItem.stat_id);
            }
          }
          subItem?.items?.forEach((ssubItem: any) => {
            if (ssubItem.status === 1) {
              job_status.push(ssubItem.stat_id);
            }
          });
        });
      }
    });

    // Blasted Sub
    if (status.includes(8)) {
      job_status = [...job_status, 3];
    }

    // Submits Sub
    if (status.includes(1)) {
      job_status.push(5);
    }

    // Interview Sub
    if (status.includes(3)) {
      job_status.push(6);
    }

    // Offers Sub
    if (status.includes(4)) {
      job_status.push(7);
    }

    const isStatus = String(status) === String([8, 2, 1, 3, 4, 5, 6]);
    const isJobStatus =
      String(job_status) ===
      String([1, 2, 4, 13, 17, 18, 8, 9, 11, 12, 14, 15, 16, 3, 5, 6, 7]);

    const payload = {
      ...filter,
      job_id: jobId,
      status: isStatus && jobsTabNav === 'all' ? null : status,
      job_status: isJobStatus && jobsTabNav === 'all' ? null : job_status,
      filter: null,
    };

    // if (abortControllerRef.current) {
    //   abortControllerRef.current.abort();
    // }

    // abortControllerRef.current = new AbortController();

    // reqCandidates({
    //   data: payload,
    //   signal: abortControllerRef.current.signal,
    // });
    dispatch(
      listCandidates({
        candidatePgBtn: payload,
      })
    );

    //removing jobsTabNav, candidatesCount  since this is resulting to execute this hook for more than 7 times, resulting to call the api at the same time
  }, [items, jobId, jobsTabNav, candidatesCount]);

  return (
    <Stack
      sx={{
        'div:first-child': {
          pl: 0,
        },
        mr: '10px',
      }}
    >
      <List items={items} compute={compute} />
    </Stack>
  );
};
