/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from '@/store/template.slice';

import { useDisclosure, useToast } from '@chakra-ui/react';
import { cleanUpload, uploadList } from '@/store/uploads.slice';

import { useSendEmailContactMutation } from '@/store/contact.slice';
import * as Yup from 'yup';
import { replacePlaceholders } from '@/utils/placeholders';
import { useSendLeadsBulkEmailMutation } from '@/services/leads/leads.mutation';
import escapeRegExp from '@/utils/escapeRegExp';
import useTemplate from '../hooks/useTemplate';

interface EmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  id?: number | string;
  subject?: string;
  ids?: Array<number>;
}
export const useEmailsModalServices = ({
  isOpen,
  onClose,
  email,
  id,
  subject,
  ids,
}: EmailModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { placehoderPair } = useSelector((state: any) => state.template);
  const { userList } = useSelector((state: any) => state.user);

  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          attachments: template?.attachments,
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };

  const { uploaded } = useSelector((state: any) => state.uploads);

  const { defaultTemplate, isTemplatesFetching, templates, refetchTemplates } =
    useTemplate({ groupId: 3 });
  const templateList = useMemo(() => {
    return (
      templates?.map((template: any) => ({
        value: template.id,
        label: template.title,
        subject: template.subject,
        body: template.body,
        attachments: template.attachments,
      })) ?? []
    ).concat({
      value: null,
      label: 'Create New Template',
      subject: '',
      body: '',
      attachments: null,
    });
  }, [templates]);

  const [reqEmail, resEmail] = useSendEmailContactMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqBulkEmail, resBulkEmail] = useSendLeadsBulkEmailMutation();

  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [quilValue, setquilValue] = useState(defaultTemplate?.body || '');
  const [ccBcc, setCcBcc] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [bccValue, setBccValue] = useState([]);
  const [ccValue, setCcValue] = useState([]);

  const initialValues: any = useMemo(() => {
    return {
      subject: subject || defaultTemplate?.subject,
      body: defaultTemplate?.body ?? '',
      to: email,
      cc: [] as any,
      bcc: [] as any,
      template: defaultTemplate ?? null,
    };
  }, [defaultTemplate, email, subject]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [tokenLink, setTokenLink] = useState('');
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };
  const replaceWithPlaceholder = (text: string) => {
    for (const placeholder in placehoder) {
      if (placehoder[placeholder] !== '') {
        const escapedPlaceholder = escapeRegExp(
          String(placehoder[placeholder])
        );
        const regex = new RegExp(escapedPlaceholder, 'g');
        text = text?.replace(regex, `{{${placeholder}}}`);
      }
    }
    return text;
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      template: Yup.object()
        .shape({ label: Yup.string(), value: Yup.number() })
        .required('Template is required.'),
      to: ids?.length
        ? Yup.string()
        : Yup.string()
            .email('Invalid email format')
            .required('Email is required.'),
    })
  );

  const replyEmail = (submitData: any) => {
    const data = { ...submitData };
    delete data.template;
    const isBulkEmail = ids;
    data['attachments'] = uploaded || [];
    data['att_template'] = attachments || [];

    let cc: any = [];
    if (ccValue && ccValue?.length > 0) {
      ccValue.forEach((item: any) => {
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (bccValue && bccValue?.length > 0) {
      bccValue.forEach((item: any) => {
        bcc.push(item.value);
      });
    }
    if (isBulkEmail) {
      const newParam = {
        bcc: bcc,
        cc: cc,
        subject: replaceWithPlaceholder(data.subject),
        body: data.body,
        att_template: attachments || [],
        lead_ids: ids,
      };
      reqBulkEmail({ data: newParam });
    } else {
      const newParam = {
        to: data.to,
        bcc: bcc,
        cc: cc,
        subject: placholders(data.subject),
        body: placholders(data.body),
        att_template: attachments || [],
      };

      reqEmail({ data: newParam, id });
    }
  };

  const handleClose = () => {
    onClose();
  };
  const templateCallback = (setFieldValue: any) => {
    refetchTemplates().then((res: any) => {
      const data = res.data.data;

      const highestIdIndex = data.reduce(
        (maxIndex: any, item: any, index: any, array: any) =>
          item.id > array[maxIndex].id ? index : maxIndex,
        0
      );

      const temp = data[highestIdIndex];

      setFieldValue('template', { ...temp, value: temp.id, label: temp.title });
      setFieldValue('body', temp.body);
      setFieldValue('subject', temp.subject);
    });
  };
  useEffect(() => {
    if (isOpen) {
      const data = {
        contact_id: id,
      } as any;
      reqPair(data);
    }
  }, [isOpen]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));

    let ccOption = [] as any;
    Object.values(userList).forEach((item: any) => {
      ccOption.push({
        label: `${[item.first_name, item.last_name]
          .filter(Boolean)
          .join(' ')} <${item.email}>`,
        value: item.email,
      });
    });
    setCcBcc(ccOption);
    setBcc(ccOption);
  }, [id]);

  useEffect(() => {
    if (resEmail.isError || resBulkEmail.isError) {
      const error: any = resEmail.error || resBulkEmail.error;
      try {
        const message =
          error?.data?.details || error?.data?.data?.message || 'Unkown error';
        if (error?.data?.data?.type === 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.error('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resEmail.isError, resBulkEmail.isError]);

  useEffect(() => {
    if (resEmail.isSuccess || resBulkEmail.isSuccess) {
      toast({
        title: 'Email sent',
        description: 'We have sent your email',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      dispatch(uploadList({ uploaded: [], attachments: [] }));
      onClose();
    }
  }, [resEmail.isSuccess, resBulkEmail.isSuccess]);

  return {
    initialValues,
    replyEmail,
    validationSchema,
    ccBcc,
    populateTemplate,
    clientTemplate: defaultTemplate,
    template: templateList,
    attachments,
    setAttachments,
    setquilValue,
    placholders,
    bcc,
    quilValue,
    isLoading: resEmail.isLoading || resBulkEmail.isLoading,
    handleClose,
    previewOpen,
    setPreviewOpen,
    isOpenAlert,
    onCloseAlert,
    openAlert,
    tokenLink,
    errMsg,
    bccValue,
    setBccValue,
    ccValue,
    setCcValue,
    templateCallback,
    placehoder,
    isTemplatesFetching,
  };
};
