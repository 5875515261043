/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  Checkbox,
  Radio,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Flex,
  Tag,
} from '@chakra-ui/react';
import TrLoading from '@/components/app/TrLoading';
import FAIcon from '@/components/lib/FAIcon';
import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailTemplateListMutation,
} from '@/store/outreach.slice';
import { EmailTemplateDrawer } from '../Drawer';
import EmailTemplateModal from '../Modal';

interface EmailTemplateProps {
  emailTemplateList: any;
  emailTemplatePgBtn: any;
  isCheck: any;
  isIndeterminate: any;
  isCheckAll: any;
  handleCheck: any;
  handleSelectAll: any;
  handleChangePrimary: any;
  handleBulkDeleteEmailTemplate: any;
  handleSyncPrimary: any;
  rowId: any;
  emailTemplateData: any;
  totalTemplateList: any;
  handlePrevData: any;
  handleNextData: any;
  handleSendEmail: any;
  isLoading: boolean;
  initialValueEmail: any;
  validationSchemaEmail: any;
  emailTemplatePage: any;
  pageChange: any;
  entryChange: any;
}
const EmailTemplateTable = ({
  emailTemplateList,
  emailTemplatePgBtn,
  isCheck,
  isIndeterminate,
  isCheckAll,
  handleCheck,
  handleSelectAll,
  handleChangePrimary,
  handleBulkDeleteEmailTemplate,
  handleSyncPrimary,
  rowId,
  emailTemplateData,
  totalTemplateList,
  handlePrevData,
  handleNextData,
  handleSendEmail,
  isLoading,
  initialValueEmail,
  validationSchemaEmail,
  emailTemplatePage,
  pageChange,
  entryChange,
}: EmailTemplateProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    isOpen: isOpenEditEmailTemplateModal,
    onOpen: onOpenEditEmailTemplateModal,
    onClose: onCloseEditEmailTemplateModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenEmailTemplateDrawer,
    onOpen: onOpenEmailTemplateDrawer,
    onClose: onCloseEmailTemplateDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  const [reqGetEmailTemplateList, resGetEmailTemplateList] =
    useGetEmailTemplateListMutation();

  useEffect(() => {
    if (params.settingTypeId) {
      reqGetEmailTemplateList(emailTemplatePgBtn);
    }
  }, [params.settingTypeId]);

  useEffect(() => {
    if (
      resGetEmailTemplateList.isSuccess &&
      params.settingType === 'email-template' &&
      params.settingTypeId
    ) {
      const mappedEmailTemplates = emailTemplateList
        .map((template: any, index: any) => {
          return {
            ...template,
            rowId: index + 1,
          };
        })
        .filter((x: any) => x.id === Number(params.settingTypeId))[0];

      dispatch(
        updateOutreach({
          emailTemplateData: mappedEmailTemplates,
        })
      );

      onOpenEmailTemplateDrawer();
    }
  }, [
    resGetEmailTemplateList.isSuccess,
    params.settingType,
    params.settingTypeId,
  ]);

  return (
    <Box px={1} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button
            variant="basic"
            leftIcon="trash"
            size="sm"
            onClick={handleBulkDeleteEmailTemplate}
          >
            Delete Selected
          </Button>
        </Flex>
      )}

      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th width="50px">
                <Checkbox
                  onChange={handleSelectAll}
                  isIndeterminate={isIndeterminate}
                  checked={isCheckAll}
                  defaultChecked={false}
                  colorScheme="purple"
                />
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Default
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Email Template Title
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Created By
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Role
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Date Added
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              ></Th>
            </Tr>
          </Thead>
          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            {isLoading ? (
              <TrLoading rows={3} columns={7} />
            ) : (
              emailTemplateList?.map((item: any, index: number) => {
                return (
                  <Tr cursor="pointer">
                    <Td>
                      <Checkbox
                        onChange={handleCheck}
                        isChecked={isCheck.includes(item.id)}
                        id={item?.id?.toString()}
                        key={item.id}
                        colorScheme="purple"
                      />
                    </Td>
                    <Td textAlign="center">
                      <Radio
                        colorScheme="purple"
                        isChecked={item.primary}
                        onChange={() => handleChangePrimary(item.id)}
                      />
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailTemplateDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.title}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailTemplateDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {[
                        item?.userCreated?.first_name,
                        item?.userCreated?.last_name,
                      ].join(' ')}
                    </Td>
                    <Td
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => {
                        onOpenEmailTemplateDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.role}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailTemplateDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {moment(item.created_at).format('MMMM DD, YYYY')}
                    </Td>
                    <Td>
                      <Menu closeOnSelect={false}>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                        >
                          <MenuItem
                            icon={<FAIcon iconName="pencil" />}
                            onClick={() => {
                              dispatch(
                                updateOutreach({
                                  emailTemplateData: {
                                    ...item,
                                    rowId: index + 1,
                                  },
                                })
                              );
                              onOpenEditEmailTemplateModal();
                            }}
                          >
                            Edit
                          </MenuItem>
                          {item.primary && (
                            <MenuItem
                              icon={<FAIcon iconName="sync" />}
                              onClick={() => {
                                handleSyncPrimary(item.id);
                              }}
                            >
                              Sync
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>

        {isOpenEditEmailTemplateModal && (
          <EmailTemplateModal
            isEdit
            isOpen={isOpenEditEmailTemplateModal}
            onClose={onCloseEditEmailTemplateModal}
          />
        )}

        {isOpenEmailTemplateDrawer && (
          <EmailTemplateDrawer
            isOpen={isOpenEmailTemplateDrawer}
            onOpen={onOpenEditEmailTemplateModal}
            onClose={onCloseEmailTemplateDrawer}
            rowId={rowId}
            emailTemplateData={emailTemplateData}
            totalTemplateList={totalTemplateList}
            handlePrevData={handlePrevData}
            handleNextData={handleNextData}
            handleSendEmail={handleSendEmail}
            isLoading={isLoading}
            initialValueEmail={initialValueEmail}
            validationSchemaEmail={validationSchemaEmail}
          />
        )}
      </TableContainer>

      <Box sx={{ boxShadow: '0px -4px 8px 0px #0000000A', mt: '32px' }}>
        <Pagination
          totalPages={emailTemplatePage.lastPage}
          currentPage={emailTemplatePage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={emailTemplatePage.count}
          onEntryChange={(entries) => entryChange(entries)}
          currentCount={emailTemplateList.length}
          targetCount={emailTemplatePgBtn.take}
        />
      </Box>
    </Box>
  );
};
export default EmailTemplateTable;
