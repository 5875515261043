import { Flex, Image, Stack, Text } from '@chakra-ui/react';

import Highlight from '@/assets/images/inboxes/MdHighlight.svg';

import MailDefault from '@/assets/images/inboxes/MdMailDefault.svg';
import SMSDefault from '@/assets/images/inboxes/MdSMSDefault.svg';
import PhoneDefault from '@/assets/images/inboxes/MdPhoneDefault.svg';
import TaskDefault from '@/assets/images/inboxes/MdTaskDefault.svg';
import CalendarDefault from '@/assets/images/inboxes/MdCalendarDefault.svg';

import MailActive from '@/assets/images/inboxes/MdMailActive.svg';
import PhoneActive from '@/assets/images/inboxes/MdPhoneActive.svg';
import TaskActive from '@/assets/images/inboxes/MdTaskActive.svg';
import CalendarActive from '@/assets/images/inboxes/MdCalendarActive.svg';

import { useNavigate, useParams } from 'react-router';

interface SideBarProps {
  setSidebarIndex: any;
}

export const SideBar = ({ setSidebarIndex }: SideBarProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const SideBarItem = [
    {
      id: 0,
      label: 'Emails',
      image: MailDefault,
      imageActive: MailActive,
      to: 'emails',
    },
    // {
    //   id: 1,
    //   label: 'SMS',
    //   image: SMSDefault,
    //   imageActive: SMSDefault,
    //   to: 'sms',
    // },
    // {
    //   id: 2,
    //   label: 'Phone Calls',
    //   image: PhoneDefault,
    //   imageActive: PhoneActive,
    //   to: 'phone-calls',
    // },
    // {
    //   id: 3,
    //   label: 'General Task',
    //   image: TaskDefault,
    //   imageActive: TaskActive,
    //   to: 'general-task',
    // },
    // {
    //   id: 4,
    //   label: 'Calendar',
    //   image: CalendarDefault,
    //   imageActive: CalendarActive,
    //   to: 'calendar',
    // },
  ];

  return (
    <Stack
      sx={{
        bgColor: '#fff',
        borderRadius: '10px',
        width: '15%',
        height: '100vh',
      }}
    >
      <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
        {SideBarItem.map((val) => {
          const isActive = params.settingTab === val.to;
          return (
            <Flex
              gap="10px"
              sx={{
                alignItems: 'center',
                p: '4px 8px',
                cursor: 'pointer',
                borderRadius: '4px',
                background: isActive && '#FAF6FF',
                color: isActive ? '#6930CA' : '#2B2D42',
              }}
              _hover={{
                borderRadius: '4px',
                background: '#FAF6FF',
                color: '#6930CA',
                p: {
                  color: '#6930CA',
                },
                svg: {
                  fill: '#6930CA',
                },
              }}
              onClick={() => {
                setSidebarIndex(val.id);
                navigate(`/inbox/${val.to}`);
              }}
            >
              {isActive && (
                <Image
                  src={Highlight}
                  height="35px"
                  width="4px"
                  sx={{ position: 'absolute', left: 0 }}
                />
              )}
              <Image
                src={isActive ? val.imageActive : val.image}
                height="24px"
                width="24px"
              />
              <Text sx={{ fontSize: '16px', fontWeight: 600 }}>
                {val.label}
              </Text>
            </Flex>
          );
        })}
      </Stack>
    </Stack>
  );
};
