import { Flex, Grid, GridItem, Image, Link, Text } from '@chakra-ui/react';
import toUrl from '@/utils/toUrl';
import linkedInImg from '@/assets/images/linkedin.svg';
import NotesBtn from '../../components/NotesBtn';

export default function ProspectDetails({
  id,
  first_name,
  last_name,
  onDetailOpen,
  linked_in_url,
  isNotesExists,
}: {
  id: number;
  first_name: string;
  last_name: string;
  onDetailOpen: () => void;
  linked_in_url: string;
  isNotesExists: boolean;
}) {
  return (
    <Grid
      columnGap="20px"
      w="100%"
      templateColumns="repeat(9,1fr)"
      alignItems={'center'}
    >
      <GridItem colSpan={8}>
        <Flex
          gap="6px"
          alignItems="center"
          onClick={onDetailOpen}
          cursor={'pointer'}
        >
          <Text isTruncated fontSize="medium" fontWeight={700}>
            {first_name} {last_name}
          </Text>
          <Link
            href={toUrl(linked_in_url)}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <Image
              src={linkedInImg}
              alt="linkedin"
              width="16px"
              height="16px"
            />
          </Link>
        </Flex>
      </GridItem>
      <GridItem colSpan={1}>
        <NotesBtn isNotesExits={isNotesExists} leadId={id} />
      </GridItem>
    </Grid>
  );
}
