import { Box, Image } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import MdLoading from '@/assets/images/ai-generator/MdLoading.gif';

const TypingEffect = ({ text, isLoading, selected, pageNo }: any) => {
  const speed = 20;
  const [displayedText, setDisplayedText] = useState('');
  const [displayText, setDisplayText] = useState(displayedText);
  const [index, setIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (displayedText) {
      setDisplayText(displayedText);
    }
  }, [displayedText]);

  useEffect(() => {
    if (isLoading && selected && text === displayText) {
      setDisplayedText('');
      setIndex(0);
      setIsComplete(false);
    } else if (text !== displayText) {
      setDisplayedText(text);
    } else {
      if (index < text.length) {
        const typeTimeout = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, speed);

        return () => clearTimeout(typeTimeout);
      } else {
        setIsComplete(true);
      }
    }
  }, [isLoading, selected, index, text, speed, displayText, pageNo]);

  return (
    <>
      {isLoading && selected ? (
        <Box
          sx={{
            '& img': {
              display: 'inline-block',
              verticalAlign: 'middle',
            },
          }}
        >
          <span style={{ display: 'inline-block' }}>
            <Image src={MdLoading} height="12px" width="12px" />
          </span>
        </Box>
      ) : (
        <Box
          sx={{
            '& img': {
              display: 'inline-block',
              verticalAlign: 'middle',
            },
          }}
        >
          {parse(displayedText)}{' '}
          {!isComplete && (
            <span style={{ display: 'inline-block' }}>
              <Image src={MdLoading} height="12px" width="12px" />
            </span>
          )}
        </Box>
      )}
    </>
  );
};

export default TypingEffect;
