import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { updateInbox, usePostTaskListMutation } from '@/store/inbox.slice';

export const useEmailsServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [search, setSearch] = useState('');

  const [reqPostTaskList, resPostTaskList] = usePostTaskListMutation();

  const { inboxPgBtn, inboxList, inboxData, countPending, countDone } =
    useSelector((state: any) => state.inbox);

  const handleSearch: any = debounce((e: any) => {
    let query = e.target.value;

    if (search === '') {
      query = '';
    }

    const params = {
      ...inboxPgBtn,
      query: query,
    };

    dispatch(updateInbox({ inboxPgBtn: params }));
    if (query.length >= 3 || search.length >= 3) {
      reqPostTaskList(params);
    }
  }, 300);

  const [active, setActive] = useState(0);

  const switchContent = [
    { id: 0, label: 'Pending', value: countPending },
    { id: 1, label: 'Done', value: countDone },
  ];

  useEffect(() => {
    navigate(`${params.settingTab}`);
    reqPostTaskList({ ...inboxPgBtn, task_status: active === 1 ? 2 : 1 });
    dispatch(
      updateInbox({
        inboxData: {},
        inboxPgBtn: { ...inboxPgBtn, task_status: active === 1 ? 2 : 1 },
      })
    );
  }, [active]);

  return {
    search,
    setSearch,
    handleSearch,
    active,
    setActive,
    switchContent,
    inboxData,
    isLoading: resPostTaskList.isLoading,
  };
};
