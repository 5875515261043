import { Box, Flex, Link, Image, Text } from '@chakra-ui/react';
import moment from 'moment';
import { fontWeights } from '@/theme/theme';
import replacePTags from '@/utils/replaceTags';
import { ActivityInformationProps } from '../types';
import useActivityInformationServices from '../useActivityInformationServices';
import GoTo from '@/assets/images/goto.svg';

export const ActivityInformation = ({
  logs,
  type,
  message,
  handleOpenPlacementForm,
}: ActivityInformationProps) => {
  const {
    convertMilitaryToStandard,
    isExpanded,
    auth,
    handleToggleExpand,
    handleFieldName,
  } = useActivityInformationServices({
    logs,
    type,
    message,
    handleOpenPlacementForm,
  });

  return (
    <Flex>
      {/* TAGS */}
      {type === 'tag' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%">
          {logs?.data?.join(', ')}
        </Flex>
      )}

      {/* FIELD NAME UPDATED */}
      {type === 'update' && !message?.includes('deleted') && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%" direction="column" gap="16px">
          {logs?.prevData &&
            Object.keys(logs?.prevData)?.map((fieldName: any) => {
              switch (fieldName) {
                case 'emails':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>
                        {logs?.prevData?.[fieldName]
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>{' '}
                      to{' '}
                      <b>
                        {logs?.data?.data?.[fieldName]
                          ?.map((email: any) => email?.email)
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>
                    </Box>
                  );
                  break;
                case 'contact':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>
                        {logs?.prevData?.[fieldName]
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>{' '}
                      to{' '}
                      <b>
                        {logs?.data?.data?.[fieldName]
                          ?.map((contact: any) => contact?.number)
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>
                    </Box>
                  );
                  break;
                case 'skills':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>
                        {logs?.prevData?.[fieldName]
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>{' '}
                      to{' '}
                      <b>
                        {logs?.data?.data?.[fieldName]
                          ?.map((skill: any) => skill?.skill?.skill)
                          ?.filter(Boolean)
                          ?.join(', ') || 'null'}
                      </b>
                    </Box>
                  );
                  break;
                case 'source':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>{logs?.prevData?.[fieldName] || 'null'}</b> to{' '}
                      <b>
                        {logs?.data?.data?.[fieldName]?.source ||
                          logs?.data?.data?.[fieldName] ||
                          'null'}
                      </b>
                    </Box>
                  );
                  break;
                case 'sub_source':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>{logs?.prevData?.[fieldName] || 'null'}</b> to{' '}
                      <b>
                        {logs?.data?.data?.['subsource']?.sub_source ||
                          logs?.data?.data?.['subsource'] ||
                          'null'}
                      </b>
                    </Box>
                  );
                  break;
                case 'experiences':
                  return (
                    <Flex direction="column" gap="16px">
                      {logs?.prevData?.[fieldName] &&
                        logs?.prevData?.[fieldName].map(
                          (exp: any, index: number) => {
                            if (Object.keys(exp).length > 0) {
                              return (
                                <Flex gap="8px" direction="column">
                                  {logs?.prevData?.[fieldName]?.length > 1 ? (
                                    <Box key={`header-${index}`}>{`Experience ${
                                      index + 1
                                    }`}</Box>
                                  ) : (
                                    <Box key={`header-${index}`}>
                                      {' '}
                                      {`Experiences`}
                                    </Box>
                                  )}
                                  <Flex gap="4px" direction="column">
                                    {Object.keys(exp).map(
                                      (field: string, fieldIndex: number) => {
                                        return (
                                          <Box
                                            key={`${index}-${field}-${fieldIndex}`}
                                          >
                                            {handleFieldName(field)} updated
                                            from{' '}
                                            <b>
                                              {String(exp?.[field]) || 'null'}
                                            </b>{' '}
                                            to{' '}
                                            <b>
                                              {String(
                                                logs?.data?.experiences?.[
                                                  index
                                                ]?.[field]
                                              ) || 'null'}
                                            </b>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Flex>
                                </Flex>
                              );
                            }
                          }
                        )}
                    </Flex>
                  );
                  break;
                case 'educations':
                  return (
                    <Flex direction="column" gap="16px">
                      {logs?.prevData?.[fieldName] &&
                        logs?.prevData?.[fieldName]?.map(
                          (educ: any, index: number) => {
                            if (Object.keys(educ).length > 0) {
                              return (
                                <Flex gap="8px" direction="column">
                                  {logs?.prevData?.[fieldName]?.length > 1 ? (
                                    <Box key={`header-${index}`}>{`Education ${
                                      index + 1
                                    }`}</Box>
                                  ) : (
                                    <Box key={`header-${index}`}>
                                      {' '}
                                      {`Educations`}
                                    </Box>
                                  )}
                                  <Flex gap="4px" direction="column">
                                    {Object.keys(educ).map(
                                      (field: string, fieldIndex: number) => {
                                        return (
                                          <Box
                                            key={`${index}-${field}-${fieldIndex}`}
                                          >
                                            {handleFieldName(field)} updated
                                            from{' '}
                                            <b>
                                              {String(educ[field]) || 'null'}
                                            </b>{' '}
                                            to{' '}
                                            <b>
                                              {String(
                                                logs?.data?.educations?.[
                                                  index
                                                ]?.[field]
                                              ) || 'null'}
                                            </b>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Flex>
                                </Flex>
                              );
                            }
                          }
                        )}
                    </Flex>
                  );
                  break;
                case 'work_status':
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>{logs?.prevData?.[fieldName] || 'null'}</b> to{' '}
                      <b>{logs?.data?.data?.[fieldName]?.status || 'null'}</b>
                    </Box>
                  );
                  break;
                default:
                  return (
                    <Box>
                      {handleFieldName(fieldName)} updated from{' '}
                      <b>{logs?.prevData?.[fieldName] || 'null'}</b> to{' '}
                      <b>{logs?.data?.data?.[fieldName] || 'null'}</b>
                    </Box>
                  );
              }
            })}
        </Flex>
      )}
      {/* CANDIDATE STATUS */}
      {type === 'change' &&
        (logs?.data?.sub?.sub_status || logs?.data?.status) && (
          <Flex bg="#F9F9FB" px="4" py="2" w="100%">
            <Box>
              Candidate Status: <b>{logs?.prev?.sub_status || 'null'}</b> to{' '}
              <b>
                {logs?.data?.sub?.sub_status ||
                  logs?.data?.status?.sub?.[0]?.sub_status ||
                  'null'}
              </b>
            </Box>
          </Flex>
        )}

      {/* NOTES */}
      {type === 'note' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%">
          {/* {auth?.id === logs?.user?.id && ( */}
          <Flex alignItems={'flex-start'} gap="1" flexDirection="column">
            <Text>Notes:</Text>
            <Box
              // maxHeight={isExpanded ? 'none' : '4.8em'} // 3 lines with line-height of 1.6em each
              overflow="hidden"
              textOverflow="ellipsis"
              // whiteSpace={isExpanded ? 'normal' : 'nowrap'}
              dangerouslySetInnerHTML={{
                __html: replacePTags(logs?.data?.note),
              }}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: isExpanded ? 'unset' : 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            />
            {/* <Box
                                as="button"
                                fontWeight="bold"
                                color="#6930CA"
                                onClick={handleToggleExpand}
                            >
                                {isExpanded ? 'See less' : 'See more'}
                            </Box> */}
          </Flex>
          {/* )} */}
        </Flex>
      )}
      {/* EMAIL */}
      {type === 'email' && logs?.user?.id === auth?.id && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%" direction="column">
          <Box fontWeight="bold">{logs?.data?.subject}</Box>
          {/* TO ADD FROM */}
          {/* <Box ><b>From </b>{logs.data?.from || ""}</Box> */}
          <Box>
            <b>From </b>
            {logs?.data?.from || ''}
          </Box>
          <Box>
            <b>To </b>
            {logs?.data?.to || ''}
          </Box>
          <Box>
            <b>CC </b>
            {logs?.data?.cc !== null
              ? Array.isArray(logs?.data?.cc)
                ? logs?.data?.cc?.join(',')
                : logs?.data?.cc?.split(',')?.join(', ')
              : ''}
          </Box>
          <Box>
            <b>BCC </b>
            {logs?.data?.bcc !== null
              ? Array.isArray(logs?.data?.bcc)
                ? logs?.data?.bcc?.join(',')
                : logs?.data?.bcc?.split(',')?.join(', ')
              : ''}
          </Box>
          <Box>
            <b>Date </b>
            {logs?.data?.created_at
              ? moment(logs?.data?.created_at).format('MMMM DD, YYYY')
              : ''}
          </Box>
          {/* {auth?.id === logs?.user?.id && ( */}
          {logs?.data?.body && (
            <Flex alignItems={'flex-start'} gap="1" flexDirection="column">
              <Box
                maxHeight={isExpanded ? 'none' : '4.8em'} // 3 lines with line-height of 1.6em each
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace={isExpanded ? 'normal' : 'nowrap'}
                dangerouslySetInnerHTML={{
                  __html: replacePTags(logs?.data?.body),
                }}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: isExpanded ? 'unset' : 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              />
              <Box
                as="button"
                fontWeight="bold"
                color="#6930CA"
                onClick={handleToggleExpand}
              >
                {isExpanded ? 'See less' : 'See more'}
              </Box>
            </Flex>
          )}

          {/* )} */}
        </Flex>
      )}
      {type === 'submit' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%" direction="column">
          <Box fontWeight="bold">{logs?.data?.subject || ''}</Box>
          {/* TO ADD FROM */}
          {/* <Box ><b>From </b>{logs.data?.from || ""}</Box> */}
          <Box>
            <b>From </b>
            {logs?.data?.from || ''}
          </Box>
          <Box>
            <b>To </b>
            {logs?.data?.to || ''}
          </Box>
          <Box>
            <b>CC </b>
            {logs?.data?.cc !== null
              ? Array.isArray(logs?.data?.cc)
                ? logs?.data?.cc?.join(',')
                : logs?.data?.cc?.split(',')?.join(', ')
              : ''}
          </Box>
          <Box>
            <b>BCC </b>
            {logs?.data?.bcc !== null
              ? Array.isArray(logs?.data?.bcc)
                ? logs?.data?.bcc?.join(',')
                : logs?.data?.bcc?.split(',')?.join(', ')
              : ''}
          </Box>
          <Box>
            <b>Date </b>
            {logs?.data?.created_at
              ? moment(logs?.data?.created_at).format('MMMM DD, YYYY')
              : ''}
          </Box>
          {/* {auth?.id === logs.user.id && ( */}
          {logs?.data?.body && (
            <Flex alignItems={'flex-start'} gap="1" flexDirection="column">
              <Box
                maxHeight={isExpanded ? 'none' : '4.8em'} // 3 lines with line-height of 1.6em each
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace={isExpanded ? 'normal' : 'nowrap'}
                dangerouslySetInnerHTML={{
                  __html: replacePTags(logs?.data?.body),
                }}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: isExpanded ? 'unset' : 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              />
              <Box
                as="button"
                fontWeight="bold"
                color="#6930CA"
                onClick={handleToggleExpand}
              >
                {isExpanded ? 'See less' : 'See more'}
              </Box>
            </Flex>
          )}

          {/* )} */}
        </Flex>
      )}
      {/* INTERVIEW */}
      {type === 'interview' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%" direction="column">
          {/* TO ADD */}
          {/* <Box><b>Interview Round: </b>{logs.data?.round}</Box> */}
          {!message.includes('re-scheduled') && (
            <Box>
              <b>Interview Round: </b>
              {logs?.data?.meeting_type}{' '}
              {logs?.data?.counter !== 0 ? logs?.data?.counter : ''}
            </Box>
          )}
          {!message.includes('cancelled') && (
            <>
              <Box>
                <b>Meeting Mode: </b>
                {logs?.data?.meeting_type}
              </Box>
              <Box>
                <b>Date and Time: </b>
                {logs?.data?.meeting_date
                  ? moment(logs?.data?.meeting_date).format('MMMM DD, YYYY')
                  : ''}{' '}
                {logs?.data?.meeting_start
                  ? convertMilitaryToStandard(logs?.data?.meeting_start)
                  : ''}
              </Box>
              {/* TO ADD */}
              {/* <Box><b>Panel Members: </b>{logs.data.panel_members.filter(Boolean).join(", ") || ''}</Box> */}
              <Box>
                <b>Panel Members: </b> {logs?.data?.panel?.join(', ') || ''}
              </Box>
              <Box
                as="span"
                display={isExpanded ? 'block' : 'flex'}
                alignItems="center"
                gap="1"
              >
                <b>Link: </b>
                <Box
                  maxWidth={isExpanded ? '100%' : '300px'}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={isExpanded ? 'normal' : 'nowrap'}
                  width="100%"
                  display={'inline-block'}
                >
                  <Link fontSize="16px" href={logs?.data?.meeting_location}>
                    {logs?.data?.meeting_location || ''}
                  </Link>
                </Box>
                <Box
                  as="button"
                  fontWeight="bold"
                  color="#6930CA"
                  onClick={handleToggleExpand}
                >
                  {isExpanded ? 'See less' : 'See more'}
                </Box>
              </Box>
            </>
          )}
        </Flex>
      )}
      {/* PLACEMENT FORM */}
      {type === 'forms' && (
        <Flex
          bg="#F9F9FB"
          px="4"
          py="2"
          w="100%"
          color="primary.800"
          fontWeight="bold"
          onClick={() => {
            handleOpenPlacementForm(logs?.data);
          }}
        >
          <Link fontSize="16px">
            <Flex gap="4px">
              View Placement Form
              <Image src={GoTo} />
            </Flex>
          </Link>
        </Flex>
      )}
      {/* SMS SENT */}
      {type === 'sms' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%">
          {logs?.data?.sms || ''}
        </Flex>
      )}

      {/* CALL */}
      {type === 'call' && (
        <Flex bg="#F9F9FB" px="4" py="2" w="100%" direction="column">
          <Box>
            <b>Status</b>
            {logs?.data?.result === 'Call connected'
              ? 'Connected'
              : 'Not Connected'}
          </Box>
          <Box>
            <b>Duration</b>
            {logs?.data?.duration || ''}
          </Box>
        </Flex>
      )}
      {/* <Box>{activity.user.name}</Box> */}
    </Flex>
  );
};
