import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AtsDropZone from '@/components/app/Dropzone';
import PDFViewer from '@/components/app/PDFViewer';
import { Field, FieldArray, Formik } from 'formik';
import Button from '@/Library/Button';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCandidate,
  useListCandidatesMutation,
  useManualCandidatesMutation,
} from '@/store/candidates.slice';
import {
  useGetCandidateWorkStatusMutation,
  useGetCountriesMutation,
} from '@/store/constant.slice';
import { CandidatesTypes } from '@/types';
import { changeUploadName } from '@/utils/newFilename';
import { cleanUpload, uploadList } from '@/store/uploads.slice';
import ScrollToFieldError from '@/components/app/ScrollError';
import { BsCurrencyDollar } from 'react-icons/bs';
import FAIcon from '@/components/lib/FAIcon';
import AtsSelect from '@/components/app/AtsSelect';
import AddressComponent from '../components/address';
import StreamAttachmentComponent from '../stream';
import { fileApplication } from '@/utils/fileApplication';

interface ManualResumeModalProps {
  isOpen: any;
  onClose: any;
  resumeData: any;
}

export default function ManualResumeModal({
  isOpen,
  onClose,
  resumeData,
}: ManualResumeModalProps) {
  const dispatch = useDispatch();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const { countries, candidate_work_status } = useSelector(
    (state: any) => state.constants
  );

  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);

  const [reqCountry] = useGetCountriesMutation();
  const [reqCandidates, resCandidate] = useListCandidatesMutation();
  const [reqCreateCandidate, resCreateCandidate] =
    useManualCandidatesMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();

  let initialValues: CandidatesTypes = {
    first_name: '',
    last_name: '',
    linkedin_url: '',
    profile_url: '',
    work_status: '',
    skills: [],
    zip_code: '',
    city: '',
    state_province: '',
    country: countryDefOption,
    // professional
    total_year_of_experience: 0,
    total_months_of_experience: 0,
    latest_job_title: '',
    latest_employer: '',
    latest_salary: 0,
    expected_salary_start: 0,
    expected_salary_end: 0,
    attachments: [],
    // exp
    experiences: [],
    // educ
    educations: [],
    // tags
    tags: [],
    //
    resume_link: '',
    emails: [
      {
        email: '',
        primary: true,
      },
    ],
    // contact
    contact: [
      {
        number: '',
        type: 'phone',
        primary: true,
        category: {
          value: 'Personal',
          label: <FAIcon iconName="user" />,
        },
      },
    ],
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      // personal
      first_name: Yup.string(),
      last_name: Yup.string().required('Last name is required.'),
      linkedin_url: Yup.string(),
      profile_url: Yup.string(),
      work_status: Yup.string().nullable(),
      skills: Yup.array().of(Yup.string()),
      zip_code: Yup.string(),
      city: Yup.string(),
      state_province: Yup.string(),
      country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
      // professional
      total_year_of_experience: Yup.number(),
      total_months_of_experience: Yup.number(),
      latest_job_title: Yup.string(),
      latest_employer: Yup.string(),
      latest_salary: Yup.string().matches(/^[\d,]+$/gi, 'Salary is invalid'),
      expected_salary_start: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      expected_salary_end: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      attachments: Yup.array().of(
        Yup.object().shape({
          file_name: Yup.string(),
          file_size: Yup.number(),
          file_type: Yup.string(),
          location: Yup.string(),
          original_file_name: Yup.string(),
        })
      ),
      // exp
      experiences: Yup.array().of(
        Yup.object().shape({
          company: Yup.string(),
          job_title: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
      // educ
      educations: Yup.array().of(
        Yup.object().shape({
          school: Yup.string(),
          major: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
      // tags
      tags: Yup.array().of(Yup.string()),
      //
      resume_link: Yup.string(),
      // emails
      emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string().required('Email is required'),
            primary: Yup.boolean(),
          })
        )
        .test(
          'emails-one-primary',
          'There should only be one primary email',
          (value) => {
            // Count the number of primary emails
            const primaryCount = value.filter((item) => item?.primary).length;

            // There should be exactly one primary email
            return primaryCount === 1;
          }
        )
        .test('email-unique', 'Email should be unique', (value) => {
          const numArr = value.map((item: any) => item.email);
          return numArr.length === new Set(numArr).size;
        }),
      // contact
      contact: Yup.array()
        .of(
          Yup.object().shape({
            number: Yup.string().matches(
              /^[\d ()+-.]+$/gi,
              'Contact is invalid'
            ),
            primary: Yup.boolean(),
            type: Yup.string(),
          })
        )
        .test(
          'contact-one-primary',
          'There should only be one primary contact',
          (value) => {
            // Count the number of primary contact
            const primaryCount = value.filter((item) => item?.primary).length;

            // There should be exactly one primary contact
            return primaryCount === 1;
          }
        )
        .test('contact-unique', 'Contact number should be unique', (value) => {
          const numArr = value.map((item: any) => item.number);
          return numArr.length === new Set(numArr).size;
        }),
    })
  );

  const [hasSkills, setHasSkills] = useState('');
  const [hasTags, setHasTags] = useState('');
  const [resumeUrl, setResumeUrl] = useState('');

  useEffect(() => {
    reqCountry({});
    reqCanWorkStat({});
  }, []);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: CandidatesTypes) => {
    data['attachments'] = await changeUploadName(uploaded, prefix);
    data.latest_salary = Number(String(data.latest_salary)?.replace(/,/g, ''));
    data.expected_salary_start = Number(
      String(data.expected_salary_start)?.replace(/,/g, '')
    );
    data.expected_salary_end = Number(
      String(data.expected_salary_end)?.replace(/,/g, '')
    );
    const checkEmailPrimary = data.emails.some(
      (item: any) => item.primary === true
    );
    data.emails[0].primary = checkEmailPrimary ? data.emails[0]?.primary : true;

    if (data.contact.length > 0) {
      const checkContactPrimary = data.contact.some(
        (item: any) => item.primary === true
      );
      data.contact[0].primary = checkContactPrimary
        ? data.contact[0]?.primary
        : true;
      const filteredContact = data.contact.filter(
        (item: any) => item.number !== ''
      );
      data.contact = filteredContact;
    }
    data.contact = data.contact.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });
    data.country = data.country.label;
    await reqCreateCandidate({ data, id: resumeData?.id });
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));

    if (resCreateCandidate.isSuccess) {
      onCloseAlert();
      onClose();
      dispatch(getCandidate({ candidateData: {} }));
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      reqCandidates({
        data: candidatePgBtn,
        signal: abortControllerRef.current.signal,
      });
    } else if (resCreateCandidate.isError) {
      onOpen();
    }
  }, [
    candidatePgBtn,
    resCreateCandidate.isError,
    resCreateCandidate.isSuccess,
  ]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption(item.country);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  const [isPdf, setIsPdf] = useState(true);

  useEffect(() => {
    const ext = fileApplication(resumeData?.file_name);
    setIsPdf(true);
    if (ext != 'pdf') {
      setIsPdf(false);
    }
  }, [resumeData]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Manual Resume
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0} overflowY="hidden">
            <Flex gap={2} overflowY="scroll">
              <Box
                width="65%"
                py={4}
                overflowY="scroll"
                borderRight="1px solid"
                borderColor="default.borderlight"
                px={6}
              >
                <Box textAlign="center">
                  {
                    //todo : check on why StreamAttachmentComponent is inifinitly rendering if the file is pdf
                  }
                  {isPdf ? (
                    <PDFViewer
                      pdfUrl={resumeData.signedUrl}
                      setResumeUrl={setResumeUrl}
                      height="75vh"
                    />
                  ) : (
                    <StreamAttachmentComponent
                      filename={resumeData?.file_name}
                      fileUrl={resumeData?.signedUrl}
                      setResumeUrl={setResumeUrl}
                      pdfHeight="60vh"
                      docHeight="63vh"
                    />
                  )}
                </Box>
              </Box>

              <Box width="45%" py={4}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <ScrollToFieldError />
                      {/* Personal Details */}
                      <Box
                        overflowY="scroll"
                        maxHeight="530px"
                        p="20px"
                        pt="0"
                        background="#fff"
                        borderRadius="8px"
                      >
                        <Box fontWeight="bold" fontSize="lg" mb="20px">
                          Personal Details
                        </Box>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.first_name && touched.first_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              First Name
                            </FormLabel>
                            <Input
                              id="first_name"
                              name="first_name"
                              type="text"
                              placeholder="First Name"
                              variant="outline"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.first_name)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.last_name && touched.last_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Last Name{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="last_name"
                              name="last_name"
                              type="text"
                              placeholder="Last Name"
                              variant="outline"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.last_name)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <Box overflowY="auto" maxHeight="450px" width="100%">
                            <FieldArray name="contact">
                              {({ remove, push }) => (
                                <div>
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Contact
                                  </FormLabel>

                                  {values.contact?.map(
                                    (cont: any, index: any) => (
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.contact && touched.contact
                                        )}
                                      >
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!(errors.contact as any[])?.[index]
                                              ?.number &&
                                              (touched.contact as any[])?.[
                                                index
                                              ]?.number
                                          )}
                                        >
                                          <Flex
                                            width="100%"
                                            direction="row"
                                            key={index}
                                            mb="12px"
                                            alignItems="center"
                                            gap="12px"
                                          >
                                            <Flex width="70%">
                                              <Field
                                                as={Input}
                                                name={`contact.${index}.number`}
                                                placeholder="Contact"
                                                size="md"
                                                type="text"
                                                onChange={handleChange}
                                              />
                                            </Flex>

                                            <Flex width="25%">
                                              <FormControl
                                                isInvalid={Boolean(
                                                  !!(errors.contact as any[])?.[
                                                    index
                                                  ]?.category &&
                                                    (
                                                      touched.contact as any[]
                                                    )?.[index]?.category
                                                )}
                                              >
                                                <Flex
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <AtsSelect
                                                    variant="outline"
                                                    menuPosition="fixed"
                                                    defaultValue={
                                                      values.contact[index]
                                                        .category
                                                    }
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `contact.${index}.category`,
                                                        e
                                                      );
                                                    }}
                                                    options={[
                                                      {
                                                        value: 'Personal',
                                                        label: (
                                                          <FAIcon iconName="user" />
                                                        ),
                                                      },
                                                      {
                                                        value: 'Work',
                                                        label: (
                                                          <FAIcon iconName="briefcase" />
                                                        ),
                                                      },
                                                    ]}
                                                    styles={{
                                                      zIndex: '9999',
                                                      indicatorSeparator:
                                                        () => {},
                                                      dropdownIndicator: (
                                                        defaultStyles: any
                                                      ) => ({
                                                        ...defaultStyles,
                                                        '& svg': {
                                                          width: '10px',
                                                        },
                                                      }),
                                                    }}
                                                  />
                                                </Flex>
                                              </FormControl>
                                            </Flex>

                                            <Flex
                                              width="5%"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <FormControl>
                                                <Flex
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Radio
                                                    id={`contact.${index}.primary`}
                                                    name={`contactPrimary`} // Set the same name for all radio buttons
                                                    value={index} // Use the index as the value
                                                    isChecked={cont.primary}
                                                    onChange={(e) => {
                                                      // Handle radio button change to update the selected row
                                                      const updatedContact =
                                                        values.contact.map(
                                                          (
                                                            contact: any,
                                                            key: number
                                                          ) => ({
                                                            ...contact,
                                                            primary:
                                                              key === index,
                                                          })
                                                        );
                                                      setFieldValue(
                                                        'contact',
                                                        updatedContact
                                                      );
                                                    }}
                                                  ></Radio>
                                                </Flex>
                                              </FormControl>
                                            </Flex>
                                            <Flex
                                              width="5%"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              {index > 0 && (
                                                <Flex
                                                  onClick={() => remove(index)}
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  color="#D5605D"
                                                  cursor="pointer"
                                                >
                                                  <FAIcon iconName="trash" />
                                                </Flex>
                                              )}
                                            </Flex>
                                          </Flex>
                                          <FormErrorMessage mb="12px">
                                            {String(
                                              (errors.contact as any[])?.[index]
                                                ?.number
                                            )}
                                          </FormErrorMessage>
                                        </FormControl>
                                        {index + 1 === values.contact.length &&
                                          typeof errors.contact !==
                                            'object' && (
                                            <FormErrorMessage mb="12px">
                                              {String(errors.contact)}
                                            </FormErrorMessage>
                                          )}
                                      </FormControl>
                                    )
                                  )}

                                  <Button
                                    leftIcon="plus"
                                    onClick={() => {
                                      push({
                                        number: '',
                                        type: 'phone',
                                        primary: false,
                                        category: {
                                          value: 'Personal',
                                          label: <FAIcon iconName="user" />,
                                        },
                                      });
                                    }}
                                  >
                                    Add Contact
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <Box overflowY="auto" maxHeight="450px" width="100%">
                            <FieldArray name="emails">
                              {({ remove, push }) => (
                                <div>
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Email
                                  </FormLabel>

                                  {values.emails?.map(
                                    (email: any, index: any) => (
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.emails && touched.emails
                                        )}
                                      >
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!(errors.emails as any[])?.[index]
                                              ?.email &&
                                              (touched.emails as any[])?.[index]
                                                ?.email
                                          )}
                                        >
                                          <Flex
                                            width="100%"
                                            direction="row"
                                            key={index}
                                            mb="12px"
                                            alignItems="center"
                                            gap="12px"
                                          >
                                            <Flex width="80%">
                                              <Field
                                                as={Input}
                                                name={`emails.${index}.email`}
                                                placeholder="Email Address"
                                                size="md"
                                                type="text"
                                                onChange={handleChange}
                                              />
                                            </Flex>

                                            <Flex
                                              width="10%"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Radio
                                                  id={`emails.${index}.primary`}
                                                  name={`emailsPrimary`} // Set the same name for all radio buttons
                                                  value={index} // Use the index as the value
                                                  isChecked={email.primary}
                                                  onChange={(e) => {
                                                    // Handle radio button change to update the selected row
                                                    const updatedEmails =
                                                      values.emails.map(
                                                        (
                                                          email: any,
                                                          key: number
                                                        ) => ({
                                                          ...email,
                                                          primary:
                                                            key === index,
                                                        })
                                                      );
                                                    setFieldValue(
                                                      'emails',
                                                      updatedEmails
                                                    );
                                                  }}
                                                ></Radio>
                                              </Flex>
                                            </Flex>

                                            <Flex
                                              width="10%"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              {index > 0 && (
                                                <Flex
                                                  onClick={() => remove(index)}
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  color="#D5605D"
                                                  cursor="pointer"
                                                >
                                                  <FAIcon iconName="trash" />
                                                </Flex>
                                              )}
                                            </Flex>
                                          </Flex>
                                          <FormErrorMessage mb="12px">
                                            {String(
                                              (errors.emails as any[])?.[index]
                                                ?.email
                                            )}
                                          </FormErrorMessage>
                                        </FormControl>
                                        {index + 1 === values.emails.length &&
                                          typeof errors.emails !== 'object' && (
                                            <FormErrorMessage mb="12px">
                                              {String(errors.emails)}
                                            </FormErrorMessage>
                                          )}
                                      </FormControl>
                                    )
                                  )}

                                  <Button
                                    leftIcon="plus"
                                    onClick={() => {
                                      push({
                                        email: '',
                                        primary: false,
                                      });
                                    }}
                                  >
                                    Add Email
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.profile_url && touched.profile_url
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Profile URL
                            </FormLabel>
                            <Input
                              id="profile_url"
                              name="profile_url"
                              type="text"
                              placeholder="Profile URL"
                              variant="outline"
                              value={values.profile_url}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.profile_url)}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={Boolean(
                              !!errors.linkedin_url && touched.linkedin_url
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              LinkedIn Url
                            </FormLabel>
                            <Input
                              id="linkedin_url"
                              name="linkedin_url"
                              type="text"
                              placeholder="LinkedIn Url"
                              variant="outline"
                              value={values.linkedin_url}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_status && touched.work_status
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Work Status
                            </FormLabel>
                            <AtsSelect
                              id="work_status"
                              name="work_status"
                              placeholder="Select"
                              variant="outline"
                              onChange={(e: any) =>
                                setFieldValue('work_status', String(e.value))
                              }
                              defaultValue={
                                (candidate_work_status?.length > 0 &&
                                  candidate_work_status
                                    .map((item: any) => ({
                                      label: item.status,
                                      value: item.id,
                                    }))
                                    .filter(
                                      (val: any) =>
                                        val.value === Number(values.work_status)
                                    )[0]) ||
                                values.work_status
                              }
                              options={
                                candidate_work_status?.length > 0 &&
                                candidate_work_status.map((item: any) => ({
                                  label: item.status,
                                  value: item.id,
                                }))
                              }
                            />
                            <FormErrorMessage>
                              {String(errors.work_status)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex gap="32px">
                          <FormControl>
                            <FieldArray name="skills">
                              {({ push }) => {
                                const handleKeyDown = (e: any) => {
                                  if (e.key === 'Enter') {
                                    push(hasSkills);
                                    setHasSkills('');
                                  }
                                };
                                return (
                                  <FormControl mb="16px">
                                    <Flex
                                      justifyContent="space-between"
                                      alignItems="flex-end"
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Skills
                                      </FormLabel>
                                    </Flex>
                                    <InputGroup>
                                      <Input
                                        name="skills"
                                        id="kills"
                                        placeholder="Type Skills Name"
                                        type="text"
                                        variant="outline"
                                        value={hasSkills}
                                        onChange={(e) =>
                                          setHasSkills(e.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                      />
                                      <InputRightElement
                                        background="#F3EEFB"
                                        borderRadius="0 4px 4px 0"
                                        children={
                                          <CheckIcon color="priamry.800" />
                                        }
                                      />
                                    </InputGroup>
                                    <Input display="none" />
                                  </FormControl>
                                );
                              }}
                            </FieldArray>
                            <FieldArray name="skills">
                              {({ remove }) => (
                                <Flex gap="10px" display="inline-block">
                                  {values.skills?.length > 0 &&
                                    values.skills?.map(
                                      (skill: any, index: any) => (
                                        <Tag
                                          style={{
                                            display: 'inline-block',
                                          }}
                                          key={index}
                                          mr="10px"
                                          mb="10px"
                                        >
                                          <Stack spacing={1}>
                                            <HStack>
                                              <TagLabel maxW="100px">
                                                {skill as String}
                                              </TagLabel>
                                              <TagCloseButton
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              />
                                            </HStack>
                                          </Stack>
                                        </Tag>
                                      )
                                    )}
                                </Flex>
                              )}
                            </FieldArray>
                          </FormControl>
                        </Flex>

                        <Divider />

                        <Box>
                          <Text mb="34px">Address Information</Text>
                          <AddressComponent
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            countryOption={countryOption}
                            countryField={{
                              name: 'country',
                              value: values.country,
                              errors: errors.country,
                              touched: touched.country,
                            }}
                            cityField={{
                              name: 'city',
                              value: values.city,
                              errors: errors.city,
                              touched: touched.city,
                            }}
                            stateField={{
                              name: 'state_province',
                              value: values.state_province,
                              errors: errors.state_province,
                              touched: touched.state_province,
                            }}
                            zipField={{
                              name: 'zip_code',
                              value: values.zip_code,
                              errors: errors.zip_code,
                              touched: touched.zip_code,
                            }}
                          />
                        </Box>

                        <Divider />

                        {/* Professional Details */}
                        <Box fontWeight="bold" fontSize="lg" my="20px">
                          Professional Details
                        </Box>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Total Experience (Year)
                        </FormLabel>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.total_year_of_experience &&
                                touched.total_year_of_experience
                            )}
                          >
                            <InputGroup>
                              <InputLeftAddon children="Years" />
                              <Input
                                id="total_year_of_experience"
                                name="total_year_of_experience"
                                type="number"
                                min={0}
                                max={100}
                                value={values.total_year_of_experience}
                                onChange={(e: any) => {
                                  const inputValue = e.target.value;
                                  const sanitizedValue = inputValue.replace(
                                    /[^\d]/g,
                                    ''
                                  ); // Remove non-digit characters
                                  const clampedValue = Math.min(
                                    100,
                                    Math.max(0, sanitizedValue)
                                  ); // Clamp value between 0 and 100

                                  handleChange({
                                    target: {
                                      name: 'total_year_of_experience',
                                      value: clampedValue,
                                    },
                                  });
                                }}
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {String(errors.total_year_of_experience)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.total_months_of_experience &&
                                touched.total_months_of_experience
                            )}
                          >
                            <InputGroup>
                              <InputLeftAddon children="Months" />
                              <Input
                                id="total_months_of_experience"
                                name="total_months_of_experience"
                                type="text"
                                min={0}
                                max={11}
                                value={values.total_months_of_experience}
                                onChange={(e: any) => {
                                  const inputValue = e.target.value;
                                  const sanitizedValue = inputValue.replace(
                                    /[^\d]/g,
                                    ''
                                  ); // Remove non-digit characters
                                  const clampedValue = Math.min(
                                    11,
                                    Math.max(0, sanitizedValue)
                                  ); // Clamp value between 0 and 100

                                  handleChange({
                                    target: {
                                      name: 'total_months_of_experience',
                                      value: clampedValue,
                                    },
                                  });
                                }}
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {String(errors.total_months_of_experience)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.latest_job_title &&
                                touched.latest_job_title
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Current / Latest Job Title
                            </FormLabel>
                            <Input
                              id="latest_job_title"
                              name="latest_job_title"
                              type="text"
                              placeholder="Job Title"
                              variant="outline"
                              value={values.latest_job_title}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.latest_job_title)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.latest_employer &&
                                touched.latest_employer
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Curent / Latest Employer
                            </FormLabel>
                            <Input
                              id="latest_employer"
                              name="latest_employer"
                              type="text"
                              placeholder="Employer"
                              variant="outline"
                              value={values.latest_employer}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.latest_employer)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.latest_salary && touched.latest_salary
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Current / Latest Salary
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={BsCurrencyDollar}
                                    color="gray.500"
                                  />
                                }
                              />
                              <Input
                                id="latest_salary"
                                name="latest_salary"
                                type="text"
                                placeholder="0.00"
                                variant="outline"
                                textAlign="right"
                                value={values.latest_salary}
                                onChange={(event: any) => {
                                  const { value } = event.target;

                                  // Remove any existing commas from the input
                                  const sanitizedValue = value.replace(
                                    /,/g,
                                    ''
                                  );

                                  // Format the number with commas
                                  const formattedValue =
                                    Number(sanitizedValue)?.toLocaleString();

                                  setFieldValue(
                                    'latest_salary',
                                    formattedValue
                                  );
                                }}
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {String(errors.latest_salary)}
                            </FormErrorMessage>
                          </FormControl>
                          <Flex gap="32px" mb="34px"></Flex>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.expected_salary_start &&
                                touched.expected_salary_start
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Expected Salary (Start)
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={BsCurrencyDollar}
                                    color="gray.500"
                                  />
                                }
                              />
                              <Input
                                id="expected_salary_start"
                                name="expected_salary_start"
                                type="text"
                                placeholder="0.00"
                                variant="outline"
                                textAlign="right"
                                value={values.expected_salary_start}
                                onChange={(event: any) => {
                                  const { value } = event.target;

                                  // Remove any existing commas from the input
                                  const sanitizedValue = value.replace(
                                    /,/g,
                                    ''
                                  );

                                  // Format the number with commas
                                  const formattedValue =
                                    Number(sanitizedValue)?.toLocaleString();

                                  setFieldValue(
                                    'expected_salary_start',
                                    formattedValue
                                  );
                                }}
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {String(errors.expected_salary_start)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.expected_salary_end &&
                                touched.expected_salary_end
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Expected Salary (End)
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={BsCurrencyDollar}
                                    color="gray.500"
                                  />
                                }
                              />
                              <Input
                                id="expected_salary_end"
                                name="expected_salary_end"
                                type="text"
                                placeholder="0.00"
                                variant="outline"
                                textAlign="right"
                                value={values.expected_salary_end}
                                onChange={(event: any) => {
                                  const { value } = event.target;

                                  // Remove any existing commas from the input
                                  const sanitizedValue = value.replace(
                                    /,/g,
                                    ''
                                  );

                                  // Format the number with commas
                                  const formattedValue =
                                    Number(sanitizedValue)?.toLocaleString();

                                  setFieldValue(
                                    'expected_salary_end',
                                    formattedValue
                                  );
                                }}
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {String(errors.expected_salary_end)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>

                        <Box mb="34px">
                          <FormControl mb="3">
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Resume
                            </FormLabel>
                          </FormControl>
                          <AtsDropZone
                            multipleFile={true}
                            uploadedEnd={(e: any) => {}}
                            deletedFile={(key: string) => {}}
                          />
                        </Box>

                        <Divider />

                        {/* Experience Details */}
                        <Box fontWeight="bold" fontSize="lg" my="40px">
                          Experience Details
                        </Box>
                        <FieldArray name="experiences">
                          {({ remove, push }) => (
                            <div>
                              {values.experiences?.length > 0 &&
                                values.experiences?.map(
                                  (experience: any, index: any) => (
                                    <div className="row" key={index}>
                                      <FormControl mb="34px">
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="flex-end"
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Company
                                          </FormLabel>
                                          {values.experiences?.length > 0 && (
                                            <Box mb="10px">
                                              <Button
                                                leftIcon="close"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Button>
                                            </Box>
                                          )}
                                        </Flex>
                                        <Field
                                          as={Input}
                                          name={`experiences.${index}.company`}
                                          placeholder="Company"
                                          type="text"
                                          variant="outline"
                                          value={experience.company}
                                          onChange={handleChange}
                                        />
                                        <FormErrorMessage>
                                          {String(
                                            `experiences${index}.company`
                                          )}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <FormControl mb="34px">
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Job Title
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`experiences.${index}.job_title`}
                                          placeholder="Job Title"
                                          type="text"
                                          variant="outline"
                                          value={experience.job_title}
                                          onChange={handleChange}
                                        />
                                        <FormErrorMessage>
                                          {String(
                                            `experiences${index}.job_title`
                                          )}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <Flex gap="32px" mb="16px">
                                        <FormControl>
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Start Date
                                          </FormLabel>
                                          <Field
                                            as={Input}
                                            name={`experiences.${index}.start_date`}
                                            placeholder="Select Date"
                                            size="md"
                                            type="date"
                                            value={experience.start_date}
                                            onChange={handleChange}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            End Date
                                          </FormLabel>
                                          <Field
                                            as={Input}
                                            name={`experiences.${index}.end_date`}
                                            placeholder="Select Date"
                                            size="md"
                                            type="date"
                                            value={experience.end_date}
                                            onChange={handleChange}
                                          />
                                        </FormControl>
                                      </Flex>

                                      <Box mb="32px">
                                        <Checkbox
                                          id={`experiences.${index}.currently_pursuing`}
                                          name={`experiences.${index}.currently_pursuing`}
                                          isChecked={
                                            experience.currently_pursuing
                                          }
                                          onChange={handleChange}
                                        >
                                          Currently Pursuing
                                        </Checkbox>
                                      </Box>
                                    </div>
                                  )
                                )}
                              <Button
                                leftIcon="plus"
                                onClick={() =>
                                  push({
                                    company: '',
                                    job_title: '',
                                    start_date: '',
                                    end_date: '',
                                    currently_pursuing: false,
                                  })
                                }
                              >
                                Add Another
                              </Button>
                            </div>
                          )}
                        </FieldArray>

                        <Divider mt="40px" />

                        {/* Education Details */}
                        <Box fontWeight="bold" fontSize="lg" my="40px">
                          Education Details
                        </Box>
                        <FieldArray name="educations">
                          {({ remove, push }) => (
                            <div>
                              {values.educations?.length > 0 &&
                                values.educations?.map(
                                  (education: any, index: any) => (
                                    <div className="row" key={index}>
                                      <FormControl mb="34px">
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="flex-end"
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Institute/School
                                          </FormLabel>
                                          {values.experiences?.length > 0 && (
                                            <Box mb="10px">
                                              <Button
                                                leftIcon="close"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Button>
                                            </Box>
                                          )}
                                        </Flex>
                                        <Field
                                          as={Input}
                                          name={`educations.${index}.school`}
                                          placeholder="Institute/School"
                                          type="text"
                                          variant="outline"
                                          value={education.school}
                                          onChange={handleChange}
                                        />
                                        <FormErrorMessage>
                                          {String(`educations${index}.school`)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <FormControl mb="34px">
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Major/Department
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`educations.${index}.major_department`}
                                          placeholder="Major/Department"
                                          type="text"
                                          variant="outline"
                                          value={education.major_department}
                                          onChange={handleChange}
                                        />
                                        <FormErrorMessage>
                                          {String(
                                            `educations${index}.major_department`
                                          )}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <Flex gap="32px" mb="16px">
                                        <FormControl>
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Start Date
                                          </FormLabel>
                                          <Field
                                            as={Input}
                                            name={`educations.${index}.start_date`}
                                            placeholder="Select Date"
                                            size="md"
                                            type="date"
                                            value={education.start_date}
                                            onChange={handleChange}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            End Date
                                          </FormLabel>
                                          <Field
                                            as={Input}
                                            name={`educations.${index}.end_date`}
                                            placeholder="Select Date"
                                            size="md"
                                            type="date"
                                            value={education.end_date}
                                            onChange={handleChange}
                                          />
                                        </FormControl>
                                      </Flex>

                                      <Box mb="32px">
                                        <Checkbox
                                          id={`educations.${index}.currently_pursuing`}
                                          name={`educations.${index}.currently_pursuing`}
                                          isChecked={
                                            education.currently_pursuing
                                          }
                                          onChange={handleChange}
                                        >
                                          Currently Pursuing
                                        </Checkbox>
                                      </Box>
                                    </div>
                                  )
                                )}
                              <Button
                                leftIcon="plus"
                                onClick={() =>
                                  push({
                                    school: '',
                                    major_department: '',
                                    start_date: '',
                                    end_date: '',
                                    currently_pursuing: false,
                                  })
                                }
                              >
                                Add Another
                              </Button>
                            </div>
                          )}
                        </FieldArray>

                        <Divider mt="40px" />

                        {/* Tags */}
                        <Box
                          fontWeight="bold"
                          fontSize="lg"
                          mt="40px"
                          mb="20px"
                        >
                          Tags
                        </Box>
                        <FormControl>
                          <FieldArray name="tags">
                            {({ push }) => {
                              const handleKeyDown = (e: any) => {
                                if (e.key === 'Enter') {
                                  push(hasTags);
                                  setHasTags('');
                                }
                              };
                              return (
                                <FormControl mb="16px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Add Tags
                                    </FormLabel>
                                  </Flex>
                                  <InputGroup>
                                    <Input
                                      name="tag"
                                      id="tag"
                                      placeholder="Type Tag Name"
                                      type="text"
                                      variant="outline"
                                      value={hasTags}
                                      onChange={(e) =>
                                        setHasTags(e.target.value)
                                      }
                                      onKeyDown={handleKeyDown}
                                    />
                                    <InputRightElement
                                      background="#F3EEFB"
                                      borderRadius="0 4px 4px 0"
                                      children={
                                        <CheckIcon color="priamry.800" />
                                      }
                                    />
                                  </InputGroup>
                                  <Input display="none" />
                                </FormControl>
                              );
                            }}
                          </FieldArray>
                          <FieldArray name="tags">
                            {({ remove }) => (
                              <Flex gap="10px" display="inline-block">
                                {values.tags?.length > 0 &&
                                  values.tags?.map((tag, index) => (
                                    <Tag
                                      style={{
                                        display: 'inline-block',
                                      }}
                                      key={index}
                                      mr="10px"
                                      mb="10px"
                                    >
                                      <Stack spacing={1}>
                                        <HStack>
                                          <TagLabel maxW="100px">
                                            {tag as String}
                                          </TagLabel>
                                          <TagCloseButton
                                            onClick={() => {
                                              remove(index);
                                            }}
                                          />
                                        </HStack>
                                      </Stack>
                                    </Tag>
                                  ))}
                              </Flex>
                            )}
                          </FieldArray>
                        </FormControl>
                      </Box>

                      <ModalFooter mb="20px" p="20px">
                        <Button
                          variant="solid"
                          onClick={() => {
                            handleSubmit();
                            console.log(errors);
                            !isValid && onOpen();
                          }}
                          disabled={isOpenAlert || uploading}
                          loading={resCreateCandidate.isLoading}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
