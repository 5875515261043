import { LoginPage } from '@/pages/Login';
import { Main } from '@/pages/MainPage';
import { PageRouteProps } from '@/types';

const pageRoute: PageRouteProps[] = [
  {
    name: 'Login',
    component: <LoginPage />,
    path: '/login',
  },
  {
    name: 'Dashboard',
    component: <Main />,
    path: '/*',
  },
];

export default pageRoute;
