import { Formik } from 'formik';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  Stack,
  Text,
} from '@chakra-ui/react';

import Talie1 from '@/assets/images/logo/talie1.svg';
import Talie from '@/assets/images/logo/talie.svg';

import FAIcon from '@/components/lib/FAIcon';
import { useLoginServices } from './useLoginServices';

export default function LoginModule() {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    initialValues,
    validationSchema,
    onSubmit,
    show,
    handleClick,
    isLoading,
  } = useLoginServices({ onClose, onOpen });

  return (
    <Box
      sx={{
        position: 'relative',
        fontFamily: 'NunitoSans Regular',
        background:
          'linear-gradient(45deg, rgba(105,48,202,1) 0%, rgba(177,0,255,1) 100%)',
      }}
    >
      <canvas
        id="granim-canvas"
        style={{
          width: '100%',
          height: '100vh',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          px: '10%',
        }}
      >
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={[0, 6]}
          sx={{ height: '100%' }}
        >
          <Flex
            sx={{
              w: ['40%', '70%'],
              m: ['0 auto', 'initial'],
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image src={Talie1} />
          </Flex>

          <Stack
            w="100%"
            justifySelf="right"
            sx={{
              bgColor: '#FFFFFF',
              maxW: '480px',
              boxShadow: ' 0px 4px 16px 0px #00000040',
              borderRadius: '50px',
              my: ['30px', '100px'],
              p: '40px 32px',
            }}
          >
            <Flex justifyContent="flex-end" sx={{ mb: '10%' }}>
              <Image src={Talie} />
            </Flex>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit} onChange={onClose}>
                  <Text sx={{ fontSize: '24px', color: '#6B6C7B' }}>
                    Sign in to your account
                  </Text>

                  {isVisible && (
                    <Alert
                      status="error"
                      justifyContent="space-around"
                      mt="10px"
                    >
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Incorrect email or password</AlertTitle>
                        <AlertDescription>
                          Try entering your information again.
                        </AlertDescription>
                      </Box>
                      <CloseButton
                        position="relative"
                        right={-1}
                        top={-3}
                        onClick={onClose}
                      />
                    </Alert>
                  )}
                  <Stack spacing="32px" mt="32px">
                    <FormControl
                      isInvalid={Boolean(!!errors.email && touched.email)}
                    >
                      <FormLabel
                        sx={{
                          fontSize: '16px',
                          lineHeight: '21.82px',
                          color: '#2B2D42',
                        }}
                      >
                        Email
                      </FormLabel>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Enter your email"
                        variant="outline"
                        value={values.email}
                        onChange={handleChange}
                        sx={{
                          background: '#fff',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          color: '#2B2D42',
                        }}
                        _focusVisible={{
                          boxShadow: '0 0 0 1px #A875FF',
                        }}
                      />
                      <FormErrorMessage>
                        {String(errors.email)}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={Boolean(!!errors.password && touched.password)}
                    >
                      <FormLabel
                        sx={{
                          fontSize: '16px',
                          lineHeight: '21.82px',
                          color: '#2B2D42',
                        }}
                      >
                        Password
                      </FormLabel>
                      <InputGroup background="#fff" borderRadius="4px">
                        <Input
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          type={show ? 'text' : 'password'}
                          variant="outline"
                          value={values.password}
                          onChange={handleChange}
                          sx={{
                            background: '#fff',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            color: '#2B2D42',
                          }}
                          _focusVisible={{
                            boxShadow: '0 0 0 1px #A875FF',
                          }}
                        />
                        <InputRightElement color="#fff">
                          <Box onClick={handleClick} cursor="pointer">
                            <FAIcon iconName={show ? 'eye' : 'eye-slash'} />
                          </Box>
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>
                        {String(errors.password)}
                      </FormErrorMessage>
                    </FormControl>

                    <Button
                      variant="ghost"
                      type="submit"
                      isDisabled={isVisible}
                      isLoading={isLoading}
                      sx={{
                        background: '#B100FF',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 700,
                        color: '#fff',
                      }}
                      _hover={{
                        bg: '#B100FF',
                      }}
                    >
                      Sign in
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Stack>
        </Grid>
      </Box>
    </Box>
  );
}
