import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const constantsSlice = createSlice({
  name: 'constants',
  initialState: {
    work_types: [],
    educations: [],
    compensations: [],
    employment_types: [],
    candidate_work_status: [],
    job_specs: [],
    experience_level: [],
    sub_status: [],
    candidate_status: [],
    countries: [],
    lead_source: [],
    candidate_filter_columns: [],
    filter_operators: [],
    job_filter_columns: [],
    candidate_source: [],
    client_types: [],
    contact_types: [],
    mail_box: {},
    job_status: [],
    meeting_types: [],
  },
  reducers: {
    loadConstant: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { loadConstant } = constantsSlice.actions;
export default constantsSlice.reducer;

const constantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetWorkTypes: builder.mutation({
      query: () => ({
        url: '/constants/worktypes',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ work_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetEducations: builder.mutation({
      query: () => ({
        url: '/constants/educations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ educations: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCompensations: builder.mutation({
      query: () => ({
        url: '/constants/compensations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ compensations: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetEmpType: builder.mutation({
      query: () => ({
        url: '/constants/employment-types',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ employment_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidateWorkStatus: builder.mutation({
      query: () => ({
        url: '/constants/candidate-work-status',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ candidate_work_status: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetJobSpecs: builder.mutation({
      query: () => ({
        url: '/constants/job-specifications',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ job_specs: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetExperienceLevel: builder.mutation({
      query: () => ({
        url: '/constants/experience-level',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ experience_level: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetSubStatus: builder.mutation({
      query: ({ id }) => ({
        url: `/constants/candidate-sub-status/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ sub_status: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidatetatus: builder.mutation({
      query: () => ({
        url: `/constants/candidate-status`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ candidate_status: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCountries: builder.mutation({
      query: () => ({
        url: `/constants/countries`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ countries: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetLeadSource: builder.mutation({
      query: () => ({
        url: `/constants/lead-source`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ lead_source: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidateFilterColumns: builder.mutation({
      query: () => ({
        url: `/constants/candidate-filter-columns`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            loadConstant({
              candidate_filter_columns: data.data.map((item: any) => ({
                ...item,
                value: item.id,
              })),
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetFilterOperators: builder.mutation({
      query: () => ({
        url: `/constants/filter-operators`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            loadConstant({
              filter_operators: data.data.map((item: any) => ({
                ...item,
                value: item.id,
              })),
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetJobFilterColumns: builder.mutation({
      query: () => ({
        url: `/constants/job-filter-columns`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            loadConstant({
              job_filter_columns: data.data.map((item: any) => ({
                ...item,
                value: item.id,
              })),
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidateSource: builder.mutation({
      query: () => ({
        url: `/constants/candidate-source`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            loadConstant({
              candidate_source: data.data,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetContactTypes: builder.mutation({
      query: () => ({
        url: '/constants/contact-types',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ contact_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetClientTypes: builder.mutation({
      query: () => ({
        url: '/constants/client-types',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ client_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetMailBoxConfig: builder.mutation({
      query: () => ({
        url: '/constants/mailbox-config',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ mail_box: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetJobStatus: builder.mutation({
      query: () => ({
        url: '/constants/job-status/active',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ job_status: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    GetMeetingTypes: builder.mutation({
      query: () => ({
        url: '/constants/meeting-type',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ meeting_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetGeneralConstant: builder.mutation({
      query: () => ({
        url: '/constants/all-constants',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const constants = data.data;
          dispatch(loadConstant({ work_types: constants.work_types }));
          dispatch(loadConstant({ educations: constants.educations }));
          dispatch(loadConstant({ compensations: constants.compensations }));
          dispatch(
            loadConstant({ employment_types: constants.employment_types })
          );
          dispatch(
            loadConstant({
              candidate_work_status: constants.candidate_work_status,
            })
          );
          dispatch(
            loadConstant({ experience_level: constants.experience_level })
          );
          dispatch(
            loadConstant({ candidate_status: constants.candidate_status })
          );
          dispatch(loadConstant({ lead_source: constants.lead_source }));
          dispatch(
            loadConstant({ candidate_source: constants.candidate_source })
          );
          dispatch(loadConstant({ client_types: constants.client_types }));
          dispatch(loadConstant({ contact_types: constants.contact_types }));
          dispatch(loadConstant({ mail_box: constants.mail_box }));
          dispatch(loadConstant({ job_status: constants.job_status }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useGetWorkTypesMutation,
  useGetEducationsMutation,
  useGetCompensationsMutation,
  useGetEmpTypeMutation,
  useGetCandidateWorkStatusMutation,
  useGetJobSpecsMutation,
  useGetExperienceLevelMutation,
  useGetSubStatusMutation,
  useGetCandidatetatusMutation,
  useGetCountriesMutation,
  useGetLeadSourceMutation,
  useGetCandidateFilterColumnsMutation,
  useGetFilterOperatorsMutation,
  useGetJobFilterColumnsMutation,
  useGetCandidateSourceMutation,
  useGetClientTypesMutation,
  useGetContactTypesMutation,
  useGetMailBoxConfigMutation,
  useGetJobStatusMutation,
  useGetMeetingTypesMutation,
  useGetGeneralConstantMutation,
} = constantsApiSlice;
