import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import MdWand from '@/assets/images/ai-generator/MdWand.svg';
import { useCallback } from 'react';

import { ContentItemLayout } from './ContentItem';

interface GenerateContentProps {
  title: string;
  content: {
    subject: string[];
    body: string[];
  }[];
  active: {
    subject: {
      item: number;
      value: string;
    };
    body: {
      item: number;
      value: string;
    };
  };
  setActive: (active: any) => void;
  handleRegenerate: (params: { index: number; field: string }) => void;
  isRegenerated: boolean;
  isLoadingList: boolean;
  isLoading: boolean;
}

export const GenerateContent = ({
  title,
  content,
  active,
  setActive,
  handleRegenerate,
  isRegenerated,
  isLoadingList,
  isLoading,
}: GenerateContentProps) => {
  const isSubject = title === 'Subject';

  const handleCss = (item: number) => {
    const isActive = isSubject
      ? active.subject.item === item
      : active.body.item === item;
    return isActive
      ? {
          bgColor: '#F9F4FF',
          border: '1px solid #6930CA',
        }
      : {};
  };

  const handleClick = useCallback(
    (item: number) => {
      setActive((prev: any) => ({
        ...prev,
        [isSubject ? 'subject' : 'body']: {
          ...prev[isSubject ? 'subject' : 'body'],
          item,
        },
      }));
    },
    [isSubject, setActive]
  );

  return (
    <Stack spacing="10px" fontFamily="NunitoSans Regular">
      <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>{title}</Text>
      {content &&
        content?.map((val, item) => (
          <Stack
            key={item}
            spacing="10px"
            sx={{
              border: '1px solid #E7E9ED',
              borderRadius: '4px',
              p: '10px',
              cursor: 'pointer',
              ...handleCss(item),
            }}
            _hover={{
              bgColor: '#F9F4FF',
            }}
            onClick={() => handleClick(item)}
          >
            <Flex gap="10px" alignItems="flex-start">
              <Image src={MdWand} mt="2px" />
              <Stack spacing="8px">
                <ContentItemLayout
                  isSubject={isSubject}
                  content={isSubject ? val.subject : val.body}
                  setActive={setActive}
                  item={item}
                  title={title}
                  handleRegenerate={handleRegenerate}
                  isRegenerated={isRegenerated}
                  isLoadingList={isLoadingList}
                  isLoading={isLoading}
                />
              </Stack>
            </Flex>
          </Stack>
        ))}
    </Stack>
  );
};
