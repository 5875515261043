import { Formik } from 'formik';
import {
  Box,
  FormControl,
  FormLabel,
  Divider,
  Stack,
  Flex,
  FormErrorMessage,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import AtsSelect from '@/components/app/AtsSelect';
import FileUpload from '@/components/app/FileUpload';
import ScrollToFieldError from '@/components/app/ScrollError';

interface ImportExcelFormProps {
  initialValues: any;
  validationSchema: any;
  handleSubmit: any;
  excelHeaders: any;
  handleUploadEnd: any;
  candidateFieldOptions: any;
  handleCandidateFieldSelect: any;
  onClose: any;
  importCount: any;
  errorHeaders: any;
  isOpenAlert: any;
}
export const ImportExcelForm = ({
  initialValues,
  validationSchema,
  handleSubmit,
  excelHeaders,
  handleUploadEnd,
  candidateFieldOptions,
  handleCandidateFieldSelect,
  onClose,
  importCount,
  errorHeaders,
  isOpenAlert,
}: ImportExcelFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, handleChange, setFieldValue, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Box p="18px 32px">
              <Box
                p="16px"
                border={excelHeaders.length > 0 && '1px solid #EEEEEE'}
                borderRadius="6px"
              >
                <FormControl>
                  <FormLabel fontSize="14px" lineHeight="18px" color="#000000">
                    Import Excel
                  </FormLabel>
                  <FileUpload
                    multipleFile={false}
                    uploadedEnd={(e: any) => {
                      handleUploadEnd(e);
                    }}
                    deletedFile={(key: string) => {}}
                  />
                </FormControl>

                {excelHeaders.length > 0 && (
                  <Box mt="40px">
                    <Text
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        color: '#2B2D42',
                      }}
                    >
                      Excel Columns with Sample Data
                    </Text>
                    <Text
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#9B9B9B',
                      }}
                    >
                      Select a column and choose their respective sequence
                      candidate fields.
                    </Text>

                    <Divider color="#E7EDF4" mt="8px" mb="16px" />

                    <Stack spacing="8px" p="8px 14px">
                      {Object.keys(excelHeaders).map((header: any) => {
                        return (
                          <FormControl
                            isInvalid={Boolean(
                              errors.mappedField &&
                                errorHeaders.includes(header)
                            )}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Flex gap="16px" w="55%">
                                <Text
                                  sx={{
                                    fontSize: '16px',
                                    color: '#2B2D42',
                                  }}
                                >
                                  {header}
                                </Text>
                                <Text
                                  sx={{
                                    fontSize: '16px',
                                    color: '#6B6C7B',
                                  }}
                                  isTruncated
                                >
                                  {excelHeaders[header]}
                                </Text>
                              </Flex>

                              <Box width="40%">
                                <AtsSelect
                                  name={`mappedField.${header}`}
                                  id={`mappedField.${header}`}
                                  value={
                                    candidateFieldOptions.filter(
                                      (option: { value: any }) =>
                                        option.value ===
                                        values.mappedField[header]
                                    )[0] ?? null
                                  }
                                  options={candidateFieldOptions}
                                  placeholder="Select a candidate field"
                                  _placeholder={{ color: '#9B9B9B' }}
                                  isClearable
                                  w="100%"
                                  onChange={(e: any) =>
                                    handleCandidateFieldSelect(
                                      e,
                                      header,
                                      setFieldValue
                                    )
                                  }
                                />
                              </Box>
                            </Flex>

                            <FormErrorMessage>
                              {String(errors.mappedField)}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="default.white.100"
              boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
            >
              <Button mr={3} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                variant="solid"
                type="submit"
                disabled={
                  importCount <= 0 || errorHeaders.length > 0 || isOpenAlert
                }
              >
                Import Data {importCount ? `${importCount}` : ''}
              </Button>
            </ModalFooter>
          </form>
        );
      }}
    </Formik>
  );
};
