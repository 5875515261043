import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Field } from 'formik';
import { number } from 'yup';
import { debounce } from 'lodash';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import '../../../../assets/css/autocomplete.css';

import { useSearchGeoTypesMutation } from '@/store/geo.slice';

import AtsSelect from '@/components/app/AtsSelect';
import AtsAutoComplete from '@/components/app/Leads/components/Autocomplete';

interface fieldInterface {
  name: string;
  value: any;
  errors?: any;
  touched?: any;
}

interface addCompInt {
  handleChange: any;
  setFieldValue: any;
  countryOption: any;
  countryField?: fieldInterface;
  locationField?: fieldInterface;
  cityField?: fieldInterface;
  stateField?: fieldInterface;
  zipField?: fieldInterface;
}

const AddressDrawerComponent = ({
  handleChange,
  setFieldValue,
  countryOption,
  countryField,
  cityField,
  stateField,
  zipField,
}: addCompInt) => {
  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [reqGeo, resGeo] = useSearchGeoTypesMutation();

  const [currentName, setName] = useState('');
  const [itemLocation, setItemLocation] = useState([]);

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName === 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoFormat = (item: any) => {
    let format: any = '';
    try {
      const cityB = currentName === 'city' ? { fontWeight: 'bold' } : {};
      const postB = currentName === 'postal_code' ? { fontWeight: 'bold' } : {};
      format = (
        <>
          <Flex gap="2px">
            <Text style={postB}>{item?.postal_code}</Text>
            <Text style={cityB}>{item?.city}</Text>
            <Text>{item?.state}</Text>
            <Text>{item?.country}</Text>
          </Flex>
        </>
      );
    } catch (e) {
      format = 'Error formatting';
    }
    return format;
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const handleOnSelect = (item: any, input: string) => {
    // the item selected
    let country_code = item.country_code === 'gb' ? 'uk' : item.country_code;
    const country = countryOption?.find((obj: any) =>
      obj.code.includes(country_code)
    );

    setFieldValue(stateField.name, item.state);
    setFieldValue(cityField.name, item.city);
    setFieldValue(zipField.name, item.postal_code);
    setFieldValue(countryField.name, country);
  };

  return (
    <div className="row" key={`jobAdd`}>
      <Flex gap="8px" flexDir="column">
        <FormControl isInvalid={Boolean(cityField.errors && cityField.touched)}>
          <Box
            sx={{
              input: {
                height: '33px',
                borderRadius: '2px',
              },
            }}
          >
            <AtsAutoComplete
              name={cityField.name}
              placeholder="City"
              type="text"
              variant="outline"
              value={cityField.value}
              onChange={(e: any) => {
                setFieldValue(cityField.name, e.target.value);
              }}
              onFocus={() => setName('city')}
              options={itemLocation}
              onKeyUp={(e: any) => geoLocationChange(e, 'city')}
              isLoading={resGeo.isLoading}
              onSelect={(item: any) => {
                setFieldValue(cityField.name, item.value);
                handleOnSelect(item, 'city');
              }}
            />
          </Box>
          <FormErrorMessage>{String(cityField.errors)}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={Boolean(stateField.errors && stateField.touched)}
        >
          <Box
            sx={{
              input: {
                height: '33px',
                borderRadius: '2px',
              },
            }}
          >
            <Field
              as={Input}
              name={stateField.name}
              placeholder="State/Province"
              type="text"
              variant="outline"
              value={stateField.value}
              onChange={handleChange}
              sx={{
                width: '350px',
              }}
            />
          </Box>
          <FormErrorMessage>{String(stateField.errors)}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={Boolean(countryField.errors && countryField.touched)}
        >
          <Box
            border={
              !!countryField.errors &&
              countryField.touched &&
              '1px solid #E53E3E'
            }
            borderRadius={
              !!countryField.errors && countryField.touched && '4px'
            }
            boxShadow={
              !!countryField.errors &&
              countryField.touched &&
              '0 0 0 1px #E53E3E'
            }
          >
            <Field
              name={countryField.name}
              placeholder="Country"
              options={countryOption}
              component={AtsSelect}
              value={
                countryField.value ||
                (countryOption.length > 0 &&
                  countryOption
                    .map((item: any) => ({
                      label: item.label,
                      value: item.value,
                    }))
                    .filter(
                      (val: any) => val.value === countryField.value?.value
                    ))
              }
              onChange={(e: any) => {
                setFieldValue(countryField.name, e);
              }}
              styles={{
                zIndex: '9999',
                indicatorSeparator: () => {},
                dropdownIndicator: (defaultStyles: any) => ({
                  ...defaultStyles,
                  paddingLeft: '0px',
                  '& svg': {
                    width: '10px',
                    height: '10px',
                  },
                }),
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  minHeight: '25px',
                  fontSize: '14px',
                  border:
                    !!countryField.errors && countryField.touched && 'none',
                }),
              }}
            />
          </Box>
          <FormErrorMessage>{String(countryField.errors)}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(zipField.errors && zipField.touched)}>
          <Box
            sx={{
              input: {
                height: '33px',
                borderRadius: '2px',
              },
            }}
          >
            <AtsAutoComplete
              name={zipField.name}
              placeholder="Zip Code"
              type="text"
              variant="outline"
              value={zipField.value}
              onChange={(e: any) => {
                setFieldValue(zipField.name, e.target.value);
              }}
              onFocus={() => setName('postal_code')}
              options={itemLocation}
              onKeyUp={(e: any) => geoLocationChange(e, 'postal_code')}
              isLoading={resGeo.isLoading}
              onSelect={(item: any) => {
                setFieldValue(zipField.name, item.value);
                handleOnSelect(item, 'postal_code');
              }}
            />
          </Box>
          <FormErrorMessage>{String(zipField.errors)}</FormErrorMessage>
        </FormControl>
      </Flex>
    </div>
  );
};

export default AddressDrawerComponent;
