import ModalLoader from '@/components/ui/Loaders/ModalLoader';
import { lazy, Suspense } from 'react';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { BiSolidMessageDots } from 'react-icons/bi';
import { LuPlus } from 'react-icons/lu';
const NotesModal = lazy(() => import('../../NotesModal'));

export default function NotesBtn({
  isNotesExits,
  leadId,
}: {
  isNotesExits: boolean;
  leadId: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        justifyContent="center"
        alignItems="center"
        position="relative"
        w="fit-content"
        h="fit-content"
        onClick={onOpen}
      >
        <IconButton
          size="xs"
          aria-label="notes-button"
          icon={<BiSolidMessageDots color="#6930CA" fontSize={'16px'} />}
          border={0}
          bgColor="#E7EDF4"
          isRound
          _hover={{
            bgColor: '#E7EDF4',
          }}
        />
        {isNotesExits ? (
          <Box
            width="8px"
            height="8px"
            bgColor="#AE8FE2"
            borderRadius="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            right={-1}
            top={0.5}
          ></Box>
        ) : (
          <Flex
            width="16px"
            height="16px"
            p="3px"
            bgColor="#6930CA"
            borderRadius="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            right={-3}
            top={0}
          >
            <LuPlus color="#fff" fontWeight={700} />
          </Flex>
        )}
      </Box>
      {isOpen ? (
        <Suspense fallback={<ModalLoader />}>
          <NotesModal leadId={leadId} onClose={onClose} />
        </Suspense>
      ) : null}
    </>
  );
}
