import {
  Flex,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Text,
  Portal,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaAngleDown, FaCircle } from 'react-icons/fa6';

const BD_STATUS = {
  6: { label: 'Contacted', color: '#177DDB', value: 6 },
  7: { label: 'Opened', color: '#FFBC6D', value: 7 },
  8: { label: 'Not Interested', color: '#CB2C2C', value: 8 },
  9: { label: 'Out of Office', color: '#718096', value: 9 },
  10: { label: 'Not the Right Person', color: '#718096', value: 10 },
  11: { label: 'Replied', color: '#38B17A', value: 11 },
};
type OptionItem = { label: string; value: number; color?: string };
interface BDStatusProps {
  onChange: (option: OptionItem) => void;
  value: keyof typeof BD_STATUS;
}

export default function BDStaus({ onChange, value }: BDStatusProps) {
  const [selectedStatus, setSelectedStatus] = useState<OptionItem>(
    BD_STATUS[value] || BD_STATUS['6']
  );
  return (
    <Menu>
      <MenuButton w="100%">
        <Flex
          alignItems={'center'}
          gap={'8px'}
          justifyContent={'space-between'}
          w="100%"
          p="5px"
          sx={{ border: '1px solid #E7E9ED', borderRadius: '50px' }}
        >
          <BDStatusItem
            statusId={selectedStatus?.value as keyof typeof BD_STATUS}
          />
          <FaAngleDown color="#718096" />
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList>
          {Object.values(BD_STATUS).map((status) => (
            <MenuItem
              key={status.value}
              onClick={() => {
                onChange(status);
                setSelectedStatus(status);
              }}
              p="8px 16px"
              bg={
                selectedStatus.value === status.value ? 'primary.500' : 'white'
              }
              _hover={{
                bg:
                  selectedStatus.value === status.value
                    ? 'primary.500'
                    : 'primary.100',
              }}
            >
              <BDStatusItem
                statusId={status.value as keyof typeof BD_STATUS}
                isOption
                isActive={selectedStatus.value === status.value}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}

export function BDStatusItem({
  statusId,
  isOption = false,
  isActive = false,
}: {
  statusId: keyof typeof BD_STATUS;
  isOption?: boolean;
  isActive?: boolean;
}) {
  return (
    <Flex alignItems="center" gap={isOption ? '8px' : '4px'}>
      <FaCircle fontSize="15px" color={BD_STATUS?.[statusId]?.color} />
      <Text
        fontSize={'md'}
        fontWeight={isOption ? '400' : '700'}
        color={isActive ? 'white' : 'default.primarytext'}
      >
        {BD_STATUS?.[statusId]?.label}
      </Text>
    </Flex>
  );
}
