import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import * as Yup from 'yup';
import axios from 'axios';

import { useDisclosure, useToast } from '@chakra-ui/react';

import {
  usePostListCandidatesMutation,
  useGetSequenceDataMutation,
} from '@/store/outreach.slice';
import { cleanUpload } from '@/store/uploads.slice';

import { BASE_URL } from '@/constants/values';
import { candidateFieldOptions } from './constant';

export const useImportExcelServices = ({ onClose }: any) => {
  const initValues = {
    mappedField: {} as any,
  };

  const [initialValues, setInitialValues] = useState(initValues);

  const { settingTypeId } = useParams();
  const { candidateSequencePgBtn, sequencesData } = useSelector(
    (state: any) => state.outreach
  );
  const [reqCandidateList] = usePostListCandidatesMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();

  const [fileupload, setFileUpload] = useState<any>(null);

  const [excelHeaders, setExcelHeader] = useState<any[]>([]);
  const [importCount, setImportCount] = useState<number>(0);
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const handleSubmit = async (data: any) => {
    // console.log("data ", data)
    const missingFields = [];
    if (!Object.values(data.mappedField).includes('first_name')) {
      missingFields.push('first_name');
    }
    if (!Object.values(data.mappedField).includes('last_name')) {
      missingFields.push('last_name');
    }
    if (!Object.values(data.mappedField).includes('primary_email')) {
      missingFields.push('primary_email');
    }
    if (missingFields.length > 0) {
      setSubmitErrorMessage(
        `Please assign the following field/s: ${missingFields.join(', ')}`
      );
      onOpenAlert();
    } else {
      let formData = new FormData();
      let url =
        BASE_URL + `/outreach/sequences/import/${Number(settingTypeId)}`;
      formData.append('file', fileupload, fileupload.name);
      formData.append('mappedField', JSON.stringify(data.mappedField));

      await axios
        .post(url, formData, { withCredentials: true })
        .then((res) => {
          handleClose();
          reqCandidateList(candidateSequencePgBtn);
          reqGetSequenceData({ id: settingTypeId });

          toast({
            title: 'Importing Candidates',
            description:
              "We've started importing your candidates to the sequence",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const handleCloseDrawer = () => {
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    setImportCount(0);
    setSubmitErrorMessage('');
    setErrorHeaders('');
    onClose();
  };

  const handleUploadEnd = async (data: any) => {
    const params = { mappedField: {} as any };
    Object.keys(data.data.mappedField).map((header) => {
      const cleanedHeader = header.replace(' ', '_').trim().toLowerCase();

      if (
        candidateFieldOptions.some((option) => option.value == cleanedHeader)
      ) {
        params.mappedField[header] = cleanedHeader;
      } else {
        params.mappedField[header] = '';
      }
    });
    if (!Object.values(params.mappedField).includes('first_name')) {
      params.mappedField[Object.keys(params.mappedField)[0]] = 'first_name';
    }
    if (!Object.values(params.mappedField).includes('last_name')) {
      params.mappedField[Object.keys(params.mappedField)[1]] = 'last_name';
    }
    if (!Object.values(params.mappedField).includes('primary_email')) {
      params.mappedField[Object.keys(params.mappedField)[2]] = 'primary_email';
    }

    setInitialValues(params);
    setSubmitErrorMessage('');
    setErrorHeaders('');
    setExcelHeader(data.data.mappedField);
    setImportCount(data.data.count);
    setFileUpload(data.file);
  };

  const handleCandidateFieldSelect = (
    e: any,
    header: string,
    setFieldValue: any
  ) => {
    console.log('e', e);
    setFieldValue(`mappedField.${header}`, e?.value ?? '');
  };

  const [errorHeaders, setErrorHeaders] = useState([] as any);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      mappedField: Yup.mixed().test(
        'has-duplicate-field',
        'A candidate field should be mapped to only one column',
        (value) => {
          console.log('value ', value);

          const values = Object.values(value) as any;
          const value_dict = {} as any;
          values.map((specific_value: string) => {
            if (specific_value) {
              if (value_dict[specific_value]) {
                value_dict[specific_value] += 1;
              } else {
                value_dict[specific_value] = 1;
              }
            }
          });
          const errorHeaderNames = [] as any;
          Object.keys(value).map((column) => {
            if (value_dict[value[column]] > 1) {
              errorHeaderNames.push(column);
            }
          });
          console.log('value_dict', value_dict);
          setErrorHeaders(errorHeaderNames);
          console.log('errorHeaderNames', errorHeaderNames);
          // Check if there are any duplicates
          return errorHeaderNames.length <= 0;
        }
      ),
    })
  );

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const handleClose = () => {
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    setSubmitErrorMessage('');
    setErrorHeaders('');
    onClose();
  };
  return {
    handleCloseDrawer,
    sequencesData,
    isOpenAlert,
    submitErrorMessage,
    onCloseAlert,
    initialValues,
    validationSchema,
    handleSubmit,
    excelHeaders,
    handleUploadEnd,
    candidateFieldOptions,
    handleCandidateFieldSelect,
    onClose,
    importCount,
    errorHeaders,
  };
};
