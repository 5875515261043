import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
} from '@chakra-ui/react';
import { TeamTrProps } from './types';
import useTableRowTeamsServices from './useTableRowTeamsServices';
import moment from 'moment';
import Button from '@/Library/Button';
import FAIcon from '@/components/lib/FAIcon';

export default function TeamTR({
  team,
  index,
  handleEdit,
  handleViewTeam,
  handleDelete,
}: TeamTrProps) {
  const { manager } = useTableRowTeamsServices({ team, index });
  return (
    <Tr cursor="pointer" bg="white">
      <Td onClick={() => handleViewTeam(team)}>{team.name}</Td>
      <Td onClick={() => handleViewTeam(team)}>{manager?.user?.email}</Td>
      <Td onClick={() => handleViewTeam(team)}>{team.members.length - 1}</Td>
      <Td onClick={() => handleViewTeam(team)}>
        {[manager?.user?.first_name, manager?.user?.last_name]
          .filter(Boolean)
          .join(' ')}
      </Td>
      <Td onClick={() => handleViewTeam(team)}>
        {String(moment(team.created_at).format('MMMM DD, YYYY'))}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <Button
              htmlType="menu"
              variant="ghost"
              iconName="ellipsis-h"
              iconSize="xl"
            />
          </MenuButton>

          <MenuList fontSize="sm">
            <MenuItem onClick={() => handleEdit(team)}>
              <Flex alignItems="center" justifyContent="space-between">
                <FAIcon iconName="pencil" />
                <Box ml={12}>Edit</Box>
              </Flex>
            </MenuItem>
            <MenuItem onClick={() => handleDelete(team)} color="caution.800">
              <Flex alignItems="center" justifyContent="space-between">
                <FAIcon iconName="trash" />
                <Box ml={12}>Delete</Box>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
