import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Pagination from '@/Library/Pagination';
import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { listCandidates } from '@/store/candidates.slice';
import { getJob, listJobs } from '@/store/jobs.slice';
import CandidateTRV2 from '../../../../../Candidates/Table/TR/CandidateTRV2';

export const FollowUpTable = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { followUpCandidates } = useSelector((state: any) => state.jobs);

  const submitsArray = followUpCandidates['Submits'] || [];
  const interviewArray = followUpCandidates['Interview'] || [];

  let rowIdNumber = 1;
  const candidateSort: any =
    followUpCandidates &&
    [...submitsArray, ...interviewArray].map((candidate: any) => ({
      ...candidate,
      rowId: rowIdNumber++,
    }));

  const candidateSortList: any = followUpCandidates;
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  const [openAccordionItems, setOpenAccordionItems] = useState([
    'Submits',
    'Interview',
  ]);

  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckWithJob, setIsCheckWithJob] = useState([] as Array<Object>);
  const [isIndeterminate, setisIndeterminate] = useState(true);

  const { isOpen, onOpen } = useDisclosure();

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (checked) {
      setIsCheck(candidateSort.map((li: any) => li.id));
      setIsCheckWithJob(
        candidateSort.map((li: any) => {
          const job = li?.jobs[0] || null;
          const date =
            job?.interview_date ?? (job?.interview_date || job?.submit_date);
          return {
            id: li.denorm_id,
            candidate_id: li.id,
            job_id: job?.id,
            status_id: job?.status.id,
            job_title: job?.title,
            status: pastTense(li.status_name),
            name: [li?.first_name, li?.last_name].filter(Boolean).join(' '),
            date,
            index: li.index,
          };
        })
      );

      dispatch(
        listJobs({
          selectedCandidates: candidateSort.map((li: any) => {
            const job = li?.jobs[0] || null;
            const date =
              job?.interview_date ?? (job?.interview_date || job?.submit_date);
            return {
              id: li.denorm_id,
              candidate_id: li.id,
              job_id: job?.id,
              status_id: job?.status.id,
              job_title: job?.title,
              status: pastTense(li.status_name),
              name: [li?.first_name, li?.last_name].filter(Boolean).join(' '),
              date,
              index: li.index,
            };
          }),
        })
      );
    } else {
      setIsCheck([]);
      setIsCheckWithJob([]);
      dispatch(
        listJobs({
          selectedCandidates: [],
        })
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleSelectAll({ target: { checked: true } });
    }, 500);
  }, [followUpCandidates]);

  const handleCheck = async (
    e: any,
    job_id: number,
    status_id: number,
    candidate: any
  ) => {
    const { id, checked } = e.target;
    const ID = parseInt(id);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
      setIsCheckWithJob(
        isCheckWithJob.filter(
          (item: any) => item.id !== ID || item.job_id !== job_id
        )
      );

      const unchecked = isCheckWithJob.filter(
        (item: any) => item.id !== ID || item.job_id !== job_id
      );

      dispatch(
        listJobs({
          selectedCandidates: unchecked,
        })
      );
    } else {
      const job = candidate?.jobs[0] || null;
      const date =
        job?.interview_date ?? (job?.interview_date || job?.submit_date);

      const param = {
        id: ID,
        candidate_id: candidate?.id,
        job_id: job_id,
        status_id: status_id,
        job_title: job?.title,
        date,
        status: pastTense(candidate.status_name),
        name: [candidate?.first_name, candidate?.last_name]
          .filter(Boolean)
          .join(' '),
        index: candidate.index,
      } as any;
      setIsCheck([...isCheck, ID]);
      setIsCheckWithJob([...isCheckWithJob, param]);
      dispatch(
        listJobs({
          selectedCandidates: [...isCheckWithJob, param],
        })
      );
    }
  };

  const pastTense = (status: string) => {
    if (status == 'Interview') return 'Interviewed';
    else if (status == 'Submits') return 'Submitted';

    return status;
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...candidatePgBtn,
      page: page,
    };
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...candidatePgBtn,
      take: entries,
    };

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
  };

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            width: '100px',
            position: 'sticky',
            left: 0,
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={handleSelectAll}
              isIndeterminate={isIndeterminate}
              checked={isCheckAll}
              defaultChecked={isCheckAll}
              colorScheme="purple"
            />
            <Box>Candidate Details</Box>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Submitted/Interviewed On</Box>
        </Th>
      ),
    },
  ];

  const accordionItems = Object.keys(candidateSortList).map((jobId, index) => {
    return (
      <>
        <Tr
          sx={{
            bg: '#FAF5FF',
            color: 'primary.600',
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
            cursor: 'pointer',
          }}
          _hover={{
            bg: '#f8f9fa',
          }}
        >
          <Td
            sx={{
              position: 'sticky',
              left: 0,
              p: '8px 24px',
            }}
            _hover={{
              bg: '#f8f9fa',
            }}
          >
            <Flex
              alignItems="center"
              width="100%"
              gap="10px"
              onClick={() => {
                const isOpen = openAccordionItems.includes(jobId);
                setOpenAccordionItems((prevItems) =>
                  isOpen
                    ? prevItems.filter((item) => item !== jobId)
                    : [...prevItems, jobId]
                );
              }}
            >
              {
                <Box width="16px">
                  {openAccordionItems.includes(jobId) ? (
                    <Icon as={BsChevronDown} />
                  ) : (
                    <Icon as={BsChevronRight} />
                  )}
                </Box>
              }
              <Flex
                sx={{
                  whiteSpace: 'initial',
                  width: '200px',
                  textTransform: 'capitalize',
                }}
              >
                {Object.keys(candidateSortList)[index]}
              </Flex>
            </Flex>
          </Td>
          <Td />
        </Tr>

        {openAccordionItems.includes(jobId) &&
          Array.isArray(candidateSortList[jobId]) &&
          candidateSortList[jobId].map((candidate: any, key: number) => {
            return (
              <CandidateTRV2
                candidate={{ ...candidate, index: key }}
                handleCheck={handleCheck}
                isCheck={isCheck}
                isCheckWithJob={isCheckWithJob}
                fromGroupByJobs={true}
                key={key}
              />
            );
          })}
      </>
    );
  });

  useEffect(() => {
    if (params.candidatesId && !isOpen) {
      if (candidateData?.id !== params.candidatesId) {
        pageChange(Number(params?.candidatesPageNum)).then(() => {
          onOpen();
        });
      }
      dispatch(getJob({ jobData: {} }));
    }
  }, [params.candidatesId, isOpen]);

  useEffect(() => {
    setisIndeterminate(false);
    // if (isCheck.length > 0 && isCheck.length < candidatePgBtn.take) {
    if (
      isCheckWithJob.length > 0 &&
      isCheckWithJob.length < candidatePgBtn.take
    ) {
      setisIndeterminate(true);
    }
    // if (isCheck.length <= 0) {
    if (isCheckWithJob.length <= 0) {
      setIsCheckAll(false);
    }
    // if (isCheck.length === candidatePgBtn.take) {
    if (isCheckWithJob.length === candidatePgBtn.take) {
      setIsCheckAll(true);
    }
  }, [candidatePgBtn.take, isCheck, isCheckWithJob, isCheckAll]);

  return (
    <Box px={6} pt={7} justifyContent="space-between">
      {isCheckWithJob.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {/* {isCheck.length} */}
                {isCheckWithJob.length}
              </Box>{' '}
              {/* {isCheck.length > 1 ? 'items' : 'item'} selected */}
              {isCheckWithJob.length > 1 ? 'candidates' : 'candidate'} selected
            </Flex>
          </Tag>
        </Flex>
      )}

      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        sx={{ overflowY: 'scroll', maxHeight: isCheckAll ? '50vh' : '50vh' }}
        height={isCheckAll ? '40vh' : '60vh'}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => title.label)}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {accordionItems}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        totalPages={followUpCandidates.lastPage}
        currentPage={followUpCandidates.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={followUpCandidates.count}
        currentCount={candidateSort?.length}
        targetCount={candidatePgBtn.take}
      />
    </Box>
  );
};
