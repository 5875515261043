import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  MenuDivider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Text,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
} from '@chakra-ui/react';

import AtsSelect2 from '../../AtsSelect2/index';
import { useParams } from 'react-router';
import { useUserListMutation } from '@/store/user.slice';
import {
  useUpdateClientMutation,
  useViewClientMutation,
  useMyListClientsMutation,
  useAllListClientsMutation,
} from '@/store/client.slice';
import Button from '@/Library/Button';
import {
  useGetCountriesMutation,
  useGetJobSpecsMutation,
} from '@/store/constant.slice';
import AddressComponent from '../../Leads/components/AddressComponent';
import ScrollToFieldError from '@/components/app/ScrollError';
import AtsSelect from '@/components/app/AtsSelect';

interface ClientEditModalProps {
  isOpen: any;
  onClose: any;
}
export default function ClientEditModal({
  isOpen,
  onClose,
}: ClientEditModalProps) {
  const params = useParams();

  const clientID = params.clientsId;

  const { clientData, clientPageBtn, allClientPageBtn } = useSelector(
    (state: any) => state.clients
  );
  const { countries } = useSelector((state: any) => state.constants);
  const { job_specs } = useSelector((state: any) => state.constants);

  const [reqUpdateClient, resUpdateClient] = useUpdateClientMutation();
  const [reqSpecs] = useGetJobSpecsMutation();
  const [reqClient] = useViewClientMutation();
  const [reqMyList] = useMyListClientsMutation();
  const [reqAllList] = useAllListClientsMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqUser, resUser] = useUserListMutation();

  const [selectOption, setSelectOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [jobSpecsOption, setJobSpecsOption] = useState([]);
  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [errorMessage] = useState('');

  let [managers, setManager] = useState([]);

  const { isOpen: isOpenAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: false,
  });

  useEffect(() => {
    if (resUpdateClient.isSuccess) {
      reqClient({ id: clientID });
      reqMyList({ data: clientPageBtn });
      reqAllList({ data: allClientPageBtn });
      onClose();
    }
  }, [
    allClientPageBtn,
    clientID,
    clientPageBtn,
    onClose,
    resUpdateClient.isSuccess,
  ]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    let options: any = [];
    // FOR SPECIFICATION OPTIONS
    if (job_specs.length === 0) {
      reqSpecs({}).then((res: any) => {
        const data = res?.data?.data;
        console.log('job specs data ', data);
        if (data && data.length > 0) {
          data.map((item: any) => {
            options.push({
              label: item.specification,
              value: item.id,
            });
            if (item.id === clientData.spec_id) {
              setJobSpecsDefOption({
                label: item.specification,
                value: item.id,
              });
            }
          });
        }
      });
    } else {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === clientData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);

  useEffect(() => {
    reqUser({});
    reqCountry({});
    let managerOption: any = [];
    clientData?.manager?.map(async (item: any) => {
      if (item.user != null) {
        await managerOption.push({
          value: item.user.id,
          label: [item.user.first_name, item.user.last_name].join(' '),
        });
      }
    });
    setManager(managerOption);
  }, [clientData?.manager, clientID]);
  useEffect(() => {
    let userOption: any = [];
    if (resUser.isSuccess) {
      if (resUser.data.data.length > 0) {
        resUser.data.data.map(async (data: any) => {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        });
        setSelectOption(userOption);
      }
    }
  }, [resUser.isSuccess]);

  const formatCountry = (country: any) => {
    let option: any = [];
    try {
      option.push({
        label: country?.country,
        value: country?.id,
        code: country?.code,
      });
    } catch (e) {
      console.log('e', e);
    }
    return option;
  };

  const addressFormat = (address: any) => {
    let newFormat: any = [];
    address?.map((item: any) => {
      newFormat.push({
        address: item.address,
        city: item.city,
        country: formatCountry(item.country),
        country_id: item.country_id,
        id: item.id,
        lead_id: item.lead_id,
        location: item.location,
        primary: item.primary,
        search: item.search,
        state: item.state,
        zip_code: item.zip_code,
      });
    });

    return newFormat;
  };

  let formValues = {
    client_name: clientData !== undefined ? clientData.name : '',
    website: clientData !== undefined ? clientData.website : '',
    isMultipleAccountManager:
      clientData?.manager?.length > 1 ? 'true' : 'false',
    manager: managers,
    address:
      clientData !== undefined
        ? addressFormat(clientData?.address)
        : [
            {
              location: '',
              address: '',
              city: '',
              state: '',
              country: [] as any,
              zip_code: '',
              primary: false,
              id: null,
            },
          ],
    spec_id: clientData?.spec_id || 0,
  };
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      client_name: Yup.string().required('Client name is required.'),
      website: Yup.string().required('Website is required.'),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      spec_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
    })
  );

  const onSbumit = async (data: any) => {
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }
    data.address?.sort((a: any, b: any) =>
      a.primary && !b.primary ? -1 : !a.primary && b.primary ? 1 : 0
    );

    reqUpdateClient({ data, id: clientID });
  };

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Client
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={onSbumit}
            >
              {({
                values,
                setFieldValue,
                handleChange,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_name && touched.client_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Client Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="client_name"
                          name="client_name"
                          type="text"
                          placeholder="Client Name"
                          variant="outline"
                          value={values.client_name}
                        />
                        <FormErrorMessage>
                          {String(errors.client_name)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.spec_id && touched.spec_id)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Industry{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          name="spec_id"
                          options={jobSpecsOption}
                          component={AtsSelect}
                          value={jobSpecsDefOption}
                          placeholder="Select"
                          onChange={(e: any) => {
                            setJobSpecsDefOption(e);
                            setFieldValue('spec_id', String(e.value));
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.website)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.website && touched.website)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Website{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="website"
                          name="website"
                          type="text"
                          placeholder="Website"
                          variant="outline"
                          value={values.website}
                        />
                        <FormErrorMessage>
                          {String(errors.website)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box>
                      <Divider />

                      <Box my="34px">
                        <FormControl>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Multiple account managers4?
                          </FormLabel>
                        </FormControl>

                        <Flex mb="20px">
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'true'}
                              checked={values.manager.length > 1}
                            />
                            Yes
                          </FormLabel>
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'false'}
                              checked={values.manager.length <= 1}
                            />
                            No
                          </FormLabel>
                        </Flex>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.manager && touched.manager
                          )}
                          mb="34px"
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Account Manager{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="manager"
                            isMulti={
                              values.isMultipleAccountManager === 'true'
                                ? true
                                : false
                            }
                            options={selectOption}
                            component={AtsSelect2}
                            defaultValue={values.manager}
                          />
                          <FormErrorMessage>
                            {String(errors.manager)}
                          </FormErrorMessage>
                        </FormControl>

                        <Box>
                          <Text
                            fontSize="16px"
                            lineHeight="18px"
                            fontWeight={600}
                            color="default.primarytext"
                            mb="20px"
                          >
                            Address Information{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </Text>

                          <FormControl
                            isInvalid={Boolean(
                              !!errors.address && touched.address
                            )}
                          >
                            <FieldArray name="address">
                              {({ remove, push }) => (
                                <div>
                                  {values.address &&
                                    values.address.length > 0 &&
                                    values.address.map(
                                      (job: any, index: number) => (
                                        <>
                                          <Accordion
                                            defaultIndex={[index]}
                                            allowMultiple
                                            key={`jobAdd-${index}`}
                                          >
                                            <AccordionItem>
                                              <Flex position="relative">
                                                <AccordionButton>
                                                  <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                  >
                                                    <Box
                                                      as="span"
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      fontWeight={600}
                                                      color="default.primarytext"
                                                      mb="8px"
                                                    >
                                                      {job.location
                                                        ? `${job.location}${
                                                            job.primary
                                                              ? ` (Primary Address)`
                                                              : ''
                                                          }`
                                                        : `Address ${
                                                            index + 1
                                                          }`}
                                                    </Box>
                                                  </Box>

                                                  <Box>
                                                    <AccordionIcon color="#6930CA" />
                                                  </Box>
                                                </AccordionButton>

                                                <Flex
                                                  gap="15px"
                                                  alignItems="center"
                                                  position="absolute"
                                                  right="55px"
                                                  top="0"
                                                  bottom="0"
                                                >
                                                  <Field
                                                    as={Switch}
                                                    colorScheme="purple"
                                                    name={`address.${index}.primary`}
                                                    isChecked={job.primary}
                                                    onChange={() =>
                                                      handleAddressSelection(
                                                        index,
                                                        setFieldValue,
                                                        values
                                                      )
                                                    }
                                                  />
                                                  <Box>
                                                    <Button
                                                      leftIcon="close"
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                      size="sm"
                                                    >
                                                      Remove
                                                    </Button>
                                                  </Box>
                                                </Flex>
                                              </Flex>
                                              <AccordionPanel pb={4}>
                                                <AddressComponent
                                                  setFieldValue={setFieldValue}
                                                  index={index}
                                                  handleChange={handleChange}
                                                  countryOption={countryOption}
                                                  countryField={{
                                                    name: `address.${index}.country`,
                                                    value: job.country,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.country,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.country,
                                                  }}
                                                  locationField={{
                                                    name: `address.${index}.location`,
                                                    value: job.location,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.location,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.location,
                                                  }}
                                                  addressField={{
                                                    name: `address.${index}.address`,
                                                    value: job.address,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.address,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.address,
                                                  }}
                                                  cityField={{
                                                    name: `address.${index}.city`,
                                                    value: job.city,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.city,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.city,
                                                  }}
                                                  stateField={{
                                                    name: `address.${index}.state`,
                                                    value: job.state,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.state,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.state,
                                                  }}
                                                  zipField={{
                                                    name: `address.${index}.zip_code`,
                                                    value: job.zip_code,
                                                    errors: (
                                                      errors.address as any[]
                                                    )?.[index]?.zip_code,
                                                    touched: (
                                                      touched.address as any[]
                                                    )?.[index]?.zip_code,
                                                  }}
                                                />
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </>
                                      )
                                    )}
                                  {clientData.address && (
                                    <Box mt="50px">
                                      <Button
                                        leftIcon="plus"
                                        onClick={() => {
                                          push({
                                            location: '',
                                            address: '',
                                            city: '',
                                            state: '',
                                            country: countryDefOption,
                                            zip_code: '',
                                            primary:
                                              clientData.address?.length ===
                                                0 ||
                                              values.address?.length === 0
                                                ? true
                                                : false,
                                            id: null,
                                          });
                                        }}
                                      >
                                        {values.address &&
                                        values.address.length > 0
                                          ? 'Add Another Address'
                                          : 'Add Address'}
                                      </Button>
                                    </Box>
                                  )}
                                </div>
                              )}
                            </FieldArray>
                            {values.address.length === 0 && (
                              <FormErrorMessage mt="20px">
                                {String(errors.address)}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      disabled={isOpenAlert}
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
