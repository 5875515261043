import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { BsCurrencyDollar } from 'react-icons/bs';
import AtsEmailBody from '@/components/app/EmailBody';
import { IntialValueType } from '../../utils/validationSchema';
import Select from '@/components/ui/Select/Select';
import {
  useFetchCompensationQuery,
  useFetchEducationQuery,
  useFetchEmployeeTypeQuery,
  useFetchExperienceLevelQuery,
  useFetchSkillsQuery,
  useFetchWorkTypeQuery,
} from '@/services/common/constants.query';
import { getLabelValueFormat } from '@/utils/querySelector';
import { useState } from 'react';
import { debounce } from 'lodash';
import { useFetchUsersQuery } from '@/services/user/users.query';
import CreatableSelect from '@/components/ui/Select/CreateableSelect';

export default function JobDetailsForm() {
  let [query, setQuery] = useState({ skills: '' });
  const { data: experienceLevelList, isFetching: isExperienceLevelFetching } =
    useFetchExperienceLevelQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'id', 'label') : [],
          ...other,
        }),
      }
    );
  const { data: recruiterList, isFetching: isRecruiterListLoading } =
    useFetchUsersQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data?.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
              }))
            : [],
          ...other,
        }),
      }
    );
  const { data: educationList, isFetching: isEducationListLoading } =
    useFetchEducationQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data?.map((education) => ({
                label: education.label,
                value: education.id,
              }))
            : [],
          ...other,
        }),
      }
    );
  const { data: workTypeList, isFetching: isWorkTypeFetching } =
    useFetchWorkTypeQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        }),
      }
    );
  const { data: employeeTypeList, isFetching: isEmployeeTypeFetching } =
    useFetchEmployeeTypeQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        }),
      }
    );
  const { data: compensationList, isFetching: isCompensationFetching } =
    useFetchCompensationQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        }),
      }
    );

  const { data: skillsList, isFetching: isSkillFetching } = useFetchSkillsQuery(
    { take: 10, query: query.skills },
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data?.data ? getLabelValueFormat(data?.data, 'id', 'skill') : [],
        ...other,
      }),
    }
  );

  const handleQueryUpdate = debounce((value: string, key: 'skills') => {
    setQuery((state) => ({ ...state, [key]: value }));
  }, 500);

  return (
    <Field>
      {({
        form: { setFieldValue, values, errors, touched },
      }: FieldProps<IntialValueType>) => {
        return (
          <Stack justifyContent="space-between">
            <Flex gap="10px" h="inherit">
              <Box
                sx={{
                  w: '50%',
                  background: '#fff',
                  borderRadius: '6px',
                  p: '16px',
                }}
              >
                {values.job_type === '2' && (
                  <Flex gap="16px" mb="24px">
                    <FormControl
                      isInvalid={Boolean(
                        errors.account_manager && touched.account_manager
                      )}
                      mb="16px"
                      isRequired
                    >
                      <FormLabel>Account Manager</FormLabel>
                      <Field
                        name="account_manager"
                        value={values.account_manager}
                        component={Select}
                        placeholder="Select"
                        onChange={(e: any) =>
                          setFieldValue('account_manager', e)
                        }
                        options={values.managerList}
                        isError={Boolean(
                          errors.account_manager && touched.account_manager
                        )}
                      />

                      <ErrorMessage
                        name="account_manager"
                        render={(msg: any) => (
                          <FormErrorMessage>{msg?.label}</FormErrorMessage>
                        )}
                      />
                    </FormControl>
                    <FormControl
                      isInvalid={Boolean(
                        errors.primary_recruiter && touched.primary_recruiter
                      )}
                      isRequired
                    >
                      <FormLabel>Primary Recruiter</FormLabel>
                      <Field
                        name="primary_recruiter"
                        component={Select}
                        value={values.primary_recruiter}
                        placeholder="Select"
                        onChange={(e: any) =>
                          setFieldValue('primary_recruiter', e)
                        }
                        options={recruiterList}
                        isError={Boolean(
                          errors.primary_recruiter && touched.primary_recruiter
                        )}
                        isLoading={isRecruiterListLoading}
                      />

                      <ErrorMessage
                        name="primary_recruiter"
                        render={(msg: any) => (
                          <FormErrorMessage>{msg?.label}</FormErrorMessage>
                        )}
                      />
                    </FormControl>
                  </Flex>
                )}
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(
                      errors.client_job_title && touched.client_job_title
                    )}
                    isRequired
                  >
                    <FormLabel>Client Job Opening Title</FormLabel>
                    <Field
                      autoFocus
                      name="client_job_title"
                      placeholder="Job Title"
                      value={values.client_job_title}
                      component={Input}
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('client_job_title', e.target.value);
                        setFieldValue('posting_title', e.target.value);
                      }}
                    />

                    <ErrorMessage
                      name="client_job_title"
                      component={FormErrorMessage}
                    />
                  </FormControl>
                  {values.job_type !== '2' && (
                    <FormControl
                      isInvalid={Boolean(
                        errors.no_of_opening && touched.no_of_opening
                      )}
                      isRequired
                    >
                      <FormLabel>No. of Openings</FormLabel>
                      <Field
                        name="no_of_opening"
                        value={values.no_of_opening}
                        type="number"
                        placeholder="0"
                        min={1}
                        component={Input}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('no_of_opening', e.target.value)
                        }
                      />
                      <ErrorMessage
                        name="no_of_opening"
                        component={FormErrorMessage}
                      />
                    </FormControl>
                  )}
                </Flex>

                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(
                      errors.experience_level && touched.experience_level
                    )}
                    isRequired
                  >
                    <FormLabel>Experience Level</FormLabel>
                    <Field
                      name="experience_level"
                      placeholder="Select Level"
                      value={values.experience_level}
                      component={Select}
                      options={experienceLevelList}
                      onChange={(e: any) =>
                        setFieldValue('experience_level', e)
                      }
                      isLoading={isExperienceLevelFetching}
                      isError={Boolean(
                        errors.experience_level && touched.experience_level
                      )}
                    />
                    <ErrorMessage
                      name="experience_level"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                  {values.job_type !== '2' ? (
                    <FormControl
                      isInvalid={Boolean(errors.education && touched.education)}
                      isRequired
                    >
                      <FormLabel>Education</FormLabel>
                      <Field
                        name="education"
                        placeholder="Select Education"
                        value={values.education}
                        component={Select}
                        options={educationList}
                        onChange={(e: any) => setFieldValue('education', e)}
                        isLoading={isEducationListLoading}
                        isError={Boolean(errors.education && touched.education)}
                      />
                      <ErrorMessage
                        name="education"
                        render={(msg: any) => (
                          <FormErrorMessage>{msg?.label}</FormErrorMessage>
                        )}
                      />
                    </FormControl>
                  ) : (
                    <FormControl></FormControl>
                  )}
                </Flex>

                {values.job_type !== '2' && (
                  <>
                    <Flex gap="16px" mb="24px" width="100%">
                      <FormControl
                        isInvalid={Boolean(
                          errors.work_type && touched.work_type
                        )}
                        isRequired
                      >
                        <FormLabel>Work Type</FormLabel>
                        <Field
                          name="work_type"
                          placeholder="Select Type"
                          value={values.work_type}
                          component={Select}
                          options={workTypeList}
                          onChange={(e: any) => setFieldValue('work_type', e)}
                          isLoading={isWorkTypeFetching}
                          isError={Boolean(
                            errors.work_type && touched.work_type
                          )}
                        />
                        <ErrorMessage
                          name="work_type"
                          render={(msg: any) => (
                            <FormErrorMessage>{msg?.label}</FormErrorMessage>
                          )}
                        />
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          errors.job_link_type && touched.job_link_type
                        )}
                        width="100%"
                      >
                        <FormLabel>Client Job Link</FormLabel>
                        <Grid templateColumns="repeat(8, 1fr)">
                          <GridItem colSpan={4}>
                            <Field
                              name="job_link_type"
                              component={Select}
                              isSearchable={false}
                              options={[
                                {
                                  value: 'https://',
                                  label: 'https://',
                                },
                                {
                                  value: 'http://',
                                  label: 'http://',
                                },
                              ]}
                              value={values.job_link_type}
                              onChange={(e: any) => {
                                setFieldValue('job_link_type', e);
                              }}
                              isError={Boolean(
                                errors.job_link_type && touched.job_link_type
                              )}
                            />
                          </GridItem>
                          <GridItem colSpan={4}>
                            <Field
                              component={Input}
                              value={values.job_link_domain}
                              name="job_link_domain"
                              margin="0px"
                              type="text"
                              onChange={(e: any) => {
                                setFieldValue(
                                  'job_link_domain',
                                  e.target.value.replace(/(^\w+:|^)\/\//, '')
                                );
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <ErrorMessage
                          name="job_link_type"
                          component={(msg: any) => (
                            <FormErrorMessage>{msg?.label}</FormErrorMessage>
                          )}
                        />
                      </FormControl>
                    </Flex>
                    <Box>
                      <Flex gap="16px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            errors.employment_type && touched.employment_type
                          )}
                          isRequired
                        >
                          <FormLabel>Employment Type</FormLabel>
                          <Field
                            name="employment_type"
                            placeholder="Select"
                            value={values.employment_type}
                            component={Select}
                            options={employeeTypeList}
                            onChange={(e: any) =>
                              setFieldValue('employment_type', e)
                            }
                            isLoading={isEmployeeTypeFetching}
                            isError={Boolean(
                              errors.employment_type && touched.employment_type
                            )}
                          />
                          <ErrorMessage
                            name="employment_type"
                            render={(msg: any) => (
                              <FormErrorMessage>{msg?.label}</FormErrorMessage>
                            )}
                          />
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            errors.compensation_type &&
                              touched.compensation_type
                          )}
                          isRequired
                        >
                          <FormLabel>Compensation Type</FormLabel>
                          <Field
                            name="compensation_type"
                            placeholder="Select"
                            value={values.compensation_type}
                            component={Select}
                            options={compensationList}
                            onChange={(e: any) =>
                              setFieldValue('compensation_type', e)
                            }
                            isLoading={isCompensationFetching}
                            isError={Boolean(
                              errors.compensation_type &&
                                touched.compensation_type
                            )}
                          />
                          <ErrorMessage
                            name="compensation_type"
                            render={(msg: any) => (
                              <FormErrorMessage>{msg?.label}</FormErrorMessage>
                            )}
                          />
                        </FormControl>
                      </Flex>

                      <Flex gap="16px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            errors.salary_range_start &&
                              touched.salary_range_start
                          )}
                          isRequired
                        >
                          <FormLabel>Salary Range Start</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              zIndex={0}
                              children={
                                <Icon as={BsCurrencyDollar} color="gray.500" />
                              }
                              sx={{
                                height: '38px',
                              }}
                            />
                            <Field
                              name="salary_range_start"
                              value={values.salary_range_start}
                              component={Input}
                              type="text"
                              placeholder="0.00"
                              onChange={(event: any) => {
                                let { value } = event.target;

                                // Allow only numeric characters and a dot
                                value = value.replace(/[^0-9.]/g, '');

                                // Remove leading zeros
                                value = value.replace(/^0+/g, '');

                                // Format the number with commas
                                const formattedValue =
                                  Number(value)?.toLocaleString();

                                setFieldValue(
                                  'salary_range_start',
                                  formattedValue
                                );
                              }}
                              sx={{ pl: '35px' }}
                            />
                          </InputGroup>

                          <ErrorMessage
                            name="salary_range_start"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            errors.salary_range_end && touched.salary_range_end
                          )}
                          isRequired
                        >
                          <FormLabel>Salary Range End</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              sx={{
                                height: '38px',
                              }}
                            >
                              <Icon as={BsCurrencyDollar} color="gray.500" />
                            </InputLeftElement>
                            <Field
                              name="salary_range_end"
                              value={values.salary_range_end}
                              component={Input}
                              type="text"
                              placeholder="0.00"
                              onChange={(event: any) => {
                                let { value } = event.target;

                                // Allow only numeric characters and a dot
                                value = value.replace(/[^0-9.]/g, '');

                                // Remove leading zeros
                                value = value.replace(/^0+/g, '');

                                // Format the number with commas
                                const formattedValue =
                                  Number(value)?.toLocaleString();

                                setFieldValue(
                                  'salary_range_end',
                                  formattedValue
                                );
                              }}
                              sx={{ pl: '35px' }}
                            />
                          </InputGroup>

                          <ErrorMessage
                            name="salary_range_end"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                  </>
                )}
                <Box mb="24px">
                  <FormControl
                    isInvalid={Boolean(errors.skills && touched.skills)}
                    isRequired
                  >
                    <FormLabel>Skills</FormLabel>

                    <Field
                      name="skills"
                      component={CreatableSelect}
                      value={values.skills}
                      placeholder="Search Skills"
                      onChange={(event: any) => {
                        setFieldValue('skills', event);
                      }}
                      onInputChange={(value: string) => {
                        handleQueryUpdate(value, 'skills');
                      }}
                      isLoading={isSkillFetching}
                      options={skillsList}
                      isClearable
                      isMulti
                      allowCreateWhileLoading
                      isError={Boolean(errors.skills && touched.skills)}
                    />

                    <ErrorMessage
                      name="skills"
                      render={(msg: any) => {
                        return (
                          <FormErrorMessage>
                            {msg || msg?.[0]?.value}
                          </FormErrorMessage>
                        );
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  w: '50%',
                  background: '#fff',
                  borderRadius: '6px',
                  p: '16px',
                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Stack gap="16px">
                  {values.job_type !== '2' && (
                    <FormControl>
                      <FormLabel>Client Job Opening Description</FormLabel>
                      <AtsEmailBody
                        id="client_job_description"
                        value={values.client_job_description}
                        onChange={(e: any) => {
                          setFieldValue('client_job_description', e);
                        }}
                        refocusTo="title"
                        height={300}
                        allowedCategory={['Candidates', 'Jobs']}
                      />
                      <ErrorMessage
                        name="client_job_description"
                        component={FormErrorMessage}
                      />
                    </FormControl>
                  )}

                  <FormControl>
                    <FormLabel>Notes</FormLabel>

                    <Field
                      component={Textarea}
                      name="client_note"
                      type="text"
                      placeholder="Type what ever you need here..."
                      variant="outline"
                      value={values.client_note}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setFieldValue('client_note', e.target.value)
                      }
                    />
                  </FormControl>
                </Stack>
              </Box>
            </Flex>
          </Stack>
        );
      }}
    </Field>
  );
}
