import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { BiSolidPurchaseTag } from 'react-icons/bi';

interface TagsProps {
  tags: string[];
  maxWidth?: number;
}

export default function Tags({ tags, maxWidth = 200 }: TagsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<string[]>(tags);
  const [showCount, setShowCount] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerWidth = container.offsetWidth;
      const tagElements = container.getElementsByClassName('tag');
      let currentWidth = 0;
      const countIndicatorWidth = 30; // Width for "+N" indicator
      const availableWidth = containerWidth - countIndicatorWidth;
      const newVisibleTags: string[] = [];

      // First try to show all tags
      for (let i = 0; i < tags.length; i++) {
        const tagElement = tagElements[i] as HTMLElement;
        if (!tagElement) continue;

        const tagWidth = tagElement.offsetWidth;
        const tagWithSpacing = tagWidth + (i > 0 ? 4 : 0); // 4px for gap

        if (currentWidth + tagWithSpacing <= availableWidth) {
          currentWidth += tagWithSpacing;
          newVisibleTags.push(tags[i]);
        } else {
          break;
        }
      }

      setVisibleTags(newVisibleTags);
      setShowCount(newVisibleTags.length < tags.length);
    };

    // Initial check after render
    const timeoutId = setTimeout(checkOverflow, 0);

    // Create a ResizeObserver to handle size changes
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      clearTimeout(timeoutId);
      resizeObserver.disconnect();
    };
  }, [tags]);

  if (!tags.length) return null;

  const remainingTags = tags.slice(visibleTags.length);
  const remainingCount = remainingTags.length;

  return (
    <Box ref={containerRef} maxW={maxWidth} overflow="hidden">
      <Flex gap="1" wrap="nowrap" alignItems="center">
        {visibleTags.map((tag, index) => (
          <Tag tag={tag} key={index} />
        ))}
        {showCount && (
          <Tooltip
            label={
              <Box>
                {remainingTags.map((tag, index) => (
                  <Text key={index} fontSize="sm" mb="1">
                    {tag}
                  </Text>
                ))}
              </Box>
            }
            placement="top"
          >
            <Text
              fontSize="sm"
              px="2"
              py="1"
              bg="gray.100"
              color="gray.600"
              borderRadius="full"
              cursor="help"
              whiteSpace="nowrap"
            >
              +{remainingCount}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
}

function Tag({ tag }: { tag: string }) {
  return (
    <Flex
      color="#6930CA"
      bgColor={'#E9D8FD'}
      padding={'4px 8px'}
      alignItems={'center'}
      gap={'4px'}
      borderRadius={'2px'}
      className="tag"
      whiteSpace="nowrap"
    >
      <BiSolidPurchaseTag />
      <Text fontSize={'14px'} fontWeight={700}>
        {tag}
      </Text>
    </Flex>
  );
}
