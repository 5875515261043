import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useDisclosure } from '@chakra-ui/react';
import {
  useGetAgreementMutation,
  useDelAgreementMutation,
  useListLeadAgreementMutation,
  getAgreement,
} from '@/store/leadagreement.sclice';

import { AtsConfirm } from '@/utils/swal';
import { content } from './constants';

export const useAgreementsServices = () => {
  const { agreements, leadAgreementPage, leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );
  const [reqget] = useGetAgreementMutation();
  const [reqdel, resDel] = useDelAgreementMutation();
  const [reqAgreement] = useListLeadAgreementMutation();

  const params = useParams();
  const leadID = params.clientsTabId || params.leadsId;
  const dispatch = useDispatch();

  const {
    isOpen: isAddAgreementOpen,
    onOpen: onAddAgreementOpen,
    onClose: onAddAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isViewAgreementOpen,
    onOpen: onViewAgreementOpen,
    onClose: onViewAgreementClose,
  } = useDisclosure();

  const editClick = async (id: number) => {
    await reqget({ id: id });
    onEditAgreementOpen();
  };

  useEffect(() => {
    reqAgreement({ data: leadAgreementPgBtn, id: leadID });
  }, [
    leadAgreementPgBtn,
    leadID,
    reqAgreement,
    onAddAgreementClose,
    onEditAgreementClose,
    onViewAgreementClose,
  ]);

  useEffect(() => {
    if (resDel.isSuccess) {
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
    }
  }, [leadAgreementPgBtn, leadID, reqAgreement, resDel.isSuccess]);

  const deleteClick = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqdel({ id: id });
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: leadAgreementPgBtn.take,
      query: leadAgreementPgBtn.query,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );
    reqAgreement({ data: newParam, id: leadID });
  };

  const initialValue = {
    search: '',
  };

  const onSubmit = async (data: any) => {
    let newParam = {
      page: leadAgreementPgBtn.page,
      take: leadAgreementPgBtn.take,
      query: data.search,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );

    reqAgreement({ data: newParam, id: leadID });
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        page: 1,
        take: leadAgreementPgBtn.take,
        query: '',
        status: leadAgreementPgBtn.status,
        types: leadAgreementPgBtn.types,
      };
      dispatch(
        getAgreement({
          leadAgreementPgBtn: newParam,
        })
      );
      reqAgreement({ data: newParam, id: leadID });
    }
  };

  const entryChange = async (entries: number) => {
    let newParam = {
      // page: leadAgreementPgBtn.page,
      page: 1,
      take: entries,
      query: leadAgreementPgBtn.query,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );
    reqAgreement({ data: newParam, id: leadID });
  };

  const viewAgreement = async (id: number) => {
    await reqget({ id: id });
    onViewAgreementOpen();
  };
  return {
    initialValue,
    onSubmit,
    clearSearch,
    agreements,
    viewAgreement,
    editClick,
    deleteClick,
    leadAgreementPage,
    pageChange,
    entryChange,
    leadAgreementPgBtn,
    isAddAgreementOpen,
    onAddAgreementClose,
    onAddAgreementOpen,
    isEditAgreementOpen,
    onEditAgreementClose,
    isViewAgreementOpen,
    onViewAgreementClose,
  };
};
