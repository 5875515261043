import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import Select from '@/components/ui/Select/Select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useFetchJobSpecificationsQuery } from '@/services/common/constants.query';
import { InitialValueType } from '../type';

export default function ClientDetails() {
  const formikContext = useFormikContext<InitialValueType>();
  const { data: jobSpecList, isLoading: isJobSpecListLoading } =
    useFetchJobSpecificationsQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data.map((job: any) => ({
                label: job.specification,
                value: job.id,
              }))
            : [],
          ...other,
        }),
      }
    );
  const jobSpecificationValue = useMemo(() => {
    return (
      jobSpecList.find(
        (job) => parseInt(job.value) === formikContext?.values?.specification_id
      ) || { label: '', value: '' }
    );
  }, [jobSpecList, formikContext.values.specification_id]);

  return (
    <>
      <Flex gap="32px" mb="34px">
        <Field name="client_name">
          {({
            form: { errors, touched, values },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.client_name && touched.client_name)}
              isRequired
            >
              <FormLabel>Client Name </FormLabel>
              <Field
                as={Input}
                id="client_name"
                name="client_name"
                type="text"
                placeholder="Client Name"
                variant="outline"
                value={values.client_name}
              />
              <FormErrorMessage>{String(errors.client_name)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="website">
          {({
            form: { errors, touched, values },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.website && touched.website)}
              isRequired
            >
              <FormLabel>Website </FormLabel>
              <Field
                as={Input}
                id="website"
                name="website"
                type="text"
                placeholder="Website"
                variant="outline"
                value={values.website}
              />
              <FormErrorMessage>{String(errors.website)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Field name="specification_id">
          {({
            form: { errors, touched, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(
                !!errors.specification_id && touched.specification_id
              )}
              isRequired
            >
              <FormLabel>Industry </FormLabel>

              <Field
                name="specification_id"
                options={jobSpecList}
                component={Select}
                placeholder="Select"
                value={jobSpecificationValue}
                onChange={(e: any) => {
                  setFieldValue('specification_id', String(e.value));
                }}
                isLoading={isJobSpecListLoading}
                isError={Boolean(
                  !!errors.specification_id && touched.specification_id
                )}
              />

              <FormErrorMessage>
                {String(errors.specification_id)}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
