/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image, Textarea, Tooltip } from '@chakra-ui/react';

import User from '@/assets/images/user.png';

import Button from '@/Library/Button';

import { useNotesServices } from './useNotesServices';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const CandidatesNotes = () => {
  const params = useParams();
  const {
    parse,
    hover,
    setHover,
    candidateNotes,
    quilValue,
    setquilValue,
    hasEditNote,
    cancelUpdate,
    UpdateNotes,
    submitNote,
    isLoading,
    candidateNotePgBtn,
    candidateID,
    reqList,
  } = useNotesServices();

  useEffect(() => {
    if (params.settingSubType === 'notes') {
      reqList({ data: candidateNotePgBtn, id: candidateID });
    }
  }, [candidateID, candidateNotePgBtn, params.settingSubType]);

  return (
    <Box mb="300px" fontFamily="NunitoSans Regular">
      <Box mb={3}>
        {Object.values(candidateNotes)
          ?.map((notes: any) => {
            return (
              <Box borderBottom="1px solid #E6E9EE" py={4}>
                <Flex
                  gap="12px"
                  alignItems="center"
                  justifyContent="flex-start"
                  fontWeight={500}
                  fontSize="md"
                  color="#545454"
                >
                  <Tooltip
                    label={[
                      notes?.user?.first_name,
                      notes?.user?.last_name,
                    ].join(' ')}
                  >
                    <Image
                      borderRadius="full"
                      boxSize="50px"
                      placeSelf="center"
                      src={notes?.user?.profile_picture || User}
                      sx={{ border: '1px solid #E7E9ED', mr: '5px' }}
                    />
                  </Tooltip>
                  <Box
                    sx={{ fontSize: '14px', fontWeight: 700, color: '#545454' }}
                  >
                    {parse(notes.notes)}
                  </Box>
                  <Box
                    sx={{ fontSize: '14px', color: '#9B9B9B' }}
                    onMouseEnter={() => {
                      setHover({ id: notes.id, hover: true });
                    }}
                    onMouseLeave={() => {
                      setHover({ id: notes.id, hover: false });
                    }}
                  >
                    {hover.id === notes.id && hover.hover
                      ? moment
                          .utc(notes.created_at)
                          .format('MM/DD/YYYY hh:mm:ss A')
                      : moment.utc(notes.created_at).format('MM/DD/YYYY')}
                  </Box>
                </Flex>
              </Box>
            );
          })
          .reverse()}
      </Box>
      <Box
        position="fixed"
        right="0"
        bottom="0"
        background="default.white.100"
        boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
        p="16px 32px"
        w="100%"
      >
        <Textarea
          value={quilValue}
          onChange={(e: any) => setquilValue(e.target.value)}
          minH="150px"
        />
        <Box mt="3" textAlign={'right'}>
          {hasEditNote ? (
            <Flex gap={2} justifyContent="flex-end">
              <Button variant="ghost" onClick={() => cancelUpdate()}>
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={() => UpdateNotes()}
                isLoading={isLoading}
              >
                Update Note
              </Button>
            </Flex>
          ) : (
            <Button
              variant="solid"
              onClick={() => submitNote()}
              isLoading={isLoading}
              loadingText="Submitting"
            >
              Add Note
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
