import { Field, Formik } from 'formik';
import parse from 'html-react-parser';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from '@/Library/Button';

import AtsEmailBody from '@/components/app/EmailBody';
import { useSelector } from 'react-redux';

interface StepsFormProps {
  initialValues: any;
  validationSchema: any;
  handleOnSubmit: any;
  handleSendEmail: any;
  isLoading: boolean;
  intialValueEmail: any;
  validationSchemaEmail: any;
  handleReset: any;
  previewData: any;
  handlePreview: any;
  isSpinner: boolean;
}

export const StepsForm = ({
  initialValues,
  validationSchema,
  handleOnSubmit,
  handleSendEmail,
  isLoading,
  intialValueEmail,
  validationSchemaEmail,
  handleReset,
  previewData,
  handlePreview,
  isSpinner,
}: StepsFormProps) => {
  const { hasAlert } = useSelector((state: any) => state.outreach);

  return (
    <Stack width="50%" bg="#fff">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack
              sx={{
                border: !values.previewOpen && '0.5px solid #EEEEEE',
                borderRadius: '6px',
              }}
            >
              <Stack sx={{ position: 'relative' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    p: '8px 14px 16px 14px',

                    button: {
                      fontSize: '14px',
                      p: '11px 16px',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing="10px"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      pt: '16px',
                      pb: '18px',
                      borderBottom: '1px solid #E7EDF4',
                    }}
                  >
                    <Text
                      sx={{
                        p: '10px',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#2B2D42',
                      }}
                    >
                      Email Content
                    </Text>
                    <Button
                      variant={values.previewOpen ? 'solid' : 'outline'}
                      leftIcon="eye"
                      onClick={() => {
                        setFieldValue(`previewOpen`, !values.previewOpen);
                        !values.previewOpen && handlePreview(values);
                      }}
                    >
                      Preview
                    </Button>
                  </Stack>
                </Stack>

                {!values.previewOpen ? (
                  <Stack spacing="10px" p="4px 22px 8px">
                    <FormControl>
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Days to Wait
                      </FormLabel>
                      <Field
                        as={Input}
                        name={`days_to_wait`}
                        value={values.days_to_wait}
                        placeholder="0"
                        type="number"
                        min={0}
                        variant="outline"
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(`days_to_wait`)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Subject
                      </FormLabel>
                      <Field
                        as={Input}
                        name={`subject`}
                        value={values.subject}
                        placeholder="Type @ for email variables"
                        type="text"
                        variant="outline"
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{String(`subject`)}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Remote Subject
                      </FormLabel>
                      <Field
                        as={Input}
                        name={`subject_remote`}
                        value={values.subject_remote}
                        placeholder="Subject Remote"
                        type="text"
                        variant="outline"
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(`subject_remote`)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <Box
                        sx={{
                          '& .ql-editor': {
                            minHeight: '20vh !important',
                          },
                        }}
                      >
                        <AtsEmailBody
                          value={values.content}
                          placeholder="Type what ever you need here..."
                          onChange={(e: any) => {
                            setFieldValue(`content`, e);
                          }}
                          height={200}
                        />
                      </Box>
                    </FormControl>

                    <Stack
                      direction="row"
                      spacing="10px"
                      justifyContent="flex-end"
                      sx={{
                        borderTop: '1px solid #E6E9EE',
                        mt: '28px',
                        pt: '9px',
                      }}
                    >
                      <Button onClick={handleReset}>Cancel</Button>
                      <Button
                        variant="solid"
                        onClick={() => {
                          setFieldValue(`isNew`, values.isNew);
                          handleOnSubmit(values);
                        }}
                        isLoading={isLoading}
                      >
                        {values.isNew ? 'Save Step' : 'Update Step'}
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  <Formik
                    initialValues={intialValueEmail}
                    validationSchema={validationSchemaEmail}
                    onSubmit={(e) => {
                      const params = {
                        to: e.to,
                        subject: values.subject,
                        content: values.content,
                      };
                      handleSendEmail(params);
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      values: valuesE,
                      handleSubmit,
                      handleChange,
                      errors,
                      touched,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Stack
                            p="4px 22px 8px"
                            spacing="10px"
                            alignItems={isSpinner && 'center'}
                          >
                            {isSpinner ? (
                              <Box
                                sx={{
                                  color: 'primary.800',
                                  alignItems: 'center',
                                }}
                              >
                                <Spinner size="sm" speed="0.65s" />
                              </Box>
                            ) : (
                              <>
                                <FormControl
                                  isInvalid={Boolean(!!errors.to && touched.to)}
                                  sx={{
                                    pb: '10px',
                                  }}
                                >
                                  <Input
                                    name="to"
                                    value={valuesE.to}
                                    onChange={handleChange}
                                    color="primary.800"
                                    fontWeight={700}
                                  />
                                  <FormErrorMessage>
                                    {String(errors.to)}
                                  </FormErrorMessage>
                                </FormControl>
                                <Text
                                  sx={{
                                    borderBottom: '1px solid #EEEEEE',
                                    mb: '10px',
                                    pb: '8px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                  }}
                                >
                                  {previewData?.subject}
                                </Text>
                                <Box
                                  sx={{
                                    mb: '10px',
                                    py: '8px',
                                  }}
                                >
                                  {parse(previewData?.body || '')}
                                </Box>
                              </>
                            )}

                            <Box
                              sx={{
                                position: 'absolute',
                                top: '25px',
                                right: '130px',
                                button: {
                                  bgColor: '#fff',
                                  fontSize: '14px',
                                  p: '11px 16px',
                                },
                              }}
                            >
                              <Button
                                leftIcon="caret-right"
                                type="submit"
                                loading={isLoading}
                                disabled={hasAlert || !!errors.to}
                                onClick={handleSubmit}
                              >
                                Send Email Test
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      );
                    }}
                  </Formik>
                )}
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Stack>
  );
};
