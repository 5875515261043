/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
  useGetSequenceDataMutation,
  usePostActiveSequenceMutation,
  usePostArchiveSequenceMutation,
  usePostDuplicateSequenceMutation,
  usePostPrimarySequenceMutation,
  usePostSequenceListMutation,
} from '@/store/outreach.slice';

export const useSequencesTableServices = () => {
  const toast = useToast();
  const dispatch = useDispatch();

  const { settingNav, settingType, settingTypeId } = useParams();

  const {
    sequencesList,
    sequencesPage,
    sequencesPgBtn,
    emailConnectionPgBtn,
    isLoading,
  } = useSelector((state: any) => state.outreach);

  const [reqPostSequencesList, resPostSequencesList] =
    usePostSequenceListMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostDuplicateSequence, resPostDuplicateSequence] =
    usePostDuplicateSequenceMutation();
  const [reqPostArchiveSequence, resPostArchiveSequence] =
    usePostArchiveSequenceMutation();
  const [reqPostActiveSequence, resPostActiveSequence] =
    usePostActiveSequenceMutation();
  const [reqPostPrimarySequence, resPostPrimarySequence] =
    usePostPrimarySequenceMutation();

  const isSequences = settingNav === 'sequences';

  const [openAccordionItems, setOpenAccordionItems] = useState([]);

  const handleDuplicate = (id: number) => {
    reqPostDuplicateSequence(id);
  };

  const handleArchive = (id: number) => {
    const params = {
      archive: 1,
    };
    reqPostArchiveSequence({ id: id, data: params });
  };

  const handleActive = ({ id, val }: any) => {
    const params = {
      active: val ? '1' : '0',
    };
    reqPostActiveSequence({ id: id, data: params });
  };

  const handlePrimarySequence = (id: number) => {
    reqPostPrimarySequence(id);
  };

  const handleFilter = (title: string) => {
    const params = {
      ...sequencesPgBtn,
      column_name: title,
      sort_order: sequencesPgBtn?.sort_order === 'ASC' ? 'DESC' : 'ASC',
    };
    dispatch(updateOutreach({ sequencesPgBtn: params }));
    reqPostSequencesList(params);
  };

  const handleOpenAccordion = (id: number) => {
    const isOpen = openAccordionItems.includes(id);
    setOpenAccordionItems((prevItems) =>
      isOpen
        ? prevItems.filter((prevItem) => prevItem !== id)
        : [...prevItems, id]
    );
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...sequencesPgBtn,
      page: page,
      take: sequencesPgBtn.take,
      query: sequencesPgBtn.query,
    };
    dispatch(
      updateOutreach({
        sequencesPgBtn: newParam,
      })
    );
    reqPostSequencesList(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...sequencesPgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      updateOutreach({
        sequencesPgBtn: newParam,
      })
    );
    reqPostSequencesList(newParam);
  };

  useEffect(() => {
    if (isSequences) {
      reqPostSequencesList(sequencesPgBtn);
      reqGetEmailConnectionList(emailConnectionPgBtn);
    }
  }, [isSequences]);

  useEffect(() => {
    if (settingType === 'sequences' && settingTypeId) {
      reqGetSequenceData({ id: settingTypeId });
    }
  }, [settingType, settingTypeId]);

  useEffect(() => {
    if (resPostDuplicateSequence.isSuccess) {
      reqPostSequencesList(sequencesPgBtn);

      toast({
        title: 'Sequence duplicated.',
        description: "We've duplicated your sequence.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDuplicateSequence.isSuccess]);

  useEffect(() => {
    if (resPostArchiveSequence.isSuccess) {
      reqPostSequencesList(sequencesPgBtn);

      toast({
        title: 'Sequence archived.',
        description: "We've archived your sequence.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostArchiveSequence.isSuccess]);

  useEffect(() => {
    if (resPostActiveSequence.isSuccess) {
      toast({
        title: 'Sequence Active.',
        description: "We've set your sequence to active.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostActiveSequence.isSuccess]);

  useEffect(() => {
    if (resPostPrimarySequence.isSuccess) {
      reqPostSequencesList(sequencesPgBtn);

      toast({
        title: 'Primary sequence saved.',
        description: "We've saved your primary sequence.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostPrimarySequence.isSuccess]);

  return {
    sequencesPgBtn,
    reqPostSequencesList,
    resPostSequencesList,
    sequencesList,
    openAccordionItems,
    setOpenAccordionItems,
    handleActive,
    handleDuplicate,
    handleArchive,
    sequencesPage,
    pageChange,
    entryChange,
    handlePrimarySequence,
    handleFilter,
    handleOpenAccordion,
    isLoading,
  };
};
