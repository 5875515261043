import apiSlice from '@/store/api.slice';
import { ApiResponse, PaginatedResponse } from '@/services/type';
import {
  CandidateByIdResInterface,
  CandidateFilterListInterface,
  CandidateGroupListInterface,
  CandidateListPayload,
  CandidateListsRes,
  CandidatePinnedFilterListInterface,
} from './candidate.type';
import { getCandidate } from '@/store/candidates.slice';
import { appError } from '@/store/app.slice';
import { listFilters } from '@/store/candidatefilter.slice';

export const candidateQuery = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchCandidateList: builder.query<
      CandidateListsRes,
      { data: CandidateListPayload }
    >({
      query: ({ data }) => {
        return {
          url: '/candidates/list',
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: data,
        };
      },
      providesTags: (result, error, { data }) => [
        { type: 'Candidates-List', id: 'List' },
      ],
      transformResponse: (response: ApiResponse<CandidateListsRes>) => {
        return response.success
          ? response.data
          : {
              count: 0,
              currentPage: 0,
              data: [],
              lastPage: 0,
              nextPage: 0,
              other: null,
              prevPage: 0,
              statusCode: '',
            };
      },
    }),
    fetchCandidateById: builder.query<
      CandidateByIdResInterface,
      { id: number }
    >({
      query: ({ id }) => {
        return {
          url: `/candidates/view/${id}`,
          method: 'GET',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        };
      },
      providesTags: (result: any, error: any, { id }) => [
        { type: 'Candidates-By-Id', id: 'List' },
        { type: 'Candidates-By-Id', id },
      ],
      transformResponse: (response: ApiResponse<CandidateByIdResInterface>) => {
        return response.success ? response.data : null;
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        //TODO: temporary
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data, ...data?.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    fetchCandidatePinnedFilterList: builder.query<
      CandidatePinnedFilterListInterface[],
      { data: CandidateListPayload; signal?: AbortSignal }
    >({
      query: ({ data, signal }) => ({
        url: `/candidate-filters/pin-list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      providesTags: (result: any, error: any) => [
        { type: 'Candidates-Pinned-Filter', id: 'List' },
      ],
      transformResponse: (
        response: ApiResponse<CandidatePinnedFilterListInterface[]>
      ) => {
        return response.success ? response.data : [];
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listFilters({ candidate_pin_filters: data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    fetchCandidateFiltersList: builder.query<
      CandidateFilterListInterface[],
      CandidateListPayload
    >({
      query: (data) => ({
        url: `/candidate-filters/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      providesTags: (result: any, error: any) => [
        { type: 'Candidates-Filter-List', id: 'List' },
      ],
      transformResponse: (
        response: ApiResponse<CandidateFilterListInterface[]>
      ) => {
        return response.success ? response.data : [];
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listFilters({ candidate_filters: data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    fetchCandiateGroupList: builder.query<
      CandidateGroupListInterface,
      { data: CandidateListPayload; signal?: AbortSignal }
    >({
      query: ({ data, signal }) => {
        return {
          url: '/candidates/group-list',
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: data,
          signal,
        };
      },
      providesTags: (result: any, error: any) => [
        { type: 'Candidates-Group-List', id: 'List' },
      ],
      transformResponse: (
        response: ApiResponse<CandidateGroupListInterface>
      ) => {
        return response.success
          ? response.data
          : {
              data: [],
              other: {
                columns: [],
                OpensearchMultipleResults: [],
                sql: '',
              },
            };
      },
    }),
  }),
});

export const {
  useFetchCandidateListQuery,
  useLazyFetchCandidateListQuery,
  useFetchCandidateByIdQuery,
  useLazyFetchCandidateByIdQuery,
  useFetchCandiateGroupListQuery,
  useFetchCandidateFiltersListQuery,
  useLazyFetchCandidateFiltersListQuery,
  useFetchCandidatePinnedFilterListQuery,
} = candidateQuery;
