import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';

import {
  Box,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  Flex,
  FormErrorMessage,
  Button,
  Checkbox,
  Text,
  useDisclosure,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  InputGroup,
  InputLeftElement,
  CloseButton,
} from '@chakra-ui/react';

import ScrollToFieldError from '@/components/app/ScrollError';
import AtsSelect from '@/components/app/AtsSelect';
import FAIcon from '@/components/lib/FAIcon';

import { useDispatch, useSelector } from 'react-redux';
import { useSkillListMutation } from '@/store/skills.slice';
import { useTagListingMutation } from '@/store/tag.slice';
import FilterInputs from '../components/FilterInputs';
import {
  useCreateCandidateFilterMutation,
  useDelCandidateFilterMutation,
  useUpdateCandidateFilterMutation,
} from '@/store/candidatefilter.slice';
import { AtsConfirm } from '@/utils/swal';
import { swalContent } from '@/types';
import { useJobTitleSearchMutation } from '@/store/jobs.slice';
import { useContactListMutation } from '@/store/contact.slice';
import { useAllListClientsMutation } from '@/store/client.slice';
import { useFetchUsersQuery } from '@/services//user/users.query';
import { setGroupFilter } from '@/store/candidates.slice';
import { useLazyFetchCandidateFiltersListQuery } from '@/services//candidates/candidate.query';
import useFilter from '@/modules/Candidates/components/tables/hooks/useFilter';
import filterSelector from '@/modules/Candidates/components/tables/utils/filterSelector';

interface CandidateViewModalProps {
  isOpen: any;
  onClose: any;
  candidateViewData: any;
  isEdit?: boolean;
  id?: number;
}

export default function CandidateViewModal({
  isOpen,
  onClose,
  candidateViewData,
  isEdit = false,
  id = 0,
}: CandidateViewModalProps) {
  const dispatch = useDispatch();
  const { candidate_filter_columns, filter_operators } = useSelector(
    (state: any) => state.constants
  );
  const { skillPageBtn } = useSelector((state: any) => state.skills);
  const { tagsPageBtn } = useSelector((state: any) => state.tags);
  const { data: userList } = useFetchUsersQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: data
          ? data?.map?.((user) => ({
              value: user.id,
              label: `${user?.first_name} ${user?.last_name}`,
            }))
          : [],
      }),
    }
  );

  const [reqCreate, resCreate] = useCreateCandidateFilterMutation();
  const [reqUpdate, resUpdate] = useUpdateCandidateFilterMutation();
  const [reqDel, resDel] = useDelCandidateFilterMutation();
  const [reJobs] = useJobTitleSearchMutation();

  const [reqSkills] = useSkillListMutation();
  const [reqTags] = useTagListingMutation();
  const [reqContacts] = useContactListMutation();
  const [reqClients] = useAllListClientsMutation();

  const [hasOperator, setHasOperator] = useState(
    candidateViewData?.operators || []
  );

  // const [users, setUsers] = useState();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    const fetch = async () => {
      reJobs({ query: '' });
      reqContacts({ query: '' });
      reqClients({ data: { query: '' } });
      reqSkills(skillPageBtn);
      reqTags(tagsPageBtn);
    };
    if (isOpen) {
      fetch();
    }
  }, [isOpen]);

  const columnsOptions = [
    'candidate_details',
    'contact',
    'location',
    'resume_update_on',
    'work_status',
    'last_activity',
    'associated_jobs',
    'tags',
    'distance',
    'submitted_on',
    'interview_status',
    'interview_date',
    'offered_date',
    'placement_date',
    'candidate_start_date',
    'last_messaged_on',
    'last_called_on',
  ];
  const columnDict = {
    candidate_details: 'Candidate Details',
    contact: 'Contact',
    location: 'Location',
    resume_update_on: 'Resume Update on',
    work_status: 'Work Status',
    last_activity: 'Last Activity',
    associated_jobs: 'Associated Jobs',
    tags: 'Tags',
    distance: 'Location/Distance',
    submitted_on: 'Submitted on/Age',
    interview_status: 'Interview Status',
    interview_date: 'Interview Date',
    offered_date: 'Offered Date/Age',
    placement_date: 'Placement Date',
    candidate_start_date: 'Candidate Start Date',
    last_messaged_on: 'Last Messaged on',
    last_called_on: 'Last Called on',
  } as any;
  const defColumns = [
    'candidate_details',
    'contact',
    'distance',
    'work_status',
    'resume_update_on',
    'last_activity',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    candidateViewData?.columns
      ? Object.keys(candidateViewData?.columns) || defColumns
      : defColumns
  );
  const [activeColumnOptions, setActiveColumnOptions] = useState(
    columnsOptions.filter(
      (colOption: string) => !selectedColumns.includes(colOption)
    )
  );

  const formatQuery = (query: any) => {
    if (query.value) {
      return query.value;
    }
    return query.split(',').filter(Boolean).join(',');
  };

  const submitFilter = (data: any) => {
    let columns = {
      candidate_details: 0,
      contact: 0,
      location: 0,
      resume_update_on: 0,
      work_status: 0,
      last_activity: 0,
      associated_jobs: 0,
      tags: 0,
      distance: 0,
      submitted_on: 0,
      interview_status: 0,
      interview_date: 0,
      offered_date: 0,
      placement_date: 0,
      candidate_start_date: 0,
      last_messaged_on: 0,
      last_called_on: 0,
    } as any;
    if (selectedColumns.length < 5) {
      onOpenAlert();
    } else {
      selectedColumns.map((colOption: string, key: number) => {
        columns[colOption] = key + 1;
      });
      const mappedModule = data.module.map((item: any, key: number) => ({
        column: item.criteria.value,
        condition: item.condition.value,
        value: formatQuery(item.query),
        operator: hasOperator[key] ? hasOperator[key].operator : '',
        order: key,
      }));
      let users: any = [];
      data.user?.map((item: any) => {
        users.push(item?.value);
      });

      const mappedData = {
        title: data.title,
        shareable: data.share,
        filters: mappedModule,
        users,
        group_by_job: data.group_by_job,
        columns: columns,
      };
      // console.log('mappedData', mappedData);
      if (!isEdit) {
        reqCreate(mappedData)
          .unwrap()
          .then((data) => {
            const selected = localStorage.getItem('candidatesFilter');
            const selectedObj = JSON.parse(selected);
            dispatch(setGroupFilter(filterSelector(data?.data)));
            localStorage.setItem('candidatesFilter', {
              ...selectedObj,
              filter: data?.data?.id,
            });
            onClose();
          });
      }
      if (isEdit) {
        reqUpdate({ data: mappedData, id })
          .unwrap()
          .then(() => {
            onClose();
          });
      }
    }
  };

  const hasModule = useCallback(
    (moduleData: any) => {
      let module: any = [];

      if (moduleData?.length > 0) {
        moduleData?.map((mod: any) => {
          module.push({
            criteria:
              candidate_filter_columns.length > 0
                ? candidate_filter_columns
                    .filter((val: { label: any }) => val.label === mod.criteria)
                    .map((item: any) => ({
                      value: item.id,
                      label: item.label,
                      ...item,
                    }))[0]
                : { value: 'null', label: '' },
            condition:
              filter_operators.length > 0
                ? filter_operators
                    .filter(
                      (val: { label: any }) => val.label === mod.condition
                    )
                    .map((item: any) => ({
                      value: item.id,
                      label: item.label,
                      ...item,
                    }))[0]
                : { value: 'null', label: '' },
            query: mod.query,
          });
        });
      }

      if (module.length > 0) {
        return module;
      }
    },
    [candidate_filter_columns, filter_operators]
  );

  const deleteFilter = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete this view`,
      buttons: ['Cancel', 'Delete'],
      icon: 'warning',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqDel({ id })
        .unwrap()
        .then(() => {
          onClose();
        });
    }
  };

  const initialValues: any = useMemo(() => {
    return candidateViewData && Object.keys(candidateViewData).length > 0
      ? {
          ...candidateViewData,
          module: hasModule(candidateViewData?.module),
        }
      : {
          title: 'Untitled View',
          module: [
            {
              criteria: { value: 'null', label: '' },
              condition: { value: 'null', label: '' },
              query: '',
            },
          ],
          share: 'only me',
          operators: [],
          user: [],
          group_by_job: false,
        };
  }, [candidateViewData, hasModule]);
  const [draggedColumn, setDraggedColumn] = useState(null);
  const [searchVal, setSearchVal] = useState<string>('');

  const handleDragStart = (index: any) => {
    setDraggedColumn(index);
  };
  const handleDragOver = (index: number) => {
    if (draggedColumn !== null) {
      const newColumns = [...selectedColumns];
      const draggedItem = newColumns[draggedColumn];
      newColumns.splice(draggedColumn, 1);
      newColumns.splice(index, 0, draggedItem);
      setSelectedColumns(newColumns);
      setDraggedColumn(index);
    }
  };
  const handleDragEnd = async () => {
    setDraggedColumn(null);
  };
  const handleRemove = async (col: string) => {
    const newColumns = selectedColumns.filter(
      (colOption: string) => colOption != col
    );
    setSelectedColumns(newColumns);
    setActiveColumnOptions([...activeColumnOptions, col]);
  };
  const handleAdd = async (col: string) => {
    const newColumns = [...selectedColumns, col];
    setSelectedColumns(newColumns);
    setActiveColumnOptions(
      columnsOptions.filter(
        (colOption: string) => !newColumns.includes(colOption)
      )
    );
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Candidate View
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    Please select at least 5 active columns
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={submitFilter}
              enableReinitialize
            >
              {({
                values,
                errors,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <ScrollToFieldError />
                    <Box p="32px">
                      <Box mb="34px">
                        <FormControl w="300px">
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Title
                          </FormLabel>
                          <Input
                            name="title"
                            type="text"
                            placeholder="Title"
                            variant="outline"
                            value={values.title}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Box>

                      <Box
                        pb="15px"
                        color="default.primarytext"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="18px"
                      >
                        Specify Criteria
                      </Box>

                      <Box background="#fff" borderRadius="8px">
                        <FieldArray name="module">
                          {({ remove, push }) => (
                            <Flex flexWrap="wrap">
                              {values.module?.length > 0 &&
                                values.module.map((mod: any, index: number) => {
                                  const criteria = `module[${index}].criteria`;
                                  const condition = `module[${index}].condition`;
                                  const query = `module[${index}].query`;

                                  const handleQueryValue = (e: any) => {
                                    if (e.value === 4) {
                                      return setFieldValue(query, '${EMPTY}');
                                    } else if (e.value === 5) {
                                      return setFieldValue(
                                        query,
                                        '${NOTEMPTY}'
                                      );
                                    }
                                    // return setFieldValue(query, '');
                                  };
                                  return (
                                    <Flex key={index} flexDirection="column">
                                      <FilterInputs
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        index={index}
                                        criteria={criteria}
                                        candidate_filter_columns={
                                          candidate_filter_columns
                                        }
                                        condition={condition}
                                        filter_operators={filter_operators}
                                        query={query}
                                        mod={mod}
                                        values={values}
                                        setHasOperator={setHasOperator}
                                        handleQueryValue={handleQueryValue}
                                        remove={remove}
                                      />
                                      {values.module.length > 1 &&
                                        values.module.length !== index + 1 && (
                                          <Flex
                                            onClick={() =>
                                              setHasOperator(
                                                (prevOperators: any[]) =>
                                                  prevOperators.map(
                                                    (operator: {
                                                      id: number;
                                                      operator: string;
                                                    }) =>
                                                      operator.id === index
                                                        ? {
                                                            ...operator,
                                                            operator:
                                                              operator.operator ===
                                                              'AND'
                                                                ? 'OR'
                                                                : 'AND',
                                                          }
                                                        : operator
                                                  )
                                              )
                                            }
                                            cursor="pointer"
                                            width="30px"
                                            alignItems="center"
                                            flexDirection="column"
                                            ml="-2px"
                                          >
                                            <Box lineHeight="0.7">|</Box>
                                            <Box fontSize="12px" lineHeight="1">
                                              {hasOperator[index]?.operator}
                                            </Box>
                                            <Box lineHeight="0.7">|</Box>
                                          </Flex>
                                        )}
                                    </Flex>
                                  );
                                })}
                              <Box ml="10px">
                                <Button
                                  onClick={() => {
                                    push({
                                      criteria: '',
                                      condition: '',
                                      query: '',
                                    });
                                    setHasOperator((prev: any) => [
                                      ...prev,
                                      {
                                        id: hasOperator.length,
                                        operator: 'AND',
                                      },
                                    ]);
                                  }}
                                >
                                  <FAIcon iconName="plus" />
                                </Button>
                              </Box>
                            </Flex>
                          )}
                        </FieldArray>
                      </Box>
                      <Box
                        pt="32px"
                        pb="15px"
                        color="default.primarytext"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="18px"
                      >
                        Choose Columns - Tabular View
                      </Box>
                      <Flex gap="10px" w="100%">
                        <Box
                          border="1px solid #E2E8F0"
                          borderRadius="4px"
                          p="10px"
                          w="100%"
                        >
                          <Box
                            color="default.primarytext"
                            fontSize="14px"
                            lineHeight="18px"
                          >
                            Available
                          </Box>
                          <Box>
                            <InputGroup background="#fff">
                              <InputLeftElement
                                cursor="pointer"
                                children={
                                  <Box sx={{ color: 'primary.800' }}>
                                    <FAIcon iconName="search" />
                                  </Box>
                                }
                              />
                              <Input
                                type="search"
                                placeholder="Search columns"
                                value={searchVal}
                                id="search"
                                // width="300px"
                                onChange={(e) => {
                                  console.log('e.target.value', e.target.value);
                                  const val = e.target.value;
                                  setSearchVal(val);
                                  setActiveColumnOptions(
                                    columnsOptions.filter((col) => {
                                      const formattedColOption =
                                        columnDict[col].toLowerCase();
                                      return (
                                        formattedColOption.includes(
                                          val.toLowerCase()
                                        ) && !selectedColumns.includes(col)
                                      );
                                    })
                                  );
                                  // handleChange(e);
                                  // clearSearch(e);
                                }}
                              />
                            </InputGroup>
                          </Box>
                          {activeColumnOptions.map((colOption: string) => {
                            const formattedColOption = columnDict[colOption];
                            return (
                              <Flex
                                border="1px solid #E2E8F0"
                                borderRadius="4px"
                                mt="2"
                                py="6px"
                                px="12px"
                                justifyContent="space-between"
                                color="primary.600"
                                _hover={{
                                  backgroundColor: 'rgba(239, 229, 255, 0.4)',
                                }}
                              >
                                <Text color="#6B6C7B" fontSize="12px">
                                  {formattedColOption}
                                </Text>
                                <Flex
                                  cursor="pointer"
                                  onClick={() => handleAdd(colOption)}
                                >
                                  <FAIcon iconName="plus" h="12px" />
                                </Flex>
                              </Flex>
                            );
                          })}
                        </Box>
                        <Box
                          border="1px solid #E2E8F0"
                          borderRadius="4px"
                          p="10px"
                          w="100%"
                        >
                          <Box
                            color="default.primarytext"
                            fontSize="14px"
                            lineHeight="18px"
                          >
                            Selected
                          </Box>
                          {selectedColumns.map(
                            (colOption: string, sk: number) => {
                              const formattedColOption = columnDict[colOption];
                              return (
                                <Flex
                                  border="1px solid #E2E8F0"
                                  borderRadius="4px"
                                  mt="2"
                                  py="6px"
                                  px="12px"
                                  justifyContent="space-between"
                                  color="primary.600"
                                  draggable
                                  onDragStart={() => handleDragStart(sk)}
                                  onDragOver={() => handleDragOver(sk)}
                                  onDragEnd={handleDragEnd}
                                  _hover={{
                                    backgroundColor: 'rgba(239, 229, 255, 0.4)',
                                  }}
                                >
                                  <Flex gap="12px">
                                    <FAIcon iconName="grip-vertical" />
                                    <Text color="#6B6C7B" fontSize="12px">
                                      {formattedColOption}
                                    </Text>
                                  </Flex>
                                  {formattedColOption != 'Contact' &&
                                    formattedColOption !=
                                      'Candidate Details' && (
                                      <Flex
                                        cursor="pointer"
                                        onClick={() => handleRemove(colOption)}
                                      >
                                        <FAIcon iconName="minus" h="12px" />
                                      </Flex>
                                    )}
                                </Flex>
                              );
                            }
                          )}
                        </Box>
                      </Flex>

                      <Box
                        pt="32px"
                        pb="15px"
                        color="default.primarytext"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="18px"
                      >
                        Share this with:
                      </Box>

                      <RadioGroup defaultValue={values.share}>
                        <Stack spacing={2} direction="column">
                          <Radio
                            colorScheme="purple"
                            name="share"
                            onChange={handleChange}
                            value="only me"
                          >
                            Only me
                          </Radio>
                          <Radio
                            colorScheme="purple"
                            name="share"
                            onChange={handleChange}
                            value="everyone"
                          >
                            Organization
                          </Radio>
                          <Flex gap="16px">
                            <Radio
                              colorScheme="purple"
                              name="share"
                              onChange={handleChange}
                              value="selected users"
                            >
                              Selected User
                            </Radio>

                            {values.share === 'selected users' && (
                              <FormControl width="300px">
                                <Field
                                  name="user"
                                  id="user"
                                  options={userList}
                                  component={AtsSelect}
                                  value={values.user}
                                  onChange={(e: any) => {
                                    setFieldValue('user', e);
                                  }}
                                  isMulti
                                />
                                <FormErrorMessage>
                                  {String(errors.user)}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Flex>
                        </Stack>
                      </RadioGroup>

                      <FormControl pt="32px" pb="15px">
                        <Checkbox
                          colorScheme="purple"
                          color="default.primarytext"
                          onChange={handleChange}
                          name="group_by_job"
                          id="group_by_job"
                          defaultChecked={values.group_by_job}
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          Group by Jobs
                        </Checkbox>
                      </FormControl>
                    </Box>

                    <ModalFooter
                      position="sticky"
                      bottom="0"
                      background="default.white.100"
                      boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                      gap="16px"
                    >
                      {isEdit && (
                        <Button
                          variant="ghost"
                          onClick={() => deleteFilter()}
                          isLoading={resDel.isLoading}
                          disabled={
                            resCreate.isLoading ||
                            resUpdate.isLoading ||
                            isOpenAlert
                          }
                        >
                          Delete
                        </Button>
                      )}

                      <Button
                        variant="solid"
                        onClick={() => handleSubmit()}
                        isLoading={resCreate.isLoading || resUpdate.isLoading}
                        disabled={resDel.isLoading || isOpenAlert}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
