import { createSlice } from '@reduxjs/toolkit';
import { suppressionListTemp } from '@/modules/Engage/Page/Layout/Recruitment/Settings/TabList/TabPanel/SuspressionList/constant';
import apiSlice from './api.slice';
import { appError } from './app.slice';
import axios from 'axios';

export const initialCandidatePgBtn = {
  page: 1,
  take: 50,
  query: '',
  job_filter: '',
  job_filter_include: 'Current',
  location: '',
  tags: [] as any,
  skills: [] as any,
  experience: [] as any,
  work_status: null as any,
  job_id: null as any,
  excluded_job_id: null as any,
  status: null as any,
  job_status: null as any,
  resume_updated_on: null as any,
  filter: null as any,
  search: '',
  boolean: true,
  reject_reason: null as any,
  source: null as any,
  sub_source: null as any,
};

export const outreachSlice = createSlice({
  name: 'outreach',
  initialState: {
    // Sequences
    isLoading: false,

    sequencesList: [],
    sequencesData: {},
    sequencesPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    sequencesPgBtn: {
      page: 1,
      take: 50,
      query: '',
      closed: false,
      open: true,
      draft: false,
      quickJobs: false,
      job_assigned: false,
      job_ads: false,
      full: false,
      all: false,
      column_name: 'jobs.title',
      sort_order: 'ASC',
      sequence_status: 'active', // active archived
    },
    sequencesCandidateStatus: [],

    candidates: [],
    sequenceCandidateData: {},
    candidateSequencePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidateSequencePgBtn: initialCandidatePgBtn,

    // Sequence - Steps
    stepsData: {},
    previewData: {},

    // Buzz
    buzzData: {},

    // SMS Automation
    smsAutomationList: [],
    smsAutomationData: {},
    smsAutomationPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    smsAutomationPgBtn: {
      query: '',
      take: 50,
      page: 1,
    },

    // SMS History
    smsHistoryList: [],
    smsHistoryData: {},
    smsHistoryPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    smsHistoryPgBtn: {
      query: '',
      take: 50,
      page: 1,
    },

    // Default Schedule
    scheduleList: {},

    // Email Template
    emailTemplateList: [],
    emailTemplateData: {},
    emailTemplatePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    emailTemplatePgBtn: {
      query: '',
      take: 50,
      page: 1,
    },

    // Email Connection
    emailConnectionUrl: null,
    emailConnectionList: [],
    emailConnectionData: {},
    emailConnectionPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    emailConnectionPgBtn: {
      query: '',
      take: 50,
      page: 1,
    },
    hasAlert: false,

    // Supression
    suppressionList: suppressionListTemp,
    suppressionData: {},
    suppressionPgBtn: {
      query: '',
      take: 50,
      page: 1,
    },
  },
  reducers: {
    updateOutreach: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateOutreach } = outreachSlice.actions;
export default outreachSlice.reducer;

let abortControllerRef = axios.CancelToken.source(); // Initialize the reference outside your slice

const outreachApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Sequence
    PostSequenceList: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesList: data.data.data }));
          dispatch(
            updateOutreach({
              sequencesPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetSequenceData: builder.mutation({
      query: ({ id }) => ({
        url: `/outreach/sequences/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetSequenceCandidateData: builder.mutation({
      query: (id) => ({
        url: `/outreach/sequences/candidate/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequenceCandidateData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostCreateSequence: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostUpdateTouchesSequence: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/touches/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostUpdateScheduleSequence: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/schedule/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostArchiveSequence: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/archive/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostActiveSequence: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/active/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostPrimarySequence: builder.mutation({
      query: (id) => ({
        url: `/outreach/sequences/primary/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostEnrichSequence: builder.mutation({
      query: (id) => ({
        url: `/outreach/sequences/candidate/enrich/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostDuplicateSequence: builder.mutation({
      query: (id) => ({
        url: `/outreach/sequences/duplicate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostAssociateEmailSequence: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/email/associate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostPrimaryEmail: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/candidate/email/primary/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostPrimaryPhone: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/candidate/phone/primary/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostValidEmail: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/candidate/email/valid/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostValidPhone: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/candidate/phone/valid/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetChangeStatus: builder.mutation({
      query: () => ({
        url: `/outreach/sequences/candidate/status/list`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ sequencesCandidateStatus: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostChangeStatus: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/candidate/mass/status`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostUpdateCandidateData: builder.mutation({
      query: ({ id, data }) => ({
        url: `/outreach/sequences/candidate/update/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostDeleteSequenceCandidate: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/candidate/mass/delete`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostAddToCampaign: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/candidate/add/campaign`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    // SMS Automation
    PostSMSAutomationList: builder.mutation({
      query: (data) => ({
        url: `/outreach/sms`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ smsAutomationList: data.data.data }));
          dispatch(
            updateOutreach({
              smsAutomationPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostSMSAutomationCreate: builder.mutation({
      query: (data) => ({
        url: `/outreach/sms/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostSMSAutomationUpdate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sms/edit/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostSMSAutomationDelete: builder.mutation({
      query: (id) => ({
        url: `/outreach/sms/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    // SMS History
    PostSMSHistoryList: builder.mutation({
      query: (data) => ({
        url: `/outreach/sms/history`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ smsHistoryList: data.data.data }));
          dispatch(
            updateOutreach({
              smsHistoryPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    // Default Schedule
    GetScheduleList: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/settings/schedule/default`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ scheduleList: data.data.schedule }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostScheduleList: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/schedule/update`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ scheduleList: data.data.schedule }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    // Email Template
    GetEmailTemplateList: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/email/template`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ emailTemplateList: data.data.data }));
          dispatch(
            updateOutreach({
              emailTemplatePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostCreateEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/email/template/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostUpdateEmailTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/settings/email/template/update/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostChangePrimaryTemplate: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/template/primary/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostSendEmail: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/email/send`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostPreviewEmail: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/email/preview`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ previewData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostSyncPrimaryTemplate: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/template/sync/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteEmailTemplate: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/template/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BulkDeleteEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/email/template/bulk/delete`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostListCandidates: builder.mutation({
      query: (data) => {
        // Cancel the previous request (if any) and create a new cancel token source
        abortControllerRef.cancel('Request canceled');
        abortControllerRef = axios.CancelToken.source();
        return {
          url: '/candidates/list',
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: data,
          cancelToken: abortControllerRef.token,
        };
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ candidates: data.data.data }));
          dispatch(updateOutreach({ candidateOthers: data.data.other }));
          dispatch(
            updateOutreach({
              candidateSequencePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
          // abortControllerRef = null;
        } catch ({ error }) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
          if (err.name === 'AbortError') {
            // Handle request cancellation
            console.log('Request was canceled.');
          } else {
            dispatch(appError(error));
          }
        }
      },
    }),

    // Email Connection
    GetEmailConnectionList: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/email/connection/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),

      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ emailConnectionList: data.data.data }));
          dispatch(
            updateOutreach({
              emailConnectionPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostChangeDefaultConnection: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/connection/default/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostConnectToOutlook: builder.mutation({
      query: () => ({
        url: `/outreach/settings/outlook/login`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ emailConnectionUrl: data.data.url }));
          localStorage.setItem('emailConnectionUrl', data.data.url);
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostConnectToOutlookGetToken: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/outlook/get-token`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostConnectToGmail: builder.mutation({
      query: () => ({
        url: `/outreach/settings/gmail/login`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ emailConnectionUrl: data.data.url }));
          localStorage.setItem('emailConnectionUrl', data.data.url);
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostConnectToGmailGetToken: builder.mutation({
      query: (data) => ({
        url: `/outreach/settings/gmail/get-token`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostUpdateEmailConnection: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/settings/email/connection/update/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostDeleteEmailConnection: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/connection/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostBuzzLogin: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/buzz/login`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ buzzData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostBuzzCheck: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/buzz/check`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOutreach({ buzzData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostBuzzSelectCredentials: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/buzz/select/credentials`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostBuzzSelectCampaign: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/buzz/select/campaign`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostBuzzDisconnect: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/buzz/disconnect`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ExportCSV: builder.mutation({
      query: ({ id }) => ({
        url: `/outreach/sequences/export/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    PostDisconnectEmailConnection: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/connection/disconnect/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostEnableSMTP: builder.mutation({
      query: ({ id, data }) => ({
        url: `/outreach/settings/email/connection/smtp/${id}/enable`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    PostDisableSMTP: builder.mutation({
      query: (id) => ({
        url: `/outreach/settings/email/connection/smtp/${id}/Disable`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  // Sequence
  usePostSequenceListMutation,
  useGetSequenceDataMutation,
  useGetSequenceCandidateDataMutation,
  usePostCreateSequenceMutation,
  usePostUpdateTouchesSequenceMutation,
  usePostUpdateScheduleSequenceMutation,
  usePostArchiveSequenceMutation,
  usePostActiveSequenceMutation,
  usePostPrimarySequenceMutation,
  usePostEnrichSequenceMutation,
  usePostDuplicateSequenceMutation,
  usePostAssociateEmailSequenceMutation,
  usePostPrimaryEmailMutation,
  usePostPrimaryPhoneMutation,
  usePostValidEmailMutation,
  usePostValidPhoneMutation,
  useGetChangeStatusMutation,
  usePostChangeStatusMutation,
  usePostUpdateCandidateDataMutation,
  usePostDeleteSequenceCandidateMutation,
  usePostAddToCampaignMutation,
  usePostListCandidatesMutation,

  // Buzz
  usePostBuzzLoginMutation,
  usePostBuzzCheckMutation,
  usePostBuzzSelectCredentialsMutation,
  usePostBuzzSelectCampaignMutation,
  usePostBuzzDisconnectMutation,

  // SMS Automation
  usePostSMSAutomationListMutation,
  usePostSMSAutomationCreateMutation,
  usePostSMSAutomationUpdateMutation,
  usePostSMSAutomationDeleteMutation,

  // SMS History
  usePostSMSHistoryListMutation,

  // Default Schedule
  useGetScheduleListMutation,
  usePostScheduleListMutation,

  // Email Template
  useGetEmailTemplateListMutation,
  usePostCreateEmailTemplateMutation,
  usePostUpdateEmailTemplateMutation,
  usePostChangePrimaryTemplateMutation,
  usePostSendEmailMutation,
  usePostPreviewEmailMutation,
  usePostSyncPrimaryTemplateMutation,
  useDeleteEmailTemplateMutation,
  useBulkDeleteEmailTemplateMutation,

  // Email Connection
  usePostConnectToOutlookMutation,
  usePostConnectToOutlookGetTokenMutation,
  usePostConnectToGmailMutation,
  usePostConnectToGmailGetTokenMutation,
  useGetEmailConnectionListMutation,
  usePostChangeDefaultConnectionMutation,
  usePostUpdateEmailConnectionMutation,
  usePostDeleteEmailConnectionMutation,
  usePostDisconnectEmailConnectionMutation,
  usePostEnableSMTPMutation,
  usePostDisableSMTPMutation,

  // Export CSV
  useExportCSVMutation,
} = outreachApiSlice;
