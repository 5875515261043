import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';

import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Switch,
  InputLeftElement,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react';
import { BsEnvelope, BsLinkedin, BsStar, BsTelephone } from 'react-icons/bs';

import FAIcon from '@/components/lib/FAIcon';
import Pagination from '@/Library/Pagination';

import { navClient } from '@/store/client.slice';
import { listContact, useContactListMutation } from '@/store/contact.slice';
import ScrollToFieldError from '@/components/app/ScrollError';
import toUrl from '@/utils/toUrl';
import { useViewContactMutation } from '@/store/contact.slice';
import TrLoading from '../../TrLoading';
import ContactEmail from '@/components/app/Global/Email/Lead';
import ContactDrawer from '@/components/app/Clients/Contacts/Drawer';
import { LeadDetailDrawerContextType } from '@/modules/Leads/types';
import LeadsDrawer from '@/modules/Leads/components/detail-drawer';

// THIS IS FOR CONTACTS TAB IN ALL CLIENTS AND MY CLIENTS
const ClientsContacts = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { clientData } = useSelector((state: any) => state.clients);

  const { contactList, contactTabPageBtn, contactPage } = useSelector(
    (state: any) => state.contacts
  );

  const [openEmail, setOpenEmail] = useState({ open: false, id: null });
  const { clientNav } = useSelector((state: any) => state.clients);
  // const [reqContacts, resContacts] = useContactListMutation();
  const [reqContacts, resContacts] = useViewContactMutation();
  const [activeSwitch, setActiveSwitch] = useState(1);

  const tableHeader: { label: string }[] = [
    { label: 'Contact Name' },
    { label: 'Contact Details' },
    { label: 'Actions' },
  ];
  const stateTab: any = params.clientsSubTab;
  const clientID = params.clientsId;

  const initialValues = {
    search: '',
  };
  const {
    isOpen: isContactDrawerVisible,
    onOpen: onOpenContactDrawer,
    onClose: onCloseContactDrawer,
  } = useDisclosure();
  const {
    isOpen: isLeadsDrawerVisible,
    onOpen: onOpenLeadsDrawer,
    onClose: onCloseLeadsDrawer,
  } = useDisclosure();
  const [selectedContact, setSelectedContact] = useState(null);
  const onSubmit = (data: any) => {
    const newParam = {
      ...contactTabPageBtn,
      query: data.search,
    };

    reqContacts({ data: newParam, id: clientID });
    dispatch(listContact({ contactTabPageBtn: newParam }));
  };

  useEffect(() => {
    if (params.clientsTabId && contactList.length) {
      const rowId = contactList.findIndex(
        (contact: any) => contact.id === Number(params.clientsTabId)
      );
      const selectedContact = contactList[rowId];
      setSelectedContact({ ...selectedContact, rowId: rowId + 1 });
    }
  }, [params.clientsTabId, contactList]);
  // ========================= FOR PAGINATION =========================
  const pageChange = async (page: number) => {
    const newParam = {
      ...contactTabPageBtn,
      page: page,
    };
    dispatch(
      listContact({
        contactTabPageBtn: newParam,
        contactPage: { ...contactPage, currentPage: page },
      })
    );
    reqContacts({ data: newParam, id: clientID });
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...contactTabPageBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      listContact({
        contactTabPageBtn: newParam,
        contactPage: { ...contactPage, take: entries },
      })
    );
    reqContacts({ data: newParam, id: clientID });
  };
  // ==================================================================

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      const newParam = {
        ...contactTabPageBtn,
        query: '',
      };
      dispatch(listContact({ contactTabPageBtn: newParam }));
      reqContacts({ data: newParam, id: clientID });
    }
  };

  const handleClick = async (contact: any) => {
    onCloseContactDrawer();
    onCloseLeadsDrawer();
    setSelectedContact(contact);
    if (contact.leadType.id === 4) {
      navigate(
        `/clients/${params.clientsTab}/${contact.client_id}/contacts/${contact?.id}/overview`
      );
    } else if (contact.leadType.id === 1) {
      // navigate(`/leads/${contact?.id}/details`);
      navigate(
        `/clients/${params.clientsTab}/${contact.client_id}/contacts/${contact?.id}/leads/details`
      );
      // dispatch(navClient({ clientNav: 'leads' }));
    }
  };
  const handleContactDrawerPrev = (selectedContact: any) => {
    const rowId = selectedContact.rowId - 2;
    const newSelect = contactList[rowId];
    const contactID = newSelect.id;
    setSelectedContact({
      ...newSelect,
      rowId: selectedContact.rowId - 1,
    });

    if (newSelect.leadType.id === 4) {
      navigate(
        `/clients/${params.clientsTab}/${newSelect.client_id}/contacts/${contactID}/overview`
      );
    } else if (newSelect.leadType.id === 1) {
      navigate(
        `/clients/${params.clientsTab}/${newSelect.client_id}/contacts/${contactID}/leads/details`
      );
    }
  };

  const handleContactDrawerNext = (selectedContact: any) => {
    const rowId = selectedContact.rowId;
    const newSelect = contactList[rowId];
    const contactID = newSelect.id;
    setSelectedContact({
      ...newSelect,
      rowId: selectedContact.rowId + 1,
    });
    if (newSelect.leadType.id === 4) {
      navigate(
        `/clients/${params.clientsTab}/${newSelect.client_id}/contacts/${contactID}/overview`
      );
    } else if (newSelect.leadType.id === 1) {
      navigate(
        `/clients/${params.clientsTab}/${newSelect.client_id}/contacts/${contactID}/leads/details`
      );
    }
  };
  useEffect(() => {
    if (stateTab === 'contacts' && clientID) {
      const newParam = {
        ...contactTabPageBtn,
        active: activeSwitch,
      };
      dispatch(listContact({ contactTabPageBtn: newParam }));
      reqContacts({ data: newParam, id: clientID });
    }
  }, [stateTab, clientID, activeSwitch]);

  useEffect(() => {
    if (clientNav === '') {
      dispatch(navClient({ clientNav: 'contacts' }));
    }
  }, [clientNav]);

  useEffect(() => {
    if (clientNav === 'contacts') {
      navigate(`/clients/contacts/${selectedContact?.id}/overview`);
    }
  }, [clientNav]);

  useEffect(() => {
    if (
      (params.clientsTab === 'my-clients' ||
        params.clientsTab === 'all-clients') &&
      params.clientsSubTab === 'contacts' &&
      params.clientSubSubTab !== 'leads' &&
      !!params.clientsTabId &&
      !!params.clientSubSubTab
    ) {
      onOpenContactDrawer();
    } else {
      onCloseContactDrawer();
    }

    if (
      (params.clientsTab === 'my-clients' ||
        params.clientsTab === 'all-clients') &&
      params.clientSubSubTab === 'leads' &&
      !!params.clientsTabId &&
      !!params.clientSubSubTab
    ) {
      onOpenLeadsDrawer();
    } else {
      onCloseLeadsDrawer();
    }
  }, [params, params.clientsSubTab]);

  // const submitForm = (params: any) => {
  //   dispatch(listContact({ contactTabPageBtn: params }));
  //   reqContact({ data: params, id: clientID });
  // };

  // const handleCheckTypes = useCallback(
  //   (e: any) => {
  //     let params = {
  //       page: contactTabPageBtn.page,
  //       take: contactTabPageBtn.take,
  //       query: contactTabPageBtn.query,
  //       types: typeIsChecked,
  //       status: statusIsChecked,
  //       contacts: contactTabPageBtn.contacts,
  //     };

  //     const { id, checked } = e.target;
  //     let ID = parseInt(id);
  //     setTypeChecked([...typeIsChecked, ID]);
  //     params.types = [...typeIsChecked, ID];
  //     if (!checked) {
  //       const isChecked = typeIsChecked.filter((item: number) => item !== ID);
  //       setTypeChecked(isChecked);
  //       params.types = isChecked;
  //     }
  //     submitForm(params);
  //   },
  //   [
  //     contactPageBtn.query,
  //     contactPageBtn.take,
  //     statusIsChecked,
  //     submitForm,
  //     typeIsChecked,
  //   ]
  // );

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" pb="19px" mt="20px">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Box pr={2}>
                <InputGroup background="#fff">
                  <InputLeftElement children={<FAIcon iconName="search" />} />
                  <Input
                    type="search"
                    value={values.search}
                    name="search"
                    placeholder="Search Contacts"
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                  />
                </InputGroup>
              </Box>
            </form>
          )}
        </Formik>
        <Flex gap="10px">
          <Switch
            colorScheme="purple"
            isChecked={Boolean(activeSwitch)}
            onChange={(e) => setActiveSwitch(e.target.checked ? 1 : 0)}
          />
          Active
        </Flex>
      </Flex>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resContacts.isLoading ? (
                <TrLoading rows={3} columns={3} />
              ) : (
                contactList?.length > 0 &&
                contactList.map((data: any, index: number) => {
                  return (
                    <Tr
                      key={data?.id}
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                      cursor="pointer"
                    >
                      <Td
                        onClick={() => {
                          handleClick({ ...data, rowId: index + 1 });
                        }}
                      >
                        <Box>
                          <Box fontWeight="bold">
                            {[data?.first_name, data?.last_name].join(' ')}
                          </Box>
                          <Box fontSize="sm"> {data?.title}</Box>
                          <Box fontSize="sm"> {data?.company}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Box fontWeight="bold" fontSize="sm">
                            {data?.primary_email}
                          </Box>
                          <Box fontSize="sm"> {data?.personal_phone}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <HStack spacing="25px">
                          <Icon as={BsStar} />
                          <Link href={toUrl(data?.linked_in_url)} isExternal>
                            <Icon as={BsLinkedin} />
                          </Link>
                          <Link
                            onClick={() =>
                              setOpenEmail({ open: true, id: index })
                            }
                          >
                            <Icon as={BsEnvelope} />
                          </Link>
                          <Link href={`tel:${data?.personal_phone}`}>
                            <Icon as={BsTelephone} />
                          </Link>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={contactPage.lastPage}
          currentPage={contactPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={contactTabPageBtn.count}
          targetCount={contactTabPageBtn.take}
        />
      </Box>
      {openEmail.open ? (
        <ContactEmail
          isOpen={openEmail.open}
          onClose={() => setOpenEmail({ open: false, id: null })}
          id={contactList[openEmail.id]?.id}
          email={contactList[openEmail.id]?.primary_email}
          allowedCategory={['Client', 'Contact', 'Signatures']}
        />
      ) : null}

      {isContactDrawerVisible && resContacts.isSuccess ? (
        <ContactDrawer
          isOpen={isContactDrawerVisible && resContacts.isSuccess}
          rowId={selectedContact?.rowId}
          clientID={params.clientsTabId}
          onClose={() => {
            navigate(
              `/clients/${params.clientsTab}/${params.clientsId}/contacts`
            );
            onCloseContactDrawer();
          }}
          totalClients={contactList?.length}
          handleNextData={() => handleContactDrawerNext(selectedContact)}
          handlePrevData={() => handleContactDrawerPrev(selectedContact)}
        />
      ) : null}

      {isLeadsDrawerVisible && resContacts.isSuccess ? (
        <LeadsDrawer
          isOpen={isLeadsDrawerVisible && resContacts.isSuccess}
          onClose={() => {
            navigate(
              `/clients/${params.clientsTab}/${params.clientsId}/contacts`
            );
            onCloseLeadsDrawer();
          }}
          rowId={selectedContact?.rowId}
          totalLeads={contactList?.length}
          handleNextData={() => handleContactDrawerNext(selectedContact)}
          handlePrevData={() => handleContactDrawerPrev(selectedContact)}
        />
      ) : null}
      {/* <Outlet
        context={
          {
            onClose: () => {
              navigate(
                `/clients/${params.clientsTab}/${params.clientsId}/contacts`
              );
              onCloseLeadsDrawer();
            },
            rowId: selectedContact?.rowId,
            totalLeads: contactList?.length,
            handleNextData: () => handleContactDrawerNext(selectedContact),
            handlePrevData: () => handleContactDrawerPrev(selectedContact),
          } satisfies LeadDetailDrawerContextType
        }
      /> */}
    </Box>
  );
};

export default ClientsContacts;
