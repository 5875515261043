/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  Checkbox,
  Flex,
  Tag,
  useDisclosure,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Image,
  Badge,
  Button as Button1,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';

import linkedIn from '@/assets/images/linkedin.svg';
import shield from '@/assets/images/engage/MdShield.svg';

import { updateOutreach } from '@/store/outreach.slice';

import TrLoading from '@/components/app/TrLoading';
import FAIcon from '@/components/lib/FAIcon';

import ChangeStatusModal from '../Modal/ChangeStatus';
import CandidateModal from '../Modal/Candidate';
import { CandidatesDrawer } from '../Drawer';

import toUrl from '@/utils/toUrl';
import { SEQUENCE_STATUS } from '../constant';

import { useCandidatesTableServices } from './useCandidatesTableServices';

const CandidatesListTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    candidates,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    handleBulkDeleteCandidates,
    rowId,
    sequenceCandidateData,
    totalCandidatesList,
    handlePrevData,
    handleNextData,
    reqGetSequenceCandidateData,
    setCandidateIds,
    seqId,
    resPostEnrichSequence,
    resCandidateList,
    handleEnrich,
    sequencesData,
    candidateIds,
    candidateSequencePgBtn,
    candidateSequencePage,
    pageChange,
    isLoading,
    entryChange,
  } = useCandidatesTableServices();

  const {
    isOpen: isOpenEditCandidatesModal,
    onOpen: onOpenEditCandidatesModal,
    onClose: onCloseEditCandidatesModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenChangeStatusModal,
    onOpen: onOpenChangeStatusModal,
    onClose: onCloseChangeStatusModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenCandidatesDrawer,
    onOpen: onOpenCandidatesDrawer,
    onClose: onCloseCandidatesDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (params.settingType === 'candidates' && params.settingSubId) {
      reqGetSequenceCandidateData(params.settingSubId);
      onOpenCandidatesDrawer();
    }
  }, [params.settingType, params.settingSubId]);

  return (
    <Box justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button
            variant="basic"
            leftIcon="trash"
            size="sm"
            onClick={() => {
              setCandidateIds(isCheck);
              handleBulkDeleteCandidates(isCheck);
            }}
          >
            Delete Selected
          </Button>
          <Button
            variant="basic"
            leftIcon="refresh"
            size="sm"
            onClick={() => {
              onOpenChangeStatusModal();
              setCandidateIds(isCheck);
            }}
          >
            Change Status
          </Button>
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="90vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th width="50px">
                <Checkbox
                  onChange={handleSelectAll}
                  isIndeterminate={isIndeterminate}
                  checked={isCheckAll}
                  defaultChecked={false}
                  colorScheme="purple"
                />
              </Th>
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Candidate Details
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Contact
              </Th>
              <Th
                sx={{
                  width: '40.5px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Enriched
              </Th>
              <Th
                sx={{
                  width: '40.5px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Status
              </Th>
              <Th
                sx={{
                  width: '40.5px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Step
              </Th>
              <Th
                sx={{
                  width: '40.5px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              ></Th>
            </Tr>
          </Thead>
          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            {isLoading ? (
              <TrLoading rows={3} columns={8} />
            ) : (
              candidates?.map((item: any, index: number) => {
                let emails = item?.emails?.filter(
                  (email: any) => email.primary
                );

                let contacts = item?.contact?.filter(
                  (contact: any) => contact.primary
                );

                return (
                  <Tr cursor="pointer">
                    <Td>
                      <Checkbox
                        onChange={handleCheck}
                        isChecked={isCheck.includes(item?.sequence?.id)}
                        id={item?.sequence?.id?.toString()}
                        key={item?.sequence?.id}
                        colorScheme="purple"
                      />
                    </Td>

                    <Td>
                      <Stack
                        direction="row"
                        spacing="15px"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '240.5px' }}
                      >
                        <Stack
                          spacing={0}
                          minH="62px"
                          justifyContent="center"
                          onClick={() => {
                            onOpenCandidatesDrawer();
                            dispatch(
                              updateOutreach({ sequenceCandidateData: item })
                            );
                            navigate(
                              `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/details/${item?.sequence?.id}`
                            );
                          }}
                        >
                          <Text
                            sx={{
                              mb: '2px',
                              fontWeight: 700,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {[item.first_name, item.last_name].join(' ')}
                          </Text>
                          {item.latest_job_title && (
                            <Text sx={{ whiteSpace: 'pre-line' }}>
                              {item.latest_job_title}
                            </Text>
                          )}
                          {item.latest_employer && (
                            <Text sx={{ whiteSpace: 'pre-line' }}>
                              {item.latest_employer}
                            </Text>
                          )}
                        </Stack>
                        <Link
                          href={toUrl(item.linkedin_url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            boxSize="20px"
                            placeSelf="center"
                            objectFit="contain"
                            src={linkedIn}
                          />
                        </Link>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack>
                        <Link href={`mailto:${emails?.[0]?.email}`}>
                          {emails?.[0]?.email}
                        </Link>
                        <Link href={`tel:${contacts[0]?.number}`}>
                          {contacts[0]?.number}
                        </Link>
                      </Stack>
                    </Td>
                    <Td>
                      {item?.sequence?.enriched ? (
                        <Badge
                          colorScheme="green"
                          sx={{
                            borderRadius: '2px',
                            p: '4px 8px',
                            fontSize: '14px',
                            fontWeight: 700,
                            textTransform: 'capitalize',
                            color: '#38B17A',
                            width: 'max-content',
                          }}
                          onClick={() => {
                            onOpenCandidatesDrawer();
                            dispatch(
                              updateOutreach({ sequenceCandidateData: item })
                            );
                            navigate(
                              `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/details/${item?.sequence?.id}`
                            );
                          }}
                        >
                          <Flex gap="10px">
                            <Image
                              boxSize="20px"
                              placeSelf="center"
                              objectFit="contain"
                              src={shield}
                            />
                            Enriched
                          </Flex>
                        </Badge>
                      ) : (
                        <Button1
                          variant="solid"
                          sx={{ bgColor: '#3182CE', border: 'none' }}
                          _hover={{
                            background: '#3182CE',
                          }}
                          isLoading={
                            seqId === item?.sequence?.id &&
                            (resPostEnrichSequence.isLoading ||
                              resCandidateList.isLoading)
                          }
                          onClick={() => handleEnrich(item?.id)}
                        >
                          Enrich
                        </Button1>
                      )}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenCandidatesDrawer();
                        dispatch(
                          updateOutreach({ sequenceCandidateData: item })
                        );
                        navigate(
                          `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/details/${item?.sequence?.id}`
                        );
                      }}
                    >
                      <Badge
                        sx={{
                          borderRadius: '2px',
                          p: '4px 8px',
                          fontSize: '14px',
                          fontWeight: 700,
                          textTransform: 'capitalize',
                          bgColor:
                            SEQUENCE_STATUS[
                              item?.sequence?.sequence_candidate_status?.status
                            ]?.bgColor,
                          color:
                            SEQUENCE_STATUS[
                              item?.sequence?.sequence_candidate_status?.status
                            ]?.color,
                        }}
                      >
                        {
                          SEQUENCE_STATUS[
                            item?.sequence?.sequence_candidate_status?.status
                          ]?.label
                        }
                      </Badge>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenCandidatesDrawer();
                        dispatch(
                          updateOutreach({ sequenceCandidateData: item })
                        );
                        navigate(
                          `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/details/${item?.sequence?.id}`
                        );
                      }}
                    >
                      {item?.sequence?.current_touch_no === null
                        ? 0
                        : item?.sequence?.current_touch_no + 1}{' '}
                      / {sequencesData?.touches?.length}
                    </Td>
                    <Td>
                      <Menu closeOnSelect={false}>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                        >
                          <MenuItem
                            icon={<FAIcon iconName="pencil" />}
                            onClick={() => {
                              onOpenEditCandidatesModal();
                              dispatch(
                                updateOutreach({ sequenceCandidateData: item })
                              );
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            icon={<FAIcon iconName="refresh" />}
                            onClick={() => {
                              onOpenChangeStatusModal();
                              setCandidateIds([item?.sequence?.id]);
                            }}
                          >
                            Change Status
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>

        {isOpenCandidatesDrawer && (
          <CandidatesDrawer
            isOpen={isOpenCandidatesDrawer}
            onClose={onCloseCandidatesDrawer}
            rowId={rowId}
            sequenceCandidateData={sequenceCandidateData}
            totalCandidatesList={totalCandidatesList}
            handlePrevData={handlePrevData}
            handleNextData={handleNextData}
          />
        )}

        {isOpenEditCandidatesModal && (
          <CandidateModal
            edit
            isOpen={isOpenEditCandidatesModal}
            onClose={onCloseEditCandidatesModal}
          />
        )}

        {isOpenChangeStatusModal && (
          <ChangeStatusModal
            id={candidateIds}
            isOpen={isOpenChangeStatusModal}
            onClose={onCloseChangeStatusModal}
          />
        )}
      </TableContainer>

      <Box sx={{ boxShadow: '0px -4px 8px 0px #0000000A', mt: '32px' }}>
        <Pagination
          totalPages={candidateSequencePage.lastPage}
          currentPage={candidateSequencePage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={candidateSequencePage.count}
          onEntryChange={(entries) => entryChange(entries)}
          currentCount={candidates.length}
          targetCount={candidateSequencePgBtn.take}
        />
      </Box>
    </Box>
  );
};
export default CandidatesListTable;
