import { Box, Button, Flex, Tag, Text, useDisclosure } from '@chakra-ui/react';
import ModalLoader from '@/components/ui/Loaders/ModalLoader';
import { lazy, Suspense } from 'react';
import {
  BiMailSend,
  BiMessageSquareDots,
  BiSolidPurchaseTag,
} from 'react-icons/bi';
import { FaBan } from 'react-icons/fa6';

const EmailsModal = lazy(() => import('@/components/app/Global/Email/Lead'));
const AddTagModal = lazy(() => import('../../AddTagModal'));
interface BultkActionProps {
  onMarkInActive: () => void;
  onAddTags: (tags: Array<string>) => Promise<any>;
  leads: Array<{ id: number; email: string }>;
}

const ICONSIZE = '16px';
export default function BulkAction({
  leads,
  onMarkInActive,
  onAddTags,
}: BultkActionProps) {
  const ids = leads.map((lead) => lead.id);
  return (
    <Flex alignItems="center" gap="10px" overflowX="auto" w="100%">
      <Tag
        display="flex"
        borderRadius="4px"
        variant="subtle"
        bg="#FAF7FF"
        fontWeight="regular"
        fontSize="md"
        border="1px solid #6930CA"
        height="32px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex gap={'10px'} fontSize="14px" alignItems="center" color="#6930CA">
          <Text fontSize="14px" fontWeight={600} whiteSpace="nowrap">
            {leads.length} {leads.length > 1 ? 'items' : 'item'} selected
          </Text>
        </Flex>
      </Tag>
      {/* <Button
        variant="basic"
        leftIcon={<BiMessageSquareDots fontSize={ICONSIZE} />}
        size="sm"
      >
        Send SMS
      </Button> */}
      <SendEmail
        ids={ids}
        primaryEmail={leads.map((lead) => lead.email).join(', ')}
      />
      <AddTags onAdd={onAddTags} />
      <Button
        variant="basic"
        leftIcon={<FaBan />}
        size="sm"
        onClick={onMarkInActive}
      >
        Mark as Inactive
      </Button>
    </Flex>
  );
}

function AddTags({ onAdd }: { onAdd: (tags: Array<string>) => Promise<any> }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="basic"
        leftIcon={<BiSolidPurchaseTag fontSize={ICONSIZE} />}
        size="sm"
        onClick={onOpen}
      >
        Add Tags
      </Button>

      {Boolean(isOpen) ? (
        <Suspense fallback={<ModalLoader />}>
          <AddTagModal onClose={onClose} onSubmit={onAdd} />
        </Suspense>
      ) : null}
    </>
  );
}
function SendEmail({
  ids,
  primaryEmail,
}: {
  ids: Array<number>;
  primaryEmail: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="basic"
        leftIcon={<BiMailSend fontSize={ICONSIZE} />}
        size="sm"
        onClick={onOpen}
      >
        Send Email
      </Button>

      {Boolean(isOpen) ? (
        <Suspense fallback={<ModalLoader />}>
          <EmailsModal
            onClose={onClose}
            isOpen={isOpen}
            id={ids[0]}
            ids={ids}
            email={primaryEmail}
            allowedCategory={['Client', 'Contact', 'Signatures']}
            isSubjectDisabled
          />
        </Suspense>
      ) : null}
    </>
  );
}
