import { Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { MdMarkEmailRead } from 'react-icons/md';
import TimeChip from './TimeChip';
import { FaPhone } from 'react-icons/fa6';
import { BiSolidCameraMovie } from 'react-icons/bi';

export default function LastActivityChip({
  sms,
  call,
  video,
  latest,
}: {
  sms: Date;
  call: Date;
  video: Date;
  latest: Date;
}) {
  return (
    <Tooltip
      p={0}
      placement="bottom-start"
      label={
        <Stack
          gap="2px"
          bgColor="#F2EFF9"
          width="100%"
          p={'16px'}
          rowGap={'8px'}
        >
          <Text color="#2B2D42" fontSize="14px">
            Last Activity
          </Text>
          <Flex gap="8px" alignItems="center">
            <MdMarkEmailRead color="#2B2D42" fontSize="16px" />
            <TimeChip text={sms} theme="gray" />
          </Flex>
          <Flex gap="8px" alignItems="center">
            <FaPhone color="#2B2D42" fontSize="16px" />
            <TimeChip text={call} theme="gray" />
          </Flex>
          <Flex gap="8px" alignItems="center">
            <BiSolidCameraMovie fontSize="18px" color="#2B2D42" />
            <TimeChip text={video} theme="gray" />
          </Flex>
        </Stack>
      }
    >
      <TimeChip text={latest} theme="gray" />
    </Tooltip>
  );
}
