// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react';
import { Tabs } from './components/tabs';
import { Button } from './components/button';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Input } from './components/input';
import { Radio } from './components/radio';
import { FormLabel } from './components/form';
import { Checkbox } from './components/checkbox';
import { Textarea } from './components/textarea';
import { Prettify } from '@/types/prettify';

export const fonts = {
  fontFamily: 'NunitoSans Regular',
  fontStyle: 'Semibold',
};

export const colors = {
  primary: {
    100: '#6930CA1A',
    200: '#ded8fc',
    400: '#A83DF9',
    500: '#805AD5',
    600: '#6B46C1',
    800: '#6930CA',
    main: '#6930CA',
  },

  secondary: {
    100: '#D9E7F2',
    400: '#479CFA',
    600: '#2164C8',
    800: '#0061AB',
  },

  default: {
    borderlight: '#E8E8E8',
    borderdark: '#A6A7B0',
    secondarytext: '#6B6C7B',
    primarytext: '#2B2D42',
    disabled: '#BABABA',

    dark: '#000000',
    yellow: '#FFC536',
    white: {
      100: '#FFFFFF',
      200: '#EEEEEE',
      400: '#E6E9EE',
      600: '#F0F2F5',
      800: '#F9FAFB',
    },
    gray: {
      100: '#C5C5C5',
      200: '#E6E9EE',
      400: '#A0AEC0',
      600: '#9B9B9B',
      800: '#545454',
      1000: '#718096',
    },
  },

  success: { 100: '#D9F1E0', 400: '#00A331', 800: '#00AD54', 1000: '#38B17A' },
  warn: { 100: '#F9ECDA', 400: '#D68309', 800: '#E97825', 1000: '#EA6E6E' },
  caution: { 100: '#CB2C2C1A', 400: '#CB2C2C', 800: '#FF3C1F' },
};

export const fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '18px',
  xl: '21px',
  '2xl': '32px',
  '3xl': '40px',
};

export const fontWeights = {
  normal: 400,
  regular: 500,
  bold: 600,
};

export const borderRadius = {
  radii: {
    none: '0',
    sm: '1px',
    base: '2px',
    md: '4px',
    lg: '6px',
    xl: '8px',
  },
};

const theme = extendTheme({
  fonts,
  colors,
  ...borderRadius,
  fontSizes,
  fontWeights,
  components: {
    Button,
    Link,
    Tabs,
    Modal,
    Input,
    FormLabel,
    Checkbox,
    Radio,
    Textarea,
  },

  breakpoints: {
    '1280px': '1280px',
    '1366px': '1366px',
    '1400px': '1400px',
    '1512px': '1512px',
    '1728px': '1728px',
    '1920px': '1920px',
  },
});

export type ATSTheme = Prettify<typeof theme>;
export default theme;
