import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const jobnotesSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobNotes: [],
    jobNoteData: null,
    jobNotePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    jobNotePgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listJobNotes: (state, { payload }) => ({ ...state, ...payload }),
    setJobNotes: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listJobNotes, setJobNotes } = jobnotesSlice.actions;
export default jobnotesSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const jobsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateJobNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs-notes/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListJobNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs-notes/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listJobNotes({
              jobNotes: data.data.data,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    DelJobNote: builder.mutation({
      query: ({ id }) => ({
        url: `/jobs-notes/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateJobNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs-notes/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateJobNoteMutation,
  useDelJobNoteMutation,
  useListJobNoteMutation,
  useUpdateJobNoteMutation,
} = jobsApiSlice;
