import { Flex, Stack, Text } from '@chakra-ui/react';
import { SearchBar } from '@/components/app/Global/SearchBar';
import { SwitchButton } from '@/components/app/Global/SwitchButton';
import EmailsTable from './Table';
import { EmailsTabList } from './TabList';
import { useEmailsServices } from './useEmailsServices';

export const EmailsPage = () => {
  const {
    search,
    setSearch,
    handleSearch,
    active,
    setActive,
    switchContent,
    inboxData,
    isLoading,
  } = useEmailsServices();

  const hasInboxData = Object.keys(inboxData).length > 0;
  return (
    <Flex gap="16px">
      <Stack w="50%">
        <Stack spacing="32px">
          <Flex gap="10px">
            <SwitchButton
              active={active}
              setActive={setActive}
              content={switchContent}
            />
            <SearchBar
              search={search}
              setSearch={setSearch}
              placeholder="Search tasks"
              handleOnChange={handleSearch}
            />
          </Flex>
          <EmailsTable active={active} isLoading={isLoading} />
        </Stack>
      </Stack>
      <Stack
        w="50%"
        justifyContent={!hasInboxData && 'center'}
        sx={{ position: 'relative', bgColor: '#fff', p: '8px 14px' }}
      >
        {hasInboxData ? (
          <EmailsTabList active={active} />
        ) : (
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              color: '#2B2D42',
              textAlign: 'center',
            }}
          >
            Please select a task
          </Text>
        )}
      </Stack>
    </Flex>
  );
};
