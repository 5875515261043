/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
  usePostConnectToGmailGetTokenMutation,
  usePostConnectToGmailMutation,
} from '@/store/outreach.slice';
import { tabOptions } from '../../constants';

export const useEmailConnectionGmailServices = ({ onOpenSMTP }: any) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const isEmailConnection = params.settingType === tabOptions[2];

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostConnectToGmail, resPostConnectToGmail] =
    usePostConnectToGmailMutation();
  const [reqPostConnectToGmailGetToken, resPostConnectToGmailGetToken] =
    usePostConnectToGmailGetTokenMutation();

  const { emailConnectionUrl, emailConnectionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const handleConnectToGmail = () => {
    reqPostConnectToGmail({});
  };

  const emailTokenData: string | null =
    localStorage.getItem('emailConnectionUrl');
  const initEmailToken =
    emailTokenData == null ? emailConnectionUrl : emailTokenData;

  useEffect(() => {
    if (resPostConnectToGmail.isSuccess && initEmailToken !== null) {
      window.location.replace(resPostConnectToGmail.data.data.url);
    }
  }, [resPostConnectToGmail.isSuccess, initEmailToken]);

  const getAccessToken = (search: any) => {
    const urlParams = new URLSearchParams(search);

    // Get the values of the parameters
    const code = urlParams.get('code');
    const scope = urlParams.get('scope');

    const accessToken = { code, scope };

    localStorage.setItem('emailConnectionUrl', null);
    dispatch(
      updateOutreach({
        emailConnectionUrl: null,
      })
    );

    return accessToken;
  };

  const [isGetAccessToken, setIsGetAccessToken] = useState(false);

  useEffect(() => {
    if (isEmailConnection && initEmailToken !== null) {
      if (location.search) {
        setIsGetAccessToken(true);
      }
    }
  }, [isEmailConnection, initEmailToken, location]);

  useEffect(() => {
    if (isGetAccessToken) {
      const accessToken = getAccessToken(window.location.search);

      reqPostConnectToGmailGetToken(accessToken);
    }
  }, [isGetAccessToken]);

  useEffect(() => {
    if (resPostConnectToGmailGetToken.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn).then((data: any) => {
        const hasEmail = data?.data?.data?.data?.filter(
          (val: any) =>
            val?.email ===
            resPostConnectToGmailGetToken?.data?.data?.data?.email
        )[0];
        dispatch(
          updateOutreach({
            emailConnectionData: hasEmail,
          })
        );
      });

      onOpenSMTP();

      toast({
        title: 'Email connection list updated.',
        description: "We've connected your Gmail.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostConnectToGmailGetToken.isSuccess]);

  return {
    handleConnectToGmail,
    isLoading: resPostConnectToGmail.isLoading,
  };
};
