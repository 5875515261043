import { Button, Image } from '@chakra-ui/react';
import MdRegenerate from '@/assets/images/ai-generator/MdRegenerate.svg';

export const RegenerateLayout = ({ isLoading, handleRegenerate }: any) => {
  return (
    <Button
      variant="borderless"
      leftIcon={<Image src={MdRegenerate} />}
      sx={{
        p: '2px 5px',
        height: '18px',
        width: 'fit-content',
        fontWeight: 400,
        color: '#6930CA',
      }}
      disabled={isLoading}
      onClick={handleRegenerate}
    >
      Regenerate
    </Button>
  );
};
