import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CheckboxComponent from './Checkbox';
import { status } from './constants';

export default function List(props: any) {
  const { items, compute } = props;

  const { jobsTabNav } = useParams();

  const { candidatePage } = useSelector((state: any) => state.candidates);

  const [show, setShow] = useState(true);

  return (
    <Stack
      sx={{
        pl: '13px',
        my: '5px',
      }}
    >
      {items.map((item: any, index: any) => {
        let childList = null;
        if (Array.isArray(item.items)) {
          childList = <List items={item.items} compute={compute} />;
        }

        const isParent = item.number_order || item.name === 'All';
        return (
          <div key={item.id}>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Flex gap="8px" alignItems="center">
                <CheckboxComponent
                  id={item.id}
                  name={item.name}
                  checked={item.status === status.checked}
                  indeterminate={item.status === status.indeterminate}
                  compute={compute}
                />
                <Text
                  sx={{
                    fontSize: '14px',
                    fontWeight:
                      item.name === 'All' || item?.number_order ? 600 : 300,
                    color: '#2B2D42',
                  }}
                >
                  {item.name}
                </Text>
                <Text
                  sx={{
                    bgColor: isParent && '#6930CA14',
                    borderRadius: '2px',
                    px: '4px',
                    fontSize: '12px',
                    fontWeight: isParent ? 700 : 600,
                    color: '#6930CA',
                  }}
                >
                  {item.name === 'All' ||
                  (jobsTabNav === 'screen' && item.id === 2) ||
                  (jobsTabNav === 'hired' && item.id === 5) ||
                  (jobsTabNav === 'rejected' && item.id === 6)
                    ? candidatePage.count
                    : item.count}
                </Text>
              </Flex>
              {childList && (
                <Box
                  onClick={() => setShow(!show)}
                  sx={{ fontSize: '12px', cursor: 'pointer' }}
                >
                  <FAIcon iconName={show ? 'chevron-up' : 'chevron-down'} />
                </Box>
              )}
            </Flex>
            {show && childList}
          </div>
        );
      })}
    </Stack>
  );
}
