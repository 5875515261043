import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AtsCraetableSelect from '@/components/app/AtsCreatabeSelect';
import ScrollToFieldError from '@/components/app/ScrollError';
import FAIcon from '@/components/lib/FAIcon';
import Button from '@/Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import replacePlaceholders from '@/utils/textPlaceholders';
import AtsEmailBody from '@/components/app/EmailBody';
import {
  setPairTemplate,
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from '@/store/template.slice';
import { useParams } from 'react-router';
import { FollowUpTable } from './Layout/Table';
import AtsSelect from '@/components/app/AtsSelect';
import EmailPreviewModal from './Layout/Preview';
import {
  listJobs,
  useListByContactCandidatesMutation,
  useListFollowUpCandidatesMutation,
  usePostFollowUpCandidatesMutation,
} from '@/store/jobs.slice';
import moment from 'moment';
import FollowUpLoading from './Layout/Loading';
import { to_resume_url } from '@/utils/encryption';
import { useGetCandidatesMutation } from '@/store/candidates.slice';
import follow_up_table from '@/constants/follow_up_table';
import { useViewClientMutation } from '@/store/client.slice';
import { clientSelectType } from '@/types';
import { useUserListMutation } from '@/store/user.slice';
import AtsSelectContact from '@/components/app/AtsSelectContact';
import { useFetchJobByIdQuery } from '@/services//jobs/job.query';
import useToast from '@/hooks/useToast';
import Select from '@/components/ui/Select/Select';
import useTemplate from '@/components/app/Global/Email/hooks/useTemplate';

interface FollowUpModalProps {
  isOpen: any;
  onClose: any;
  isAll: boolean;
  job_id: number;
}

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    subject: Yup.string().required('Subject is required.'),
    body: Yup.string().required('Body is required.'),
    to: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
    template: Yup.object().shape({ value: Yup.number(), label: Yup.string() }),
  })
);
const toResumeUrl = (id: any, name: any) => {
  const url = to_resume_url(id);
  return `<a href="${url}" target="_blank">${name}</a>`;
};
function singleTableConstructor(candidates: Array<any>) {
  if (!candidates?.length) return follow_up_table.tr;
  let tbody = '';
  candidates.forEach((candidate: any) => {
    const placeholder = {
      job_id: candidate?.job_id,
      candidate_full_name: toResumeUrl(candidate.candidate_id, candidate.name),
      status: candidate.status,
      submitted_interview_date: moment.utc(candidate.date).format('MM/DD/YYYY'),
      feedback: '',
    };
    tbody += replacePlaceholders(follow_up_table.tr, placeholder);
  });

  return tbody;
}
export const FollowUpModal = ({
  isOpen,
  onClose,
  isAll,
  job_id,
}: FollowUpModalProps) => {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenPreview,
    onClose: onClosePreview,
    onOpen: openPreview,
  } = useDisclosure();

  const { placehoderPair } = useSelector((state: any) => state.template);
  const { candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { byContactCandidates, selectedCandidates } = useSelector(
    (state: any) => state.jobs
  );
  const { resumes } = useSelector((state: any) => state.candidateResume);
  const toast = useToast();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqGetClient] = useViewClientMutation();
  const [reqUsers] = useUserListMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqListByContactCandidates] = useListByContactCandidatesMutation();
  const [reqListFollowUpCandidates, resListFollowUpCandidates] =
    useListFollowUpCandidatesMutation();
  const [reqPostFollowUpCandidates, resPostFollowUpCandidates] =
    usePostFollowUpCandidatesMutation();
  const {
    data: jobData,
    isSuccess: isJobSuccess,
    isFetching: isJobFetching,
  } = useFetchJobByIdQuery({
    id: job_id || parseInt(params.jobId),
  });
  const [attachments, setAttachments] = useState([]);

  const [company, setCompany] = useState<clientSelectType | object>({});
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [jobSelect, setJobSelect] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([1, 3]);
  const [contacts, setContacts] = useState([]);
  const jobSelectOptions = useMemo(() => {
    return (
      byContactCandidates?.map((item: any) => ({
        value: item.id,
        label: item.title,
      })) ?? []
    );
  }, [byContactCandidates]);

  const [jobIds, setJobIds] = useState([]);
  const { defaultTemplate, templates, isTemplatesFetching } = useTemplate({
    groupId: 6,
  });

  const formInitialValues = useMemo(() => {
    const body =
      constructFollowupCandidateTable(defaultTemplate?.body ?? '') +
      '<p></p> <p></p>';
    return {
      to: {
        label: `${jobData?.data?.lead?.first_name} ${jobData?.data?.lead?.last_name}`,
        value: jobData?.data?.lead?.primary_email,
      },
      cc: contacts.filter((contact) =>
        jobData?.bcc?.cc?.includes(contact.value)
      ),
      bcc: contacts.filter((contact) =>
        jobData?.bcc?.bcc?.includes(contact.value)
      ),
      subject: replacePlaceholders(
        defaultTemplate?.subject ?? '',
        placehoderPair
      ),
      body: body ?? '',
      primary_resume: true,
      template: defaultTemplate ?? '',
    };
  }, [jobData, contacts, defaultTemplate, placehoderPair]);

  const placholders = (text: string, values: any) => {
    const work_status = candidate_work_status
      .filter(Boolean)
      .filter((item: any) => Number(item.id) == Number(values.work_status));

    const placeholderParam = {
      ...placehoderPair,
      candidate_work_status: work_status?.[0]?.status ?? '',
      client_job_title: jobData?.data?.title || '',
      contact_first_name: jobData?.data?.lead?.first_name || '',
      job_id: jobData?.data?.id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  const onSubmit = (e: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(e.body, 'text/html');
    const bodyParse = doc.querySelector('body').innerHTML;

    const data = {
      ...e,
      to: e.to.value,
      cc: e.cc.map((cc: any) => cc.value),
      bcc: e.bcc.map((cc: any) => cc.value),
      body: bodyParse,
      subject: placholders(e.subject, e),
      candidates: selectedCandidates.map(
        ({ candidate_id, status_id, job_id }: any) => ({
          candidate_id,
          status_id,
          job_id,
        })
      ),
    };
    delete data.template;
    reqPostFollowUpCandidates({
      id: jobData?.data?.lead_id || 0, // TODO: Which id to be used
      data,
    })
      .unwrap()
      .then(() => {
        onClose();
        setJobSelect([]);
        dispatch(listJobs({ byContactCandidates: [] }));
        toast({
          status: 'success',
          title: isAll
            ? 'Succssfully sent follow-up for all jobs!'
            : 'Succssfully sent follow-up!',
        });
      });
  };

  const jobSelectChange = (e: any) => {
    setJobSelect(e);
    fetchCandidatesList(
      selectedStatus,
      e.map((job: any) => job.value)
    );
  };

  const handlePreview = () => {
    reqGetCandidate({ id: selectedCandidates[0]?.candidate_id });
    openPreview();
  };

  async function fetchCandidatesList(status: number[], jobIds: number[]) {
    return reqListFollowUpCandidates({
      job_id: jobIds,
      status_id: status,
    }).unwrap();
  }
  function handleStatusCheck(id: number, checked: boolean) {
    setSelectedStatus((state) => {
      const updated = checked
        ? [...state, id]
        : state.filter((num) => num !== id);
      fetchCandidatesList(updated, jobIds);
      return updated;
    });
  }
  async function fetchPlaceholderPair(
    job_id: number,
    candidate_id: number,
    contact_id: number
  ) {
    return reqPair({
      job_id,
      candidate_id,
      contact_id,
    }).unwrap();
  }
  function replaceTableWithPlaceholder(body: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(body, 'text/html');
    const tables = doc.querySelectorAll('.custom-wrapper');
    if (tables?.length) {
      // Replace the table with the placeholder
      tables.forEach((ele) => {
        ele.outerHTML = '{{follow_up_table}}';
      });
      return doc.body.innerHTML;
    }

    return body;
  }
  function constructFollowupCandidateTable(content: string) {
    const html = `<div class="custom-wrapper"></div>`;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const container = doc.querySelector(`.custom-wrapper`);
    const sortedCandidates = [...selectedCandidates].sort((a, b) => {
      // First sort by status (Interviewed before Submitted)
      if (a.status !== b.status) {
        return a.status === 'Interviewed' ? -1 : 1;
      }
      // Then sort by index within same status
      return a.index - b.index;
    });
    const body = replaceTableWithPlaceholder(content);
    if (isAll) {
      //more than one job follow-up
      let tables = '';
      jobSelectOptions.forEach((job: any, i: number) => {
        const candidates = sortedCandidates.filter(
          (candidate: any) => candidate.job_id === job.value
        );
        const table_content = singleTableConstructor(candidates);

        tables += replacePlaceholders(follow_up_table.table_container, {
          table_content: replacePlaceholders(follow_up_table.table, {
            tbody: table_content,
          }),
          client_job_title: job.label,
        });
        if (i !== jobSelectOptions.length - 1) {
          tables += '<p></p>';
        }
      });
      if (container) {
        container.innerHTML = tables;
        const serializer = new XMLSerializer();
        const htmlString = serializer.serializeToString(doc);
        return replacePlaceholders(body, { follow_up_table: htmlString });
      }
    } else {
      if (container) {
        let content = replacePlaceholders(follow_up_table.table, {
          tbody: singleTableConstructor(sortedCandidates),
        });

        container.innerHTML = content;
        const serializer = new XMLSerializer();
        const htmlString = serializer.serializeToString(doc);
        return replacePlaceholders(body, { follow_up_table: htmlString });
      }
    }
  }
  function fetchInitialData() {
    if (!jobData?.data?.lead_id) return;
    if (isAll) {
      //fetch related jobs to that lead, when all-followup
      reqListByContactCandidates({ id: jobData?.data?.lead_id })
        .unwrap()
        .then((res) => {
          setJobSelect(
            res.data.map((item: any) => ({
              value: item.id,
              label: item.title,
            }))
          );
          setJobIds(res.data.map((job: any) => job.id));
          fetchCandidatesList(
            selectedStatus,
            res.data.map((job: any) => job.id)
          ).then((res) => {
            const candidate = []
              .concat(res?.data?.Interview)
              .concat(res?.data?.Submits);
            fetchPlaceholderPair(
              job_id,
              candidate?.at?.(0)?.id,
              jobData?.data?.lead_id
            );
          });
        });
    } else {
      fetchCandidatesList(selectedStatus, [job_id]).then((res) => {
        const candidate = []
          .concat(res?.data?.Interview)
          .concat(res?.data?.Submits);

        fetchPlaceholderPair(
          job_id,
          candidate?.at?.(0)?.id,
          jobData?.data?.lead_id
        );
      });
      setJobIds([job_id]);
    }
    //fetch templates & placeholder pair values
    // reqList({ id: 6 })
    //   .unwrap()
    //   .then((res) => {
    //     let option: any =
    //       res?.data?.map((item: any) => ({
    //         value: item.id || 0,
    //         label: item.title,
    //         subject: item.subject,
    //         body: item.body,
    //         attachments: item.attachments,
    //         table: item.table || null,
    //         tr: item.tr || null,
    //         placeholders: item.placehoder_reference || null,
    //         subjectmultiple: item.subjectmultiple || item.subject,
    //         bodymultiple: item.bodymultiple || item.body,
    //         table_container: item.table_container || null,
    //       })) ?? [];
    //     setTemplate(option);
    //   });

    //setting contact list (cc,bcc) & company
    reqGetClient({ id: jobData?.data?.client_id })
      .unwrap()
      .then((res) => {
        const data = res.data;
        setCompany({ label: data?.name, value: data?.id });
        setContacts((state) => {
          const arr = [...state]
            .concat(
              data.leads.map((lead: any) => ({
                label: `${lead.first_name} ${lead.last_name}`,
                value: lead.primary_email,
              }))
            )
            .reduce((acc: any, curr: any) => {
              acc[curr.value] = curr;
              return acc;
            }, {});
          return Object.values(arr);
        });
      });
    reqUsers({})
      .unwrap()
      .then((res) => {
        const data = res.data;
        setContacts((state) => {
          const arr = [...state]
            .concat(
              data.map((user: any) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.email,
              }))
            )
            .reduce((acc: any, curr: any) => {
              acc[curr.value] = curr;
              return acc;
            }, {});
          return Object.values(arr);
        });
      });
  }

  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
    }
  }, [resumes]);

  //intial load
  useEffect(() => {
    if (isJobSuccess) {
      fetchInitialData();
    }
  }, [isJobSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
        setJobSelect([]);
        dispatch(listJobs({ byContactCandidates: [] }));
      }}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          <Flex justifyContent="space-between">
            <Flex sx={{ gap: '16px', alignItems: 'center' }}>
              <Box mr="8px">
                {isAll ? 'Follow-up for All Jobs' : 'Follow-up'}
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  isChecked={selectedStatus.includes(1)}
                  name="submit"
                  id="submit"
                  onChange={(e) => {
                    handleStatusCheck(1, e.target.checked);
                  }}
                >
                  Submit
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  isChecked={selectedStatus.includes(3)}
                  name="interview"
                  id="interview"
                  onChange={(e) => {
                    handleStatusCheck(3, e.target.checked);
                  }}
                >
                  Interview
                </Checkbox>
              </Box>
            </Flex>
            {isAll ? (
              <Box
                sx={{
                  mr: '30px',
                  '.chakra-select__icon-wrapper': {
                    color: 'primary.800',
                  },
                }}
              >
                <AtsSelect
                  name="job_select"
                  options={jobSelectOptions}
                  value={jobSelect || []}
                  variant="outline"
                  onChange={jobSelectChange}
                  isMulti
                  styles={{
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#A0AEC0',
                    }),
                    control: (styles: any) => ({
                      ...styles,
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minWidth: '400px',
                      maxWidth: '400px',
                      '&:hover': { border: '1px solid #E2E8F0' },
                    }),
                    valueContainer: (styles: any) => ({
                      ...styles,
                      overflowY: 'scroll',
                      maxHeight: '70px',
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#6930CA',
                      zIndex: 9999999,
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#6930CA',
                        fontWeight: 600,
                      },
                    }),
                  }}
                  components={{
                    Option: ({ label, innerProps }: any) => (
                      <Box onClick={(e) => innerProps.onClick(e)}>
                        <Flex
                          sx={{
                            fontFamily: 'NunitoSans Regular',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '12px 24px',
                            cursor: 'pointer',
                          }}
                          _hover={{
                            backgroundColor: 'rgba(239, 229, 255, 0.4)',
                            color: 'primary.800',
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                            }}
                          >
                            {label}
                          </Box>
                        </Flex>
                      </Box>
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box
                id={`tagTooltip`}
                color="primary.600"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={500}
                height="fit-content"
                mr="30px"
              >
                <Link
                  href={`/jobs/${jobData?.data?.id}/details`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {jobData?.data?.title}
                </Link>
              </Box>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          sx={{
            margin: isAll && '20px 0',
            top: isAll ? 0 : '13px',
            right: '20px',
            color: 'default.secondarytext',
          }}
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                {/* <AlertDescription>{errMsg}</AlertDescription> */}
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          overflow="hidden"
          height="100vh"
        >
          {isJobFetching || resListFollowUpCandidates.isLoading ? (
            <FollowUpLoading />
          ) : (
            <Formik
              initialValues={formInitialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <ScrollToFieldError />

                    <Flex>
                      <Box
                        width="60%"
                        height="90vh"
                        py={2}
                        borderRight="1px solid"
                        borderColor="default.borderlight"
                        px={3}
                        pr={0}
                      >
                        <FollowUpTable />
                      </Box>

                      <Box
                        width="40%"
                        p="32px"
                        sx={{ overflowY: 'scroll', height: '90vh' }}
                      >
                        <>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.to && touched.to)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                To
                              </FormLabel>
                              <Field
                                id="to"
                                name="to"
                                component={AtsSelectContact}
                                value={values.to}
                                client={company}
                                options={contacts}
                                placeholder="Select A Contact"
                                defaultValue={values.to}
                                isSaveInput={true}
                              />
                              <FormErrorMessage>
                                {String(errors.to)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.cc && touched.cc)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Cc
                              </FormLabel>
                              <Field
                                id="cc"
                                name="cc"
                                component={AtsSelectContact}
                                value={values.cc}
                                client={company}
                                options={contacts}
                                isMulti={true}
                                placeholder="cc"
                                defaultValue={values.cc}
                                isSaveInput={true}
                              />

                              <FormErrorMessage>
                                {String(errors.cc)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Bcc
                              </FormLabel>

                              <Field
                                id="bcc"
                                name="bcc"
                                component={AtsSelectContact}
                                value={values.bcc}
                                client={company}
                                options={contacts}
                                isMulti={true}
                                placeholder="bcc"
                                defaultValue={values.bcc}
                                isSaveInput={true}
                              />
                              <FormErrorMessage>
                                {String(errors.cc)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl>
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Template
                              </FormLabel>
                              <Select
                                placeholder="Select"
                                isMulti={false}
                                options={templates}
                                id="template"
                                isLoading={isTemplatesFetching}
                                value={values.template}
                                onChange={(e: any) => {
                                  const body = constructFollowupCandidateTable(
                                    e.body
                                  );
                                  setFieldValue('template', e);
                                  setFieldValue('body', body);
                                  setFieldValue(
                                    'subject',
                                    replacePlaceholders(
                                      e.subject,
                                      placehoderPair
                                    )
                                  );
                                  setAttachments(e.attachments);
                                }}
                              />
                            </FormControl>
                          </Box>
                          <Box mb="34px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Subject{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                id="subject"
                                name="subject"
                                type="text"
                                variant="outline"
                                placeholder="Subject"
                                value={values.subject}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box height={'650px'}>
                            <FormControl
                              isInvalid={Boolean(!!errors.body && touched.body)}
                            >
                              <Text mb="10px" fontWeight={700}>
                                Body
                              </Text>

                              <EmailBody
                                value={values.body}
                                setFieldValue={setFieldValue}
                                constructFollowupCandidateTable={
                                  constructFollowupCandidateTable
                                }
                              />
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                        </>
                      </Box>
                    </Flex>

                    <ModalFooter
                      position="sticky"
                      bottom="0"
                      background="default.white.100"
                      boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                      zIndex={200}
                    >
                      <Flex sx={{ gap: '10px', alignItems: 'center' }}>
                        <Box>
                          <Checkbox
                            colorScheme="purple"
                            color="default.secondarytext"
                            defaultChecked={values.primary_resume}
                            name="primary_resume"
                            id="primary_resume"
                            onChange={handleChange}
                          >
                            Attach Primary Resume
                          </Checkbox>
                        </Box>
                        <Button onClick={() => handlePreview()}>
                          <Flex gap="10px" dir="column" alignItems="center">
                            <FAIcon iconName="eye" />
                            <Box>Preview</Box>
                          </Flex>
                        </Button>
                        <Button
                          variant="solid"
                          type="submit"
                          disabled={
                            isOpenAlert || resPostFollowUpCandidates.isLoading
                          }
                          loading={resPostFollowUpCandidates.isLoading}
                          onClick={() => onSubmit(values)}
                        >
                          Send Follow-up
                        </Button>
                      </Flex>

                      {isOpenPreview && (
                        <EmailPreviewModal
                          data={{
                            ...values,
                            body: replacePlaceholders(
                              values.body ?? '',
                              placehoderPair
                            ),
                            attachments,
                            resume: `${selectedVersion?.original_file_name}.${
                              selectedVersion?.file_name
                                .split('.')
                                .splice(-1)[0]
                            }`,
                          }}
                          // selectedCandidate={selectedCandidates[0]}
                          isOpen={isOpenPreview}
                          onClose={onClosePreview}
                          onSubmit={handleSubmit}
                          disabled={
                            isOpenAlert || resPostFollowUpCandidates.isLoading
                          }
                          isLoading={resPostFollowUpCandidates.isLoading}
                          isFollowUp
                        />
                      )}
                    </ModalFooter>
                  </form>
                );
              }}
            </Formik>
          )}
          {/* <FollowUpLoading /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FollowUpModal;
function EmailBody({
  value,
  setFieldValue,
  constructFollowupCandidateTable,
}: {
  value: string;
  setFieldValue: (field: string, value: string) => void;
  constructFollowupCandidateTable: (body: string) => string;
}) {
  const { selectedCandidates } = useSelector((state: any) => state.jobs);

  useEffect(() => {
    setFieldValue('body', constructFollowupCandidateTable(value));
  }, [selectedCandidates]);
  return (
    <AtsEmailBody
      id="notes"
      value={value}
      height={'500px'}
      onChange={(e: any) => {
        setFieldValue(
          'body',
          e.includes('{{follow_up_table}}')
            ? constructFollowupCandidateTable(e)
            : e
        );
      }}
    />
  );
}
