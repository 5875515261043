import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const userSlice = createSlice({
  name: 'inbox',
  initialState: {
    inboxList: [],
    countPending: 0,
    countDone: 0,
    inboxData: {},
    candidateData: {},
    inboxPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    inboxPgBtn: {
      page: 1,
      take: 50,
      query: '',
      task_status: 1, //1 for pending 2 for done
    },
  },
  reducers: {
    updateInbox: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateInbox } = userSlice.actions;
export default userSlice.reducer;

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    PostTaskList: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/email/task/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateInbox({
              inboxList: data.data.data.data,
              countPending: data.data.countPending,
              countDone: data.data.countDone,
              inboxPage: {
                count: data.data.data.count,
                currentPage: data.data.data.currentPage,
                lastPage: data.data.data.lastPage,
                nextPage: data.data.data.nextPage,
                prevPage: data.data.data.nextPage,
              },
            })
          );
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    GetTaskView: builder.mutation({
      query: (id) => ({
        url: `/outreach/sequences/email/task/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateInbox({ inboxData: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    PostReplyTask: builder.mutation({
      query: ({ data, id }) => ({
        url: `/outreach/sequences/email/task/reply/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateInbox({ inbox: data.data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    PostUpdateStatus: builder.mutation({
      query: (data) => ({
        url: `/outreach/sequences/email/task/update/status`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateInbox({ inbox: data.data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    GetInboxCandidate: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateInbox({
              candidateData: { ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  usePostTaskListMutation,
  useGetTaskViewMutation,
  usePostReplyTaskMutation,
  usePostUpdateStatusMutation,
  useGetInboxCandidateMutation,
} = userApiSlice;
