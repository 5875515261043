import { Flex, Text } from '@chakra-ui/react';
import { getDuration } from '@/modules/Leads/utils/date-format';
import { forwardRef } from 'react';
import { FaRegClock } from 'react-icons/fa6';

interface TimeChipInterface {
  text: Date; // A string representing the start time
  theme?: 'gray' | 'base' | 'dark';
}

const COLOR_THEME = {
  base: { bgColor: '#E9D8FD', textColor: 'primary.800' },
  gray: { bgColor: '#E7E9ED', textColor: '#718096' },
  dark: { bgColor: '#fff', textColor: '#718096' },
};
const TimeChip = forwardRef<HTMLDivElement, TimeChipInterface>(
  ({ text, theme = 'base', ...rest }, ref) => {
    const { days, hours } = getDuration(text);
    const str = hours > 0 ? `${days > 0 ? days : 0}d ${hours}h` : `0d 1h`;
    const currentColor = COLOR_THEME[theme];
    return (
      <Flex
        gap="4px"
        p="2px 4px"
        bgColor={currentColor.bgColor}
        borderRadius="5px"
        alignItems="center"
        w="fit-content"
        h="fit-content"
        color={currentColor.textColor}
        ref={ref}
        {...rest}
      >
        <FaRegClock fontSize="12px" />
        <Text
          fontSize="sm"
          lineHeight="16.37px"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {str}
        </Text>
      </Flex>
    );
  }
);

export default TimeChip;
