import * as Yup from 'yup';

import { Field, FieldArray, Formik } from 'formik';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import ScrollToFieldError from '@/components/app/ScrollError';
import Button from '@/Library/Button';
import moment from 'moment';
import AtsSelect from '@/components/app/AtsSelect';
import { useSelector } from 'react-redux';

interface initialValueInt {
  educations: {
    school: string;
    major: string;
    start_date: string;
    end_date: string;
    degree_level_id: number;
    currently_pursuing: boolean;
  }[];
}

interface CandidateEducationProps {
  initialValues: initialValueInt;
  onSubmit: (e: any) => void;
  CandidateModalFooter?: any;
  degree: any;
}

export default function CandidateEducation({
  initialValues,
  onSubmit,
  CandidateModalFooter,
  degree,
}: CandidateEducationProps) {
  const { educations } = useSelector((state: any) => state.constants);

  const degreeDefault = (id: any) => {
    try {
      const data = educations
        .filter((item: any) => item.id === id)
        .map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
      return data;
    } catch (e) {
      console.warn(`id ${id}`, e);
      return [];
    }
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      educations: Yup.array().of(
        Yup.object().shape({
          school: Yup.string(),
          major: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          degree_level_id: Yup.number(),
          currently_pursuing: Yup.boolean(),
        })
      ),
    })
  );
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />
            {/* Education Details */}
            <Box
              overflowY="scroll"
              maxHeight="450px"
              p="30px"
              background="#fff"
              borderRadius="8px"
              minHeight="450px"
            >
              <FieldArray name="educations">
                {({ remove, push }) => (
                  <div>
                    {values.educations?.length > 0 &&
                      values.educations?.map((education: any, index: any) => {
                        education.start_date = moment(
                          education.start_date
                        ).format('YYYY-MM-DD');
                        education.end_date = moment(education.end_date).format(
                          'YYYY-MM-DD'
                        );
                        return (
                          <div className="row" key={index}>
                            <FormControl mb="34px">
                              <Flex
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Institute/School
                                </FormLabel>
                                {values.educations?.length > 0 && (
                                  <Box mb="10px">
                                    <Button
                                      leftIcon="close"
                                      onClick={() => remove(index)}
                                      size="sm"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                )}
                              </Flex>
                              <Field
                                as={Input}
                                name={`educations.${index}.school`}
                                placeholder="Institute/School"
                                type="text"
                                variant="outline"
                                value={education.school}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(`educations${index}.school`)}
                              </FormErrorMessage>
                            </FormControl>
                            <Flex gap="34px" mb="16px">
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Major/Department
                                </FormLabel>
                                <Field
                                  as={Input}
                                  name={`educations.${index}.major_department`}
                                  placeholder="Major/Department"
                                  type="text"
                                  variant="outline"
                                  value={education.major_department}
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {String(
                                    `educations${index}.major_department`
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.educations as any[])?.[index]
                                    ?.degree_level_id &&
                                    (touched.educations as any[])?.[index]
                                      ?.degree_level_id
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Degree
                                </FormLabel>

                                <AtsSelect
                                  id="degree_level_id"
                                  name="degree_level_id"
                                  placeholder="Select"
                                  variant="outline"
                                  defaultValue={degreeDefault(
                                    education.degree_level_id
                                  )}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      `educations.${index}.degree_level_id`,
                                      Number(e.value)
                                    );
                                  }}
                                  options={degree}
                                />
                                <FormErrorMessage>
                                  {String(
                                    (errors.educations as any[])?.[index]
                                      ?.degree_level_id
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>
                            <Flex gap="34px" mb="16px">
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Start Date
                                </FormLabel>
                                <Field
                                  as={Input}
                                  name={`educations.${index}.start_date`}
                                  placeholder="Select Date"
                                  size="md"
                                  type="date"
                                  value={education.start_date}
                                  onChange={handleChange}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  End Date
                                </FormLabel>
                                <Field
                                  as={Input}
                                  name={`educations.${index}.end_date`}
                                  placeholder="Select Date"
                                  size="md"
                                  type="date"
                                  value={education.end_date}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </Flex>

                            <Box mb="32px">
                              <Checkbox
                                id={`educations.${index}.currently_pursuing`}
                                name={`educations.${index}.currently_pursuing`}
                                isChecked={education.currently_pursuing}
                                onChange={handleChange}
                              >
                                Currently Pursuing
                              </Checkbox>
                            </Box>
                          </div>
                        );
                      })}
                    <Button
                      leftIcon="plus"
                      onClick={() =>
                        push({
                          school: '',
                          major_department: '',
                          start_date: '',
                          end_date: '',
                          degree_level_id: null,
                          currently_pursuing: false,
                        })
                      }
                    >
                      Add Another
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Box>
            {CandidateModalFooter(handleSubmit, errors, isValid, values)}
          </form>
        )}
      </Formik>
    </>
  );
}
