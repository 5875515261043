import { Button, Flex, Image, Stack, Text } from '@chakra-ui/react';
import MdFailed from '@/assets/images/engage/MdFailed.svg';
import { useNavigate } from 'react-router';

export const AlertModal = () => {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: '10%',
        right: '2%',
        zIndex: 9999,
        bgColor: '#FFF2F2',
        maxWidth: '387px',
        boxShadow: '0px 3px 6px 0px #00000040',
        borderRadius: '5px',
        p: '12px',
      }}
    >
      <Flex gap="12px" sx={{ alignItems: 'flex-start' }}>
        <Image src={MdFailed} />
        <Stack spacing="4px">
          <Text sx={{ fontSize: '14px', fontWeight: 600, color: '#000000CC' }}>
            Connection with your email account has been lost.
          </Text>
          <Text sx={{ pb: '12px', fontSize: '14px', color: '#000000CC' }}>
            Emails will not send or sync until you reconnect. Click the button
            below to reconnect.
          </Text>

          <Button
            variant="ghost"
            sx={{
              bgColor: '#EA6E6E',
              p: '6.5px 16px',
              width: '108px',
              color: '#fff',
              fontSize: '14px',
              letterSpacing: '1.25px',
            }}
            _hover={{ bgColor: '#EA6E6E', color: '#fff' }}
            onClick={() =>
              navigate('/engage/settings/recruitment/email-connection')
            }
          >
            Reconnect
          </Button>
        </Stack>
      </Flex>
    </Stack>
  );
};
