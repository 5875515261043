import { Box, Text } from '@chakra-ui/react';
import Button from '@/Library/Button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMsGraphLoginMutation } from '@/store/msgraph.slice';

export default function MsGraphMe() {
  return (
    <Box
      fontFamily="NunitoSans Regular"
      padding="8px 16px"
      minHeight="88vh"
      textAlign="center"
    >
      <Text>User credentials has been retrieved</Text>
    </Box>
  );
}
