import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { updateOutreach } from '@/store/outreach.slice';

export const useSMSHistoryTableServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { smsHistoryList, smsHistoryData, smsHistoryPgBtn, isLoading } =
    useSelector((state: any) => state.outreach);

  const [rowId, setRowId] = useState(smsHistoryData?.rowId);
  const [totalSMSHistoryList, setTotalSMSHistoryList] = useState(
    smsHistoryList.length
  );

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        take: smsHistoryPgBtn.take,
        query: '',
        page: smsHistoryData.page,
      };
      dispatch(
        updateOutreach({
          smsHistoryPgBtn: params,
        })
      );
    }
  };

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const handlePrevData = () => {
    const templateID = smsHistoryList[rowId - 1 - 1].id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleNextData = () => {
    const templateID = smsHistoryList[rowId - 1 + 1].id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  useEffect(() => {
    setTotalSMSHistoryList(smsHistoryList.length);
  }, [smsHistoryList]);

  useEffect(() => {
    if (smsHistoryData?.rowId) {
      setRowId(smsHistoryData?.rowId);
    }
  }, [smsHistoryData?.rowId]);

  return {
    rowId,
    totalSMSHistoryList,
    smsHistoryList,
    smsHistoryData,
    smsHistoryPgBtn,
    clearSearch,
    onSubmit,
    handlePrevData,
    handleNextData,
    isLoading,
  };
};
