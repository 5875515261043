import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import Button from '@/Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import replacePTags from '@/utils/replaceTags';
import replacePlaceholders from '@/utils/textPlaceholders';
import '@/assets/css/list-style.css';
import { CAREER_PAGE_URL } from '@/constants/values';

interface EmailPreviewModalProps {
  isOpen: boolean;
  onClose: any;
  data: any;
  onSubmit: any;
  disabled: boolean;
  isLoading?: boolean;
  isFollowUp?: boolean;
  // selectedCandidate?: any
}

const EmailPreviewModal = ({
  isOpen,
  onClose,
  data,
  onSubmit,
  disabled,
  isLoading,
  isFollowUp,
}: // selectedCandidate,
EmailPreviewModalProps) => {
  const { attachments } = useSelector((state: any) => state.uploads);

  const ReplacePWithDiv = ({ children }: any) => {
    return (
      <div>
        {children.map((child: any) => {
          if (child.type === 'p') {
            return <div>{child.props.children}</div>;
          } else {
            return child;
          }
        })}
      </div>
    );
  };

  const [ccLabel, setCC] = useState([]);
  const [BccLabel, setBcC] = useState([]);

  useEffect(() => {
    let ccOption = [] as any;
    data?.cc?.map((item: any) => {
      ccOption.push(item?.value); //remove the item in the pushed options, causing to display [object]
    });

    setCC(ccOption);
    let BccOption = [] as any;
    data?.bcc?.map((item: any) => {
      BccOption.push(item?.value);
    });
    setBcC(BccOption);
  }, [data]);

  const { jobData, byContactCandidates, selectedCandidates } = useSelector(
    (state: any) => state.jobs
  );

  const { placehoderPair } = useSelector((state: any) => state.template);

  const { candidateData } = useSelector((state: any) => state.candidates);

  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  const placholders = (text: string) => {
    let candidateParam = {} as any;
    if (isFollowUp && candidateData) {
      const candidate_full_name = candidateData?.data
        ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
            .filter(Boolean)
            .join(', ')
        : [candidateData?.first_name, candidateData?.last_name]
            .filter(Boolean)
            .join(', ') || '';
      const candidate_first_name =
        candidateData?.data?.first_name ||
        candidateData?.first_name ||
        placehoderPair?.first_name ||
        '';
      const candidate_email =
        candidateData?.data?.primary_email ||
        candidateData?.primary_email ||
        placehoderPair?.candidate_email ||
        '';
      const candidate_current_position =
        candidateData?.data?.latest_job_title ||
        candidateData?.latest_job_title ||
        placehoderPair?.candidate_current_position ||
        '';
      const candidate_current_company =
        candidateData?.data?.latest_employer ||
        candidateData?.latest_employer ||
        placehoderPair?.candidate_current_company ||
        '';
      const candidate_mobile =
        getPrimaryMobile(candidateData?.data?.contact) ||
        placehoderPair?.candidate_mobile ||
        '';
      const candidate_linkedin_url =
        candidateData?.linkedin_url ||
        placehoderPair?.candidate_linkedin_url ||
        '';
      const candidate_work_status =
        candidateData?.work_status?.status ||
        placehoderPair?.candidate_work_status ||
        '';
      const candidate_city =
        candidateData?.city || placehoderPair?.candidate_city || '';
      const candidate_state =
        candidateData?.state_province || placehoderPair?.candidate_state || '';
      let candidate_degree = placehoderPair?.candidate_degree || '';
      if (candidateData?.education?.length > 0) {
        candidate_degree = candidateData?.education?.[0]?.degree_level?.name;
      }
      const candidate_total_years_of_exp =
        candidateData?.total_year_of_experience ||
        placehoderPair?.candidate_total_years_of_exp ||
        '';
      let job_url = '';
      let job_name_url = '';
      let job_title = '';
      let job_location = '';
      let job_contact_name = '';
      let job_contact_first_name = '';
      let job_company_name = '';
      let job_primary_recruiter_name = '';
      if (jobData?.id || candidateData?.jobs?.[0]?.job_id) {
        job_title = jobData?.title || placehoderPair?.job_title || '';
        job_location =
          [
            jobData?.data?.location?.city,
            jobData?.data?.location?.state,
            jobData?.data?.location?.country?.country,
          ]
            .filter(Boolean)
            .join(', ') ||
          placehoderPair?.job_location ||
          '';
        job_contact_name =
          [jobData?.lead?.first_name, jobData?.lead?.last_name]
            .filter(Boolean)
            .join(', ') ||
          placehoderPair?.job_contact_name ||
          '';
        job_contact_first_name =
          jobData?.lead?.first_name ||
          placehoderPair?.job_contact_first_name ||
          '';
        job_company_name =
          jobData?.client?.name || placehoderPair?.job_company_name || '';
        job_primary_recruiter_name =
          [
            jobData?.primary?.recruiter?.first_name,
            jobData?.primary?.recruiter?.last_name,
          ]
            .filter(Boolean)
            .join(', ') ||
          placehoderPair?.job_primary_recruiter_name ||
          '';
        if (jobData?.jobAds?.length > 0) {
          const ads = jobData?.jobAds[0];

          if (ads) {
            const url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
            job_url = `<a href="${url}" target="_blank">${url}</a>`;
            job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
          }
        }
      }
      candidateParam = {
        candidate_full_name,
        candidate_first_name,
        candidate_email,
        candidate_current_position,
        candidate_current_company,
        candidate_mobile,
        candidate_linkedin_url,
        candidate_work_status,
        candidate_city,
        candidate_state,
        candidate_degree,
        candidate_total_years_of_exp,
        job_url,
        job_name_url,
        client_job_title: job_title,
        job_location,
        job_contact_name,
        job_contact_first_name,
        job_company_name,
        job_primary_recruiter_name,
      };
    }

    const placeholderParam = {
      ...placehoderPair,
      ...(isFollowUp &&
        Object.keys(candidateParam).length > 0 &&
        candidateParam),
      client_job_title: jobData?.title || '',
      contact_first_name: jobData?.lead?.first_name || '',
      job_id: jobData?.id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader></ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody
            borderRadius="0 0 4px 4px"
            p="24px"
            fontFamily="NunitoSans Regular"
          >
            <Flex gap="16px" direction="column">
              <Box sx={{ fontSize: '18px', fontWeight: 700, color: '#2D3748' }}>
                {placholders(data?.subject)}
              </Box>

              <Flex gap="8px" direction="column">
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  To: <span style={{ color: '#000' }}>{data?.to?.value}</span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Cc:{' '}
                  <span style={{ color: '#000' }}>
                    {(ccLabel as any).filter(Boolean).join(', ')}
                  </span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Bcc:{' '}
                  <span style={{ color: '#000' }}>
                    {(BccLabel as any).filter(Boolean).join(', ')}
                  </span>
                </Box>
              </Flex>

              <Divider />

              <Box>
                <div
                  style={{
                    fontFamily: 'verdana',
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replacePTags(placholders(data?.body)),
                  }}
                />
              </Box>

              <Flex gap="5px" direction="column">
                {data?.primary && (
                  <Tag width="fit-content">
                    <Stack spacing={1}>
                      <HStack>
                        <TagLabel sx={{ fontWeight: 600 }}>
                          {data?.resume}
                        </TagLabel>
                      </HStack>
                    </Stack>
                  </Tag>
                )}
                {attachments.length > 0 &&
                  attachments.map((item: any, key: number) => {
                    return (
                      <Tag key={'attachments-' + key} width="fit-content">
                        <Stack spacing={1}>
                          <HStack>
                            <TagLabel sx={{ fontWeight: 600 }}>
                              {item.name}
                            </TagLabel>
                          </HStack>
                        </Stack>
                      </Tag>
                    );
                  })}
              </Flex>
            </Flex>

            <Box textAlign="right">
              <Button
                variant="solid"
                type="submit"
                disabled={disabled}
                onClick={onSubmit}
                loading={isLoading}
              >
                {isFollowUp ? 'Send Follow-up' : 'Submit to Client'}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailPreviewModal;
