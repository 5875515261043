import {
  Box,
  Button as Button1,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import AtsEmailBody from '@/components/app/EmailBody';
import BoxLoading from '@/components/app/Loading/box';
import ScrollToFieldError from '@/components/app/ScrollError';
import { Field, FieldArray, Formik } from 'formik';
import Button from '@/Library/Button';
import parse from 'html-react-parser';
import { FaRegCopy } from 'react-icons/fa';
import { EmailTemplateModalProps } from '../types';
import { useEmailTemplateModalServices } from './useEmailTemplateModalServices';
import { useDispatch, useSelector } from 'react-redux';
import { updateOutreach } from '@/store/outreach.slice';

const EmailTemplateModal = ({
  isEdit,
  isOpen,
  onClose,
}: EmailTemplateModalProps) => {
  const dispatch = useDispatch();

  const {
    initialValues,
    validationSchema,
    placeholders,
    resPlaceholder,
    placeHolderClick,
    handleOnSubmit,
    handleSendEmail,
    isLoading,
    initialValueEmail,
    validationSchemaEmail,
    previewData,
    handlePreview,
    isSpinner,
  } = useEmailTemplateModalServices({ isEdit, isOpen, onClose });

  const { hasAlert } = useSelector((state: any) => state.outreach);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {isEdit ? 'Edit Email Template' : 'Add Email Template'}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box
                  sx={{
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    p: '18px 32px',
                  }}
                >
                  <Stack spacing="24px" mb="24px">
                    <FormControl
                      isInvalid={Boolean(!!errors.title && touched.title)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Template Name{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        placeholder="Name"
                        variant="outline"
                        value={values.title}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.title)}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Stack direction="row" spacing="8px">
                    <Stack
                      width="40%"
                      sx={{
                        border: '0.5px solid #E7E9ED',
                        borderRadius: '6px',
                      }}
                    >
                      <Text
                        sx={{
                          p: '15px 20px',
                          borderBottom: '0.5px solid #E7E9ED',
                          fontSize: '12px',
                          fontWeight: 700,
                          textTransform: 'uppercase',
                          letterSpacing: '1.5px',
                        }}
                      >
                        Default Email Variables
                      </Text>

                      <Box width="100%" mt={0} p="15px 20px">
                        {resPlaceholder.isLoading ? (
                          <BoxLoading box={20} width="70px" />
                        ) : (
                          <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                            {Object.values(placeholders).length > 0 &&
                              Object.values(placeholders).map(
                                (category: any) => {
                                  let allowedCategory = [
                                    'Candidates',
                                    'Jobs',
                                  ] as any;

                                  if (
                                    allowedCategory.includes(category.category)
                                  ) {
                                    return (
                                      <Box mb="20px">
                                        <Text
                                          color="gray.700"
                                          mb="5px"
                                          fontSize="12px"
                                        >
                                          {category.category}
                                        </Text>
                                        <Wrap spacing={1}>
                                          {category.subs.map((item: any) => {
                                            return (
                                              <WrapItem>
                                                <Button1
                                                  leftIcon={<FaRegCopy />}
                                                  onClick={() =>
                                                    placeHolderClick(item)
                                                  }
                                                  sx={{
                                                    height: 'fit-content',
                                                    p: '5px 10px',
                                                    fontSize: '10px',
                                                  }}
                                                >
                                                  {item}
                                                </Button1>
                                              </WrapItem>
                                            );
                                          })}
                                        </Wrap>
                                      </Box>
                                    );
                                  }
                                }
                              )}
                          </Grid>
                        )}
                      </Box>
                    </Stack>

                    <Stack width="60%" position="relative">
                      <Text sx={{ fontSize: '14px', fontWeight: 700 }}>
                        Step Form
                      </Text>

                      <Stack>
                        <FieldArray name="touches">
                          {({ remove, push }) => (
                            <Stack
                              sx={{
                                border: '0.5px solid #EEEEEE',
                                borderRadius: '6px',
                              }}
                            >
                              {values.touches?.length > 0 &&
                                values.touches?.map(
                                  (touch: any, index: any) => {
                                    return (
                                      <Stack key={index}>
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          sx={{
                                            bgColor: '#F9F9FB',
                                            p: '16px 12px',
                                          }}
                                        >
                                          <Text
                                            sx={{
                                              fontSize: '14px',
                                              color: '#9B9B9B',
                                            }}
                                          >
                                            Step # {index + 1}
                                          </Text>
                                          {values.touches?.length > 0 && (
                                            <Stack
                                              direction="row"
                                              spacing="10px"
                                            >
                                              <Button
                                                variant={
                                                  touch.previewOpen
                                                    ? 'solid'
                                                    : 'outline'
                                                }
                                                leftIcon="eye"
                                                onClick={() => {
                                                  setFieldValue(
                                                    `touches.${index}.previewOpen`,
                                                    !touch.previewOpen
                                                  );
                                                  if (!touch.previewOpen) {
                                                    const updatedTouches =
                                                      values.touches.map(
                                                        (t: any, i: any) => ({
                                                          ...t,
                                                          previewOpen:
                                                            i === index,
                                                        })
                                                      );
                                                    setFieldValue(
                                                      'touches',
                                                      updatedTouches
                                                    );
                                                  }
                                                  !touch.previewOpen &&
                                                    dispatch(
                                                      updateOutreach({
                                                        previewData: {},
                                                      })
                                                    ) &&
                                                    handlePreview(
                                                      values.touches[index]
                                                    );
                                                }}
                                                size="sm"
                                              >
                                                Preview
                                              </Button>
                                              <Button
                                                variant="delete"
                                                leftIcon="trash"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Button>
                                            </Stack>
                                          )}
                                        </Stack>

                                        {!touch.previewOpen ? (
                                          <Stack spacing="10px" p="12px">
                                            <FormControl>
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Days to Wait
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`touches.${index}.days_to_wait`}
                                                value={touch.days_to_wait}
                                                placeholder="Day(s)"
                                                type="number"
                                                min={0}
                                                variant="outline"
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  `touches${index}.days_to_wait`
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl>
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Subject
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`touches.${index}.subject`}
                                                value={touch.subject}
                                                placeholder="Subject"
                                                type="text"
                                                variant="outline"
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  `touches${index}.subject`
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl>
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Remote Subject
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`touches.${index}.subject_remote`}
                                                value={touch.subject_remote}
                                                placeholder="Subject Remote"
                                                type="text"
                                                variant="outline"
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  `touches${index}.subject_remote`
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl>
                                              <Box
                                                sx={{
                                                  height: '50vh !important',
                                                  '& .p-editor-content': {
                                                    minHeight:
                                                      '40vh !important',
                                                    height: '40vh !important',
                                                  },
                                                }}
                                              >
                                                <AtsEmailBody
                                                  value={touch.content}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `touches.${index}.content`,
                                                      e
                                                    );
                                                  }}
                                                  height={200}
                                                />
                                              </Box>
                                            </FormControl>
                                          </Stack>
                                        ) : (
                                          <Formik
                                            initialValues={initialValueEmail}
                                            validationSchema={
                                              validationSchemaEmail
                                            }
                                            onSubmit={(e) => {
                                              const params = {
                                                to: e.to,
                                                subject: touch.subject,
                                                content: touch.content,
                                              };
                                              handleSendEmail(params);
                                            }}
                                            enableReinitialize={true}
                                          >
                                            {({
                                              values: valuesE,
                                              handleSubmit,
                                              handleChange,
                                              errors,
                                              touched,
                                            }) => {
                                              return (
                                                <form onSubmit={handleSubmit}>
                                                  <Stack
                                                    p="12px"
                                                    spacing="10px"
                                                    alignItems={
                                                      isSpinner && 'center'
                                                    }
                                                  >
                                                    {isSpinner ? (
                                                      <Box
                                                        sx={{
                                                          color: 'primary.800',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <Spinner
                                                          size="sm"
                                                          speed="0.65s"
                                                        />
                                                      </Box>
                                                    ) : (
                                                      <Stack
                                                        sx={{
                                                          position: 'relative',
                                                        }}
                                                      >
                                                        <FormControl
                                                          isInvalid={Boolean(
                                                            !!errors.to &&
                                                              touched.to
                                                          )}
                                                          sx={{
                                                            borderBottom:
                                                              '1px solid #EEEEEE',
                                                            py: '8px',
                                                          }}
                                                        >
                                                          <FormLabel
                                                            sx={{
                                                              fontSize: '14px',
                                                              color: '#9B9B9B',
                                                            }}
                                                          >
                                                            To:{' '}
                                                            <Box
                                                              as="span"
                                                              color="caution.800"
                                                            >
                                                              *
                                                            </Box>
                                                          </FormLabel>
                                                          <Input
                                                            name="to"
                                                            value={valuesE.to}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            color="primary.800"
                                                            fontWeight={700}
                                                          />
                                                          <FormErrorMessage>
                                                            {String(errors.to)}
                                                          </FormErrorMessage>
                                                        </FormControl>
                                                        <Text
                                                          sx={{
                                                            borderBottom:
                                                              '1px solid #EEEEEE',
                                                            py: '8px',
                                                            fontSize: '14px',
                                                          }}
                                                        >
                                                          {previewData?.subject}
                                                        </Text>
                                                        <Box
                                                          sx={{
                                                            borderBottom:
                                                              '1px solid #EEEEEE',
                                                            py: '8px',
                                                          }}
                                                        >
                                                          {parse(
                                                            previewData?.body ||
                                                              ''
                                                          )}
                                                        </Box>
                                                        <Box
                                                          sx={{
                                                            position:
                                                              'absolute',
                                                            top: '-76px',
                                                            right: '162px',
                                                          }}
                                                        >
                                                          <Button
                                                            leftIcon="caret-right"
                                                            variant="solid"
                                                            type="submit"
                                                            loading={isLoading}
                                                            disabled={
                                                              hasAlert ||
                                                              !!errors.to
                                                            }
                                                            onClick={
                                                              handleSubmit
                                                            }
                                                            size="sm"
                                                          >
                                                            Send Email Test
                                                          </Button>
                                                        </Box>
                                                      </Stack>
                                                    )}
                                                  </Stack>
                                                </form>
                                              );
                                            }}
                                          </Formik>
                                        )}
                                      </Stack>
                                    );
                                  }
                                )}
                              <Box
                                sx={{
                                  textAlign: 'right',
                                  '&& button': {
                                    m: '20px 12px',
                                  },
                                }}
                              >
                                <Button
                                  leftIcon="plus"
                                  onClick={() => {
                                    dispatch(
                                      updateOutreach({ previewData: {} })
                                    );
                                    push({
                                      days_to_wait: 1,
                                      subject: '',
                                      subject_remote: '',
                                      content: '',
                                      order:
                                        values.touches && values.touches.length,
                                    });
                                  }}
                                >
                                  Add Step
                                </Button>
                              </Box>
                            </Stack>
                          )}
                        </FieldArray>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Stack direction="row" spacing="10px">
                    <Button onClick={onClose}>Close</Button>
                    <Button
                      variant="solid"
                      type="submit"
                      isLoading={isLoading}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailTemplateModal;
