import { useDispatch, useSelector } from 'react-redux';
import { Button, Image } from '@chakra-ui/react';
import TalieIcon from '@/assets/images/talie/talie-icon.svg';
import { updateApp } from '@/store/app.slice';

export const TalieButton = () => {
  const dispatch = useDispatch();
  const { isTalieShow } = useSelector((state: any) => state.app);

  return (
    <Button
      variant="solid"
      leftIcon={<Image src={TalieIcon} />}
      sx={{
        borderRadius: '40px',
        width: '100px',
        fontFamily: 'NunitoSans Regular',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#FFF',
      }}
      onClick={() => dispatch(updateApp({ isTalieShow: !isTalieShow }))}
    >
      Talie AI
    </Button>
  );
};
