import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useFetchCandidateByIdQuery } from '@/services//candidates/candidate.query';
import { CandidateByIdResInterface } from '@/services//candidates/candidate.type';
import { getCandidate } from '@/store/candidates.slice';

type formatCurrentDisplayJobArgs =
  | CandidateByIdResInterface['data']['jobs'][0]
  | undefined;
function formatCurrentDisplayJob(job: formatCurrentDisplayJobArgs) {
  if (job) {
    let displayJob = { ...job } as typeof job & { location: string };
    if (job?.job?.jobads?.length) {
      const jobAds = job?.job?.jobads?.at(0);
      const location = [jobAds?.city, jobAds?.state]
        ?.filter(Boolean)
        ?.join(' ');
      displayJob = {
        ...displayJob,
        distance_from_job: Math.ceil(job?.distance_from_job ?? 0),
        location,
      };
    }
    return displayJob;
  }
  return null;
}
function getInterviewStatus(
  data: { status: number; counter: number } | undefined
) {
  if (data) {
    const { status, counter } = data;
    if (status === 1) {
      return `Interview #${counter} - Scheduled`;
    }
    if (status === 2) {
      return `Interview #${counter} - Completed`;
    }
  }
  return '';
}
function formatCandidateData(
  data: CandidateByIdResInterface | undefined,
  jobId: number
) {
  if (data) {
    const { data: candidateDetail, ...otherData } = data;
    return {
      ...candidateDetail,
      ...otherData,
      currentDisplayJob: formatCurrentDisplayJob(
        candidateDetail?.jobs?.find?.((job) => job?.job_id === jobId)
      ),
      rejectedJobs:
        candidateDetail?.jobs?.filter?.(
          (job) => job.candidate_status_id === 6
        ) ?? [],
      unrejectedJobs:
        candidateDetail?.jobs?.filter?.(
          (job) => job.candidate_status_id !== 6
        ) ?? [],
      interviewStatus: getInterviewStatus(
        otherData?.interviews
          ?.filter(Boolean)
          ?.find?.((interview) => interview?.job_id === jobId)
      ),
    };
  }
  return null;
}
export default function useGetCandidate() {
  const params = useParams();
  const jobId = parseInt(params?.jobId ?? '0');
  const candidateId = parseInt(params?.candidateId ?? '0');
  const dispatch = useDispatch();
  const {
    data: candidateDetails,
    isFetching: isLoadingCandidate,
    isSuccess,
    apiData,
    ...other
  } = useFetchCandidateByIdQuery(
    {
      id: candidateId,
    },
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          ...other,
          data: formatCandidateData(data, jobId),
          apiData: data,
        };
      },
    }
  );

  useEffect(() => {
    if (isSuccess && apiData) {
      dispatch(
        getCandidate({
          candidateData: { ...apiData.data, ...apiData },
        })
      );
    }
  }, [isSuccess, candidateId]);

  return { candidateDetails, isLoadingCandidate, isSuccess, ...other };
}
