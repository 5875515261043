import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGetResumeViewMutation } from '@/store/resumerparse.slice';
import ResumeLoading from '../ResumeLoading';
import CandidatesAll from './All';
import CandidatesFilterBar from './VerticalFilterBar';

export default function CandidatesResume() {
  document.documentElement.dir = 'ltr';
  const params = useParams();
  const [reqViewResume, resViewResume] = useGetResumeViewMutation();
  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {
    if (params.resumeId) {
      reqViewResume({ id: params.resumeId }).then((res: any) => {
        setHtmlContent(res?.data?.data?.html || '');
      });
    }
  }, [params.resumeId]);

  return (
    <Flex mt="-44px" fontFamily="NunitoSans Regular">
      {resViewResume.isSuccess && (
        <Box margin="auto" mt="3em" mb="3em">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Box>
      )}

      {resViewResume.isError && (
        <Box margin="auto" mt="3em" mb="3em" width="70%">
          <Box
            padding={10}
            boxShadow="lg"
            bg="white"
            height="60vh"
            textAlign="center"
          >
            <Text color="red" fontSize="xl">
              Failed to load resume
            </Text>
          </Box>
        </Box>
      )}

      {resViewResume.isLoading && (
        <Box
          textAlign="center"
          width="70%"
          margin="auto"
          mt="3em"
          mb="3em"
          minHeight="70vh"
        >
          <ResumeLoading />
          <ResumeLoading />
        </Box>
      )}
    </Flex>
  );
}
