import moment from 'moment';
import { Box, Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ProfileCard from '@/components/profile-card.component';
import LoadingPage from '@/components/app/Loading';

const ClientsOverview = ({ isLoading = false }: any) => {
  const { clientData } = useSelector((state: any) => state.clients);

  return (
    <>
      {clientData && (
        <Box fontFamily="NunitoSans Regular" py={4}>
          <SimpleGrid
            columns={2}
            fontWeight={500}
            fontSize="16px"
            lineHeight="18px"
          >
            <GridItem>
              <Box>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  margin="28px 0"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">Industry</GridItem>
                  <GridItem>{clientData?.specs?.specification || ''}</GridItem>
                </SimpleGrid>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  marginBottom="28px"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">Account Manager</GridItem>
                  <GridItem>
                    {clientData?.manager?.length > 0 ? (
                      <>
                        <ProfileCard
                          id={clientData.id}
                          user_id={clientData?.manager[0]?.user?.id}
                          name={[
                            clientData?.manager[0]?.user?.first_name,
                            clientData?.manager[0]?.user?.last_name,
                          ].join(' ')}
                          email={clientData?.manager[0]?.user?.email}
                          thumbnail={
                            clientData?.manager[0]?.user?.thumbnailSignedUrl
                          }
                          profile={clientData?.manager[0]?.user?.signedUrl}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </GridItem>
                </SimpleGrid>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  margin="28px 0"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">Last Activity</GridItem>
                  <GridItem>last activity here</GridItem>
                </SimpleGrid>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  margin="28px 0"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">
                    Last Activity Date
                  </GridItem>
                  <GridItem>{clientData?.lastActivityDate}</GridItem>
                </SimpleGrid>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  margin="28px 0"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">
                    Agreement Signed On
                  </GridItem>
                  {clientData?.agreements?.length > 0 ? (
                    <GridItem>
                      {moment
                        .utc(clientData?.agreements.slice(-1)[0].date_signed)
                        .format('MM/DD/YYYY')}
                    </GridItem>
                  ) : (
                    <></>
                  )}
                </SimpleGrid>
                <SimpleGrid
                  columns={2}
                  w="80%"
                  margin="28px 0"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="18px"
                >
                  <GridItem color="default.gray.600">
                    Agreement Validity
                  </GridItem>
                  {clientData?.agreements?.length > 0 ? (
                    <GridItem>
                      {clientData?.agreements.slice(-1)[0]?.valid_until
                        ? moment
                            .utc(
                              clientData?.agreements.slice(-1)[0]?.valid_until
                            )
                            .format('MM/DD/YYYY')
                        : 'No Expiry'}
                    </GridItem>
                  ) : (
                    <></>
                  )}
                </SimpleGrid>
              </Box>
            </GridItem>
            {isLoading !== false ? (
              <LoadingPage />
            ) : (
              <GridItem>
                <Flex
                  gap="18px"
                  alignItems="center"
                  textAlign="center"
                  mb="18px"
                >
                  <Box
                    pt="20px"
                    pb="14px"
                    width="516px"
                    border="0.5px solid rgba(197, 197, 197, 0.8)"
                    borderRadius="4px"
                  >
                    <Box
                      fontWeight={700}
                      fontSize="20px"
                      lineHeight="18px"
                      marginBottom="8px"
                    >
                      {clientData?.job_counter?.open}
                    </Box>
                    <Box
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="#6B6C7B"
                    >
                      Open Jobs
                    </Box>
                  </Box>
                </Flex>
                <Flex
                  gap="18px"
                  alignItems="center"
                  textAlign="center"
                  mb="18px"
                >
                  <Box
                    pt="20px"
                    pb="14px"
                    width="249px"
                    border="0.5px solid rgba(197, 197, 197, 0.8)"
                    borderRadius="4px"
                  >
                    <Box
                      fontWeight={700}
                      fontSize="20px"
                      lineHeight="18px"
                      marginBottom="8px"
                    >
                      {clientData?.job_counter?.submits}
                    </Box>
                    <Box
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="#6B6C7B"
                    >
                      Submits
                    </Box>
                  </Box>
                  <Box
                    pt="20px"
                    pb="14px"
                    width="249px"
                    border="0.5px solid rgba(197, 197, 197, 0.8)"
                    borderRadius="4px"
                  >
                    <Box
                      fontWeight={700}
                      fontSize="20px"
                      lineHeight="18px"
                      marginBottom="8px"
                    >
                      {clientData?.job_counter?.interviews}
                    </Box>
                    <Box
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="#6B6C7B"
                    >
                      Interviews
                    </Box>
                  </Box>
                </Flex>
                <Flex gap="18px" alignItems="center" textAlign="center">
                  <Box
                    pt="20px"
                    pb="14px"
                    width="249px"
                    border="0.5px solid rgba(197, 197, 197, 0.8)"
                    borderRadius="4px"
                  >
                    <Box
                      fontWeight={700}
                      fontSize="20px"
                      lineHeight="18px"
                      marginBottom="8px"
                    >
                      {clientData?.job_counter?.offers}
                    </Box>
                    <Box
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="#6B6C7B"
                    >
                      Offers
                    </Box>
                  </Box>
                  <Box
                    pt="20px"
                    pb="14px"
                    width="249px"
                    border="0.5px solid rgba(197, 197, 197, 0.8)"
                    borderRadius="4px"
                  >
                    <Box
                      fontWeight={700}
                      fontSize="20px"
                      lineHeight="18px"
                      marginBottom="8px"
                    >
                      {clientData?.job_counter?.placements}
                    </Box>
                    <Box
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="#6B6C7B"
                    >
                      Placements
                    </Box>
                  </Box>
                </Flex>
              </GridItem>
            )}
          </SimpleGrid>
        </Box>
      )}
    </>
  );
};

export default ClientsOverview;
