import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from '@chakra-ui/react';
import AtsSelect from '@/components/app/AtsSelect';
import AtsDropZone from '@/components/app/Dropzone';
import { Field, FieldProps } from 'formik';
import { useSelector } from 'react-redux';
import { useFetchLeadSourcesQuery } from '@/services/common/constants.query';
import { InitialValueType } from '../type';

export default function AdditionlDetails() {
  const { userList } = useSelector((state: any) => state.user);
  const { data: leadSourceList } = useFetchLeadSourcesQuery({});
  const leadSrouceList = leadSourceList?.map((data: any) => ({
    label: data.source,
    value: data.id,
  }));

  return (
    <>
      <Box>
        <Text fontWeight={600} mb="34px">
          Additional Information
        </Text>
        <Flex gap="32px" mb="34px">
          <Field name="created_by">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.created_by && touched.created_by)}
              >
                <FormLabel>Created By:</FormLabel>
                <AtsSelect
                  id="created_by"
                  name="created_by"
                  placeholder="Select"
                  variant="outline"
                  value={
                    (Object.values(userList).length > 0 &&
                      Object.values(userList)
                        .map((item: any) => ({
                          label: [item.first_name, item.last_name].join(' '),
                          value: item.id,
                        }))
                        .filter(
                          (val: any) => val.value === Number(values.created_by)
                        )[0]) ||
                    values.created_by
                  }
                  onChange={(e: any) =>
                    setFieldValue('created_by', String(e.value))
                  }
                  options={Object.values(userList).map((item: any) => ({
                    label: [item.first_name, item.last_name].join(' '),
                    value: item.id,
                  }))}
                />
                <FormErrorMessage>{String(errors.created_by)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="lead_source">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.lead_source && touched.lead_source)}
                isRequired
              >
                <FormLabel>Source </FormLabel>
                <Field
                  id="lead_source"
                  name="lead_source"
                  options={leadSrouceList}
                  component={AtsSelect}
                  value={values.lead_source}
                  onChange={(e: any) => {
                    setFieldValue(`lead_source`, e);
                  }}
                />
                <FormErrorMessage>
                  {errors.lead_source &&
                    touched.lead_source &&
                    String((errors.lead_source as any).label)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Flex mb="34px" gap="32px">
          <Field name="lead_owner">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.lead_owner && touched.lead_owner)}
                isRequired
              >
                <FormLabel>Lead Owner</FormLabel>
                <AtsSelect
                  id="lead_owner"
                  name="lead_owner"
                  placeholder="Select"
                  variant="outline"
                  value={
                    (Object.values(userList).length > 0 &&
                      Object.values(userList)
                        .map((item: any) => ({
                          label: [item.first_name, item.last_name].join(' '),
                          value: item.id,
                        }))
                        .filter(
                          (val: any) => val.value === Number(values.lead_owner)
                        )[0]) ||
                    values.lead_owner
                  }
                  onChange={(e: any) =>
                    setFieldValue('lead_owner', String(e.value))
                  }
                  options={Object.values(userList).map((item: any) => ({
                    label: [item.first_name, item.last_name].join(' '),
                    value: item.id,
                  }))}
                />
                <FormErrorMessage>{String(errors.lead_owner)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <FormControl />
        </Flex>
      </Box>
      <Box mb="34px">
        <FormControl>
          <FormLabel>Attachments</FormLabel>
        </FormControl>
        <AtsDropZone
          multipleFile={true}
          uploadedEnd={(e: any) => {}}
          deletedFile={(key: string) => {}}
        />
      </Box>
      <Box>
        <Field name="notes">
          {({
            form: { errors, touched, values, handleChange, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.lead_owner && touched.lead_owner)}
            >
              <FormLabel>Notes</FormLabel>
              <Textarea
                id="notes"
                name="notes"
                placeholder="Type here"
                variant="outline"
                value={values.notes}
                onChange={handleChange}
              />
              <Text
                mt="8px"
                fontSize="12px"
                lineHeight="18px"
                color="default.secondarytext"
              >
                Maximum 200 characters allowed
              </Text>
              <FormErrorMessage>{String(errors.notes)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Box>
    </>
  );
}
