import { createSlice } from '@reduxjs/toolkit';
import { AI_LIST } from '@/components/app/AIGenerator/Rewrite/constants';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const aiSlice = createSlice({
  name: 'ai',
  initialState: {
    aiList: AI_LIST,
  },
  reducers: {
    updateAI: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateAI } = aiSlice.actions;
export default aiSlice.reducer;

const aiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    PostGeneratePrompt: builder.mutation({
      query: (data) => ({
        url: '/ai/prompt',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAI({ aiList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostRegeneratePrompt: builder.mutation({
      query: (data) => ({
        url: '/ai/regenerate',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAI({ aiList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  usePostGeneratePromptMutation,
  usePostRegeneratePromptMutation,
} = aiApiSlice;
