import { useState } from 'react';
import { ActivityInformationProps } from './types';

// PICTURES
import { authStorage } from '@/utils/localStorage';

function useActivityInformationServices({
  logs,
  type,
}: ActivityInformationProps) {
  const auth = authStorage();
  const convertMilitaryToStandard = (militaryTime: string): string => {
    // Validate input format using a regular expression
    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
    // if (!timeFormat.test(militaryTime)) {
    // throw new Error("Invalid time format. Please use HH:MM format.");
    // }

    // Split the time into hours and minutes
    const [hourStr, minuteStr] = militaryTime.split(':');
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    let standardHour: number;
    let period: string;

    // Convert hour and determine period (AM/PM)
    if (hour === 0) {
      standardHour = 12;
      period = 'AM';
    } else if (hour < 12) {
      standardHour = hour;
      period = 'AM';
    } else if (hour === 12) {
      standardHour = 12;
      period = 'PM';
    } else {
      standardHour = hour - 12;
      period = 'PM';
    }

    // Format the standard time
    const standardTime = `${standardHour}:${minuteStr.padStart(
      2,
      '0'
    )}${period}`;
    return standardTime;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleFieldName = (fieldName: string) => {
    let formattedArr = [] as any;
    const arrName = fieldName.split('_');
    arrName.map((word: string) => {
      formattedArr.push(word.charAt(0).toUpperCase() + word.slice(1));
    });
    return formattedArr.filter(Boolean).join(' ');
  };

  return {
    isExpanded,
    auth,
    handleFieldName,
    handleToggleExpand,
    convertMilitaryToStandard,
  };
}
export default useActivityInformationServices;
