import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import ScrollToFieldError from '@/components/app/ScrollError';
import { Formik, FormikValues } from 'formik';
import ErrorAlert from '@/modules/Leads/components/FormErrorAlert';
import { useFetchLeadDetailQuery } from '@/services/leads/leadsApi';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchCountriesQuery } from '@/services/common/constants.query';
import { useUserListMutation } from '@/store/user.slice';
import { changeUploadName } from '@/utils/newFilename';
import { addressFormat, leadSourceFormat } from '@/utils/utilFormat';
import * as Yup from 'yup';
import { LeadsResponseType } from '../../types/api';
import AddressInformation from './components/AddressInformation';
import ClientDetails from './components/ClientDetails';
import Contact from './components/Contact';
import NotesAndAttachments from './components/NotesAndAttachments';
import FormLoader from '@/components/ui/Loaders/FormLoader';

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    client_name: Yup.string().required('Client name is required.'),
    first_name: Yup.string()
      .required('First name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
    last_name: Yup.string()
      .required('Last name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
    address: Yup.array()
      .of(
        Yup.object().shape({
          location: Yup.string().required('Location is required.'),
          address: Yup.string(),
          city: Yup.string().required('City is required.'),
          state: Yup.string().required('State is required.'),
          country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
          zip_code: Yup.string(),
          primary: Yup.boolean(),
        })
      )
      .min(1, 'Address is required.'),
    website: Yup.string().required('Website is required.'),
    email: Yup.string().email('Invalid email.').required('Email is required.'),
    personal_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Personal phone is invalid'
    ),
    work_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Work phone is invalid'
    ),
    extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
    linkedIn_url: Yup.string(),
    title: Yup.string().required('Title is required.'),
    owner_id: Yup.string().required('Lead owner is required.'),
    isMultipleAccountManager: Yup.string(),
    manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
    notes: Yup.string(),
    // lead_source: Yup.array().min(1, 'Pick at least 1 lead').nullable(),
    lead_source: Yup.object().shape({
      label: Yup.string().required('Pick at least 1 source'),
      value: Yup.number(),
    }),
    status: Yup.number(),
    specification_id: Yup.number()
      .required('Industry is required.')
      .min(1, 'Invalid level.'),
  })
);
export default function RequestToConvertModal({
  isOpen,
  onClose,
  leadId,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: any;
  leadId: number;
  onSubmit: (data: FormikValues, lead: LeadsResponseType) => Promise<any>;
}) {
  const toast = useToast();
  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const { data: leadDetail, isLoading: isLoadingForm } =
    useFetchLeadDetailQuery({ id: leadId });
  const { data: countryList } = useFetchCountriesQuery({});
  const [isSubmitError, setIsSubmitError] = useState({
    message: '',
    open: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const initialValue = useMemo(() => {
    return {
      client_name: leadDetail?.lead?.company || '',
      first_name: leadDetail?.lead?.first_name || '',
      last_name: leadDetail?.lead?.last_name || '',
      address: addressFormat(leadDetail?.address, countryList) || [],
      website: leadDetail?.lead?.website || '',
      email: leadDetail?.lead?.primary_email || '',
      personal_phone: leadDetail?.lead?.personal_phone || '',
      work_phone: leadDetail?.lead?.work_phone || '',
      extension: leadDetail?.lead?.extension || '',
      linkedIn_url: leadDetail?.lead?.linked_in_url || '',
      title: leadDetail?.lead?.title || '',
      owner_id: leadDetail?.lead?.owner_id || '',
      isMultipleAccountManager: 'false',
      manager: leadDetail?.client?.manager
        ?.filter((manager) => manager?.user)
        ?.map((manager) => ({
          value: manager.user.id,
          label: `${manager.user.first_name} ${manager.user.last_name}`,
        })),
      notes: '',
      lead_source: leadSourceFormat(leadDetail?.lead?.lead_source),
      specification_id: leadDetail?.lead?.spec_id,
    };
  }, [leadDetail]);
  async function handleSubmit(data: FormikValues) {
    data['attachments'] = await changeUploadName(uploaded, prefix);
    data['manager'] = [data['manager']];
    setSubmitting(true);
    const res = await onSubmit(data, leadDetail.lead);
    if (!res.success) {
      setIsSubmitError({
        open: true,
        message: res?.error?.message ?? 'Something went wrong!',
      });
    } else {
      onClose();
      toast({
        title: 'Request to Convert',
        description: `Lead was successfully converted to a contact`,
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
    setSubmitting(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={onClose}
        size="3xl"
        scrollBehavior="inside"
        variant="form"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Request to Convert</ModalHeader>
          <ModalCloseButton />
          {isSubmitError.open ? (
            <ErrorAlert
              onClose={() =>
                setIsSubmitError((state) => ({ ...state, open: false }))
              }
              text={isSubmitError.message}
            />
          ) : null}
          <ModalBody>
            {isLoadingForm ? (
              <FormLoader />
            ) : (
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <ScrollToFieldError />

                    <Box p="32px">
                      <ClientDetails />
                      <AddressInformation
                        allowAddAddress={!leadDetail?.address}
                      />
                      <Contact />
                      <NotesAndAttachments />
                    </Box>
                    <ModalFooter>
                      <Button
                        variant="solid"
                        type="submit"
                        disabled={uploading}
                        isLoading={submitting}
                      >
                        Convert
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
