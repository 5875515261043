import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import MdWandWhite from '@/assets/images/ai-generator/MdWandWhite.svg';
import MdBook from '@/assets/images/ai-generator/MdBook.svg';
import MdDiamond from '@/assets/images/ai-generator/MdDiamond.svg';
import { useEffect, useState } from 'react';

interface AIGeneratorBarProps {
  hasAIGenerate: string;
  setHasAIGenerate: any;
  setIsGenerate: any;
  setIsError: any;
  handleValues: (subject: string, body: string) => void;
  subject: string;
  body: string;
}
export const AIGeneratorBar = ({
  setHasAIGenerate,
  setIsGenerate,
  setIsError,
  handleValues,
  subject,
  body,
}: AIGeneratorBarProps) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    handleValues(subject, body);

    if (!subject || !body) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [subject, body]);

  return (
    <Menu placement="top">
      <MenuButton
        as={Button}
        leftIcon={<img src={MdWandWhite} />}
        sx={{
          background: 'linear-gradient(90deg, #6930CA 0%, #B100FF 100%)',
          boxShadow: '0px 2px 8px 0px #0000000A',
          border: 'none',
          borderRadius: '30px',
          p: '8px 12px',
          fontFamily: 'NunitoSans Regular',
          fontSize: '12px',
          fontWeight: 600,
          color: '#fff',
          cursor: 'pointer',
        }}
        _hover={{}}
        _active={{}}
        // disabled={isDisabled}
        isDisabled={true}
      >
        Writing with AI
      </MenuButton>
      <MenuList>
        <MenuItem
          sx={{
            fontFamily: 'NunitoSans Regular',
            fontSize: '14px',
            color: '#2B2D42',
          }}
          icon={<img src={MdBook} />}
          onClick={() => {
            setHasAIGenerate((prev: string) =>
              prev === 'rewrite' ? '' : 'rewrite'
            );
            setIsError(false);
            setIsGenerate(false);
          }}
        >
          Rewrite with AI
        </MenuItem>

        <MenuItem
          sx={{
            fontFamily: 'NunitoSans Regular',
            fontSize: '14px',
            color: '#2B2D42',
          }}
          icon={<img src={MdDiamond} />}
          onClick={() => {
            setHasAIGenerate((prev: string) =>
              prev === 'compose' ? '' : 'compose'
            );
            setIsError(false);
            setIsGenerate(false);
          }}
        >
          Compose with AI
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
