import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import JobsAds from '@/modules/Jobs/Layout/JobModule/Drawer/Layout/TabPanel/JobAds';
import JobsDetails from '@/modules/Jobs/Layout/JobModule/Drawer/Layout/TabPanel/Details';
import JobsRecruitmentTeam from '@/modules/Jobs/Layout/JobModule/Drawer/Layout/TabPanel/RecruitmentTeam';
import JobsNotes from '../TabPanel/JobNotes';

const JobsTabs = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { jobData } = useSelector((state: any) => state.jobs);

  const tabIndex: any = {
    details: 0,
    'recruitment-team': 1,
    'job-ads': 2,
    notes: 3,
  };

  const stateTab: any = params.jobsTab;

  const handleRoute = (route: string) => {
    navigate(`/jobs/${jobData.id}/${route}`);
  };

  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="500" sx={{ padding: '0 20px' }}>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('details')}
        >
          Details
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('recruitment-team')}
        >
          Recruitment Team
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('job-ads')}
        >
          Job Ads
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('notes')}
        >
          Notes
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <JobsDetails />
        </TabPanel>
        <TabPanel>
          <JobsRecruitmentTeam />
        </TabPanel>
        <TabPanel>
          <JobsAds />
        </TabPanel>
        <TabPanel>
          <JobsNotes />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default JobsTabs;
