/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  useGetSequenceCandidateDataMutation,
  useGetSequenceDataMutation,
  usePostDeleteSequenceCandidateMutation,
  usePostEnrichSequenceMutation,
  usePostListCandidatesMutation,
} from '@/store/outreach.slice';

export const useCandidatesTableServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const [reqCandidateList, resCandidateList] = usePostListCandidatesMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqPostDeleteSequenceCandidate, resPostDeleteSequenceCandidate] =
    usePostDeleteSequenceCandidateMutation();
  const [reqPostEnrichSequence, resPostEnrichSequence] =
    usePostEnrichSequenceMutation();
  const [reqGetSequenceCandidateData] = useGetSequenceCandidateDataMutation();

  const {
    candidates,
    candidateSequencePgBtn,
    sequencesData,
    sequenceCandidateData,
    candidateSequencePage,
    isLoading,
  } = useSelector((state: any) => state.outreach);

  const [rowId, setRowId] = useState(
    candidates.findIndex(
      (val: any) => Number(val.id) === Number(sequenceCandidateData?.id)
    ) + 1
  );

  const [candidateIds, setCandidateIds] = useState([]);
  const [totalCandidatesList, setTotalCandidatesList] = useState(
    candidates?.length
  );
  const [seqId, setSeqId] = useState(null);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        take: candidateSequencePgBtn.take,
        query: '',
        page: sequenceCandidateData.page,
      };
      dispatch(
        updateOutreach({
          candidateSequencePgBtn: params,
        })
      );
    }
  };

  const onSubmit = (data: any) => {
    // let params = {
    //   take: candidateSequencePgBtn.take,
    //   query: data.search,
    //   page: sequenceCandidateData.page,
    // };
    // reqGetCandidatesList(params);
  };

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(candidates.map((li: any) => li.sequence?.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < candidates.length) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === candidates.length) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, candidates]);

  const handlePrevData = () => {
    const templateID = candidates[rowId - 1 - 1]?.sequence?.id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/${params.settingSubType}/${templateID}`
    );
  };

  const handleNextData = () => {
    const templateID = candidates[rowId - 1 + 1]?.sequence?.id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/${params.settingSubType}/${templateID}`
    );
  };

  const handleBulkDeleteCandidates = (id: any) => {
    const payload = {
      candidate_sequence_id: id,
    };

    reqPostDeleteSequenceCandidate(payload);
  };

  const handleEnrich = (id: number) => {
    setSeqId(id);
    reqPostEnrichSequence(id);
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...candidateSequencePgBtn,
      page: page,
      take: candidateSequencePgBtn.take,
      query: candidateSequencePgBtn.query,
    };
    dispatch(
      updateOutreach({
        candidateSequencePgBtn: newParam,
      })
    );
    reqCandidateList(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...candidateSequencePgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      updateOutreach({
        candidateSequencePgBtn: newParam,
      })
    );
    reqCandidateList(newParam);
  };

  useEffect(() => {
    setTotalCandidatesList(candidates.length);
  }, [candidates]);

  useEffect(() => {
    if (sequenceCandidateData?.id) {
      setRowId(
        candidates.findIndex(
          (val: any) => Number(val.id) === Number(sequenceCandidateData?.id)
        ) + 1
      );
    }
  }, [sequenceCandidateData?.id, candidates]);

  useEffect(() => {
    if (resPostEnrichSequence.isSuccess) {
      reqCandidateList(candidateSequencePgBtn);

      toast({
        title: 'Candidate enriched.',
        description: "We've enriched your candidate.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostEnrichSequence.isSuccess]);

  useEffect(() => {
    if (resPostDeleteSequenceCandidate.isSuccess) {
      reqCandidateList(candidateSequencePgBtn);
      reqGetSequenceData({ id: params.settingTypeId });

      toast({
        title: 'Sequence candidate deleted.',
        description: "We've deleted your sequence candidate.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDeleteSequenceCandidate.isSuccess]);

  return {
    rowId,
    totalCandidatesList,
    candidates,
    sequenceCandidateData,
    candidateSequencePgBtn,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    clearSearch,
    onSubmit,
    handlePrevData,
    handleNextData,
    handleBulkDeleteCandidates,
    reqGetSequenceCandidateData,
    setCandidateIds,
    seqId,
    resPostEnrichSequence,
    resCandidateList,
    handleEnrich,
    sequencesData,
    candidateIds,
    candidateSequencePage,
    pageChange,
    entryChange,
    isLoading,
  };
};
