import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  Stack,
  Tag,
  HStack,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from '@/components/app/ScrollError';
import 'react-quill/dist/quill.snow.css';
import '@/assets/css/minHquill.css';
import AtsDropZone from '@/components/app/Dropzone';
import AtsCraetableSelect from '@/components/app/AtsCreatabeSelect';
import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from '@/store/template.slice';
import Select from 'react-select';
import { BsEnvelope } from 'react-icons/bs';
import replacePlaceholders from '@/utils/textPlaceholders';
import { useSendEmailContactMutation } from '@/store/contact.slice';
import { cleanUpload } from '@/store/uploads.slice';
import AtsEmailBody from '@/components/app/EmailBody';

interface contactEmailInt {
  isOpen: any;
  onClose: any;
  id: any;
  email: any;
}

export default function ContactEmail({
  isOpen,
  onClose,
  id,
  email,
}: contactEmailInt) {
  const { placehoderPair, defaultTemplateList } = useSelector(
    (state: any) => state.template
  );

  const clientTemplate: any =
    defaultTemplateList.filter(
      (template: any) => template?.group_id === 3
    )?.[0] ?? null;

  const [quilValue, setquilValue] = useState(clientTemplate?.body || '');
  const [reqList, resList] = useRawListTemplateMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [template, setTemplate] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [reqSend, resSend] = useSendEmailContactMutation();
  const [ccBcc, setCcBcc] = useState([]);
  const dispatch = useDispatch();
  const initialValues = {
    subject: clientTemplate?.subject || '',
    body: clientTemplate?.body || '',
    to: email,
    cc: [] as any,
  };

  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          attachments: template?.attachments,
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };

  const { userList } = useSelector((state: any) => state.user);

  useEffect(() => {
    reqList({ id: 3 });
    const data = {
      contact_id: id,
    };
    reqPair(data);
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    let ccOption = [] as any;
    Object.values(userList).map((item: any) => {
      ccOption.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });
    setCcBcc(ccOption);
  }, [id]);

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          option.push({
            value: item.id,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
          });
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  useEffect(() => {
    if (resSend.isSuccess) {
      onClose();
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    }
  }, [resSend.isSuccess]);

  const { uploaded, uploading } = useSelector((state: any) => state.uploads);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.string().email('Invalid email.').required('Email is required.'),
    })
  );

  const submitEmail = (data: any) => {
    data['attachments'] = uploaded;
    data['att_template'] = attachments;
    let cc: any = [];
    if (data.cc.length > 0) {
      data.cc.map((item: any) => {
        cc.push(item.value);
      });
    }
    data.cc = cc;
    reqSend({ data, id });
  };

  const removeAtt = (key: any) => {
    setAttachments([
      ...attachments.slice(0, key),
      ...attachments.slice(key + 1, attachments.length),
    ]);
  };

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoderPair);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Send Email
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            onSubmit={submitEmail}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px">
                  <Box mb="20px" gap="32px">
                    <Flex gap="32px" mb="20px">
                      <FormControl
                        isInvalid={Boolean(!!errors.to && touched.to)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          To
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="to"
                          name="to"
                          type="email"
                          variant="outline"
                          value={values.to}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>{String(errors.to)}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.cc && touched.cc)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Cc
                        </FormLabel>
                        <AtsCraetableSelect
                          id="cc"
                          name="cc"
                          onChange={(event: any) => setFieldValue('cc', event)}
                          options={ccBcc}
                          isMulti={true}
                          placeholder="cc"
                          defaultValue={values.cc}
                        />

                        <FormErrorMessage>{String(errors.cc)}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <FormControl mb="20px">
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Template
                      </FormLabel>
                      <Select
                        placeholder="Select"
                        isMulti={false}
                        defaultValue={populateTemplate(clientTemplate)}
                        options={template}
                        onChange={(e: any) => {
                          setquilValue(placholders(e.body));
                          setFieldValue('body', placholders(e.body));
                          setFieldValue('subject', placholders(e.subject));
                          setAttachments(e.attachments);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      isInvalid={Boolean(!!errors.subject && touched.subject)}
                      mb="20px"
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Subject
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        name="subject"
                        type="text"
                        placeholder="Subject"
                        variant="outline"
                        value={values.subject}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.subject)}
                      </FormErrorMessage>
                    </FormControl>
                    <Box mb="20px">
                      <FormControl
                        isInvalid={Boolean(!!errors.body && touched.body)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Body
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsEmailBody
                          initialValue={''}
                          value={quilValue}
                          onChange={(e: any) => {
                            setquilValue(placholders(e));
                            setFieldValue('body', placholders(e));
                          }}
                        />
                        {/* <ReactQuill
                          theme="snow"
                          value={quilValue}
                          onChange={(e) => {
                            setquilValue(placholders(e));
                            setFieldValue('body', placholders(e));
                          }}
                          placeholder="Type what ever you need here..."
                          className={
                            Boolean(!!errors.body && touched.body)
                              ? 'invalid-box'
                              : ''
                          }
                        /> */}
                        <FormErrorMessage>
                          {String(errors.body)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <FormControl mb="20px">
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Attachment(s)
                      </FormLabel>
                      <Stack
                        direction={['column', 'row']}
                        spacing="24px"
                        mb="10px"
                      >
                        <Box gap="10px" display="inline-block">
                          {attachments?.length > 0 &&
                            attachments.map((item: any, key: number) => {
                              return (
                                <Tag
                                  style={{ display: 'inline-block' }}
                                  key={'attachments-' + key}
                                  mr="10px"
                                  mb="10px"
                                >
                                  <Stack spacing={1}>
                                    <HStack>
                                      <TagLabel maxW="100px">
                                        {item.original_file_name}
                                      </TagLabel>
                                      <TagCloseButton
                                        onClick={() => removeAtt(key)}
                                      />
                                    </HStack>
                                  </Stack>
                                </Tag>
                              );
                            })}
                        </Box>
                      </Stack>
                      <AtsDropZone
                        multipleFile={true}
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button
                    variant="solid"
                    type="submit"
                    disabled={uploading}
                    isLoading={resSend.isLoading}
                    leftIcon={<BsEnvelope />}
                  >
                    Send Email
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
