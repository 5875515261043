import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import Button from '@/Library/Button';
import SuppressionModal from './Modal';
import SuspressionListTable from './Table';

const SuppressionList = () => {
  const {
    isOpen: isOpenSuppressionModal,
    onOpen: onOpenSuppressionModal,
    onClose: onCloseSuppressionModal,
  } = useDisclosure({ defaultIsOpen: false });

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end" mt="20px" mb="32px" px={1}>
        <Flex gap={5}>
          <Button leftIcon="file" variant="outline">
            Import Excel
          </Button>
          <Button
            leftIcon="plus"
            variant="solid"
            onClick={onOpenSuppressionModal}
          >
            Add Record
          </Button>
        </Flex>
      </Flex>
      <SuspressionListTable />

      {isOpenSuppressionModal && (
        <SuppressionModal
          isOpen={isOpenSuppressionModal}
          onClose={onCloseSuppressionModal}
        />
      )}
    </Box>
  );
};

export default SuppressionList;
