import { useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { TbRuler3 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateTeamMutation,
  useListTeamsMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  updateTeam,
} from '@/store/teams.slice';
import { useUserListMutation } from '@/store/user.slice';
import { swalContent } from '@/types';
import { authStorage } from '@/utils/localStorage';
import { AtsConfirm } from '@/utils/swal';
import * as Yup from 'yup';
import {
  MembersDataInt,
  MemberType,
  TeamDataInt,
  TeamInt,
} from './components/types';
import { TeamsProps } from './types';

export default function useTeamsServices({}: TeamsProps) {
  const auth = authStorage();
  const dispatch = useDispatch();
  const {
    isOpen: isOpenTeamModal,
    onOpen: onOpenTeamModal,
    onClose: onCloseTeamModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenViewTeam,
    onOpen: onOpenViewTeam,
    onClose: onCloseViewTeam,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const { teamList } = useSelector((state: any) => state.teams);

  // INITIAL VALUES
  const initTeamValues: TeamInt = {
    name: '',
    // description: '',
    members: [],
    manager: [],
  };

  const [reqTeams, resTeams] = useListTeamsMutation();

  const [reqCreate, resCreate] = useCreateTeamMutation();
  const [reqUpdate, resUpdate] = useUpdateTeamMutation();
  const [reqDelete, resDelete] = useDeleteTeamMutation();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updateTeamId, setUpdateTeamId] = useState<number | null>(null);
  const [selectedViewTeam, setSelectedViewTeam] = useState<TeamDataInt>(
    {} as TeamDataInt
  );
  const [initialValues, setInitialValues] = useState<TeamInt>(initTeamValues);
  const [errMsg, setErrorMsg] = useState<string>(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [errorList, setErrorList] = useState({});

  // VALIDATION SCHEMA
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      name: Yup.string().required('Team name is required'),
      // description: Yup.string().required('Description is required'),
      members: Yup.array().min(1, 'Pick at least 1 member').nullable(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
    })
  );

  const loadConstant = async () => {
    await reqTeams({});
  };

  const [teams, setTeams] = useState<TeamDataInt[]>([]);

  const updateTeamMembers = (data: any) => {
    if (data.length > 0) {
      let team_members: any = [];
      data[0].members.map((member: any) => {
        if (member.role !== 'manager') {
          team_members.push(member);
        }
      });
      // setTeamMembers(team_members.filter(Boolean))
      dispatch(updateTeam({ teamMembers: team_members.filter(Boolean) }));
    } else {
      dispatch(updateTeam({ teamMembers: [] }));
    }
  };

  useEffect(() => {
    if (resTeams.isSuccess) {
      const data = resTeams.data.data.data;
      console.log('data ', data);
      updateTeamMembers(data);
      setTeams(data);
    }
  }, [resTeams.isSuccess]);

  useEffect(() => {
    loadConstant();
  }, []);

  useEffect(() => {
    if (resCreate.isSuccess) {
      handleCloseTeamModal();

      handleCloseViewTeam();
      loadConstant();
    }
    if (resCreate.isError) {
      onOpen();
      let errorMessage: any = resCreate.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [resCreate.isSuccess, resCreate.isError]);

  useEffect(() => {
    if (resUpdate.isSuccess) {
      handleCloseTeamModal();
      handleCloseViewTeam();
      loadConstant();
    }
    if (resUpdate.isError) {
      onOpen();
      let errorMessage: any = resCreate.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [resUpdate.isSuccess, resUpdate.isError]);

  useEffect(() => {
    if (resDelete.isSuccess) {
      handleCloseViewTeam();
      setTimeout(() => {
        loadConstant();
      }, 500);
    }
    if (resDelete.isError) {
    }
  }, [resDelete.isSuccess, resDelete.isError]);

  // HANDLERS

  const handleSearch = debounce(async (query: any) => {
    const param = {
      query: query,
    };
    await reqTeams(param);
  }, 500);

  const handleSubmit = (values: TeamInt) => {
    console.log('values ', values);
    const params = {
      name: values.name,
      // description: values.description,
      members: values.members.map((member: any) => member.user_id),
      manager: values.manager.map((member: any) => member.user_id)[0],
      user_id: auth?.id,
    };
    console.log('params ', params);
    if (isEdit) {
      reqUpdate({ data: params, id: updateTeamId });
    } else {
      reqCreate(params);
    }
  };

  const handleEditTeam = (values: TeamDataInt) => {
    setUpdateTeamId(values.id);
    const params = {
      name: values.name,
      // description: values.description,
      members: values.members
        .map((member: MembersDataInt) => {
          if (member.role === 'member') {
            const memberParam: MemberType = {
              first_name: member?.user?.first_name || '',
              last_name: member?.user?.last_name,
              email: member.user.email,
              user_id: member.user_id,
            };
            return memberParam;
          }
        })
        .filter(Boolean),
      manager: values.members
        .map((member: MembersDataInt) => {
          if (member.role === 'manager') {
            const memberParam: MemberType = {
              first_name: member?.user?.first_name || '',
              last_name: member?.user?.last_name,
              email: member.user.email,
              user_id: member.user_id,
            };
            return memberParam;
          }
        })
        .filter(Boolean),
    };
    console.log('params ', params);
    setIsEdit(true);
    setInitialValues(params);
    onOpenTeamModal();
  };

  const handleDeleteTeam = async (values: TeamDataInt) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete a team: ${values?.name} id: ${values?.id}`,
      buttons: ['Cancel', 'Delete!'],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDelete({ id: values.id });
    }
  };

  const handleViewTeam = (values: TeamDataInt) => {
    setSelectedViewTeam(values);
    onOpenViewTeam();
  };
  const handleOpenTeamModal = () => {
    setInitialValues(initTeamValues);
    onOpenTeamModal();
  };

  const handleCloseTeamModal = () => {
    setUpdateTeamId(null);
    setInitialValues(initTeamValues);
    setIsEdit(false);
    onCloseTeamModal();
    onCloseAlert();
  };

  const handleCloseViewTeam = () => {
    setUpdateTeamId(null);
    setInitialValues(initTeamValues);
    setIsEdit(false);
    onCloseViewTeam();
  };
  return {
    isOpenTeamModal,
    isOpenViewTeam,
    isOpenAlert,
    onCloseAlert,
    isEdit,
    resTeams,
    resCreate,
    resUpdate,
    errMsg,
    errorList,
    teams,
    teamList,
    selectedViewTeam,
    initialValues,
    validationSchema,
    setErrorList,
    handleEditTeam,
    handleSearch,
    handleDeleteTeam,
    handleOpenTeamModal,
    handleCloseTeamModal,
    handleViewTeam,
    handleCloseViewTeam,
    handleSubmit,
  };
}
