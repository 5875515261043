/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

// Formik components for handling forms
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';

// Chakra UI components for layout and UI elements
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link,
  Flex,
  Divider,
  Image,
  RadioGroup,
  Stack,
  Radio,
  useDisclosure,
} from '@chakra-ui/react';

// Components from the application
import ScrollToFieldError from '@/components/app/ScrollError';
import AtsSelect from '@/components/app/AtsSelect';
import AtsEmailBody from '@/components/app/EmailBody';
import AtsSelectContact from '@/components/app/AtsSelectContact';
import { useHiredModalServices } from './useHiredModalServices';

// Assets
import MdConfetti from '@/assets/images/MdConfetti.svg';
import GoTo from '@/assets/images/goto.svg';

// Utilities
import moment from 'moment';
import { truncateToTwoDecimalPlaces } from '@/utils/utilFormat';

// Custom Library Components
import Button from '@/Library/Button';
import { listClients } from '@/store/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import AtsSelectAddress from '@/components/app/AtsSelectAddress';
import {
  setClientAgreement,
  useGetClientAgreementMutation,
} from '@/store/clientagreement.slice';
import ClientEditgreementModal from '@/components/app/Clients/Agreement/edit';
import { StreamAttachmentModal } from '@/modules/Leads/components/detail-drawer/TabList/TabPanel/Attachments/Stream';
import ClientAddAgreementModal from '@/components/app/Clients/Agreement/add';
import AtsCraetableSelect from '@/components/app/AtsCreatabeSelect';

interface HiredModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseStatus?: () => void;
  candidate?: any;
  onCallback: () => void;
  jobId?: number;
  jobDetails?: any;
  groupItem?: any;
  isEdit?: boolean;
  logs?: any;
}

export default function HiredModal({
  isOpen,
  onClose,
  onCloseStatus = () => {},
  candidate,
  onCallback,
  jobId,
  jobDetails,
  groupItem,
  isEdit,
  logs,
}: HiredModalProps) {
  const {
    formRef,
    initialValues,
    handleSubmit,
    validationSchema,
    invoiceNameOption,
    setIsSplit,
    recOptions,
    leadSourceList,
    candSourceList,
    isLoading,
    fileData,
    setFileData,
    locationOption,
    ccOption,
  } = useHiredModalServices({
    jobId,
    candidate,
    onCloseStatus,
    onClose,
    onCallback,
    isEdit,
    logs,
  });
  console.log('🚀 ~ locationOption:', locationOption);

  const { work_types } = useSelector((state: any) => state.constants);
  const { clientData } = useSelector((state: any) => state.clients);
  const { clientAttachments } = useSelector(
    (state: any) => state.clientAgreements
  );

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isAddAgreementOpen,
    onOpen: onAddAgreementOpen,
    onClose: onAddAgreementClose,
  } = useDisclosure();

  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick
      lockFocusAcrossFrames
      blockScrollOnMount
      onClose={() => {
        onClose();
        onCloseStatus();
        dispatch(listClients({ clientData: {} }));
        dispatch(
          setClientAgreement({
            clientAgreementData: {},
            clientAgreements: [],
            clientAttachments: [],
          })
        );
      }}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        overflowY="hidden"
        borderRadius="8px"
      >
        <ModalHeader
          background="primary.800"
          p="10px 32px"
          borderRadius="8px 8px 0 0"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box
              fontSize="24px"
              lineHeight="18px"
              textAlign="left"
              color="white"
            >
              {isEdit ? 'Edit Placement Form' : 'Placement Form'}
            </Box>
            <Image height="70px" mr="25px" src={MdConfetti} alt="logo" />
          </Flex>
        </ModalHeader>
        <ModalCloseButton top="13px" right="20px" color="white" />

        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mb="32px"
                    >
                      Candidate Information
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_name && touched.candidate_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Candidate Name{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="candidate_name"
                          name="candidate_name"
                          type="text"
                          variant="outline"
                          value={values.candidate_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_email && touched.candidate_email
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Candidate Email{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="candidate_email"
                          name="candidate_email"
                          type="email"
                          variant="outline"
                          value={values.candidate_email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_email)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mb="32px"
                    >
                      Agreements
                    </Box>
                    {clientAttachments.length > 0 ? (
                      <Flex gap="32px" mb="24px" alignItems="center">
                        <>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.primarytext"
                            m={0}
                          >
                            File Name
                          </FormLabel>
                          <Link
                            style={{
                              display: 'flex',
                              color: 'primary.800',
                              gap: '7px',
                              alignItems: 'center',
                            }}
                            rel="noreferrer"
                            onClick={() => {
                              onStreamOpen();
                              setFileData(
                                clientAttachments[clientAttachments.length - 1]
                              );
                            }}
                          >
                            {
                              clientAttachments[clientAttachments.length - 1]
                                ?.original_file_name
                            }
                            <span>
                              <Image src={GoTo} alt="logo" />
                            </span>
                          </Link>

                          <Button
                            variant="solid"
                            type="button"
                            onClick={onEditAgreementOpen}
                          >
                            Edit
                          </Button>
                        </>
                      </Flex>
                    ) : (
                      <Flex gap="8px" alignItems="center">
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="#718096"
                          m={0}
                        >
                          No Agreements{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Button onClick={onAddAgreementOpen}>Add</Button>
                      </Flex>
                    )}

                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Job Information
                    </Box>
                    <Stack spacing="16px">
                      <Flex gap="32px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.job_title && touched.job_title
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.primarytext"
                          >
                            Job Title{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="job_title"
                            name="job_title"
                            placeholder="Job Title"
                            type="text"
                            variant="outline"
                            value={values.job_title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.job_title)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.job_start_date && touched.job_start_date
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.primarytext"
                          >
                            Start Date{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="job_start_date"
                            name="job_start_date"
                            placeholder="Select Date"
                            type="date"
                            value={values.job_start_date}
                            onChange={(e) => {
                              handleChange(e);
                              const date = e.target.value;
                              const newDate = new Date(date);

                              const paymentDueDate = moment(newDate)
                                .add(
                                  Number(values.compensation_guarantee_days),
                                  'days'
                                )
                                .format('YYYY-MM-DD');

                              setFieldValue(
                                'invoice_payment_due_date',
                                paymentDueDate
                              ); // Set the formatted date
                            }}
                            min={moment().format('YYYY-MM-DD')}
                          />

                          <FormErrorMessage>
                            {String(errors.job_start_date)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.job_work_type_id &&
                              touched.job_work_type_id
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                          >
                            Work Type{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>

                          <Box
                            border={
                              !!errors.job_work_type_id &&
                              touched.job_work_type_id &&
                              '1px solid #E53E3E'
                            }
                            borderRadius={
                              !!errors.job_work_type_id &&
                              touched.job_work_type_id &&
                              '4px'
                            }
                            boxShadow={
                              !!errors.job_work_type_id &&
                              touched.job_work_type_id &&
                              '0 0 0 1px #E53E3E'
                            }
                          >
                            <AtsSelect
                              id="job_work_type_id"
                              name="job_work_type_id"
                              placeholder="Select Type"
                              variant="outline"
                              value={
                                work_types
                                  .map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value ===
                                      Number(values.job_work_type_id)
                                  )[0] || values.job_work_type_id
                              }
                              onChange={(e: any) => {
                                setFieldValue(
                                  'job_work_type_id',
                                  String(e.value)
                                );
                              }}
                              options={
                                work_types?.length > 0 &&
                                work_types.map((item: any) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                              }
                              styles={{
                                control: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  border: '1px solid #E7E9ED',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  boxShadow: 'none',
                                  '&:hover': {},
                                  '& ::-webkit-scrollbar': {
                                    display: 'none',
                                  },
                                }),
                                valueContainer: (styles: any) => ({
                                  ...styles,
                                  maxHeight: '33px',
                                  overflowY: 'scroll',
                                }),
                                dropdownIndicator: (styles: any) => ({
                                  ...styles,
                                  color: '#676767',
                                }),
                                indicatorSeparator: () => ({
                                  display: 'none',
                                }),
                                placeholder: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  color: '#9B9B9B',
                                }),
                              }}
                            />
                          </Box>
                          <FormErrorMessage>
                            {String(errors.job_work_type_id)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.job_site_location_id &&
                              touched.job_site_location_id
                          )}
                          mb="24px"
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                          >
                            Job Site Location{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>
                          <Box
                            border={
                              !!errors.job_site_location_id &&
                              touched.job_site_location_id &&
                              '1px solid #E53E3E'
                            }
                            borderRadius={
                              !!errors.job_site_location_id &&
                              touched.job_site_location_id &&
                              '4px'
                            }
                            boxShadow={
                              !!errors.job_site_location_id &&
                              touched.job_site_location_id &&
                              '0 0 0 1px #E53E3E'
                            }
                          >
                            <Field
                              name="job_site_location_id"
                              options={locationOption}
                              component={AtsSelectAddress}
                              value={
                                locationOption?.filter(
                                  (loc: any) =>
                                    loc?.value === values.job_site_location_id
                                )?.[0] || values.job_site_location_id
                              }
                              placeholder="Select Location"
                              styles={{
                                control: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  border: '1px solid #E7E9ED',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  boxShadow: 'none',
                                  '&:hover': {},
                                  '& ::-webkit-scrollbar': {
                                    display: 'none',
                                  },
                                }),
                                valueContainer: (styles: any) => ({
                                  ...styles,
                                  maxHeight: '33px',
                                  overflowY: 'scroll',
                                }),
                                dropdownIndicator: (styles: any) => ({
                                  ...styles,
                                  color: '#676767',
                                }),
                                indicatorSeparator: () => ({
                                  display: 'none',
                                }),
                                placeholder: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  color: '#9B9B9B',
                                }),
                              }}
                            />
                          </Box>
                          <FormErrorMessage>
                            {String(errors.job_site_location_id)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Stack>

                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Compensation Details
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_base_salary &&
                            touched.compensation_base_salary
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Base Salary{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_base_salary"
                          name="compensation_base_salary"
                          placeholder="0.00"
                          type="text"
                          variant="outline"
                          value={values.compensation_base_salary}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue(
                              'compensation_base_salary',
                              formattedValue
                            );

                            const totalFee =
                              Number(
                                typeof value === 'string'
                                  ? Number(value.replace(/,/g, ''))
                                  : value
                              ) *
                              (Number(values.compensation_fee_percentage) /
                                100);

                            setFieldValue(
                              'compensation_total_fee_to_invoice',
                              truncateToTwoDecimalPlaces(
                                totalFee
                              ).toLocaleString()
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_base_salary)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_fee_percentage &&
                            touched.compensation_fee_percentage
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Fee %{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_fee_percentage"
                          name="compensation_fee_percentage"
                          placeholder="%"
                          type="number"
                          min={0}
                          max={100}
                          variant="outline"
                          value={values.compensation_fee_percentage}
                          onChange={(e) => {
                            let { value } = e.target;

                            handleChange(e);

                            const totalFee =
                              Number(
                                typeof values?.compensation_base_salary ===
                                  'string'
                                  ? Number(
                                      values?.compensation_base_salary.replace(
                                        /,/g,
                                        ''
                                      )
                                    )
                                  : values?.compensation_base_salary
                              ) *
                              (Number(value) / 100);

                            setFieldValue(
                              'compensation_total_fee_to_invoice',
                              truncateToTwoDecimalPlaces(
                                totalFee
                              ).toLocaleString()
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_fee_percentage)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="16px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_payable_days &&
                            touched.compensation_payable_days
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Payable Days{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_payable_days"
                          name="compensation_payable_days"
                          type="number"
                          variant="outline"
                          min={0}
                          value={values.compensation_payable_days}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_payable_days)}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_guarantee_days &&
                            touched.compensation_guarantee_days
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Guarantee Days{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_guarantee_days"
                          name="compensation_guarantee_days"
                          type="number"
                          min={0}
                          borderRightRadius="none"
                          variant="outline"
                          value={values.compensation_guarantee_days}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_guarantee_days)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Discount Given
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_sign_on_bonus &&
                            touched.compensation_sign_on_bonus
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Sign On Bonus to Candidate from Talently{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_sign_on_bonus"
                          name="compensation_sign_on_bonus"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.compensation_sign_on_bonus}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue(
                              'compensation_sign_on_bonus',
                              formattedValue
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_sign_on_bonus)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_discount_given &&
                            touched.compensation_discount_given
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Fee Discount to the Client{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_discount_given"
                          name="compensation_discount_given"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.compensation_discount_given}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue(
                              'compensation_discount_given',
                              formattedValue
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_discount_given)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box
                      sx={{
                        bg: '#FAF5FF',
                        borderRadius: '6px',
                        mb: '16px',
                        p: '16px',
                      }}
                    >
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.compensation_total_fee_to_invoice &&
                            touched.compensation_total_fee_to_invoice
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Total Fee to Invoice{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="compensation_total_fee_to_invoice"
                          name="compensation_total_fee_to_invoice"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.compensation_total_fee_to_invoice}
                          onChange={handleChange}
                          bg="#fff"
                        />
                        <FormErrorMessage>
                          {String(errors.compensation_total_fee_to_invoice)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Invoice Information
                    </Box>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Who do we Invoice?
                    </Box>
                    <FormControl
                      isInvalid={Boolean(
                        !!(errors.invoice_contact_id as any)?.label &&
                          touched.invoice_contact_id
                      )}
                      mb="24px"
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.primarytext"
                      >
                        Contact Details{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>
                      <Field
                        id="invoice_contact_id"
                        name="invoice_contact_id"
                        placeholder="Select Contact Details"
                        component={AtsSelectContact}
                        value={
                          invoiceNameOption?.filter(
                            (contact: any) =>
                              Number(contact?.value) ===
                              values.invoice_contact_id
                          )?.[0] || values.invoice_contact_id
                        }
                        onChange={(event: any) => {
                          const leadData =
                            clientData.leads?.filter(
                              (lead: any) => lead.id === Number(event.value)
                            )?.[0] || null;

                          if (leadData) {
                            setFieldValue('invoice_contact_id', leadData?.id);
                          }
                        }}
                        client={{
                          label: clientData?.name,
                          value: clientData?.id,
                        }}
                        options={invoiceNameOption}
                        defaultValue={values.invoice_contact_id}
                        isSaveName={true}
                        isSaveInput={true}
                      />
                      <FormErrorMessage>
                        {String((errors.invoice_contact_id as any)?.label)}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_company_name &&
                            touched.invoice_company_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Full Company Name{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="invoice_company_name"
                          name="invoice_company_name"
                          type="text"
                          variant="outline"
                          placeholder="Full Company Name"
                          value={values.invoice_company_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.invoice_company_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_payment_due_date &&
                            touched.invoice_payment_due_date
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Payment Due Date{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="invoice_payment_due_date"
                          name="invoice_payment_due_date"
                          placeholder="mm/dd/yyyy"
                          type="date"
                          value={values.invoice_payment_due_date}
                          onChange={handleChange}
                          min={moment().format('YYYY-MM-DD')}
                        />

                        <FormErrorMessage>
                          {String(errors.invoice_payment_due_date)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.invoice_company_location_id &&
                          touched.invoice_company_location_id
                      )}
                      mb="24px"
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                      >
                        Company's Billing Address{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>
                      <Box
                        border={
                          !!errors.invoice_company_location_id &&
                          touched.invoice_company_location_id &&
                          '1px solid #E53E3E'
                        }
                        borderRadius={
                          !!errors.invoice_company_location_id &&
                          touched.invoice_company_location_id &&
                          '4px'
                        }
                        boxShadow={
                          !!errors.invoice_company_location_id &&
                          touched.invoice_company_location_id &&
                          '0 0 0 1px #E53E3E'
                        }
                      >
                        <Field
                          name="invoice_company_location_id"
                          options={locationOption}
                          component={AtsSelectAddress}
                          value={
                            locationOption?.filter(
                              (loc: any) =>
                                loc?.value ===
                                values.invoice_company_location_id
                            )?.[0] || values.invoice_company_location_id
                          }
                          placeholder="Select Billing Address"
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      </Box>
                      <FormErrorMessage>
                        {String(errors.invoice_company_location_id)}
                      </FormErrorMessage>
                    </FormControl>

                    <Box mb="32px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_cc && touched.invoice_cc
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          cc
                        </FormLabel>
                        <AtsCraetableSelect
                          id="invoice_cc"
                          name="invoice_cc"
                          onChange={(event: any) => {
                            setFieldValue('invoice_cc', event);
                          }}
                          options={ccOption}
                          isMulti={true}
                          placeholder="cc"
                          defaultValue={values.invoice_cc}
                        />

                        <FormErrorMessage>
                          {String(errors.invoice_cc)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Recruitment Collaboration Details
                    </Box>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Split with another Recruiter?
                    </Box>
                    <RadioGroup
                      defaultValue={values.split_recruiter}
                      onChange={handleChange}
                    >
                      <Stack spacing={2} direction="row" mb="16px">
                        <Radio
                          colorScheme="purple"
                          name="split_recruiter"
                          onChange={(e) => {
                            handleChange(e);
                            setIsSplit(true);
                          }}
                          value="Y"
                        >
                          Yes
                        </Radio>
                        <Radio
                          colorScheme="purple"
                          name="split_recruiter"
                          onChange={(e: any) => {
                            handleChange(e);
                            setIsSplit(false);
                          }}
                          value="N"
                        >
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>

                    {values.split_recruiter === 'Y' && (
                      <FieldArray name="recruiters">
                        {({ remove, push }) => (
                          <div>
                            {values.recruiters.length > 0 &&
                              values.recruiters.map(
                                (recruiter: any, index: number) => {
                                  const recruiter_id = `recruiters[${index}].recruiter_id`;
                                  const split = `recruiters[${index}].split`;

                                  return (
                                    <>
                                      <Box
                                        className="row"
                                        key={`recr-${index}`}
                                      >
                                        {values.recruiters.length > 0 && (
                                          <Flex justifyContent="flex-end">
                                            <Button
                                              leftIcon="close"
                                              onClick={() => remove(index)}
                                              size="sm"
                                            >
                                              Remove
                                            </Button>
                                          </Flex>
                                        )}
                                        <Flex mb="20px" gap="32px">
                                          <FormControl
                                            isInvalid={Boolean(
                                              !!(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.recruiter_id &&
                                                (touched.recruiters as any)?.[
                                                  index
                                                ]?.id
                                              )
                                            )}
                                          >
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.primarytext"
                                            >
                                              Recruiter{' '}
                                              <Box
                                                as="span"
                                                color="primary.800"
                                              >
                                                *
                                              </Box>
                                            </FormLabel>
                                            <AtsSelect
                                              name={recruiter_id}
                                              id={recruiter_id}
                                              placeholder="Select"
                                              variant="outline"
                                              onChange={(e: any) =>
                                                setFieldValue(
                                                  recruiter_id,
                                                  Number(e.value)
                                                )
                                              }
                                              value={
                                                (recOptions?.length > 0 &&
                                                  recOptions
                                                    .filter(
                                                      (item: any) =>
                                                        item.value ===
                                                        recruiter.recruiter_id
                                                    )
                                                    .map((item: any) => ({
                                                      label: item.label,
                                                      value: item.value,
                                                    }))) ||
                                                recruiter.recruiter_id
                                              }
                                              options={
                                                recOptions?.length > 0 &&
                                                recOptions.map((item: any) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                }))
                                              }
                                            />
                                            <FormErrorMessage>
                                              {String(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.recruiter_id
                                              )}
                                            </FormErrorMessage>
                                          </FormControl>

                                          <FormControl
                                            isInvalid={Boolean(
                                              !!(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.split &&
                                                (touched.recruiters as any)?.[
                                                  index
                                                ]?.split
                                              )
                                            )}
                                          >
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.primarytext"
                                            >
                                              What % Split{' '}
                                              <Box
                                                as="span"
                                                color="primary.800"
                                              >
                                                *
                                              </Box>
                                            </FormLabel>
                                            <Field
                                              as={Input}
                                              id={split}
                                              name={split}
                                              placeholder="Split Percentage"
                                              type="number"
                                              variant="outline"
                                              max={100}
                                              min={0}
                                              value={recruiter.split}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  split,
                                                  e.target.value
                                                );
                                              }}
                                            />

                                            <FormErrorMessage>
                                              {String(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.split
                                              )}
                                            </FormErrorMessage>
                                          </FormControl>
                                        </Flex>
                                      </Box>
                                      <Divider mb="20px" />
                                    </>
                                  );
                                }
                              )}
                            <Button
                              leftIcon="plus"
                              onClick={() =>
                                push({
                                  name: '',
                                  split_percentage: null as any,
                                })
                              }
                            >
                              {values.recruiters.length > 0
                                ? 'Add Another Recruiter'
                                : 'Add Recruiter'}
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    )}

                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Client and Candidate Source
                    </Box>
                    <Flex gap="24px" mb="24px" flexDir="column">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_source_id && touched.client_source_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Source of the Client{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          options={leadSourceList}
                          name="client_source_id"
                          id="client_source_id"
                          value={
                            leadSourceList?.filter(
                              (leadSource: any) =>
                                leadSource?.value === values.client_source_id
                            )?.[0] || values.client_source_id
                          }
                          onChange={(e: any) => {
                            setFieldValue('client_source_id', e.value);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.client_source_id)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_source_id &&
                            touched.candidate_source_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Source of the Candidate{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          options={candSourceList}
                          name="candidate_source_id"
                          id="candidate_source_id"
                          isLoading={isLoading}
                          value={
                            candSourceList?.filter(
                              (candSource: any) =>
                                Number(candSource?.value) ===
                                values.candidate_source_id
                            )?.[0] || values.candidate_source_id
                          }
                          onChange={(e: any) => {
                            setFieldValue('candidate_source_id', e.value);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_source_id)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.lifetime_value_client &&
                            touched.lifetime_value_client
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Lifetime Value of the Client
                        </FormLabel>
                        <Input
                          id="lifetime_value_client"
                          name="lifetime_value_client"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.lifetime_value_client}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue(
                              'lifetime_value_client',
                              formattedValue
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.lifetime_value_client)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Additional Notes
                    </Box>
                    <Box mb="24px">
                      <FormControl
                        isInvalid={Boolean(!!errors.notes && touched.notes)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Notes
                        </FormLabel>
                        <AtsEmailBody
                          id="notes"
                          value={values.notes}
                          height={200}
                          onChange={(e: any) => {
                            setFieldValue('notes', e);
                          }}
                          refocusTo="candidate_name"
                        />
                        <Box color="red.700">
                          <ErrorMessage
                            name="notes"
                            className="alert alert-danger"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      onClick={(e: any) => {
                        console.log(errors, 'errors');
                        console.log(values, 'xxx e');
                        handleSubmit(e);
                      }}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              );
            }}
          </Formik>
          {isStreamOpen && (
            <StreamAttachmentModal
              isOpen={isStreamOpen}
              onClose={onStreamClose}
              file={fileData}
              route={'/clients-agreement/download/'}
            />
          )}
          {isEditAgreementOpen && (
            <ClientEditgreementModal
              isOpen={isEditAgreementOpen}
              onClose={onEditAgreementClose}
            />
          )}

          {isAddAgreementOpen && (
            <ClientAddAgreementModal
              isOpen={isAddAgreementOpen}
              onClose={onAddAgreementClose}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
