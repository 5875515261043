/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  usePostCreateEmailTemplateMutation,
  useGetEmailTemplateListMutation,
  usePostUpdateEmailTemplateMutation,
  updateOutreach,
  usePostSendEmailMutation,
  usePostPreviewEmailMutation,
} from '@/store/outreach.slice';
import { usePlaceHoldersMutation } from '@/store/template.slice';
import { EMAIL_SCHEMA, EMAIL_TEMPLATE_SCHEMA } from './constant';

export const useEmailTemplateModalServices = ({
  isEdit,
  isOpen,
  onClose,
}: any) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const [reqGetEmailTemplateList] = useGetEmailTemplateListMutation();
  const [reqPostCreateEmailTemplate, resPostCreateEmailTemplate] =
    usePostCreateEmailTemplateMutation();
  const [reqPostUpdateEmailTemplate, resPostUpdateEmailTemplate] =
    usePostUpdateEmailTemplateMutation();
  const [reqPostSendEmail, resPostSendEmail] = usePostSendEmailMutation();
  const [reqPostPreviewEmail, resPostPreviewEmail] =
    usePostPreviewEmailMutation();

  const { placehodlers: placeholders } = useSelector(
    (state: any) => state.template
  );
  const { sequencesData, emailTemplateData, emailTemplatePgBtn, previewData } =
    useSelector((state: any) => state.outreach);
  const { currentProfile } = useSelector((state: any) => state.auth);

  const [toEmail, setToEmail] = useState('');

  const initialValues = isEdit
    ? {
        title: emailTemplateData.title,
        role: emailTemplateData.role,
        primary: emailTemplateData.primary,
        touches:
          emailTemplateData?.touches &&
          emailTemplateData?.touches?.map((touch: any) => ({
            days_to_wait: touch.days_to_wait,
            subject: touch.subject,
            subject_remote: touch.subject_remote,
            content: touch.content,
            order: touch.touch_order,
            previewOpen: false,
          })),
      }
    : {
        title: '',
        role: 'recruiter',
        primary: false,
        touches: [
          {
            days_to_wait: 1,
            subject: '',
            subject_remote: '',
            content: '',
            order: 0,
            previewOpen: false,
          },
        ],
      };

  const validationSchema: any = EMAIL_TEMPLATE_SCHEMA;

  const initialValueEmail = {
    to: sequencesData?.user?.email || currentProfile?.email || '',
  };

  const validationSchemaEmail = EMAIL_SCHEMA;

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const handleSendEmail = (data: any) => {
    const payload = {
      subject: data.subject,
      body: data.content,
      to: data.to,
    };

    reqPostSendEmail(payload);
  };

  const handleOnSubmit = (data: any) => {
    data.touches = data.touches.map(
      ({
        previewOpen,
        ...rest
      }: {
        previewOpen: boolean;
        [key: string]: any;
      }) => rest
    );

    if (isEdit) {
      reqPostUpdateEmailTemplate({ data: data, id: emailTemplateData.id });

      dispatch(
        updateOutreach({
          emailTemplateData: { ...emailTemplateData, ...data },
        })
      );
    } else {
      reqPostCreateEmailTemplate(data);
    }
  };

  const handlePreview = (values: any) => {
    const payload = {
      subject: values.subject,
      body: values.content,
    };
    reqPostPreviewEmail(payload);
  };

  useEffect(() => {
    if (isOpen) {
      const load = async () => {
        await reqPlaceHolder({});
      };

      load();
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      resPostCreateEmailTemplate.isSuccess ||
      resPostUpdateEmailTemplate.isSuccess
    ) {
      reqGetEmailTemplateList(emailTemplatePgBtn);
      onClose();

      isEdit
        ? toast({
            title: 'Email Template updated.',
            description: "We've updated your email template.",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        : toast({
            title: 'Email Template added.',
            description: "We've added your new email template.",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
    }
  }, [
    resPostCreateEmailTemplate.isSuccess,
    resPostUpdateEmailTemplate.isSuccess,
  ]);

  useEffect(() => {
    if (resPostSendEmail.isSuccess) {
      toast({
        title: 'Email preview sent.',
        description: "We've emailed a template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostSendEmail.isSuccess]);

  return {
    initialValues,
    validationSchema,
    placeholders,
    resPlaceholder,
    isOpen,
    onClose,
    toEmail,
    setToEmail,
    placeHolderClick,
    reqPlaceHolder,
    handleOnSubmit,
    handleSendEmail,
    isLoading:
      resPostUpdateEmailTemplate.isLoading ||
      resPostCreateEmailTemplate.isLoading ||
      resPostSendEmail.isLoading,
    initialValueEmail,
    validationSchemaEmail,
    previewData,
    handlePreview,
    isSpinner: resPostPreviewEmail.isLoading,
  };
};
