/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplateListMutation,
  usePostPreviewEmailMutation,
} from '@/store/outreach.slice';
import { EmailTemplateModalProps } from '../types';

export const useEmailTemplateDrawerServices = ({
  onClose,
}: EmailTemplateModalProps) => {
  const toast = useToast();

  const [reqGetEmailTemplateList] = useGetEmailTemplateListMutation();
  const [reqDeleteEmailTemplate, resDeleteEmailTemplate] =
    useDeleteEmailTemplateMutation();
  const [reqPostPreviewEmail, resPostPreviewEmail] =
    usePostPreviewEmailMutation();

  const { emailTemplateData, emailTemplatePgBtn, previewData } = useSelector(
    (state: any) => state.outreach
  );

  const handleDeleteTemplate = () => {
    reqDeleteEmailTemplate(emailTemplateData.id);
  };

  const handlePreview = (values: any) => {
    const payload = {
      subject: values.subject,
      body: values.content,
    };
    reqPostPreviewEmail(payload);
  };

  useEffect(() => {
    if (resDeleteEmailTemplate.isSuccess) {
      reqGetEmailTemplateList(emailTemplatePgBtn);
      onClose();

      toast({
        title: 'Email Template deleted.',
        description: "We've deleted your email template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resDeleteEmailTemplate.isSuccess]);

  return {
    handleDeleteTemplate,
    previewData,
    handlePreview,
    isSpinner: resPostPreviewEmail.isLoading,
  };
};
