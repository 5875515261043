import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import ScrollToFieldError from '@/components/app/ScrollError';
import { Formik, FormikValues } from 'formik';
import ErrorAlert from '@/modules/Leads/components/FormErrorAlert';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchCountriesQuery } from '@/services/common/constants.query';
import { useUserListMutation } from '@/store/user.slice';
import { swalContent } from '@/types';
import { authStorage } from '@/utils/localStorage';
import { changeUploadName } from '@/utils/newFilename';
import { AtsConfirm } from '@/utils/swal';
import * as Yup from 'yup';
import AdditionalInformation from './components/AdditionalInformation';
import AddressInformation from './components/AddressInformation';
import CompanyDetails from './components/CompanyDetails';
import LeadDetails from './components/LeadDetails';

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .required('First name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
    last_name: Yup.string()
      .required('Last name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
    title: Yup.string().required('Title is required.'),
    company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
    specification_id: Yup.number()
      .required('Industry is required.')
      .min(1, 'Invalid level.'),
    email: Yup.string().email('Invalid email.').required('Email is required.'),
    personal_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Personal phone is invalid'
    ),
    work_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Work phone is invalid'
    ),
    extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
    address: Yup.array()
      .of(
        Yup.object().shape({
          location: Yup.string().required('Location is required.'),
          address: Yup.string(),
          city: Yup.string().required('City is required.'),
          state: Yup.string().required('State is required.'),
          country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
          zip_code: Yup.string(),
          primary: Yup.boolean(),
        })
      )
      .min(1, 'Address is required.'),
    linkedIn_url: Yup.string(),
    website: Yup.string().required('Website is required.'),
    client_job_link: Yup.string(),
    isMultipleAccountManager: Yup.string(),
    manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
    lead_owner: Yup.string().required('Lead owner is required.'),
    // lead_source: Yup.array().min(1, 'Pick at least 1 source').nullable(),
    lead_source: Yup.object().shape({
      label: Yup.string().required('Pick at least 1 source'),
      value: Yup.number(),
    }),
    created_by: Yup.string().required('Created by is required.'),
    notes: Yup.string(),
    status: Yup.number(),
  })
);
export default function AddLeadModal({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: any;
  onSubmit: (data: FormikValues) => Promise<any>;
}) {
  const toast = useToast();
  const { uploaded, prefix, uploading, withPrefix } = useSelector(
    (state: any) => state.uploads
  );

  const auth = authStorage();
  const { data: countryList } = useFetchCountriesQuery({});
  const defaultCountry = countryList
    ?.filter((country: any) => country.code === 'us')
    ?.map((country: any) => ({
      label: country.country,
      value: country.id,
      code: country.code,
    }))
    ?.at(0) || { label: '', value: '', code: '' };
  const [isSubmitError, setIsSubmitError] = useState({
    message: '',
    open: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const initialValue = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      title: '',
      company: {},
      specification_id: '',
      email: '',
      personal_phone: '',
      work_phone: '',
      extension: '',
      address: [
        {
          location: '',
          address: '',
          city: '',
          state: '',
          country: defaultCountry,
          zip_code: '',
          primary: true,
          id: null as any,
        },
      ],
      linkedIn_url: '',
      website: '',
      lead_owner: 0,
      lead_source: '',
      created_by: String(auth?.id),
      notes: '',
      status: 1,
      client_job_link: '',
      isMultipleAccountManager: 'false',
      manager: {
        value: auth?.id,
        label: `${auth?.first_name} ${auth?.last_name}`,
      },
    }),
    [auth]
  );

  async function handleSubmit(data: FormikValues) {
    data['created_by'] = parseInt(data.created_by);
    data['lead_owner'] = parseInt(data.lead_owner);
    data['attachments'] = withPrefix
      ? await changeUploadName(uploaded, prefix)
      : uploaded;

    data['manager'] = [data.manager].flat();
    delete data['company']['address'];
    delete data['company']['location'];
    delete data['company']['website'];
    delete data['company']['specification'];
    const isLeadOwner = data.lead_owner === data.created_by;
    const isSendForApproval = data.status === 3;

    let doAskConfirmation = isSendForApproval && !isLeadOwner;

    let submitForm = !doAskConfirmation;

    if (doAskConfirmation) {
      //ask for confirmation
      const content: swalContent = {
        title: 'Do you still want to proceed?',
        text: 'The lead owner and account manager are different.',
        buttons: ['Cancel', true],
        icon: 'warning',
      };
      const isConfirm = await AtsConfirm(content);
      submitForm = isConfirm;
    }

    if (submitForm) {
      setSubmitting(true);
      const res = await onSubmit(data);
      if (!res.success) {
        setIsSubmitError({
          open: true,
          message: res?.error?.message ?? 'Something went wrong!',
        });
      } else {
        onClose();
        toast({
          title: 'Added Lead',
          description: `New Lead successfully added`,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      }
      setSubmitting(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      size="3xl"
      scrollBehavior="inside"
      variant="form"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> Create New Lead</ModalHeader>
        <ModalCloseButton />
        {isSubmitError.open ? (
          <ErrorAlert
            onClose={() =>
              setIsSubmitError((state) => ({ ...state, open: false }))
            }
            text={isSubmitError.message}
          />
        ) : null}
        <ModalBody>
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px">
                  <LeadDetails />
                  <Box mb="40px">
                    <CompanyDetails />
                    <AddressInformation allowAddAddress />
                  </Box>
                  <AdditionalInformation isAddNew />
                </Box>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={uploading}
                    isLoading={submitting}
                    mr={3}
                    onClick={() => props.setFieldValue('status', 1)}
                  >
                    Add
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    disabled={uploading}
                    isLoading={submitting}
                    onClick={() => props.setFieldValue('status', 3)}
                  >
                    Add & Send for approval
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
