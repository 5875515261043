import {
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Pagination from '@/Library/Pagination';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  listCandidates,
  useAssociateJobListMutation,
  useGetCandidatesMutation,
} from '@/store/candidates.slice';
import moment from 'moment';
import { useGetCandidatetatusMutation } from '@/store/constant.slice';
import { listJobs } from '@/store/jobs.slice';
import ChangeStatusModal from '@/modules/Jobs/Layout/ViewCandidatesModule/Modal/ChangeStatus';

const CandidatesAssociatedJobs = () => {
  const tableHeader: { label: any }[] = [
    { label: 'Job Name' },
    { label: 'Stage' },
    { label: 'Status' },
    { label: 'Last Activity Date' },
  ];

  const { associateList, associatePage, associatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  const { candidate_status } = useSelector((state: any) => state.constants);
  const { candidateData } = useSelector((state: any) => state.candidates);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidateID = candidateData?.id || params.candidatesId;
  const [SelectedId, setSelectedId] = useState<number>();
  const [mainStatus, setMainStatus] = useState<number>();
  const [subStatus, setSubStatus] = useState<number>();
  const [selectedkey, setSelectedKey] = useState<number>();

  const [reqAssociate] = useAssociateJobListMutation();
  const [reqCandidateStatus] = useGetCandidatetatusMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();

  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();

  useEffect(() => {
    reqAssociate({ id: candidateID, data: associatePgBtn });
    reqCandidateStatus({});
  }, [candidateID]);

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: associatePgBtn.take,
      query: associatePgBtn.query,
    };

    dispatch(
      listCandidates({
        associatePgBtn: newParam,
      })
    );
    reqAssociate({ id: candidateID, data: newParam });
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: associatePgBtn.query,
      hot: associatePgBtn.hot,
    };

    dispatch(
      listCandidates({
        associatePgBtn: newParam,
      })
    );
    reqAssociate({ id: candidateID, data: newParam });
  };

  const statusChange = (item: any, key: number) => {
    console.log('item', item);
    setSelectedId(Number(item.id));
    setSelectedKey(key);
    setSubStatus(Number(item?.candidate_sub_status_id));
    setMainStatus(Number(item?.candidate_status_id));
    onOpenStatus();
    getSub(Number(item?.candidate_sub_status_id));
  };

  const StatusSuccess = (value: any) => {
    const { sub_id } = value;
    const { sub, status } = getSub(sub_id);
    const updatedCollection = [...associateList];
    updatedCollection[selectedkey] = {
      ...updatedCollection[selectedkey],
      sub,
      status,
    };
    dispatch(
      listCandidates({
        associateList: updatedCollection,
      })
    );
    reqGetCandidate({ id: candidateID });
  };

  const getSub = (id: number) => {
    let status = {} as any;
    let sub = {} as any;
    candidate_status.map((item: any) => {
      item.sub?.map((subs: any) => {
        if (subs.id == id) {
          sub = subs;
          status = {
            ...item,
            sub: [sub],
          };
        }
      });
    });

    return { status, sub };
  };

  const JOB_URL: any = {
    Screen: 'in-progress/screen',
    Submits: 'in-progress/submits',
    Interview: 'in-progress/interviews',
    Offered: 'in-progress/offers',
    Hired: 'in-progress/hired',
    Rejected: 'in-progress/rejected',
  };

  const JOB_ROUTE: any = {
    Screen: '',
    Submits: 'submits',
    Interview: 'interviews',
    Offered: 'offers',
    Hired: '',
    Rejected: '',
  };

  return (
    <Box justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.800">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {associateList.length > 0 &&
              associateList.map((item: any, key: number) => {
                return (
                  <Tr cursor="pointer" key={item?.id + '-assoc'}>
                    <Td>
                      <Box>
                        <Box fontSize="md" color="default.gray.800">
                          <Link
                            target="_blank"
                            href={`/jobs/${item.job?.id}/details`}
                          >
                            {item.job?.title}
                          </Link>
                        </Box>
                      </Box>
                    </Td>
                    <Td>
                      <Link
                        target="_blank"
                        href={`/jobs/${item.job?.id}/view-candidates/${
                          JOB_URL[item?.status?.status]
                        }/${candidateID}/about`}
                      >
                        {item?.status?.status}
                      </Link>
                    </Td>
                    <Td>
                      <Link onClick={() => statusChange(item, key)}>
                        {item?.sub?.sub_status || 'N/A'}
                      </Link>
                    </Td>
                    <Td>{moment.utc(item?.updated_at).format('MM/DD/YYYY')}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>

      {isOpenStatus && (
        <ChangeStatusModal
          isOpen={isOpenStatus}
          onClose={onCloseStatus}
          onSuccess={(e) => StatusSuccess(e)}
          id={SelectedId}
          main_status={mainStatus}
          sub_status={subStatus}
          key={selectedkey}
          candidate={candidateData}
        />
      )}

      <Pagination
        totalPages={associatePage.lastPage}
        currentPage={associatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        totalEntries={associatePage.count}
        onEntryChange={(entries) => entryChange(entries)}
        currentCount={associateList.length}
        targetCount={associatePgBtn.take}
      />
    </Box>
  );
};

export default CandidatesAssociatedJobs;
