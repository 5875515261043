import {
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';

import FAIcon from '@/components/lib/FAIcon';

import RequestToConvertModal from '@/modules/Leads/components/request-to-convert-modal';
import SendForApprovalModal from '@/modules/Leads/components/send-for-approval-modal';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import toUrl from '@/utils/toUrl';
import { LeadsAPIResponseType } from '../../types';
import AddLeadModal from '../add-edit-lead-modal/AddLeadModal';
import BulkAction from './components/BulkAction';
import {
  CancelApproval,
  MarkAsActive,
  MarkAsInactive,
  RequestToConvert,
  SendForApproval,
  ViewContact,
} from '../menu-btn/MenuItems';
import SkeletonLoader from './components/SkeletonLoader';
import { TableProps } from './TableInterface';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import TimeChip from '../chip/TimeChip';
import MenuBtn from '../menu-btn/MenuBtn';
import TableLoader from '@/components/ui/Loaders/TableLoader';
import { BsThreeDots } from 'react-icons/bs';

const tableHeader = ['', 'Lead Details', 'Client Job Link', 'Created Date', ''];
type MyLeadTableCol = {
  index: number;
  id: number;
  detail: {
    first_name: string;
    last_name: string;
    linked_in_url: string;
    title: string;
    client_name: string;
    isNotesExists: boolean;
    client_id: number;
  };
  created_at: Date;
  client_job_link: string;
  client_job_title: string;
  lead_type: { id: number; type: string };
  lead_status: { id: number; lead_status: string };
  allData: LeadsAPIResponseType;
};
export default function UnclaimedLeadsTable({
  rows,
  onDateSort,
  onSubmitEmail,
  onAddLead,
  onBulkInActive,
  onAddTags,
  onCancelApproval,
  onChangeToActiveStatus,
  onChangeToInActiveStatus,
  onConvertToClient,
  onSendApproval,
  onOpenDetailDrawer,
  isLoading,
  pagination: {
    totalPages,
    currentPage,
    onPageChange,
    totalEntries,
    onEntryChange,
    currentCount,
    targetCount,
  },
}: TableProps) {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<
    { id: number; email: string }[]
  >([]);

  const tableContainerRef = useRef(null);

  const { rowSelection, setRowSelection } = useRowSelection(
    setSelectedRows,
    rows
  );
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [sendToApproval, setSendToApproval] = useState({
    id: 0,
    isOpen: false,
  });
  const [convertToClient, setConvertToClient] = useState({
    id: 0,
    isOpen: false,
  });
  const columns: ColumnDef<MyLeadTableCol>[] = useMemo(() => {
    function handleClick(id: number, index: number) {
      navigate(`/leads/unclaimed-leads/${id}/details`);
      onOpenDetailDrawer(index);
    }
    return [
      {
        id: 'select',
        size: 41,
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllPageRowsSelected()}
            isIndeterminate={table.getIsSomePageRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            aria-label="Select all"
            name="all"
            id="select-all"
          />
        ),
        cell: ({ row }) => {
          return (
            <Checkbox
              isChecked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              aria-label="Select row"
              id={String(row.original.id)}
              name={String(row.original.id)}
            />
          );
        },
        meta: { sticky: true, left: 0 },
      },
      {
        accessorKey: 'detail',
        header: 'Lead Details',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Text
              isTruncated
              fontSize="medium"
              fontWeight={700}
              onClick={() => handleClick(rowData.id, rowData.index)}
              cursor={'pointer'}
            >
              {rowData.detail.first_name} {rowData.detail.last_name}
            </Text>
          );
        },
        meta: { sticky: true, left: 41, border: true },
      },

      {
        accessorKey: 'job_title',
        header: 'Job Title',
        size: 300,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Text isTruncated fontSize={'md'}>
              {rowData.detail.title}
            </Text>
          );
        },
      },
      {
        accessorKey: 'client_job_link',
        header: 'Client Job Link',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Box isTruncated width="100%" height="fit-content">
              {!!rowData.client_job_link && (
                <Link
                  href={toUrl(rowData?.client_job_link)}
                  target="_blank"
                  rel="noreferrer"
                  fontSize={'md'}
                >
                  {rowData?.client_job_title || toUrl(rowData?.client_job_link)}
                </Link>
              )}
            </Box>
          );
        },
      },

      {
        accessorKey: 'created_at',
        header: 'Created On',
        size: 200,
        cell: ({ getValue }) => {
          return (
            <Text fontSize={'md'}>
              {moment.utc(getValue() as Date).format('MM/DD/YYYY hh:mm A')}
            </Text>
          );
        },
      },
      {
        accessorKey: 'action',
        header: '',
        size: 60,
        cell: ({ row }) => {
          const type = row.original?.lead_type?.type;
          const status = row.original?.lead_status?.lead_status;
          const leadId = row.original?.id ?? 0;
          const lead = row.original.allData as LeadsAPIResponseType;

          return (
            <Flex justifyContent="center" w="100%">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Menus"
                  icon={<BsThreeDots fontSize="16px" />}
                  variant="ghost"
                  size="sm"
                  _hover={{ bgColor: 'none' }}
                  _active={{ bgColor: 'none' }}
                />
                <Portal>
                  <MenuList>
                    {' '}
                    {status === 'Active' || status === 'Draft' ? (
                      <MarkAsInactive
                        onClick={() => {
                          onChangeToInActiveStatus(lead);
                        }}
                      />
                    ) : null}
                    {(type === 'Qualified' || type === 'Lead') &&
                    (status === 'Draft' || status === 'Rejected') ? (
                      <SendForApproval
                        onClick={() => {
                          setSendToApproval({ isOpen: true, id: lead.id });
                        }}
                      />
                    ) : null}
                    {status === 'Active' ? <ViewContact /> : null}
                    {status === 'Inactive' ? (
                      <MarkAsActive
                        onClick={() => onChangeToActiveStatus(lead)}
                      />
                    ) : null}
                    {status === 'Approval Pending' ? (
                      <CancelApproval onClick={() => onCancelApproval(lead)} />
                    ) : null}
                    {status !== 'Approval Pending' &&
                    status !== 'Inactive' &&
                    type !== 'Cold' &&
                    type !== 'Contact' ? (
                      <RequestToConvert
                        onClick={() =>
                          setConvertToClient({ isOpen: true, id: leadId })
                        }
                      />
                    ) : null}
                  </MenuList>
                </Portal>
              </Menu>
            </Flex>
          );
        },
      },
    ];
  }, []);
  const data = useMemo(
    () =>
      rows.map((lead, index) => ({
        id: lead.id,
        index,
        // first_name: string;
        detail: {
          first_name: lead?.first_name ?? '',
          last_name: lead?.last_name ?? '',
          linked_in_url: lead?.linked_in_url ?? '',
          title: lead?.title ?? '',
          client_name: lead?.client?.name ?? '',
          isNotesExists: Boolean(lead?.note_count),
          client_id: lead?.client?.id ?? 0,
        },
        created_at: lead?.created_at,
        client_job_link: lead?.client_job_link ?? '',
        client_job_title: lead?.client_job_title ?? '',
        lead_type: lead?.leadType,
        lead_status: lead?.leadStatus,
        allData: lead,
      })),
    [rows]
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (originalRow) => String(originalRow.id),
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
  });
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 6,
  });

  const tableRows = table.getRowModel();

  return (
    <>
      {isLoading ? (
        <TableLoader header={tableHeader} />
      ) : (
        <>
          <Flex justifyContent="space-between" pb={'16px'}>
            <Flex gap={4}>
              {selectedRows.length > 0 ? (
                <BulkAction
                  leads={selectedRows}
                  onAddTags={(tags) =>
                    onAddTags(
                      tags,
                      selectedRows.map((data) => data.id)
                    )
                  }
                  onMarkInActive={() =>
                    onBulkInActive(selectedRows.map((data) => data.id))
                  }
                />
              ) : (
                <Box mb={6}></Box>
              )}
            </Flex>

            <Button leftIcon="plus" variant="solid" onClick={onOpenModal}>
              Add New lead
            </Button>
          </Flex>
          <Grid templateRows={'repeat(10,1fr)'} h={'calc(100% - 65px)'}>
            <GridItem rowStart={1} rowEnd={10} w="100%" overflow="hidden">
              <TableContainer
                boxSizing="border-box"
                border="1px solid"
                borderColor="default.white.400"
                borderRadius="md"
                sx={{ overflow: 'auto', position: 'relative' }}
                maxH="100%"
                minH="100%"
                ref={tableContainerRef}
              >
                <Table sx={{ display: 'grid' }}>
                  <Thead sx={{ position: 'sticky', zIndex: 30, top: 0 }}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr
                        key={headerGroup.id}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          position: 'relative',
                        }}
                        bg="default.white.600"
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: header.getSize(),
                              }}
                              bg="default.white.600"
                              color="default.gray.600"
                              p="10px 20px"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>

                  <Tbody
                    boxSizing="border-box"
                    background="default.white.100"
                    borderBottom="1px solid"
                    borderColor="default.white.400"
                    sx={{
                      display: 'grid',
                      height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                      position: 'relative', //needed for absolute positioning of rows
                    }}
                  >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                      const row = tableRows.rows[
                        virtualRow.index
                      ] as Row<MyLeadTableCol>;
                      return (
                        <Tr
                          data-index={virtualRow.index} //needed for dynamic row height measurement
                          ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                          key={row.id}
                          borderBottom="1px solid #EEEEEE"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%',
                          }}
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: cell.column.getSize(),
                                  bgColor: '#fff',
                                  zIndex: 2,
                                }}
                                h={'50px'}
                                p={'10px 20px'}
                                borderBottom={0}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </GridItem>
            <GridItem rowStart={10} rowEnd={11} zIndex={20}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalEntries={totalEntries}
                onEntryChange={onEntryChange}
                currentCount={currentCount}
                targetCount={targetCount}
              />
            </GridItem>
          </Grid>
        </>
      )}
      {sendToApproval.isOpen ? (
        <SendForApprovalModal
          isOpen={sendToApproval.isOpen}
          leadId={sendToApproval.id}
          onClose={() => setSendToApproval({ isOpen: false, id: null })}
          onSubmit={onSendApproval}
        />
      ) : null}
      {convertToClient.isOpen ? (
        <RequestToConvertModal
          isOpen={convertToClient.isOpen}
          leadId={convertToClient.id}
          onClose={() => setConvertToClient({ isOpen: false, id: null })}
          onSubmit={onConvertToClient}
        />
      ) : null}
      {isOpenModal ? (
        <AddLeadModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          onSubmit={onAddLead}
        />
      ) : null}
    </>
  );
}

function useRowSelection(
  callbackFn: (rows: Array<{ id: number; email: string }>) => void,
  rows: LeadsAPIResponseType[]
) {
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    const rowIds = Object.keys(rowSelection).map(Number);
    const selectedLead = rows
      .filter((leadId) => rowIds.includes(leadId.id))
      .map((lead) => {
        return { id: lead?.id, email: lead?.primary_email };
      });
    callbackFn(selectedLead);
  }, [callbackFn, rowSelection, rows]);

  return { rowSelection, setRowSelection };
}
