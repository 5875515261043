import { MenuItem } from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import React from 'react';
import { FaBan } from 'react-icons/fa6';

export function AddNewLead(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem icon={<FAIcon iconName="briefcase" />} fontSize="14px" {...props}>
      Add New Lead
    </MenuItem>
  );
}
export function EditLead(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props} icon={<FAIcon iconName="pencil" />} fontSize="14px">
      Edit
    </MenuItem>
  );
}
export function RequestToConvert(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem icon={<FAIcon iconName="briefcase" />} fontSize="14px" {...props}>
      Request to Convert
    </MenuItem>
  );
}
export function MarkAsInactive(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem
      icon={<FAIcon iconName="ban" />}
      fontSize="14px"
      color="red.500"
      {...props}
    >
      Mark as Inactive
    </MenuItem>
  );
}
export function CancelApproval(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem icon={<FaBan />} fontSize="14px" {...props}>
      Cancel Approval
    </MenuItem>
  );
}
export function ViewContact(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem icon={<FAIcon iconName="briefcase" />} fontSize="14px" {...props}>
      View Contact
    </MenuItem>
  );
}
export function SendForApproval(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem
      icon={<FAIcon iconName="check-circle" />}
      fontSize="14px"
      {...props}
    >
      Send for Approval
    </MenuItem>
  );
}
export function MarkAsActive(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem
      icon={<FAIcon iconName="square-check" />}
      fontSize="14px"
      {...props}
    >
      Mark as Active
    </MenuItem>
  );
}
