import { Box, Flex } from '@chakra-ui/react';
import Button from '@/Library/Button';
import SMSHistoryListTable from './Table';

const SMSHistoryList = () => {
  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end" mb="16px" px={6}>
        <Flex gap={5}>
          <Button leftIcon="file" variant="solid">
            Export CSV
          </Button>
        </Flex>
      </Flex>
      <SMSHistoryListTable />
    </Box>
  );
};

export default SMSHistoryList;
