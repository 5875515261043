import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import AtsSelect from '@/components/app/AtsSelect';
import { Field } from 'formik';
import { debounce } from 'lodash';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/css/autocomplete.css';
import { useSearchGeoTypesMutation } from '@/store/geo.slice';
import { number } from 'yup';
import AtsAutoComplete from '@/components/app/Leads/components/Autocomplete';

interface fieldInterface {
  name: any;
  value: any;
  errors?: any;
  touched?: any;
  customChange?: () => void;
}

interface addCompInt {
  handleChange: any;
  setFieldValue: any;
  countryField?: fieldInterface;
  cityField?: fieldInterface;
  stateField?: fieldInterface;
  zipField?: fieldInterface;
}

const AddressComponent = ({
  handleChange,
  setFieldValue,
  countryField,
  cityField,
  stateField,
  zipField,
}: addCompInt) => {
  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState('');

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName == 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoFormat = (item: any) => {
    let format: any = '';
    try {
      const cityB = currentName == 'city' ? { fontWeight: 'bold' } : {};
      const postB = currentName == 'postal_code' ? { fontWeight: 'bold' } : {};
      format = (
        <>
          <Flex gap="2px">
            <Text style={postB}>{item?.postal_code}</Text>
            <Text style={cityB}>{item?.city}</Text>
            <Text>{item?.state}</Text>
            <Text>{item?.country}</Text>
          </Flex>
        </>
      );
    } catch (e) {
      format = 'Error formatting';
    }
    return format;
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const handleOnSelect = (item: any, input: string) => {
    if (stateField) {
      setFieldValue(stateField.name, item.state);
    }
    if (cityField) {
      setFieldValue(cityField.name, item.city);
    }
    if (countryField) {
      setFieldValue(countryField.name, item.country);
    }
    if (zipField) {
      setFieldValue(zipField.name, item.postal_code);
    }

    try {
      cityField.customChange();
    } catch (e) {
      console.warn(e);
    }
    try {
      stateField.customChange();
    } catch (e) {
      console.warn(e);
    }
    try {
      countryField.customChange();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      <Flex gap="32px" mb="34px">
        <FormControl
          isInvalid={Boolean(!!cityField.errors && cityField.touched)}
        >
          <FormLabel fontSize="14px" lineHeight="18px">
            City
          </FormLabel>

          <AtsAutoComplete
            name={cityField.name}
            placeholder="City"
            type="text"
            variant="outline"
            value={cityField.value}
            onChange={(e: any) => {
              setFieldValue(cityField.name, e.target.value);
            }}
            onFocus={() => setName('city')}
            options={itemLocation}
            onKeyUp={(e: any) => geoLocationChange(e, 'city')}
            isLoading={resGeo.isLoading}
            onSelect={(item: any) => {
              setFieldValue(cityField.name, item.value);
              handleOnSelect(item, 'city');
            }}
          />

          <FormErrorMessage>{String(cityField.errors)}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap="32px" mb="34px">
        <FormControl
          isInvalid={Boolean(!!stateField.errors && stateField.touched)}
        >
          <FormLabel fontSize="14px" lineHeight="18px">
            State / Province
          </FormLabel>
          <Input
            id={stateField.name}
            name={stateField.name}
            type="text"
            placeholder="State / Province"
            variant="outline"
            value={stateField.value}
            onChange={handleChange}
            sx={{
              height: '38px',
              border: 'none',
              borderBottom: '1px solid',
              borderColor: '#E7E9ED',
              p: '8px 12px',
              fontSize: '14px',
            }}
          />
          <FormErrorMessage>{String(stateField.errors)}</FormErrorMessage>
        </FormControl>
        {countryField && (
          <FormControl
            isInvalid={Boolean(!!countryField.errors && countryField.touched)}
          >
            <FormLabel fontSize="14px" lineHeight="18px">
              Country
            </FormLabel>
            <Input
              id={countryField.name}
              name={countryField.name}
              type="text"
              placeholder="Country"
              variant="outline"
              value={countryField.value}
              onChange={handleChange}
              sx={{
                height: '38px',
                border: 'none',
                borderBottom: '1px solid',
                borderColor: '#E7E9ED',
                p: '8px 12px',
                fontSize: '14px',
              }}
            />
            <FormErrorMessage>{String(countryField.errors)}</FormErrorMessage>
          </FormControl>
        )}
        {zipField && (
          <FormControl isInvalid={Boolean(zipField.errors && zipField.touched)}>
            <FormLabel fontSize="14px" lineHeight="18px">
              Zip Code
            </FormLabel>

            <AtsAutoComplete
              name={zipField.name}
              placeholder="Zip Code"
              type="text"
              variant="outline"
              value={zipField.value}
              onChange={(e: any) => {
                setFieldValue(zipField.name, e.target.value);
              }}
              onFocus={() => setName('postal_code')}
              options={itemLocation}
              onKeyUp={(e: any) => geoLocationChange(e, 'postal_code')}
              isLoading={resGeo.isLoading}
              onSelect={(item: any) => {
                setFieldValue(zipField.name, item.value);
                handleOnSelect(item, 'postal_code');
              }}
            />
            <FormErrorMessage>{String(zipField.errors)}</FormErrorMessage>
          </FormControl>
        )}
      </Flex>
    </>
  );
};

export default AddressComponent;
