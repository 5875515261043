/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  usePostDisconnectEmailConnectionMutation,
  useGetEmailConnectionListMutation,
  usePostDeleteEmailConnectionMutation,
} from '@/store/outreach.slice';
import { EmailConnectionModalProps } from '../types';

export const useEmailConnectionDrawerServices = ({
  onClose,
}: EmailConnectionModalProps) => {
  const toast = useToast();

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostDisconnectEmailConnection, resPostDisconnectEmailConnection] =
    usePostDisconnectEmailConnectionMutation();
  const [reqPostDeleteEmailConnection, resPostDeleteEmailConnection] =
    usePostDeleteEmailConnectionMutation();

  const { emailConnectionData, emailConnectionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const handleDisconnectConnection = () => {
    reqPostDisconnectEmailConnection(emailConnectionData.id);
  };

  const handleDeleteConnection = () => {
    reqPostDeleteEmailConnection(emailConnectionData.id);
  };

  useEffect(() => {
    if (resPostDisconnectEmailConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
      onClose();

      toast({
        title: 'Email connection disconnect.',
        description: "We've disconnect your email connection.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDisconnectEmailConnection.isSuccess]);

  useEffect(() => {
    if (resPostDeleteEmailConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
      onClose();

      toast({
        title: 'Email connection delete.',
        description: "We've delete your email connection.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDeleteEmailConnection.isSuccess]);

  return { handleDisconnectConnection, handleDeleteConnection };
};
