import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';

import Button from '@/Library/Button';
import FAIcon from '@/components/lib/FAIcon';

import ScrollToFieldError from '@/components/app/ScrollError';
import LoadingPage from '@/components/app/Loading';
import SignatureTable from './table';
import SignatureAddModal from './modal/add';
import { useListSignatureMutation } from '@/store/signature.slice';

export default function EmailSignatures() {
  const initialValue = {
    search: '',
  };

  const { signaturePageBtn, signaturesList } = useSelector(
    (state: any) => state.signature
  );
  const [reqListSignature, resListSignature] = useListSignatureMutation();

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: signaturePageBtn.take,
      query: data.search,
    };
    submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: signaturePageBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (form: any) => {
    reqListSignature(form);
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" px={6}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Flex
              justifyContent="space-around"
              maxWidth="40%"
              alignItems="center"
            ></Flex>
          )}
        </Formik>
        {signaturesList.length == 0 && (
          <Button leftIcon="plus" variant="solid" onClick={addOnOpen}>
            Add Signature
          </Button>
        )}
      </Flex>
      <Box>
        <SignatureTable />
      </Box>
      {addOpen && <SignatureAddModal isOpen={addOpen} onClose={addOnClose} />}
    </Box>
  );
}
