import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import FAIcon from '@/components/lib/FAIcon';
import { SettingsData } from '@/types';
import ClientShareModal from '../ShareModal';

const ClientsSettings = () => {
  const { clientData } = useSelector((state: any) => state.clients);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableHeader: { label: string }[] = [
    { label: 'Recruiter Name' },
    { label: 'Start Date' },
    { label: 'End Date' },
    { label: '' },
  ];

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" py="24px">
        <Button variant="active" onClick={() => {}}>
          Share
        </Button>
        <Button leftIcon="plus" variant="solid" onClick={onOpen}>
          Recruiter
        </Button>
      </Flex>

      <Flex justifyContent="space-between" pb="19px">
        <Box pr={2}>
          <InputGroup>
            <InputLeftElement children={<FAIcon iconName="search" />} />
            <Input type="search" placeholder="Search Settings" />
          </InputGroup>
        </Box>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {clientData?.settingsData?.map((settingsData: SettingsData) => (
                <Tr key={settingsData.id} cursor="pointer" onClick={() => {}}>
                  <Td>{settingsData.recruiterName}</Td>
                  <Td>{settingsData.startDate}</Td>
                  <Td>{settingsData.endDate}</Td>
                  <Td>
                    <Menu>
                      <MenuButton>
                        <Button
                          htmlType="menu"
                          variant="ghost"
                          iconName="ellipsis-h"
                          iconSize="xl"
                        />
                      </MenuButton>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {isOpen && <ClientShareModal isOpen={isOpen} onClose={onClose} />}
      </Box>
    </Box>
  );
};

export default ClientsSettings;
