import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ScrollToFieldError from '@/components/app/ScrollError';
import { Formik, FormikValues } from 'formik';
import ErrorAlert from '@/modules/Leads/components/FormErrorAlert';
import { useFetchLeadDetailQuery } from '@/services//leads/leadsApi';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useFetchCountriesQuery,
  useFetchJobSpecificationsQuery,
} from '@/services//common/constants.query';
import { useUserListMutation } from '@/store/user.slice';
import { changeUploadName } from '@/utils/newFilename';
import { addressFormat, leadSourceFormat } from '@/utils/utilFormat';
import * as Yup from 'yup';
import AdditionalInformation from './components/AdditionalInformation';
import AddressInformation from './components/AddressInformation';
import CompanyDetails from './components/CompanyDetails';
import LeadDetails from './components/LeadDetails';
import { useFetchUsersQuery } from '@/services//user/users.query';
import FormLoader from '@/components/ui/Loaders/FormLoader';

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .required('First name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
    last_name: Yup.string()
      .required('Last name is required.')
      .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
    title: Yup.string().required('Title is required.'),
    company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
    specification_id: Yup.number()
      .required('Industry is required.')
      .min(1, 'Invalid level.'),
    email: Yup.string().email('Invalid email.').required('Email is required.'),
    personal_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Personal phone is invalid'
    ),
    work_phone: Yup.string().matches(
      /^[\d ()+-.]+$/gi,
      'Work phone is invalid'
    ),
    extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
    address: Yup.array()
      .of(
        Yup.object().shape({
          location: Yup.string().required('Location is required.'),
          address: Yup.string(),
          city: Yup.string().required('City is required.'),
          state: Yup.string().required('State is required.'),
          country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
          zip_code: Yup.string(),
          primary: Yup.boolean(),
        })
      )
      .min(1, 'Address is required.'),
    linkedIn_url: Yup.string(),
    website: Yup.string().required('Website is required.'),
    client_job_link: Yup.string(),
    isMultipleAccountManager: Yup.string(),
    manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
    lead_owner: Yup.number().required('Lead owner is required.'),
    lead_source: Yup.object().shape({
      label: Yup.string().required('Pick at least 1 source'),
      value: Yup.number(),
    }),
    created_by: Yup.string().required('Created by is required.'),
    notes: Yup.string(),
    status: Yup.number(),
  })
);
export default function EditLeadModal({
  isOpen,
  onClose,
  leadId,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: any;
  leadId?: number;
  onSubmit: (data: FormikValues, id: any) => Promise<any>;
}) {
  const toast = useToast();
  const { uploaded, prefix, uploading, withPrefix } = useSelector(
    (state: any) => state.uploads
  );
  const { data: countryList } = useFetchCountriesQuery({});

  const { data: leadDetail, isLoading: isLoadingForm } =
    useFetchLeadDetailQuery({ id: leadId });
  const [isSubmitError, setIsSubmitError] = useState({
    message: '',
    open: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const initialValue = useMemo(
    () => ({
      first_name: leadDetail?.lead?.first_name ?? '',
      last_name: leadDetail?.lead?.last_name ?? '',
      title: leadDetail?.lead?.title ?? '',
      company: {
        value: leadDetail?.client?.id ?? 0,
        label: leadDetail?.client?.name ?? '',
      },
      specification_id: leadDetail?.lead?.spec_id ?? 0,
      email: leadDetail?.lead?.primary_email ?? '',
      personal_phone: leadDetail?.lead?.personal_phone ?? '',
      work_phone: leadDetail?.lead?.work_phone ?? '',
      extension: leadDetail?.lead?.extension ?? '',
      address: addressFormat(leadDetail?.address, countryList),
      linkedIn_url: leadDetail?.lead?.linked_in_url ?? '',
      website: leadDetail?.lead?.website ?? '',
      lead_owner: leadDetail?.lead?.owner_id || '',
      lead_source: leadSourceFormat(leadDetail?.lead?.lead_source),
      created_by: String(leadDetail?.lead?.user_id),
      notes: '',
      status: leadDetail?.lead?.leadStatus?.id,
      client_job_link: leadDetail?.lead?.client_job_link?.replace(
        /(^\w+:|^)\/\//,
        ''
      ),
      isMultipleAccountManager: leadDetail?.lead?.multipleAm ? 'true' : 'false',
      manager: leadDetail?.client?.manager
        ?.filter((manager) => manager?.user)
        ?.map((manager) => ({
          value: manager.user.id,
          label: `${manager.user.first_name} ${manager.user.last_name}`,
        })),
    }),
    [leadDetail]
  );

  async function handleSubmit(data: FormikValues) {
    data['created_by'] = parseInt(data.created_by);
    data['lead_owner'] = parseInt(data.lead_owner);
    data['attachments'] = withPrefix
      ? await changeUploadName(uploaded, prefix)
      : uploaded;

    data['manager'] = [data.manager].flat();
    delete data['company']['address'];
    delete data['company']['location'];
    delete data['company']['website'];
    delete data['company']['specification'];

    setSubmitting(true);
    const res = await onSubmit(data, leadDetail.lead.id);
    if (!res.success) {
      setIsSubmitError({
        open: true,
        message: res?.error?.message ?? 'Something went wrong!',
      });
    } else {
      onClose();
      toast({
        title: 'Added Lead',
        description: `New Lead successfully added`,
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
    setSubmitting(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={onClose}
        size="3xl"
        scrollBehavior="inside"
        variant="form"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Edit Lead</ModalHeader>
          <ModalCloseButton />
          {isSubmitError.open ? (
            <ErrorAlert
              onClose={() =>
                setIsSubmitError((state) => ({ ...state, open: false }))
              }
              text={isSubmitError.message}
            />
          ) : null}
          <ModalBody>
            {isLoadingForm ? (
              <FormLoader />
            ) : (
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <ScrollToFieldError />
                    <Box p="32px">
                      <LeadDetails />
                      <Box mb="40px">
                        <CompanyDetails />
                        <AddressInformation allowAddAddress />
                      </Box>
                      <AdditionalInformation isAddNew />
                    </Box>
                    <ModalFooter>
                      <Button
                        type="submit"
                        disabled={uploading}
                        isLoading={submitting}
                      >
                        Update
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
