import moment from 'moment';
import { Formik } from 'formik';
import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Input,
  Link,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import Button from '@/Library/Button';
import Pagination from '@/Library/Pagination';

import FAIcon from '@/components/lib/FAIcon';
import ScrollToFieldError from '@/components/app/ScrollError';

import { LeadsAddAgreementModal } from './Add';
import { LeadsEditAgreementModal } from './Edit';
import { LeadsViewAgreementModal } from './View';

import { useAgreementsServices } from './useAgreementsServices';
import { tableHeader } from './constants';

export const LeadsAgreements = () => {
  const {
    initialValue,
    onSubmit,
    clearSearch,
    agreements,
    viewAgreement,
    editClick,
    deleteClick,
    leadAgreementPage,
    pageChange,
    entryChange,
    leadAgreementPgBtn,
    isAddAgreementOpen,
    onAddAgreementClose,
    onAddAgreementOpen,
    isEditAgreementOpen,
    onEditAgreementClose,
    isViewAgreementOpen,
    onViewAgreementClose,
  } = useAgreementsServices();
  return (
    <Box>
      <Flex justifyContent="space-between" py={6}>
        <Box pr={2}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                    placeholder="Search File Name"
                  />
                </InputGroup>
              </form>
            )}
          </Formik>
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={onAddAgreementOpen}>
          Add Agreement
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {agreements.length > 0 &&
                agreements?.map((data: any) => (
                  <Tr key={data.id} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Link onClick={() => viewAgreement(data.id)}>
                        {data.title}
                      </Link>
                    </Td>
                    <Td>{moment.utc(data.date_signed).format('MM/DD/YYYY')}</Td>
                    <Td>
                      {data?.valid_until
                        ? moment.utc(data?.valid_until).format('MM/DD/YYYY')
                        : 'No Expiry'}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => editClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="pencil" />
                              <Box ml={12} cursor="pointer">
                                Edit
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => deleteClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12} cursor="pointer">
                                Delete
                              </Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={leadAgreementPage.lastPage}
          currentPage={leadAgreementPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={leadAgreementPage.count}
          currentCount={agreements?.length}
          targetCount={leadAgreementPgBtn.take}
        />
        {isAddAgreementOpen && (
          <LeadsAddAgreementModal
            isOpen={isAddAgreementOpen}
            onClose={onAddAgreementClose}
          />
        )}
        {isEditAgreementOpen && (
          <LeadsEditAgreementModal
            isOpen={isEditAgreementOpen}
            onClose={onEditAgreementClose}
          />
        )}
        {isViewAgreementOpen && (
          <LeadsViewAgreementModal
            isOpen={isViewAgreementOpen}
            onClose={onViewAgreementClose}
          />
        )}
      </Box>
    </Box>
  );
};
