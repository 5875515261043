/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCandidateSourceMutation } from '@/store/constant.slice';
import {
  updateOutreach,
  usePostSMSAutomationCreateMutation,
  usePostSMSAutomationListMutation,
  usePostSMSAutomationUpdateMutation,
} from '@/store/outreach.slice';
import { SMS_AUTOMATION_RECORD_SCHEMA } from './constant';

export const useSMSAutomationModalServices = ({
  isOpen,
  onClose,
  isEdit,
}: any) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { smsAutomationData, smsAutomationPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const [reqCandSource] = useGetCandidateSourceMutation();
  const [reqPostSMSAutomationList] = usePostSMSAutomationListMutation();
  const [reqPostSMSAutomationCreate, resPostSMSAutomationCreate] =
    usePostSMSAutomationCreateMutation();
  const [reqPostSMSAutomationUpdate, resPostSMSAutomationUpdate] =
    usePostSMSAutomationUpdateMutation();

  const [candSource, setCandSource] = useState([]);

  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: item.source,
            value: item.id,
          });
        });
      }
      setCandSource(option);
    });
  }, []);

  const initialValues: any = isEdit
    ? {
        name: smsAutomationData.name,
        trigger: smsAutomationData.trigger,
        sources: smsAutomationData.sources?.map((val: any) => {
          return {
            label: val.candidate_source.source,
            value: val.candidate_source.id,
          };
        }),
        send_immediately:
          smsAutomationData.send_immediately === 1 ? true : false,
        schedule_details: {
          timezone: smsAutomationData.timezone,
          start_time: smsAutomationData.start_time,
          end_time: smsAutomationData.end_time,
        },
        message: smsAutomationData.message,
      }
    : {
        name: '',
        trigger: '',
        sources: [],
        send_immediately: true,
        schedule_details: {
          timezone: 'US/Pacific',
          start_time: '00:00',
          end_time: '00:00',
        },
        message: '',
      };

  const validationSchema = SMS_AUTOMATION_RECORD_SCHEMA;

  const handleOnSubmit = (data: any) => {
    const send_immediately = data.send_immediately == 'true' ? 1 : 0;

    const payload = {
      ...data,
      ...data.schedule_details,
      sources: data.sources?.map((val: any) => val.value),
      send_immediately: send_immediately,
    };

    console.log(data, 'xx submit');

    if (isEdit) {
      reqPostSMSAutomationUpdate({
        data: payload,
        id: smsAutomationData.id,
      });
    } else {
      reqPostSMSAutomationCreate(payload);
    }

    dispatch(
      updateOutreach({
        smsAutomationData: { ...smsAutomationData, data },
      })
    );
  };

  useEffect(() => {
    if (
      resPostSMSAutomationCreate.isSuccess ||
      resPostSMSAutomationUpdate.isSuccess
    ) {
      reqPostSMSAutomationList(smsAutomationPgBtn);
      onClose();

      isEdit
        ? toast({
            title: 'SMS Automation updated.',
            description: "We've updated your SMS Automation.",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        : toast({
            title: 'SMS Automation added.',
            description: "We've added your new SMS Automation.",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
    }
  }, [
    resPostSMSAutomationCreate.isSuccess,
    resPostSMSAutomationUpdate.isSuccess,
  ]);

  return {
    initialValues,
    validationSchema,
    isOpen,
    onClose,
    handleOnSubmit,
    candSource,
  };
};
