import {
  Box,
  Flex,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Image,
  Heading,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { authStorage } from '@/utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUser,
  useUserListPaginateMutation,
  useViewProfileMutation,
} from '@/store/user.slice';
import { UserTypes } from '@/types';
import FAIcon from '@/components/lib/FAIcon';
import User from '@/assets/images/user.png';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router';

export default function MyProfile() {
  const dispatch = useDispatch();
  const params = useParams();
  // const user = authStorage();
  const { currentUser } = useSelector((state: any) => state.user);
  const [reqView, resView] = useViewProfileMutation();
  const [profile, setProfile] = useState<any>(null);
  const { auth } = useSelector((state: any) => state);

  const default_pic = User;

  // When user refreshes the page it retrieves the user data again from the database
  useEffect(() => {
    // if (Object.keys(currentUser).length <= 0) {
    reqView({ id: parseInt(params.profileID) });
    // }
  }, [params.profileID]);

  useEffect(() => {
    if (resView.isSuccess) {
      const data = resView.data.data;
      setProfile(data);
    }
  }, [resView.isSuccess]);

  return (
    <Box fontFamily="NunitoSans Regular">
      <Tabs colorScheme="purple" variant="unstyled">
        <Flex gap={2} background="#F9F9FB" mb="32px">
          <Flex gap="32px" height="fit-content">
            <Box>
              {resView.isLoading ? (
                <SkeletonCircle size="200" />
              ) : profile?.profile !== null &&
                profile?.profile !== '' &&
                profile?.profile !== undefined ? (
                <Image
                  src={profile?.profile}
                  alt="Selected"
                  borderRadius="full"
                  boxSize="200px"
                  placeSelf="center"
                  objectFit="contain"
                />
              ) : (
                <Image
                  src={default_pic}
                  alt="Selected"
                  borderRadius="full"
                  boxSize="200px"
                  placeSelf="center"
                />
              )}
            </Box>
            <Box alignContent="center" verticalAlign="center" margin="auto">
              <Heading>
                {resView.isLoading ? (
                  <Box width="200px">
                    <SkeletonText noOfLines={1} skeletonHeight="10" />
                  </Box>
                ) : (
                  profile?.name
                )}
              </Heading>
              <Box color="default.gray.600">
                {resView.isLoading ? (
                  <SkeletonText mt={2} noOfLines={1} skeletonHeight="4" />
                ) : (
                  <Box>
                    <FAIcon iconName="envelope" /> {' ' + profile?.email}
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Flex
          borderRadius="4px"
          border="1px solid #EEEEEE"
          background="#FFFFFF"
        >
          <Box height="500px"></Box>
        </Flex>
      </Tabs>
    </Box>
  );
}
