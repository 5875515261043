/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Flex, Stack, Text } from '@chakra-ui/react';
import Button from '@/Library/Button';

import {
  updateOutreach,
  useGetChangeStatusMutation,
  useGetSequenceDataMutation,
} from '@/store/outreach.slice';

import { SequenceTabs } from './SequenceTabs';

const SubPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sequencesData } = useSelector((state: any) => state.outreach);

  const params = useParams();

  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqGetChangeStatus] = useGetChangeStatusMutation();

  useEffect(() => {
    if (params.settingTypeId) {
      reqGetSequenceData({ id: params.settingTypeId });
      reqGetChangeStatus({});
    }
  }, [params.settingType, params.settingTypeId]);

  return (
    <Stack spacing="16px" sx={{ mx: '8px' }}>
      <Flex gap="16px">
        <Button
          iconName="chevron-left"
          onClick={() => {
            navigate(`/engage/sequences/recruitment`);
            dispatch(updateOutreach({ sequencesData: {} }));
          }}
        />
        <Text sx={{ fontSize: '28px', color: '#252930' }}>
          {sequencesData?.title || ''}
        </Text>
      </Flex>

      <SequenceTabs />
    </Stack>
  );
};

export default SubPage;
