import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import moment from 'moment';
import parse from 'html-react-parser';
import { SMSHistoryModalProps } from '../types';
import { useSMSHistoryTableServices } from '../Table/useSMSHistoryTableServices';
import { useNavigate } from 'react-router';

export const SMSHistoryDrawer = ({ isOpen, onClose }: SMSHistoryModalProps) => {
  const navigate = useNavigate();

  const {
    rowId,
    smsHistoryData,
    totalSMSHistoryList,
    handlePrevData,
    handleNextData,
  } = useSMSHistoryTableServices();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/engage/sms/recruitment/sms-history');
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B' }}>
            Showing <b>{rowId}</b> of {totalSMSHistoryList} entries
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() =>
                  rowId !== totalSMSHistoryList && handleNextData()
                }
                disabled={rowId === totalSMSHistoryList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="24px 36px">
          <Stack>
            <Stack spacing="16px" mb="32px">
              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Recipient
                </Text>
                <Stack spacing="0px">
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {[
                      smsHistoryData?.candidate?.first_name,
                      smsHistoryData?.candidate?.last_name,
                    ].join(' ')}
                  </Text>
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {smsHistoryData?.phone}
                  </Text>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Job Title
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {smsHistoryData?.candidate?.latest_job_title}
                  </Text>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Automation Name
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {smsHistoryData?.outreach_sms_automation?.name}
                  </Text>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Trigger
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {smsHistoryData?.outreach_sms_automation?.trigger}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Source List
                </Text>

                <Stack direction="row" spacing="8px">
                  {smsHistoryData?.outreach_sms_automation?.source?.map(
                    (val: any) => {
                      return (
                        <Badge
                          sx={{
                            p: '4px 8px',
                            borderRadius: '2px',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {val?.label}
                        </Badge>
                      );
                    }
                  )}
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Date Sent
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {moment(smsHistoryData?.created_at).format(
                    'MMMM DD, YYYY HH:MM A'
                  )}
                </Text>
              </Stack>
            </Stack>

            <Stack spacing="16px">
              <Text
                sx={{ fontSize: '16px', fontWeight: 600, color: '#2B2D42' }}
              >
                SMS Message
              </Text>
              <Box
                sx={{
                  py: '8px',
                  fontWeight: 300,
                }}
              >
                {parse(
                  smsHistoryData.sms_message || 'No sms message available'
                )}
              </Box>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
