import swal from 'sweetalert';
import { SwalParams } from 'sweetalert/typings/core';
import { swalContent } from '@/types';

const AtsConfirm = async (content: swalContent) => {
  return await swal(content).then((value) => {
    return value;
  });
};

const AtsSwalLoading = () => {
  return swal({
    text: 'Processing...',
    closeOnClickOutside: false, // Prevents closing the alert by clicking outside
    closeOnEsc: false, // Prevents closing the alert using Escape key
    icon: 'info',
    buttons: [false, false],
    content: {
      element: 'div',
      attributes: {
        innerHTML: "<div class='loader'></div>", // Add custom loader HTML if needed
      },
    },
  });
};

const AtsSuccess = () => {
  return swal({ text: 'Success', icon: 'success' });
};

const AtsSwalClose = () => {
  return swal.close();
};

const AtsSwalError = () => {
  return swal({ icon: 'error' });
};

const RejectAts = async (data: any) => {
  let rejectSwal = {
    title: 'Reason for rejection',
    content: {
      element: 'textarea',
      attributes: {
        placeholder: 'Type reason',
      },
    },
    buttons: ['Cancel', 'Reject'],
    icon: 'warning',
  };

  return await swal(rejectSwal).then((value) => {
    let content = (
      document.querySelector('.swal-content__textarea') as HTMLInputElement
    ).value;
    return [value, content];
  });
};

const RejectOptionsAts = async (data: any, options: any) => {
  const options2: any = {
    option1: 'Option 1',
    option2: 'Option 2',
    option3: 'Option 3',
  };

  const inputOptions = Object.keys(options2).reduce((acc: any, key) => {
    acc[key] = options[key];
    return acc;
  }, {});

  let rejectSwal = {
    title: 'Reason for rejection',
    input: 'select',
    inputOptions,
    inputPlaceholder: 'Select reason',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    buttons: ['Cancel', 'Reject'],
    icon: 'warning',
    preConfirm: (selectedOption: any) => {
      // Handle the selected option here if needed
      return selectedOption;
    },
  };

  return await swal(rejectSwal).then((value) => {
    let content = (
      document.querySelector('.swal-content__textarea') as HTMLInputElement
    ).value;
    return [value, content];
  });
};

async function ConfirmWithReason(content: swalContent) {
  return await swal({
    ...content,
    content: {
      element: 'div',
      attributes: {
        innerHTML: `
          <label for="reason-textarea" style="display:block;margin-bottom:4px;text-align:left">Reason <span style="line-height:17.84px;color:#6930CA;">*</span></label>
          <textarea id="reason-textarea" placeholder="Enter a reason" rows="3" style="width:100%;border:1px solid #E7E9ED; padding:12px;" required></textarea>
        `,
      },
    },
    buttons: [true, 'Submit'], // Ensure confirm/cancel buttons are displayed
  }).then(() => {
    const textareaValue = (
      document.getElementById('reason-textarea') as HTMLTextAreaElement
    ).value;
    return textareaValue;
  });
}

export {
  AtsConfirm,
  RejectAts,
  RejectOptionsAts,
  AtsSwalLoading,
  AtsSwalClose,
  AtsSuccess,
  AtsSwalError,
  ConfirmWithReason,
};
