import apiSlice from '@/store/api.slice';
import { CreateSMTPPaylod } from './engage.type';

export const engageMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createSMTP: builder.mutation<any, CreateSMTPPaylod>({
      query: (data) => ({
        url: '/outreach/settings/email/connection/create/smtp',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
    }),
  }),
});

export const { useCreateSMTPMutation } = engageMutation;
