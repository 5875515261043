/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { updateOutreach } from '@/store/outreach.slice';
import { SUPPRESSION_RECORD_SCHEMA } from './constant';

export const useSuppressionModalServices = ({
  isOpen,
  onClose,
  isEdit,
}: any) => {
  const dispatch = useDispatch();

  const { suppressionData } = useSelector((state: any) => state.outreach);

  const initialValues = isEdit
    ? {
        company: suppressionData.company,
        type: suppressionData.type,
        domain: suppressionData.domain,
        expiration_date: suppressionData.expiration_date,
        is_permanent: suppressionData.is_permanent,
        description: suppressionData.description,
      }
    : {
        company: '',
        type: '',
        domain: '',
        expiration_date: '',
        is_permanent: false,
        description: '',
      };

  const validationSchema = SUPPRESSION_RECORD_SCHEMA;

  const handleOnSubmit = (data: any) => {
    console.log(data, 'xx submit');
    dispatch(
      updateOutreach({
        suppressionData: { ...suppressionData, data },
      })
    );

    onClose();
  };

  return {
    initialValues,
    validationSchema,
    isOpen,
    onClose,
    handleOnSubmit,
  };
};
