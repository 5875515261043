import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const resumeParseSlice = createSlice({
  name: 'resumeParse',
  initialState: {
    resumeData: [],
    batches: [],
    batchesPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    batchesPgBtn: {
      page: 1,
      take: 10,
      status: null,
    },
  },
  reducers: {
    listBatch: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listBatch } = resumeParseSlice.actions;
export default resumeParseSlice.reducer;

const resumeParseSliceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetParseList: builder.mutation({
      query: (data) => ({
        url: `/resume-parser/list/batch`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listBatch({ batches: data.data.data }));
          dispatch(
            listBatch({
              batchesPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    reParse: builder.mutation({
      query: ({ data, id }) => ({
        url: `/resume-parser/extract/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listBatch({ resumeData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    getResumeView: builder.mutation({
      query: ({ data, id }) => ({
        url: `/resume-parser/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listBatch({ batches: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    deleteResume: builder.mutation({
      query: ({ id }) => ({
        url: `/resume-parser/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listBatch({ batches: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useGetParseListMutation,
  useReParseMutation,
  useGetResumeViewMutation,
  useDeleteResumeMutation,
} = resumeParseSliceApiSlice;
