import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  MenuDivider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Select as BasicSelect,
  FormErrorMessage,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useToast,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

import {
  useByClientMutation,
  useUpdateLeadsMutation,
} from '@/store/leads.slice';
import { cleanUpload, uploadList } from '@/store/uploads.slice';
import { changeUploadName } from '@/utils/newFilename';
import { useUserListMutation } from '@/store/user.slice';
import { useGetLeadsMutation } from '@/store/leads.slice';

import Button from '@/Library/Button';
import {
  useClientSearchMutation,
  useViewClientMutation,
} from '@/store/client.slice';
import {
  useGetJobSpecsMutation,
  useGetLeadSourceMutation,
} from '@/store/constant.slice';

import ScrollToFieldError from '@/components/app/ScrollError';
import AtsSelect2 from '@/components/app/AtsSelect2';
import AtsDropZone from '@/components/app/Dropzone';
import AtsCraetableSelDyn from '@/components/app/AtsCreatabeSelect/Dyanmic';
import { useContactListMutation } from '@/store/contact.slice';
import AtsSelect from '@/components/app/AtsSelect';
import AddressComponent from '@/components/app/Leads/components/AddressComponent';

import { addressFormat, leadSourceFormat } from '@/utils/utilFormat';

interface ClientsEditContactModalProps {
  isOpen: any;
  onClose: any;
  leadId?: number;
}
export default function ClientsEditContactModal({
  isOpen,
  onClose,
  leadId = 0,
}: ClientsEditContactModalProps) {
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useToast();

  const { leadClient } = useSelector((state: any) => state.leads);

  const { contactData, contactPageBtn } = useSelector(
    (state: any) => state.contacts
  );
  const { uploaded, prefix, withPrefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const { userList } = useSelector((state: any) => state.user);
  const { countries, lead_source, job_specs } = useSelector(
    (state: any) => state.constants
  );

  const [reqLeads] = useGetLeadsMutation();
  const [reqUpdate, resUpdate] = useUpdateLeadsMutation();
  const [reqLeadClient] = useByClientMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqClientData, resClientData] = useViewClientMutation();
  const [reqCompnay, resCompany] = useClientSearchMutation();
  const [reqContacts] = useContactListMutation();
  const [reqSpecs] = useGetJobSpecsMutation();

  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [jobSpecsOption, setJobSpecsOption] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);

  const leadID = params.leadsId;

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const leadClientData: any = Object.values(leadClient).filter(
    (lead: any) => lead.id === contactData.id
  );

  const contactId = params.clientsId;

  useEffect(() => {
    let options: any = [];
    if (job_specs?.length > 0) {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === contactData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  useEffect(() => {
    if (isOpen) {
      reqCompnay({ search: '' });
      reqSpecs({});
    }
  }, [isOpen]);

  useEffect(() => {
    reqUser({}).then((res: any) => {
      let userOption: any = [];
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      res?.data?.data?.map(async (data: any) => {
        if (!managersId?.includes(data.id)) {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        }
      });
      setSelectOption(userOption);
    });
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ withPrefix: false }));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    reqLeadClient({ id: contactData?.client_id });

    if (resUpdate.isSuccess) {
      reqContacts(contactPageBtn);
      reqClientData({ id: contactData?.client_id });
      onClose();

      try {
        const description = `Lead was successfully converted to a client`;

        toast({
          title: 'Request to Convert',
          description: description,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
    if (resUpdate.isError) {
      let errorMessage: any = resUpdate.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMessage(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMessage(errorMessage['data']['message']);
        } catch (e) {
          setErrorMessage('Server Error');
        }
      }
      onOpen();
    }
  }, [
    leadID,
    resUpdate.isError,
    resUpdate.isSuccess,
    onOpen,
    dispatch,
    reqLeadClient,
    contactData?.client_id,
    resUpdate.error,
    onClose,
    contactPageBtn,
    toast,
  ]);

  useEffect(() => {
    if (resCompany.isSuccess) {
      const data: any = resCompany.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
          website: item.website,
          address: item.address,
        });
      });
      setCompanyList(option);
    }
  }, [resCompany.isSuccess]);

  useEffect(() => {
    reqLeads({ id: contactId });
  }, [contactId, reqLeads]);

  useEffect(() => {
    let userOption: any = [];

    if (resClientData.isSuccess) {
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      if (resUser.isSuccess) {
        if (resUser.data.data.length > 0) {
          resUser.data.data.map(async (data: any) => {
            if (!managersId?.includes(data.id)) {
              await userOption.push({
                value: data.id,
                label: [data.first_name, data.last_name].join(' '),
              });
            }
          });
          setSelectOption(userOption);
        }
      }
    }
  }, [
    resClientData?.data?.data?.manager,
    resClientData.isSuccess,
    resUser?.data?.data,
    resUser.isSuccess,
  ]);

  const formValues = {
    first_name: contactData !== undefined ? contactData.first_name : '',
    last_name: contactData !== undefined ? contactData.last_name : '',
    title: contactData !== undefined ? contactData.title : '',
    company:
      contactData !== undefined
        ? {
            value: contactData?.client_id,
            label: contactData?.company,
          }
        : {
            value: '',
            label: '',
          },
    specification_id:
      contactData !== undefined ? contactData.spec_id : (undefined as any),
    email: contactData !== undefined ? contactData.primary_email : '',
    personal_phone: contactData !== undefined ? contactData.personal_phone : '',
    work_phone: contactData !== undefined ? contactData.work_phone : '',
    extension: contactData !== undefined ? contactData.extension : '',
    address: addressFormat(leadClientData[0]?.client.address, countries) || [
      {
        location: '',
        address: '',
        city: '',
        state: '',
        country: countryDefOption,
        zip_code: '',
        primary: true,
        id: null as any,
      },
    ],
    linkedIn_url: contactData !== undefined ? contactData.linked_in_url : '',
    website: contactData !== undefined ? contactData.website : '',
    lead_owner: contactData !== undefined ? contactData.owner_id : 0,
    lead_source:
      contactData !== undefined
        ? leadSourceFormat(
            lead_source.filter(
              (val: any) => val.id === Number(contactData.lead_source_id)
            )[0]
          )
        : '',
    isMultipleAccountManager: 'false',
    manager: {
      value: contactData?.client.manager[0].user?.id,
      label: [
        contactData?.client.manager[0].user?.first_name,
        contactData?.client.manager[0].user?.last_name,
      ].join(' '),
    },
    notes: '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      specification_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      website: Yup.string().required('Website is required.'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),

      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      linkedIn_url: Yup.string(),
      title: Yup.string().required('Title is required.'),
      lead_owner: Yup.string().required('Lead owner is required.'),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      notes: Yup.string(),
      lead_source: Yup.array().min(1, 'Pick at least 1 source').nullable(),
      status: Yup.number(),
    })
  );

  const onSbumit = async (data: any) => {
    data['attachments'] = withPrefix
      ? await changeUploadName(uploaded, prefix)
      : uploaded;
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }

    try {
      delete data['company']['address'];
      delete data['company']['location'];
      delete data['company']['website'];
    } catch (e) {}

    reqUpdate({ data, id: contactData.id });
  };

  const SearchCompany = (e: any) => {
    // if (e.length > 3) {
    reqCompnay({ search: e });
    // }
  };

  const [isDisabledWebsite, setIsDisabledWebsite] = useState(false);

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Contact
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={onSbumit}
              enableReinitialize={true}
            >
              {({
                values,
                setFieldValue,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.title && touched.title)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Title{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Title"
                          variant="outline"
                          value={values.title}
                        />
                        <FormErrorMessage>
                          {String(errors.title)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.specification_id && touched.specification_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Industry{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          name="specification_id"
                          options={jobSpecsOption}
                          component={AtsSelect}
                          value={jobSpecsDefOption}
                          placeholder="Select"
                          onChange={(e: any) => {
                            setJobSpecsDefOption(e);
                            setFieldValue('specification_id', String(e.value));
                          }}
                        />
                        {/* <AtsSelect
                          id="specification_id"
                          name="specification_id"
                          placeholder="Select"
                          variant="outline"
                          onChange={(e: any) =>
                            setFieldValue('specification_id', String(e.value))
                          }
                          value={
                            (job_specs?.length > 0 &&
                              job_specs
                                .map((item: any) => ({
                                  label: item.specification,
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === values.specification_id
                                )[0]) ||
                            values.specification_id
                          }
                          options={
                            job_specs?.length > 0 &&
                            job_specs.map((item: any) => ({
                              label: item.specification,
                              value: item.id,
                            }))
                          }
                        /> */}
                        <FormErrorMessage>
                          {String(errors.specification_id)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.company && touched.company)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Company{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsCraetableSelDyn
                          id="company"
                          name="company"
                          defaultValue={values.company}
                          placeholder="Company"
                          onChange={(event: any) => {
                            reqClientData({ id: event?.value });
                            setFieldValue('company', event);
                            if (event?.address) {
                              setFieldValue('address', event.address);
                            }

                            if (event?.website) {
                              setFieldValue('website', event.website);
                              setIsDisabledWebsite(true);
                            }

                            if (!event) {
                              setFieldValue('address', []);
                              setFieldValue('website', '');
                              setIsDisabledWebsite(false);
                            }
                          }}
                          onInputChange={SearchCompany}
                          isLoading={resCompany.isLoading}
                          options={companyList}
                          isClearable
                        />
                        <FormErrorMessage>
                          {String(errors.company)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.website && touched.website)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Website{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="website"
                          name="website"
                          type="text"
                          placeholder="Website"
                          variant="outline"
                          value={values.website}
                          disabled={values.website !== '' || isDisabledWebsite}
                        />
                        <FormErrorMessage>
                          {String(errors.website)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box>
                      <Text fontWeight={600} mb="34px">
                        Address Information{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </Text>
                    </Box>

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.address && touched.address)}
                      >
                        <FieldArray name="address">
                          {({ remove, push }) => (
                            <div>
                              {values.address &&
                                values.address.length > 0 &&
                                values.address.map(
                                  (job: any, index: number) => (
                                    <Accordion
                                      defaultIndex={[index]}
                                      allowMultiple
                                      key={`jobAdd-${index}`}
                                    >
                                      <AccordionItem className="accordion-item">
                                        <Flex position="relative">
                                          <AccordionButton>
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                            >
                                              <Box
                                                as="span"
                                                fontSize="14px"
                                                lineHeight="18px"
                                                fontWeight={600}
                                                color="default.primarytext"
                                                mb="8px"
                                              >
                                                {job.location
                                                  ? `${job.location}${
                                                      job.primary
                                                        ? ` (Primary Address)`
                                                        : ''
                                                    }`
                                                  : `Address ${index + 1}`}
                                              </Box>
                                            </Box>

                                            <Box>
                                              <AccordionIcon color="#6930CA" />
                                            </Box>
                                          </AccordionButton>
                                          <Flex
                                            gap="15px"
                                            alignItems="center"
                                            position="absolute"
                                            right="55px"
                                            top="0"
                                            bottom="0"
                                          >
                                            <Field
                                              as={Switch}
                                              colorScheme="purple"
                                              name={`address.${index}.primary`}
                                              isChecked={job.primary}
                                              onChange={() =>
                                                handleAddressSelection(
                                                  index,
                                                  setFieldValue,
                                                  values
                                                )
                                              }
                                            />
                                            <Box>
                                              <Button
                                                leftIcon="close"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Button>
                                            </Box>
                                          </Flex>
                                        </Flex>
                                        <AccordionPanel
                                          pb={4}
                                          className="accorion-panel"
                                        >
                                          <AddressComponent
                                            setFieldValue={setFieldValue}
                                            index={index}
                                            handleChange={handleChange}
                                            countryOption={countryOption}
                                            countryField={{
                                              name: `address.${index}.country`,
                                              value: job.country,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.country,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.country,
                                            }}
                                            locationField={{
                                              name: `address.${index}.location`,
                                              value: job.location,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.location,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.location,
                                            }}
                                            addressField={{
                                              name: `address.${index}.address`,
                                              value: job.address,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.address,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.address,
                                            }}
                                            cityField={{
                                              name: `address.${index}.city`,
                                              value: job.city,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.city,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.city,
                                            }}
                                            stateField={{
                                              name: `address.${index}.state`,
                                              value: job.state,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.state,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.state,
                                            }}
                                            zipField={{
                                              name: `address.${index}.zip_code`,
                                              value: job.zip_code,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.zip_code,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.zip_code,
                                            }}
                                          />
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  )
                                )}
                              {!contactData.address && (
                                <Box mt="20px">
                                  <Button
                                    leftIcon="plus"
                                    onClick={() => {
                                      push({
                                        location: '',
                                        address: '',
                                        city: '',
                                        state: '',
                                        country: countryDefOption,
                                        zip_code: '',
                                        primary:
                                          contactData.address?.length === 0 ||
                                          values.address?.length === 0
                                            ? true
                                            : false,
                                      });
                                    }}
                                  >
                                    {values.address && values.address.length > 0
                                      ? 'Add Another Address'
                                      : 'Add Address'}
                                  </Button>
                                </Box>
                              )}
                            </div>
                          )}
                        </FieldArray>
                        {values?.address?.length === 0 && (
                          <FormErrorMessage mt="20px">
                            {String(errors.address)}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>

                    <Box>
                      <Text fontWeight={600} mb="34px">
                        Contact
                      </Text>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.first_name && touched.first_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact First Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            variant="outline"
                            value={values.first_name}
                          />
                          <FormErrorMessage>
                            {String(errors.first_name)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.last_name && touched.last_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact Last Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder="Contact Last Name"
                            variant="outline"
                            value={values.last_name}
                          />
                          <FormErrorMessage>
                            {String(errors.last_name)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.personal_phone && touched.personal_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Personal Phone
                          </FormLabel>
                          <Field
                            as={Input}
                            id="personal_phone"
                            name="personal_phone"
                            type="text"
                            placeholder="Phone Number"
                            variant="outline"
                            value={values.personal_phone}
                          />
                          <FormErrorMessage>
                            {String(errors.personal_phone)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width="100%" gap="32px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_phone && touched.work_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Work Phone
                            </FormLabel>
                            <Input
                              id="work_phone"
                              name="work_phone"
                              type="text"
                              placeholder="Work Phone"
                              variant="outline"
                              value={values.work_phone}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.work_phone)}
                            </FormErrorMessage>
                          </FormControl>
                          <Flex width="30%">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.work_phone && touched.work_phone
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Extension
                              </FormLabel>
                              <Input
                                id="extension"
                                name="extension"
                                type="number"
                                placeholder="Ext"
                                variant="outline"
                                value={values.extension}
                                onChange={(e) => {
                                  setFieldValue(
                                    'extension',
                                    String(e.target.value)
                                  );
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.extension)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.email && touched.email)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Email{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            variant="outline"
                            value={values.email}
                          />
                          <FormErrorMessage>
                            {String(errors.email)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.linkedIn_url && touched.linkedIn_url
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            LinkedIn Link
                          </FormLabel>
                          <Field
                            as={Input}
                            id="linkedIn_url"
                            name="linkedIn_url"
                            type="text"
                            placeholder="LinkedIn Link"
                            variant="outline"
                            value={values.linkedIn_url}
                          />
                          <FormErrorMessage>
                            {String(errors.linkedIn_url)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Text fontWeight={600} mb="34px">
                        Additional Information
                      </Text>

                      <Box my="34px">
                        <FormControl>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Multiple account managers?
                          </FormLabel>
                        </FormControl>

                        <Flex mb="20px">
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'true'}
                            />
                            Yes
                          </FormLabel>
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'false'}
                            />
                            No
                          </FormLabel>
                        </Flex>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.manager && touched.manager
                          )}
                          mb="20px"
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Account Manager{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="manager"
                            isMulti={
                              values.isMultipleAccountManager === 'true'
                                ? true
                                : false
                            }
                            options={selectOption}
                            component={AtsSelect2}
                            defaultValue={values.manager}
                            variant="outline"
                          />

                          <FormErrorMessage>
                            {String(errors.manager)}
                          </FormErrorMessage>
                        </FormControl>

                        <Flex gap="32px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.lead_owner && touched.lead_owner
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Contact Owner{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Field
                              as={BasicSelect}
                              id="lead_owner"
                              name="lead_owner"
                              placeholder="Select"
                              variant="outline"
                              value={values.lead_owner}
                            >
                              {Object.keys(userList).length > 0 ? (
                                Object.values(userList).map((data: any) => {
                                  return (
                                    <option value={data.id}>
                                      {[data.first_name, data.last_name].join(
                                        ' '
                                      )}
                                    </option>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Field>
                            <FormErrorMessage>
                              {String(errors.lead_owner)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.lead_source && touched.lead_source
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Lead Source{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>

                            <Field
                              id="lead_source"
                              name="lead_source"
                              options={leadSrouceList}
                              component={AtsSelect}
                              value={values.lead_source}
                              onChange={(e: any) => {
                                setFieldValue(`lead_source`, e);
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.lead_source)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Box>
                    </Box>
                    <Box mb="34px">
                      <FormControl mb="3">
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Attachments
                        </FormLabel>
                      </FormControl>
                      <AtsDropZone
                        multipleFile={true}
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                    </Box>
                    <Box>
                      <FormLabel
                        mb="8px"
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Notes
                      </FormLabel>
                      <Field
                        as={Textarea}
                        id="notes"
                        name="notes"
                        type="text"
                        placeholder="Here is a sample placeholder"
                        variant="outline"
                        value={values.notes}
                      />
                      <Text
                        mt="8px"
                        fontSize="12px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Maximum 200 characters allowed
                      </Text>
                    </Box>
                  </Box>
                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      disabled={isOpenAlert || uploading}
                      type="submit"
                      onClick={() => {
                        console.log(errors);
                        handleSubmit();
                      }}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
