import moment from 'moment';
import { Box, Stack, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';

import MdEye from '@/assets/images/engage/MdEye.svg';
import MdRefresh from '@/assets/images/engage/MdRefresh.svg';
import MdBounce from '@/assets/images/engage/MdBounce.svg';
import MdArrowRight from '@/assets/images/engage/MdArrowRight.svg';
// import MdUnsubscribe from '@/assets/images/engage/MdUnsubscribe.svg';
// import MdClick from '@/assets/images/engage/MdClick.svg';

import { ViewModal } from './Modal';
import { useActivityLogServices } from './useActivityLogServices';

export const CandidateActivities = () => {
  const { activities, hasView, setView } = useActivityLogServices();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLog = (data: any) => {
    const candidateStatus = data;

    const status = candidateStatus?.sequence_candidate_status?.status;
    const date = candidateStatus?.created_at;
    const step = candidateStatus?.email_sent_log?.touch_no + 1;

    let val;

    switch (status) {
      case 'opened':
        val = {
          image: MdEye,
          step: step,
          date: moment(date).format('MMM DD, YYYY'),
          description: 'Email Opened',
          buttonText: 'View',
          buttonLink: () => {
            onOpen();
            setView(candidateStatus);
          },
        };
        break;
      case 'replied':
        val = {
          image: MdRefresh,
          step: step,
          date: moment(date).format('MMM DD, YYYY'),
          description: 'Email Replied',
          buttonText: 'View',
          buttonLink: () => {
            onOpen();
            setView(candidateStatus);
          },
        };
        break;
      case 'messaged':
        val = {
          image: MdArrowRight,
          step: step,
          date: moment(date).format('MMM DD, YYYY'),
          description: 'Email Sent',
          buttonText: 'View',
          buttonLink: () => {
            onOpen();
            setView(candidateStatus);
          },
        };
        break;
      default:
        val = {
          image: MdBounce,
          step: step,
          date: moment(date).format('MMM DD, YYYY'),
          description: 'Email Bounced',
          buttonText: 'View',
          buttonLink: () => {
            onOpen();
            setView(candidateStatus);
          },
        };
    }

    return (
      <Flex
        sx={{
          alignItems: 'center',
          borderBottom: '1px solid #E6E9EE',
          py: '14px',
        }}
        gap="17px"
      >
        <Image src={val.image} height="50px" width="50px" />
        <Stack spacing="6px">
          <Flex gap="12px">
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: 'primary.800',
              }}
            >
              Step {val.step}
            </Text>
            <Text sx={{ fontSize: '14px', color: '#9B9B9B' }}>{val.date}</Text>
          </Flex>
          <Flex gap="8px">
            <Text
              sx={{
                fontSize: '16px',
                color: '#2B2D42',
              }}
            >
              {val.description}
            </Text>
            <Text
              onClick={val.buttonLink}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
            >
              {val.buttonText}
            </Text>
          </Flex>
        </Stack>
      </Flex>
    );
  };

  return (
    <Box pt="8px">
      <Stack spacing="10px">
        {activities?.length > 0 ? (
          activities?.map((val: any) => {
            return handleLog(val);
          })
        ) : (
          <Text
            sx={{
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '18px',
              color: '#2B2D42',
            }}
          >
            No activities available
          </Text>
        )}
      </Stack>

      {isOpen && <ViewModal isOpen={isOpen} onClose={onClose} data={hasView} />}
    </Box>
  );
};
