import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ALL_TAGS } from '@/constants/tagTypes';
import { BASE_URL } from '@/constants/values';

const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
  tagTypes: ALL_TAGS,
});
export default apiSlice;
