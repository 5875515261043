import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react';
import AtsCraetableSelDyn from '@/components/app/AtsCreatabeSelect/Dyanmic';
import AtsSelect from '@/components/app/AtsSelect';
import FAIcon from '@/components/lib/FAIcon';
import { Field, FieldArray, FieldProps } from 'formik';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useFetchCountriesQuery } from '@/services/common/constants.query';
import { useClientSearchMutation } from '@/store/client.slice';
import { InitialValueType } from '../type';

interface CompanyDetailsPropsType {
  allowAddAddress: boolean;
}
export default function CompanyDetails({
  allowAddAddress,
}: CompanyDetailsPropsType) {
  const [reqCompanyList, resCompanyList] = useClientSearchMutation(); //TODO: query based
  const { data: countryList } = useFetchCountriesQuery({});
  const companyList =
    resCompanyList?.data?.data?.map((data: any) => ({
      ...data,
      value: data.id,
      label: data.name,
    })) ?? [];
  const [disableWebsitInput, setDisableWebsiteInput] = useState(false);
  const countryOption =
    countryList?.map((country: any) => ({
      label: country.country,
      value: country.id,
      code: country.code,
    })) ?? [];

  const handleSearch = debounce((search: string) => {
    reqCompanyList({ search });
  }, 400);

  return (
    <>
      <Text fontWeight={600} mb="34px">
        Company Details
      </Text>
      <Flex gap="32px" mb="34px">
        <Field name="title">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.title && touched.title)}
              isRequired
            >
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                type="text"
                placeholder="Title"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.title)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="company">
          {({
            form: { errors, touched, values, handleChange, setFieldValue },
          }: FieldProps<InitialValueType>) => {
            return (
              <FormControl
                isInvalid={Boolean(!!errors.company && touched.company)}
                isRequired
              >
                <FormLabel>Company</FormLabel>
                <AtsCraetableSelDyn
                  id="company"
                  name="company"
                  defaultValue={values.company}
                  placeholder="Company"
                  onChange={(event: any) => {
                    setFieldValue('company', event);
                    setFieldValue('address', event?.address ?? []);
                    setFieldValue('website', event?.website ?? '');
                    setDisableWebsiteInput(!!event?.website);
                  }}
                  onInputChange={handleSearch}
                  isLoading={resCompanyList.isLoading}
                  options={companyList}
                  isClearable
                />
                <FormErrorMessage>{String(errors.company)}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </Flex>
      <Box>
        <Text fontWeight={600} mb="10px">
          Address Information{' '}
          <Box as="span" color="caution.800">
            *
          </Box>
        </Text>
      </Box>

      {/* TODO: Break from here into another component */}
      <Flex gap="32px" mb="34px">
        <Field name="address">
          {({
            form: { errors, touched, values, handleChange, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.address && touched.address)}
            >
              <FieldArray name="address">
                {({ remove, push }) => (
                  <div>
                    {values.address &&
                      values.address?.length > 0 &&
                      values.address.map((job: any, index: number) => (
                        <Accordion
                          defaultIndex={[index]}
                          allowMultiple
                          key={`jobAdd-${index}`}
                        >
                          <AccordionItem>
                            <Flex position="relative">
                              <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                  <Box
                                    as="span"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    fontWeight={600}
                                    color="default.primarytext"
                                    mb="8px"
                                  >
                                    {job.location
                                      ? `${job.location}${
                                          job.primary
                                            ? ` (Primary Address)`
                                            : ''
                                        }`
                                      : `Address ${index + 1}`}
                                  </Box>
                                </Box>

                                <Box>
                                  <AccordionIcon color="#6930CA" />
                                </Box>
                              </AccordionButton>
                              <Flex
                                gap="15px"
                                alignItems="center"
                                position="absolute"
                                right="55px"
                                top="0"
                                bottom="0"
                              >
                                <Field
                                  as={Switch}
                                  colorScheme="purple"
                                  name={`address.${index}.primary`}
                                  isChecked={job.primary}
                                  onChange={() => {
                                    const address = [...values.address];
                                    address[index].primary =
                                      !address[index]?.primary;
                                    setFieldValue('address', address);
                                  }}
                                />
                                <Box>
                                  <Button
                                    leftIcon={<FAIcon iconName="close" />}
                                    onClick={() => remove(index)}
                                    size="sm"
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Flex>
                            </Flex>
                            <AccordionPanel pb={4}>
                              <div className="row" key={`jobAdd-${index}`}>
                                <Field>
                                  {({
                                    form: {
                                      errors,
                                      touched,
                                      values,
                                      handleChange,
                                    },
                                  }: FieldProps<InitialValueType>) => (
                                    <FormControl
                                      mb="34px"
                                      isInvalid={Boolean(
                                        (errors.address as any[])?.[index]
                                          ?.location &&
                                          (touched.address as any[])?.[index]
                                            ?.location
                                      )}
                                      isRequired
                                    >
                                      <FormLabel>Location Name</FormLabel>
                                      <Field
                                        as={Input}
                                        name={`address.${index}.location`}
                                        placeholder="Location Name"
                                        type="text"
                                        variant="outline"
                                        value={job.location}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(
                                          (errors.address as any[])?.[index]
                                            ?.location
                                        )}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <FormControl
                                  mb="34px"
                                  isInvalid={Boolean(
                                    (errors.address as any[])?.[index]
                                      ?.address &&
                                      (touched.address as any[])?.[index]
                                        ?.address
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Address
                                  </FormLabel>
                                  <Field
                                    as={Input}
                                    name={`address.${index}.address`}
                                    placeholder="Address"
                                    type="text"
                                    variant="outline"
                                    value={job.address}
                                    onChange={handleChange}
                                  />
                                  <FormErrorMessage>
                                    {String(
                                      (errors.address as any[])?.[index]
                                        ?.address
                                    )}
                                  </FormErrorMessage>
                                </FormControl>

                                <Flex gap="32px" mb="34px">
                                  <FormControl
                                    isInvalid={Boolean(
                                      (errors.address as any[])?.[index]
                                        ?.city &&
                                        (touched.address as any[])?.[index]
                                          ?.city
                                    )}
                                    isRequired
                                  >
                                    <FormLabel>City</FormLabel>
                                    <Field
                                      as={Input}
                                      name={`address.${index}.city`}
                                      placeholder="City"
                                      type="text"
                                      variant="outline"
                                      value={job.city}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {String(
                                        (errors.address as any[])?.[index]?.city
                                      )}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={Boolean(
                                      (errors.address as any[])?.[index]
                                        ?.state &&
                                        (touched.address as any[])?.[index]
                                          ?.state
                                    )}
                                    isRequired
                                  >
                                    <FormLabel>State / Province</FormLabel>
                                    <Field
                                      as={Input}
                                      name={`address.${index}.state`}
                                      placeholder="State / Province"
                                      type="text"
                                      variant="outline"
                                      value={job.state}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {String(
                                        (errors.address as any[])?.[index]
                                          ?.state
                                      )}
                                    </FormErrorMessage>
                                  </FormControl>
                                </Flex>
                                <Flex gap="32px" mb="34px">
                                  <FormControl
                                    isInvalid={Boolean(
                                      (errors.address as any[])?.[index]
                                        ?.country &&
                                        (touched.address as any[])?.[index]
                                          ?.country
                                    )}
                                    isRequired
                                  >
                                    <FormLabel color="default.secondarytext">
                                      Country
                                    </FormLabel>
                                    <Field
                                      name={`address.${index}.country`}
                                      options={countryOption}
                                      component={AtsSelect}
                                      defaultValue={job.country}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `address.${index}.country`,
                                          e
                                        );
                                      }}
                                    />
                                    <FormErrorMessage>
                                      {String(
                                        (errors.address as any[])?.[index]
                                          ?.country
                                      )}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={Boolean(
                                      (errors.address as any[])?.[index]
                                        ?.zip_code &&
                                        (touched.address as any[])?.[index]
                                          ?.zip_code
                                    )}
                                  >
                                    <FormLabel>Zip Code</FormLabel>
                                    <Field
                                      as={Input}
                                      name={`address.${index}.zip_code`}
                                      placeholder="Zip Code"
                                      type="text"
                                      variant="outline"
                                      value={job.zip_code}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {String(
                                        (errors.address as any[])?.[index]
                                          ?.zip_code
                                      )}
                                    </FormErrorMessage>
                                  </FormControl>
                                </Flex>
                              </div>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                    {allowAddAddress && (
                      <Box mt="50px">
                        <Button
                          leftIcon={<FAIcon iconName="plus" />}
                          onClick={() => {
                            push({
                              location: '',
                              address: '',
                              city: '',
                              state: '',
                              country: '',
                              zip_code: '',
                              primary: allowAddAddress,
                            });
                          }}
                        >
                          {values.address && values.address?.length > 0
                            ? 'Add Another Address'
                            : 'Add Address'}
                        </Button>
                      </Box>
                    )}
                  </div>
                )}
              </FieldArray>
              {values.address?.length === 0 && (
                <FormErrorMessage mt="20px">
                  {String(errors.address)}
                </FormErrorMessage>
              )}
            </FormControl>
          )}
        </Field>
      </Flex>

      <Flex gap="32px" mb="34px">
        <Field name="linkedIn_url">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.website && touched.website)}
              isRequired
            >
              <FormLabel
                fontSize="14px"
                lineHeight="18px"
                color="default.secondarytext"
              >
                Company Website{' '}
              </FormLabel>
              <Input
                id="website"
                name="website"
                type="text"
                placeholder="Company Website"
                variant="outline"
                value={values.website}
                onChange={handleChange}
                disabled={disableWebsitInput}
              />
              <FormErrorMessage>{String(errors.website)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
