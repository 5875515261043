import {
  Box,
  Center,
  Circle,
  Flex,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import linkedInImg from '@/assets/images/linkedin.svg';
import toUrl from '@/utils/toUrl';
import TimeChip from '../chip/TimeChip';
import { BiMailSend, BiNotepad, BiPhone } from 'react-icons/bi';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LeadsAPIResponseType } from '@/modules/Leads/types';
import EmailsModal from '@/components/app/Global/Email/Lead';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useVirtualizer } from '@tanstack/react-virtual';

interface MyLeadsKanbanProps {
  data: Record<number, LeadsAPIResponseType[]>;
  isLoading: boolean;
  onStageUpdate: (stageId: number, leadId: number) => Promise<any>;
  onOpenDrawer: (colId: number, rowId: number) => void;
}
export default function MyLeadsKanban({
  data,
  isLoading,
  onStageUpdate,
  onOpenDrawer,
}: MyLeadsKanbanProps) {
  const [list, setList] = useState({ ...data });

  function onCardMove(colId: number, rowId: number, dropColId: number) {
    setList((state) => {
      const moveCard = state[colId][rowId]; //card that is moved
      const presentCol = state[colId].filter((_, i) => i !== rowId); //remove from present column
      const newCol = state[dropColId].concat(moveCard); //added to dropped col
      return { ...state, [colId]: presentCol, [dropColId]: newCol };
    });
  }

  useEffect(() => {
    if (!isLoading) {
      setList(data);
    }
  }, [data]);
  if (isLoading) return <KanbanLoader />;
  return (
    <DndProvider backend={HTML5Backend}>
      <Flex h="100%" gap="16px" overflowX={'auto'} px="20px">
        <Column
          header="Unqualified"
          leads={list?.[1] ?? []}
          stageId={1}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
        <Column
          header="Discovery Call"
          leads={list?.[2] ?? []}
          stageId={2}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
        <Column
          header="Job Posts"
          leads={list?.[3] ?? []}
          stageId={3}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
        <Column
          header="Submits"
          leads={list?.[4] ?? []}
          stageId={4}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
        <Column
          header="Interviews"
          leads={list?.[5] ?? []}
          stageId={5}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
        <Column
          header="Converted"
          leads={list?.[6] ?? []}
          stageId={6}
          onStageUpdate={onStageUpdate}
          onOpenDrawer={(rowId: number) => onOpenDrawer(1, rowId)}
        />
      </Flex>
    </DndProvider>
  );
}

function Card({
  lead,
  rowId,
  onOpenDrawer,
}: {
  lead: LeadsAPIResponseType;
  rowId: number;
  onOpenDrawer: () => void;
}) {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'Card',
    item: { id: lead.id, rowId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    previewOptions: {
      captureDraggingState: false,
    },
  }));

  return (
    <Stack
      bgColor="#fff"
      w="300px"
      minH="170px"
      borderRadius={'10px'}
      p="16px"
      gap="10px"
      ref={drag}
      opacity={collected.isDragging ? 0.5 : 1}
    >
      <Stack gap={'2px'}>
        <Flex justifyContent="space-between" alignItems="center" gap="20px">
          <Flex
            gap="6px"
            alignItems="center"
            onClick={onOpenDrawer}
            cursor="pointer"
          >
            <Text isTruncated fontSize="medium" fontWeight={700}>
              {lead?.first_name} {lead?.last_name}
            </Text>
            <Link
              href={toUrl(lead?.linked_in_url)}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                src={linkedInImg}
                alt="linkedin"
                width="16px"
                height="16px"
              />
            </Link>
          </Flex>
          <TimeChip text={lead.created_at} />
        </Flex>
        <Text fontSize={'14px'} lineHeight="19.1px">
          {lead.title}
        </Text>
      </Stack>
      <Stack gap={0}>
        <Link
          href={`clients/my-clients/${lead.client_id}/overview`}
          fontSize={'14px'}
          lineHeight="19.1px"
          color="#718096"
          isTruncated
        >
          {lead.client.name}
        </Link>
        <Link
          href={toUrl(lead.client_job_link)}
          fontSize={'14px'}
          lineHeight="19.1px"
          color="#718096"
          isTruncated
        >
          {lead.client_job_title || lead.client_job_link}
        </Link>
      </Stack>
      <Flex
        justifyContent="space-between"
        pt="8px"
        borderTop="1px solid #E7EDF4"
      >
        <Flex gap="8px" alignItems="flex-end">
          <EmailModalBtn email={lead.primary_email} leadId={lead.id} />
          {Boolean(lead.personal_phone) && (
            <Link href={`tel:${lead.personal_phone}`}>
              <Circle bgColor="#E7EDF4" color="#718096" size={'24px'}>
                <BiPhone />
              </Circle>
            </Link>
          )}
        </Flex>
        {Boolean(lead?.agreements?.length) ? (
          <NavLink to={`/leads/my-leads/${lead.id}/agreements`}>
            <Flex
              alignItems="center"
              gap="4px"
              p={'2px'}
              borderRadius="5px"
              bgColor="#E9D8FD"
              color="#6930CA"
            >
              <BiNotepad />
              <Text fontSize="sm" lineHeight={'16.37px'}>
                Agreement
              </Text>
            </Flex>
          </NavLink>
        ) : null}
      </Flex>
    </Stack>
  );
}

function NoData({ text }: { text: string }) {
  return (
    <Box
      color="#718096"
      bgColor="#fff"
      borderRadius="10px"
      h="100%"
      w="300px"
      p="16px"
      textAlign={'center'}
      fontSize={'14px'}
      lineHeight="19.1px"
    >
      {text}
    </Box>
  );
}
function Column({
  header,
  leads,
  stageId,
  onStageUpdate,
  onOpenDrawer,
}: {
  header: string;
  leads: LeadsAPIResponseType[];
  stageId: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  onStageUpdate: (stageId: number, leadId: number) => Promise<any>;
  onOpenDrawer: (rowId: number) => void;
}) {
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: leads.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 178,
    overscan: 5,
  });
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top',
  });
  const navigate = useNavigate();
  const [collectedProps, drop] = useDrop(() => ({
    accept: 'Card',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: stageId < 5,
      item: monitor.getItem(),
    }),
    drop: (item: any, monitor) => {
      if (stageId < 5) {
        const kanbanUpdate = onStageUpdate(stageId, item.id);
        toast.promise(kanbanUpdate, {
          success: { title: 'The stage has been successfully changed' },
          error: {
            title: 'Update error',
            description: 'Something wrong',
          },
          loading: { title: 'Updating...' },
        });
      }
    },
  }));

  function handleClick(rowId: number, leadId: number) {
    navigate(`/leads/my-leads/${leadId}/details`);
    onOpenDrawer(rowId);
  }

  return (
    <Stack gap="8px" minW="fit-content" ref={drop}>
      <Flex gap="8px" alignItems={'center'}>
        <Text fontSize={'16px'} lineHeight="21.82px" fontWeight={600}>
          {header}
        </Text>
        <Center
          bgColor="rgba(105, 48, 202, 0.08)"
          color="#6930CA"
          borderRadius={'2px'}
          p="4px"
          fontSize={'16px'}
          lineHeight="18px"
          fontWeight={700}
        >
          {leads.length}
        </Center>
      </Flex>
      <Stack
        overflowX="hidden"
        overflowY={'auto'}
        h="100%"
        ref={parentRef}
        w="300px"
      >
        {leads.length ? (
          <Stack
            position="relative"
            height={`${rowVirtualizer.getTotalSize()}px`}
            w="100%"
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => (
              <Box
                key={virtualRow.index}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <Card
                  lead={leads[virtualRow.index]}
                  rowId={virtualRow.index}
                  onOpenDrawer={() =>
                    handleClick(virtualRow.index, leads[virtualRow.index].id)
                  }
                />
              </Box>
            ))}
          </Stack>
        ) : (
          <NoData text={`No ${header.toLowerCase()}`} />
        )}
      </Stack>
    </Stack>
  );
}

function EmailModalBtn({ email, leadId }: { email: string; leadId: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Circle
        bgColor="#E7EDF4"
        color="#718096"
        size={'24px'}
        onClick={onOpen}
        cursor="pointer"
      >
        <BiMailSend />
      </Circle>
      {isOpen ? (
        <EmailsModal
          isOpen={isOpen}
          onClose={onClose}
          email={email}
          id={leadId}
          allowedCategory={['Client', 'Contact', 'Signatures']}
        />
      ) : null}
    </>
  );
}

function KanbanLoader() {
  return (
    <Flex h="100%" gap="16px" overflowX={'auto'}>
      {[1, 2, 3, 4, 5, 6].map((col) => (
        <Skeleton key={col} minW="300px" h="100%" />
      ))}
    </Flex>
  );
}
