import React from 'react';
import { CandidateFilterListInterface } from '@/services//candidates/candidate.type';
import { getColumnsOrderAndVisibility } from './tableUtil';

function sortColumn(obj: Record<string, number>) {
  return Object.entries(obj)
    ?.filter(([_, value]) => value)
    ?.reduce((acc, curr) => {
      acc[curr[0]] = curr[1];
      return acc;
    }, {} as Record<string, number>);
}
export default function filterSelector(filter: CandidateFilterListInterface) {
  const columns = {
    candidate_details: filter.candidate_details,
    contact: filter.contact,
    location: filter.location,
    resume_update_on: filter.resume_update_on,
    work_status: filter.work_status,
    last_activity: filter.last_activity,
    associated_jobs: filter.associated_jobs,
    tags: filter.tags,
    distance: filter.distance,
    submitted_on: filter.submitted_on,
    interview_status: filter.interview_status,
    interview_date: filter.interview_date,
    offered_date: filter.offered_date,
    placement_date: filter.placement_date,
    candidate_start_date: filter.candidate_start_date,
    last_messaged_on: filter.last_messaged_on,
    last_called_on: filter.last_called_on,
  };
  return {
    value: filter.id,
    label: filter.title,
    isPinned: !!filter.pin,
    group_by_job: filter.group_by_job,
    data: {
      title: filter.title,
      module:
        filter?.condition?.map((condition) => ({
          criteria: condition.column.label,
          condition: condition.condition.label,
          query: condition.condition_value,
        })) ?? [],
      share: filter.shareable,
      user:
        filter?.users?.map((user) => ({
          label: `${user?.user?.first_name} ${user?.user?.last_name}`,
          value: user?.user?.id,
        })) ?? [],
      operators:
        filter?.condition?.map((condition) => ({
          id: 0,
          operator: condition.condition_operator,
        })) ?? [],
      group_by_job: filter.group_by_job,
      columns: sortColumn(columns),
      tableCol: getColumnsOrderAndVisibility(columns),
    },
  };
}
