import { CandidateTableColumnsType } from '@/modules/Candidates/types';

export function getColumnsOrderAndVisibility(
  order: Record<CandidateTableColumnsType, number>
) {
  if (!order)
    return {
      order: [] as Array<CandidateTableColumnsType>,
      visibility: {} as Record<CandidateTableColumnsType, boolean>,
    };
  const columnsOrder = Object.entries(order).sort((a, b) => a[1] - b[1]);

  return {
    order: ['select'].concat(
      columnsOrder.filter(([_, value]) => value).map(([key]) => key)
    ) as CandidateTableColumnsType[],
    visibility: columnsOrder
      .filter(([_, value]) => !value)
      .reduce((acc, curr) => {
        acc[curr[0] as CandidateTableColumnsType] = !!curr[1];
        return acc;
      }, {} as Record<CandidateTableColumnsType, boolean>),
  };
}
