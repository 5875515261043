// Importing necessary components and libraries
import { Box, Flex, Stack } from '@chakra-ui/react';
import Button from '@/Library/Button';

// Importing custom components
import CandidatesFilterBar from '@/components/app/Candidates/VerticalFilterBar';
import JobsViewCandidatesTable from './Table';
import ViewCandidateTab from './Tabs';
import TabSearch from './Tabs/Search';
import { useViewCandidatesModuleServices } from './useViewCandidatesModuleServices';

export default function JobsViewCandidatesModule() {
  const {
    isClear,
    handleBackButton,
    jobData,
    locationParse,
    activeTab,
    candidatePgBtn,
    params,
    reloadCount,
    setIsClear,
    hasStatus,
    setReloadCount,
  } = useViewCandidatesModuleServices();

  return (
    <Flex
      gap="8px"
      mt="4px"
      p="8px"
      height="90vh"
      fontFamily="NunitoSans Regular"
    >
      {/* Filter bar for candidates */}
      <CandidatesFilterBar isClear={isClear} />

      {/* Main content area */}
      <Stack
        spacing={0}
        sx={{
          height: '100%',
          w: 'calc(100% - 300px)',
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
      >
        {/* Job title and client details */}
        <Flex gap="20px">
          <Button iconName="chevron-left" onClick={handleBackButton} />
          <Box>
            {/* Job details */}
            <Flex gap={2} fontSize="md" color="default.gray.600">
              <Box fontSize="md" color="default.gray.800">
                {jobData?.title}
              </Box>
              |
              <Box fontSize="md" color="default.gray.800">
                {jobData?.serial_number}
              </Box>
              |
              <Box fontSize="md" color="default.gray.800">
                {locationParse(jobData?.location)}
              </Box>
            </Flex>
            {/* Client details */}
            <Flex
              alignItems="center"
              gap={2}
              fontSize="md"
              color="default.gray.600"
            >
              <Box fontSize="sm" color="default.secondarytext">
                {jobData?.client?.name}
              </Box>
              |
              <Box fontSize="sm" color="default.secondarytext">
                {jobData?.client?.website}
              </Box>
            </Flex>
          </Box>
        </Flex>

        {/* Conditional rendering of tabs based on activeTab */}
        {activeTab === 'search' ? (
          <TabSearch />
        ) : (
          <ViewCandidateTab
            candidatePgBtn={candidatePgBtn}
            job_id={Number(params?.jobId || jobData?.id)}
            reload={reloadCount}
            setIsClear={setIsClear}
          />
        )}

        {/* Table displaying candidates */}
        <JobsViewCandidatesTable
          hasStatus={hasStatus}
          reloadCandidate={(e) => setReloadCount(e)}
        />
      </Stack>
    </Flex>
  );
}
