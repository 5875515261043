/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { Field, Formik } from 'formik';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalFooter,
  Stack,
  Switch,
} from '@chakra-ui/react';

import {
  useGetEmailConnectionListMutation,
  useGetEmailTemplateListMutation,
} from '@/store/outreach.slice';

import AtsSelect from '@/components/app/AtsSelect';

import { useSettingsServices } from './useSettingsServices';

const SettingsList = () => {
  const params = useParams();

  const {
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
    initialValues,
    validationSchema,
    handleOnSubmit,
    isLoading,
  } = useSettingsServices();

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqGetEmailTemplateList] = useGetEmailTemplateListMutation();

  useEffect(() => {
    if (params.settingType === 'settings') {
      reqGetEmailConnectionList(emailConnectionPgBtn);
      reqGetEmailTemplateList(emailTemplatePgBtn);
    }
  }, [params.settingType]);

  return (
    <Box
      fontFamily="NunitoSans Regular"
      sx={{
        bgColor: '#fff',
        borderRadius: '15px',
        margin: '0 auto',
        p: '20px',
        width: '70%',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing="24px" mb="24px">
              <FormControl>
                <Switch
                  name="primary"
                  colorScheme="purple"
                  value={values.primary === true ? 'true' : 'false'}
                  defaultChecked={values.primary}
                  onChange={handleChange}
                >
                  Set as Default
                </Switch>
              </FormControl>

              <Stack direction="row" spacing="8px" mb="34px">
                <FormControl
                  isInvalid={Boolean(
                    !!errors.job_opening && touched.job_opening
                  )}
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Job Opening{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="job_opening"
                    name="job_opening"
                    type="text"
                    variant="outline"
                    value={values.job_opening}
                    disabled
                  />
                  <FormErrorMessage>
                    {String(errors.job_opening)}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(
                    !!errors.sequence_name && touched.sequence_name
                  )}
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Sequence Name{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="sequence_name"
                    name="sequence_name"
                    type="text"
                    placeholder="Sequence Name"
                    variant="outline"
                    value={values.sequence_name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>
                    {String(errors.sequence_name)}
                  </FormErrorMessage>
                </FormControl>
              </Stack>

              <FormControl
                isInvalid={Boolean(!!errors.mailboxes && touched.mailboxes)}
              >
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.secondarytext"
                >
                  Sender Email
                </FormLabel>
                <Field
                  name="mailboxes"
                  options={emailConnectionList?.map((val: any) => {
                    return {
                      value: val?.id,
                      label: val?.email,
                    };
                  })}
                  component={AtsSelect}
                  value={values.mailboxes}
                  placeholder="Select"
                  onChange={(e: any) => {
                    setFieldValue('mailboxes', e);
                  }}
                  isMulti
                />
                <FormErrorMessage>{String(errors.mailboxes)}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(!!errors.template && touched.template)}
              >
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.secondarytext"
                >
                  Email Template{' '}
                  <Box as="span" color="caution.800">
                    *
                  </Box>
                </FormLabel>
                <Field
                  name="template"
                  options={emailTemplateList?.map((val: any) => {
                    return {
                      value: val?.id,
                      label: val?.title,
                    };
                  })}
                  component={AtsSelect}
                  value={values.template}
                  disabled
                />
                <FormErrorMessage>{String(errors.template)}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing="10px">
              <Button
                variant="solid"
                type="submit"
                isLoading={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SettingsList;
