import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const candidatenotesSlice = createSlice({
  name: 'candidates',
  initialState: {
    candidateNotes: [],
    candidateNotesData: null,
    candidateNotePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidateNotePgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listCandidateNotes: (state, { payload }) => ({ ...state, ...payload }),
    setCandidateNotes: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listCandidateNotes, setCandidateNotes } =
  candidatenotesSlice.actions;
export default candidatenotesSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const candidatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateCandidateNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-notes/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListCandidateNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-notes/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listCandidateNotes({
              candidateNotes: { ...data.data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    DelCandidateNote: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-notes/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateCandidateNote: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-notes/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateCandidateNoteMutation,
  useDelCandidateNoteMutation,
  useListCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} = candidatesApiSlice;
