/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGroupCandidatesMutation,
  listCandidates,
  useListCandidatesMutation,
} from '@/store/candidates.slice';
import {
  candidateGroups,
  rowData,
  candidateDataGroup,
  CandidateData,
} from '@/types';

export const useTRservices = () => {
  const dispatch = useDispatch();
  const { candidatePgBtn, candidateGroups, candidateDataJobId } = useSelector(
    (state: any) => state.candidates
  );

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGroups, resGroups] = useGroupCandidatesMutation();

  const [job_id, setJob_Id] = useState(candidatePgBtn?.job_id || 0); //no matter what, this always  starts at zero. putting the candidatePgBtn?.job_id to avoid it

  const [candidateList, setCandidateList] = useState([]);
  const [groupItem, setGroupItem] = useState<candidateGroups | null>(null);
  const [rowId, setRowId] = useState<rowData[]>([]);
  const [groupings, setGroupings] =
    useState<candidateGroups[]>(candidateGroups);
  const [candidateCount, setCandidateCount] = useState(0);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  const abortJobControllerRef = useRef<AbortController | null>(null);

  const abortGroupControllerRef = useRef<AbortController | null>(null);

  const fetchDandidate = async () => {
    const params = {
      ...candidatePgBtn,
      // take: 25, //this causing issue when you already have set your take in the candidaetPgBtn commenting out
      // job_id, // commenting this out, seems job id is always 0
    };

    //making sure job id is not null before calling the api request
    if (params.job_id) {
      if (abortJobControllerRef.current) {
        abortJobControllerRef.current.abort();
      }

      abortJobControllerRef.current = new AbortController();
      reqCandidates({
        data: params,
        signal: abortJobControllerRef.current.signal,
      });
    }
  };

  const reloadGroups = () => {
    const newParam = {
      ...candidatePgBtn,
      job_status: null,
      status: null,
      job_id: null,
      filter: filter,
    };

    if (abortGroupControllerRef.current) {
      abortGroupControllerRef.current.abort();
    }

    abortGroupControllerRef.current = new AbortController();
    reqGroups({
      data: newParam,
      signal: abortGroupControllerRef.current.signal,
    });
    fetchDandidate();
  };

  useEffect(() => {
    if (resGroups.isSuccess) {
      let total: number = 0;
      const data: candidateGroups[] = resGroups.data?.data?.data;
      let candidates: rowData[] = [];
      let groups: candidateGroups[] = [];

      const sorted = [...data].sort(
        (a: candidateGroups, b: candidateGroups) => {
          const titleA = a?.job_title?.toUpperCase(); // ignore upper and lowercase
          const titleB = b?.job_title?.toUpperCase(); // ignore upper and lowercase
          if (titleA < titleB) {
            return -1; // a comes first
          }
          if (titleA > titleB) {
            return 1; // b comes first
          }
          // names must be equal
          return 0;
        }
      );

      let job_ids: number[] = [];
      Promise.all(
        sorted.map((item: candidateGroups) => {
          total += Number(item.total);
          job_ids.push(item.job_id);
          let rowNumber = 0;
          const candidateData: candidateDataGroup[] = item.candidateData;
          let candidateDataItem: candidateDataGroup[] = [];
          item.candidates?.split(',').map((candidate: string) => {
            rowNumber++;
            const info = candidateData.find(
              (cand: candidateDataGroup) => cand.id === Number(candidate)
            );
            candidates.push({
              ...info,
              job_id: item.job_id,
              id: Number(candidate),
              rowId: rowNumber,
            });
            candidateDataItem.push({
              ...info,
              job_id: item.job_id,
              id: Number(candidate),
              rowId: rowNumber,
            });
          });
          groups.push({
            ...item,
            candidateData: candidateDataItem,
          });
        })
      );
      dispatch(listCandidates({ candidateGroups: groups }));
    }
  }, [resGroups.data?.data, resGroups.isSuccess]);

  useEffect(() => {
    if (resCandidates.isSuccess) {
      const data = resCandidates.data?.data;

      const candidates = data?.data || [];
      setCandidateList(candidates);
      const find: candidateGroups = groupItem;
      const currentCandidate =
        (find?.candidateData as rowData[]) || ([] as rowData[]);

      let rowList: rowData[] = [];
      let unChartedCandidates: rowData[] = [];
      candidates.map((item: CandidateData, key: number) => {
        const lookup = currentCandidate.find(
          (cand: rowData) => cand.id === item.id
        );
        rowList.push({
          ...lookup,
          rowId: key + 1,
          id: item.id,
          job_id,
          first_name: item.first_name,
          last_name: item.last_name,
        });
      });

      find?.candidateData?.map((item: candidateDataGroup) => {
        const lookup = rowList.find((row: rowData) => row.id == item.id);

        if (!lookup) {
          unChartedCandidates.push(item as rowData);
        }
      });

      let combined = [...rowList, ...unChartedCandidates];

      let formatted: rowData[] = [];
      combined.map((item: rowData, key: number) => {
        formatted.push({
          ...item,
          rowId: key + 1,
        });
      });

      setRowId(formatted);

      let newCandidates: candidateDataGroup[] = [];
      find?.candidateData?.map((data: candidateDataGroup) => {
        const lookup = candidates.find(
          (item: candidateDataGroup) => item.id === data.id
        );
        if (lookup) {
          newCandidates.push(data);
        }
      });

      setCandidateCount(groupItem?.total || 0);
      if (candidateDataJobId == job_id) {
        // console.log({ newGroups });
        // await dispatch(listCandidates({ candidateGroups: newGroups }));
        // setFirstLoad(false);
      }
    }
  }, [
    candidateDataJobId,
    groupItem,
    job_id,
    resCandidates.data?.data,
    resCandidates.isSuccess,
  ]);

  return {
    reloadGroups,
    job_id,
    setJob_Id,
    candidateList,
    setCandidateList,
    rowId,
    setRowId,
    candidateCount,
    setCandidateCount,
    groupings,
    setGroupings,
    groupItem,
    setGroupItem,
    fetchDandidate,
  };
};
