import moment from 'moment';
import { Formik } from 'formik';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import AtsSelect3 from '@/components/app/AtsSelect3';
import { timeOptions, timezoneOptions } from './constants';

import { useDefaultScheduleServices } from './useDefaultSchedule';
import { useEffect } from 'react';

const DefaultSchedule = () => {
  const { initialValues, validationSchema, handleSubmit, isLoading } =
    useDefaultScheduleServices();

  useEffect(() => {}, [initialValues]);

  return (
    <Box
      p={4}
      sx={{ bg: '#FFF', border: '1px solid #EEEEEE', borderRadius: '6px' }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <VStack align="stretch" spacing={4}>
                <Box>
                  <Stack spacing="24px">
                    <FormControl id="time-zone">
                      <FormLabel>
                        Set the Timezone{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <AtsSelect3
                        id="timezone"
                        name="timezone"
                        variant="outline"
                        value={{
                          label: values.timezone,
                          value: values.timezone,
                        }}
                        onChange={(e: any) => {
                          setFieldValue('timezone', e.value);
                        }}
                        options={timezoneOptions}
                      />
                    </FormControl>

                    {/* Monday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Monday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="monday_start"
                            name="monday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('monday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.monday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.monday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>

                        <FormControl>
                          <AtsSelect3
                            id="monday_end"
                            name="monday_end"
                            placeholder="00:00 PM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('monday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(values.monday_end, 'HH:mm').format(
                                'hh:mm A'
                              ),
                              value: moment(
                                values.monday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Tuesday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Tuesday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="tuesday_start"
                            name="tuesday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('tuesday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.tuesday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.tuesday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="tuesday_end"
                            name="tuesday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('tuesday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(values.tuesday_end, 'HH:mm').format(
                                'hh:mm A'
                              ),
                              value: moment(
                                values.tuesday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Wednesday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Wednesday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="wednesday_start"
                            name="wednesday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('wednesday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.wednesday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.wednesday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="wednesday_end"
                            name="wednesday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('wednesday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.wednesday_end,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.wednesday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Thursday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Thursday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="thursday_start"
                            name="thursday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('thursday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.thursday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.thursday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="thursday_end"
                            name="thursday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('thursday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.thursday_end,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.thursday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Friday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Friday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="friday_start"
                            name="friday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('friday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.friday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.friday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="friday_end"
                            name="friday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('friday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(values.friday_end, 'HH:mm').format(
                                'hh:mm A'
                              ),
                              value: moment(
                                values.friday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Saturday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Saturday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="saturday_start"
                            name="saturday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('saturday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.saturday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.saturday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="saturday_end"
                            name="saturday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('saturday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.saturday_end,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.saturday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {/* Sunday */}
                    <Stack justify="space-between">
                      <Text fontWeight="medium">Sunday</Text>
                      <Stack direction="row">
                        <FormControl>
                          <AtsSelect3
                            id="sunday_start"
                            name="sunday_start"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('sunday_start', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(
                                values.sunday_start,
                                'HH:mm'
                              ).format('hh:mm A'),
                              value: moment(
                                values.sunday_start,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <AtsSelect3
                            id="sunday_end"
                            name="sunday_end"
                            placeholder="00:00 AM"
                            variant="outline"
                            onChange={(e: any) => {
                              setFieldValue('sunday_end', e.value);
                            }}
                            isMulti={false}
                            options={timeOptions}
                            value={{
                              label: moment(values.sunday_end, 'HH:mm').format(
                                'hh:mm A'
                              ),
                              value: moment(
                                values.sunday_end,
                                'hh:mm A'
                              ).format('HH:mm'),
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
                <Stack direction="row" justify="end" mt="32px">
                  <Button variant="solid" type="submit" isLoading={isLoading}>
                    Save Changes
                  </Button>
                </Stack>
              </VStack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default DefaultSchedule;
