import { Flex, Stack } from '@chakra-ui/react';
import { SideBar } from '@/components/app/Inboxes/SideBar';
import { CalendarPage } from './Calendar';
import { EmailsPage } from './Emails';
import { GeneralTaskPage } from './GeneralTask';
import { PhoneCallsPage } from './PhoneCalls';
import { SMSPage } from './SMS';
import { useInboxesServices } from './useInboxesServices';

export const InboxesPage = () => {
  const { sidebarIndex, setSidebarIndex } = useInboxesServices();
  return (
    <Flex
      gap="28px"
      sx={{ bgColor: '#F9F9FB', fontFamily: 'NunitoSans Regular' }}
    >
      <SideBar setSidebarIndex={setSidebarIndex} />
      <Stack sx={{ width: '85%' }}>
        {sidebarIndex === 0 && <EmailsPage />}
        {/* {sidebarIndex === 1 && <SMSPage />}
        {sidebarIndex === 2 && <PhoneCallsPage />}
        {sidebarIndex === 3 && <GeneralTaskPage />}
        {sidebarIndex === 4 && <CalendarPage />} */}
      </Stack>
    </Flex>
  );
};
