import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserListMutation } from '@/store/user.slice';
import { MemberType } from './types';

export default function useModalTeamsServices({}) {
  const { userList } = useSelector((state: any) => state.user);

  const [reqUsers, resUsers] = useUserListMutation();

  const [userOptions, setUserOptions] = useState<any[]>([]);

  const formRef = useRef<any>(null);

  const handleOptionChange = (
    fieldName: string,
    event: any,
    setFieldValue: any
  ) => {
    console.log('event ', event);
    let value = event;
    if (fieldName === 'manager') {
      value = [event];
    }
    setFieldValue(fieldName, value);
  };

  const handleOptionValue = (members: MemberType[]) => {
    const data = userOptions.filter((user: any) => {
      return members?.some((member: MemberType) => {
        return user.user_id === member.user_id;
      });
    });
    console.log('Data ', data);
    return data;
  };

  const handleOptions = (members: MemberType[], manager: MemberType[]) => {
    const merged = [...members, ...manager];

    const data = userOptions.filter((user: any) => {
      return !merged?.some((member: MemberType) => {
        return user.user_id === member.user_id;
      });
    });

    console.log('data', data);

    return data;
  };

  const loadConstants = async () => {
    await reqUsers({});
  };
  useEffect(() => {
    loadConstants();
  }, []);

  useEffect(() => {
    if (Object.values(userList).length > 0) {
      setUserOptions(
        Object.values(userList).map((user: any) => ({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          user_id: user.id,
          label: [user.first_name, user.last_name].filter(Boolean).join(' '),
          value: user.id,
        }))
      );
    }
  }, [userList]);

  return {
    handleOptions,
    formRef,
    handleOptionValue,
    handleOptionChange,
  };
}
