import { swalContent } from '@/types';

export const content: swalContent = {
  title: 'Are you sure?',
  text: 'Do you really want to delete this agreement?',
  buttons: ['Cancel', true],
  icon: 'warning',
};

export const tableHeader: { label: string }[] = [
  { label: 'Name Of Agreement' },
  { label: 'Signed On' },
  { label: 'Valid Until' },
  { label: '' },
];
