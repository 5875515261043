import { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  ChakraStyledOptions,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useGetCandidatesMutation } from '@/store/candidates.slice';
import { useTRservices } from '../../../Table/useTRservices';

//TODO: Remove direct api call dependency

function DynamicInputWidthForm({
  sx,
  value,
  onSubmit,
  placeholder,
}: {
  sx: ChakraStyledOptions;
  value: string;
  onSubmit: (data: string) => void;
  placeholder: string;
}) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);
  return (
    <Editable
      defaultValue={value}
      value={inputValue}
      placeholder={placeholder}
      sx={{ ...sx, w: 'fit-content', p: 0 }}
      onSubmit={(data) => {
        if (data !== value) {
          onSubmit(data);
        }
      }}
      onChange={(e) => {
        setInputValue(e);
      }}
    >
      <EditablePreview />
      <EditableInput
        sx={{
          border: '0px solid',
          '&: focus-visible': {
            boxShadow: 'none',
            border: '1px solid ',
            borderColor: 'primary.600',
          },
          ...sx,
          w: 'fit-content',
          p: 0,
        }}
      />
    </Editable>
  );
}

export default DynamicInputWidthForm;
