import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUnparsedResumeByJobMutation } from '@/store/candidateresume.slice';
import { useGetJobsMutation } from '@/store/jobs.slice';
import { JobDetailInt, ManualResumeProps } from './types';

export function useManualResumeServices({
  onClose,
  job_id,
}: ManualResumeProps) {
  const [reqUnparsedList] = useUnparsedResumeByJobMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();

  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [selected, setSelected] = useState<number>(0);
  const [drawerJobDetails, setDrawerJobDetails] = useState<JobDetailInt>({
    jobTitle: '',
    jobLink: '',
    clientTitle: '',
    clientLink: '',
  });

  const loadConstant = async () => {
    await reqUnparsedList({ id: job_id });
    await reqGetJob({ id: job_id });
  };
  useEffect(() => {
    loadConstant();
  }, [job_id]);

  useEffect(() => {
    if (resGetJob.isSuccess) {
      const jobData = resGetJob?.data?.data?.data;
      if (jobData) {
        setDrawerJobDetails({
          jobTitle: jobData?.title,
          jobLink: `/jobs/${jobData?.id}/details`,
          clientTitle: jobData?.client?.name,
          clientLink: `/clients/${jobData?.client?.id}/overview/all-clients`,
        });
      }
    }
  }, [resGetJob.isSuccess]);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleClose = () => {
    onClose();
  };
  return {
    handleClose,
    handleSelect,
    selected,
    reqUnparsedList,
    drawerJobDetails,
    resumes,
  };
}
