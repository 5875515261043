import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const signatureSlice = createSlice({
  name: 'signature',
  initialState: {
    signatureRawList: [],
    signaturesList: [],
    placehodlers: [],
    signatureData: {},
    signaturePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    signaturePageBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listSignature: (state, { payload }) => ({ ...state, ...payload }),
    setPairSignature: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listSignature, setPairSignature } = signatureSlice.actions;
export default signatureSlice.reducer;

const signatureApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSignature: builder.mutation({
      query: (data) => ({
        url: `/email-signature/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListSignature: builder.mutation({
      query: (data) => ({
        url: `/email-signature/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ signaturesList: data.data.data }));
          dispatch(
            listSignature({
              signaturePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    updateSignature: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-signature/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    deActivateSignature: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-signature/de-activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ActivateSignature: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-signature/activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DelSignature: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-signature/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    RawListSignature: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-signature/raw-list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listSignature({ signatureRawList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateSignatureMutation,
  useListSignatureMutation,
  useUpdateSignatureMutation,
  useActivateSignatureMutation,
  useDeActivateSignatureMutation,
  useDelSignatureMutation,
  useRawListSignatureMutation,
} = signatureApiSlice;
