import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const emailGroupSlice = createSlice({
  name: 'email_group',
  initialState: {
    groupList: [],
    groups: [],
    groupData: {},
    groupPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    groupPageBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listGroupTemplate: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listGroupTemplate } = emailGroupSlice.actions;
export default emailGroupSlice.reducer;

const templateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation({
      query: (data) => ({
        url: `/email-group/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listGroupTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListGroup: builder.mutation({
      query: (data) => ({
        url: `/email-group/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(listGroupTemplate({ groupList: data.data.data }));
          dispatch(
            listGroupTemplate({
              groupPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ViewGroup: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-group/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listGroupTemplate({ groupData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateGroup: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-group/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteGroup: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-group/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    Groups: builder.mutation({
      query: (data) => ({
        url: `/email-group/group`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(listGroupTemplate({ groups: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateGroupMutation,
  useListGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useViewGroupMutation,
  useGroupsMutation,
} = templateApiSlice;
