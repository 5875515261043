import { Container, Image } from '@chakra-ui/react';

import Loading from '@/assets/images/loading.svg';

const LoadingPage = () => {
  return (
    <Container
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: 'fixed',
        width: '100%',
        maxWidth: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: '#ffffffc7',
        zIndex: 9999999999,
      }}
    >
      <Image src={Loading} height="10%" />
    </Container>
  );
};

export default LoadingPage;
