import { useEffect, useState } from 'react';
import { usePostBuzzLoginMutation } from '@/store/outreach.slice';

interface useBuzzConnectionLoginServicesProps {
  handleLogin: () => void;
}

export const useBuzzConnectionLoginServices = ({
  handleLogin,
}: useBuzzConnectionLoginServicesProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [reqPostBuzzLogin, resPostBuzzLogin] = usePostBuzzLoginMutation();

  const handleInputUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    const fetchLogin = async () => {
      if (username && password) {
        const payload = {
          username,
          password,
        };
        await reqPostBuzzLogin(payload);
      }
    };

    fetchLogin();
  };

  useEffect(() => {
    if (resPostBuzzLogin.isSuccess) {
      setUsername('');
      setPassword('');

      handleLogin();
    }
  }, [resPostBuzzLogin.isSuccess]);

  return {
    username,
    password,
    handleInputUsername,
    handleInputPassword,
    handleSubmit,
    isLoading: resPostBuzzLogin.isLoading,
  };
};
