import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const msGraphSlice = createSlice({
  name: 'msgraph',
  initialState: {
    graphUrl: '',
    graphAccessToken: '',
    graphEndPoint: '',
    msAccount: [],
  },
  reducers: {
    getMsGraph: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getMsGraph } = msGraphSlice.actions;
export default msGraphSlice.reducer;

const msGraphApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    MsGraphLogin: builder.mutation({
      query: (data) => ({
        url: `/ms-graph/login`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    MsGraphLogout: builder.mutation({
      query: (data) => ({
        url: `/ms-graph/logout`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    MsGraphGetToken: builder.mutation({
      query: (data) => ({
        url: `/ms-graph/get-token`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const res: any = data.data;
          dispatch(getMsGraph({ graphAccessToken: res?.accessToken }));
          dispatch(getMsGraph({ graphEndPoint: res?.endPoint }));
          localStorage.setItem('graphAccessToken', res?.accessToken);
          localStorage.setItem('graphEndPoint', res?.endPoint);
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    MsGraphMe: builder.mutation({
      query: (data) => ({
        url: `/ms-graph/me`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const res: any = data.data;
          console.log('data', data);
          dispatch(getMsGraph({ msAccount: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useMsGraphLoginMutation,
  useMsGraphGetTokenMutation,
  useMsGraphMeMutation,
  useMsGraphLogoutMutation,
} = msGraphApiSlice;
