import LoadingPage from '@/components/app/Loading';
import { BuzzConnectionLogin } from './Login';
import { BuzzConnectionSetup } from './Setup';
import { useBuzzConnectionServices } from './useBuzzConnectionServices';

export const BuzzConnection = () => {
  const { isLoggedin, handleLogin, hasSetup, setHasSetup, isLoading } =
    useBuzzConnectionServices();

  return isLoading ? (
    <LoadingPage />
  ) : !isLoggedin ? (
    <BuzzConnectionLogin handleLogin={handleLogin} />
  ) : (
    <BuzzConnectionSetup
      handleLogin={handleLogin}
      hasSetup={hasSetup}
      setHasSetup={setHasSetup}
    />
  );
};
