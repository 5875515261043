import React from 'react';
import { useSelector } from 'react-redux';
import { CandidateListPayload } from '@/services//candidates/candidate.type';

function getValueArr(arr: { label: string; value: number }[]) {
  if (!arr) return [];
  return arr?.map((tag: { label: string; value: number }) => tag.value);
}

interface FilterProps {
  page?: number;
  take?: number;
  job_id?: number;
}
export default function useFilter({
  take = 50,
  page = 1,
  job_id = null,
}: FilterProps): CandidateListPayload {
  const filter = useSelector((state: any) => state.candidates.filter);
  const groupFilter = useSelector((state: any) => state.candidates.groupFilter);

  return {
    page: page || 1,
    take: take || 50,
    experience: [],
    tags: getValueArr(filter?.tags),
    work_status: getValueArr(filter?.workStatus),
    resume_updated_on: filter?.resumeUpdatedOn?.value,
    has_resume: filter.isResumePresent,
    boolean: true,
    search: filter.booleanSearch,
    query: filter.search,
    location: filter.locationSearch,
    orderBy: filter.orderBy,
    zip_code: {
      code: filter?.zipCode?.value,
      distance: filter?.miles?.value,
    },
    source: filter.candidateSource?.value,
    sub_source: filter.candidateSubSource?.value,
    job_filter: filter.jobTitle,
    excluded_job_id: null,
    job_filter_include:
      filter?.include?.length === 1
        ? filter?.include?.at(0)
        : 'Current or Past',
    status: null,
    job_id,
    job_status: null,
    filter: groupFilter?.value ?? null,
    reject_reason: null,
    skills: [],
  };
}
