/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
  usePostConnectToOutlookGetTokenMutation,
  usePostConnectToOutlookMutation,
} from '@/store/outreach.slice';
import { tabOptions } from '../../constants';

export const useEmailConnectionOutlookServices = ({ onOpenSMTP }: any) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const isEmailConnection = params.settingType === tabOptions[2];

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostConnectToOutlook, resPostConnectToOutlook] =
    usePostConnectToOutlookMutation();
  const [reqPostConnectToOutlookGetToken, resPostConnectToOutlookGetToken] =
    usePostConnectToOutlookGetTokenMutation();

  const { emailConnectionUrl, emailConnectionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const handleConnectToOutlook = () => {
    reqPostConnectToOutlook({});
  };

  const emailTokenData: string | null =
    localStorage.getItem('emailConnectionUrl');
  const initEmailToken =
    emailTokenData == null ? emailConnectionUrl : emailTokenData;

  useEffect(() => {
    if (resPostConnectToOutlook.isSuccess && initEmailToken !== null) {
      window.location.replace(resPostConnectToOutlook.data.data.url);
    }
  }, [resPostConnectToOutlook.isSuccess, initEmailToken]);

  const [emailCode, setEmailCode] = useState('');

  const getAccessToken = (hash: any) => {
    const urlParams = new URLSearchParams(hash);

    // Get the values of the parameters
    const code = urlParams.get('code');
    const clientInfo = urlParams.get('client_info');
    const state = urlParams.get('state');
    const sessionState = urlParams.get('session_state');

    setEmailCode(code);

    const accessToken = { code, clientInfo, state, sessionState };

    localStorage.setItem('emailConnectionUrl', null);
    dispatch(
      updateOutreach({
        emailConnectionUrl: null,
      })
    );

    return accessToken;
  };

  const [isGetAccessToken, setIsGetAccessToken] = useState(false);

  useEffect(() => {
    if (isEmailConnection && initEmailToken !== null) {
      if (location.hash) {
        setIsGetAccessToken(true);
      }
    }
  }, [isEmailConnection, initEmailToken, location]);

  useEffect(() => {
    if (isGetAccessToken) {
      const hash = window.location.hash.replace('#code', '?code');
      const accessToken = getAccessToken(hash);

      reqPostConnectToOutlookGetToken(accessToken);
    }
  }, [isGetAccessToken]);

  useEffect(() => {
    if (resPostConnectToOutlookGetToken.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn).then((data: any) => {
        const hasEmail = data?.data?.data?.data?.filter(
          (val: any) => val?.session?.authCodeRequest?.code === emailCode
        )[0];
        dispatch(
          updateOutreach({
            emailConnectionData: hasEmail,
          })
        );
      });

      onOpenSMTP();

      toast({
        title: 'Email connection list updated.',
        description: "We've connected your Outlook.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostConnectToOutlookGetToken.isSuccess]);

  return {
    handleConnectToOutlook,
    isLoading: resPostConnectToOutlook.isLoading,
  };
};
