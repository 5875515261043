/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePostAssociateEmailSequenceMutation } from '@/store/outreach.slice';
import { SETTINGS_SCHEMA } from './constant';

export const useSettingsServices = () => {
  const toast = useToast();

  const [reqPostAssociateEmailSequence, resPostAssociateEmailSequence] =
    usePostAssociateEmailSequenceMutation();

  const {
    sequencesData,
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
  } = useSelector((state: any) => state.outreach);

  const initialValues = {
    primary: sequencesData?.primary,
    job_opening: sequencesData?.job?.title,
    sequence_name: sequencesData?.title || '',
    mailboxes:
      (sequencesData?.mailboxes &&
        sequencesData?.mailboxes?.length > 0 &&
        sequencesData?.mailboxes?.map((val: any) => {
          return {
            value: val?.outreach_email_connection?.id,
            label: val?.outreach_email_connection?.email,
          };
        })) ||
      [],
    template:
      [
        {
          value: sequencesData?.outreach_email_template?.id,
          label: sequencesData?.outreach_email_template?.title,
        },
      ] || [],
  };

  const validationSchema = SETTINGS_SCHEMA;

  const handleOnSubmit = (data: any) => {
    const params = {
      primary: data.primary,
      sequence_name: data.sequence_name,
      mailboxes:
        (data.mailboxes &&
          data.mailboxes?.length > 0 &&
          data.mailboxes?.map((val: any) => {
            return val.value;
          })) ||
        [],
    };
    reqPostAssociateEmailSequence({ data: params, id: sequencesData.id });

    console.log(data, 'xx submit');
  };

  useEffect(() => {
    if (resPostAssociateEmailSequence.isSuccess) {
      toast({
        title: 'Settings updated.',
        description: "We've updated your settings.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostAssociateEmailSequence.isSuccess]);

  return {
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
    initialValues,
    validationSchema,
    handleOnSubmit,
    isLoading: resPostAssociateEmailSequence.isLoading,
  };
};
