import {
  Stack,
  Button,
  Text,
  RadioGroup,
  Radio,
  Flex,
  Image,
} from '@chakra-ui/react';
import { useBuzzConnectionSetupServices } from './useBuzzConnectionSetupServices';

import Logout from '@/assets/images/engage/Logout.svg';

interface BuzzConnectionSetupProps {
  handleLogin: () => void;
  hasSetup: boolean;
  setHasSetup: any;
}

export const BuzzConnectionSetup = ({
  handleLogin,
  hasSetup,
  setHasSetup,
}: BuzzConnectionSetupProps) => {
  const {
    selectCredential,
    selectCampaign,
    handleLogout,
    handleSelectCredential,
    handleSelectCampaign,
    handleSubmit,
    handleUpdate,
    buzzData,
    isLoadingSubmit,
    isLoadingDisconnect,
  } = useBuzzConnectionSetupServices({ handleLogin, hasSetup, setHasSetup });

  return (
    <Stack spacing="16px" fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end">
        <Button
          variant="skeleton"
          onClick={handleLogout}
          isLoading={isLoadingDisconnect}
          sx={{ border: 'none' }}
        >
          <Flex
            gap="10px"
            alignItems="center"
            sx={{
              bgColor: '#fff',
              border: '1px solid #E7EDF4',
              borderRadius: '40px',
              p: '8px 16px',
              width: 'max-content',
              cursor: 'pointer',
            }}
          >
            <Text sx={{ fontWeight: 700, color: '#2B2D42' }}>
              {buzzData?.buzz_connection?.email}
            </Text>
            <Image src={Logout} />
          </Flex>
        </Button>
      </Flex>

      {!hasSetup ? (
        <>
          <Flex gap="16px">
            <Stack
              w="50%"
              h="80vh"
              spacing="16px"
              sx={{
                overflowY: 'scroll',
                bgColor: '#fff',
                border: '1px solid #E7EDF4',
                borderRadius: '5px',
                p: '16px',
              }}
            >
              <Text
                sx={{
                  borderBottom: '1px solid #E7EDF4',
                  pb: '8px',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#718096',
                }}
              >
                Select Credentials
              </Text>

              <RadioGroup value={selectCredential}>
                {buzzData?.credentials?.map((item: any) => {
                  return (
                    <Stack justifyContent="flex-start" mb="16px">
                      <Radio
                        colorScheme="purple"
                        value={item._id}
                        onChange={() => handleSelectCredential(item._id)}
                      >
                        <Stack spacing={0}>
                          <Text sx={{ color: '#2B2D42' }}>
                            {item.full_name}
                          </Text>
                          <Text sx={{ color: '#718096' }}>{item.username}</Text>
                        </Stack>
                      </Radio>
                    </Stack>
                  );
                })}
              </RadioGroup>
            </Stack>

            <Stack
              w="50%"
              h="80vh"
              spacing="16px"
              sx={{
                overflowY: 'scroll',
                bgColor: '#fff',
                border: '1px solid #E7EDF4',
                borderRadius: '5px',
                p: '16px',
              }}
            >
              <Text
                sx={{
                  borderBottom: '1px solid #E7EDF4',
                  pb: '8px',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#718096',
                }}
              >
                Select Campaign
              </Text>

              <RadioGroup value={selectCampaign}>
                {buzzData?.campaigns?.map((item: any) => {
                  return (
                    <Stack justifyContent="flex-start" mb="16px">
                      <Radio
                        colorScheme="purple"
                        value={item._id}
                        onChange={() => handleSelectCampaign(item._id)}
                      >
                        <Text>{item.name}</Text>
                      </Radio>
                    </Stack>
                  );
                })}
              </RadioGroup>
            </Stack>
          </Flex>

          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="solid"
              type="button"
              sx={{ width: 'max-content', mt: '8px' }}
              onClick={handleSubmit}
              isLoading={isLoadingSubmit}
            >
              Submit
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Stack
            spacing="16px"
            sx={{
              bgColor: '#fff',
              border: '1px solid #E7EDF4',
              borderRadius: '5px',
              p: '16px',
            }}
          >
            <Text
              sx={{
                borderBottom: '1px solid #E7EDF4',
                pb: '8px',
                fontSize: '16px',
                fontWeight: 600,
                color: '#718096',
              }}
            >
              Details
            </Text>

            <Stack spacing="4px">
              <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>
                Credentials:
              </Text>
              {buzzData?.credentials
                ?.filter((item: any) => item._id === selectCredential)
                .map((val: any) => {
                  return (
                    <Text sx={{ fontSize: '14px', color: '#718096' }}>
                      {val.username}
                    </Text>
                  );
                })}
            </Stack>

            <Stack spacing="4px">
              <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>Campaign:</Text>
              {buzzData?.campaigns
                ?.filter((item: any) => item._id === selectCampaign)
                .map((val: any) => {
                  return (
                    <Text sx={{ fontSize: '14px', color: '#718096' }}>
                      {val.name}
                    </Text>
                  );
                })}
            </Stack>
          </Stack>

          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="solid"
              type="button"
              sx={{ width: 'max-content', mt: '8px' }}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Flex>
        </>
      )}
    </Stack>
  );
};
