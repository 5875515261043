import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import FAIcon from '@/components/lib/FAIcon';
import {
  useGetRingCentralMutation,
  useSetRingCentralMutation,
} from '@/store/ringcentral.slice';
import ScrollToFieldError from '@/components/app/ScrollError';

export default function RingCentralAuth() {
  return (
    <Box fontFamily="NunitoSans Regular">
      <Box
        bg="white"
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
      ></Box>
    </Box>
  );
}
