import { createSlice } from '@reduxjs/toolkit';
import apiSlice from './api.slice';
import { appError } from './app.slice';

export const candidatetagsSlice = createSlice({
  name: 'candidates',
  initialState: {
    candidateTags: [],
    candidateTagPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidateTagPgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listCandidateTag: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listCandidateTag } = candidatetagsSlice.actions;
export default candidatetagsSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const candidatesTagApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateCandidateTag: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-tags/insert/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Candidates-List', id: 'List' },
              { type: 'Candidates-By-Id', id },
            ]
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BulkCreateCandidateTag: builder.mutation({
      query: ({ data }) => ({
        url: `/candidate-tags/bulk-insert`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Candidates-List', id: 'List' }] : [],
    }),
  }),
});

export const {
  useCreateCandidateTagMutation,
  useBulkCreateCandidateTagMutation,
} = candidatesTagApiSlice;
