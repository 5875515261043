import {
  Stack,
  Box,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
  Image,
  Text,
} from '@chakra-ui/react';
import User from '@/assets/images/user.png';
import { BsChevronDown } from 'react-icons/bs';

interface RecruiterMenuProps {
  recruiters: any[];
}
export const RecruiterMenu = ({ recruiters }: RecruiterMenuProps) => {
  const userPopulate = (data: any[]) => {
    const memoizedMenuButton = (key: number, less?: boolean) => {
      return (
        <Stack
          key={`tr-ul-${key}`}
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #E7E9ED',
            borderRadius: '50px',
            height: '45px',
            width: 'max-content',
            p: '5px',
          }}
        >
          <Stack direction="row" spacing="-10px">
            {data?.map((val: any, index: number) => {
              return (
                (less ? index < key : index > key) && (
                  <Tooltip
                    label={`${[
                      val?.recruiter?.first_name,
                      val?.recruiter?.last_name,
                    ].join(' ')}`}
                  >
                    <Image
                      borderRadius="full"
                      boxSize="40px"
                      placeSelf="center"
                      src={val?.recruiter?.profile_picture || User}
                      sx={{ border: '1px solid #E7E9ED' }}
                    />
                  </Tooltip>
                )
              );
            })}
          </Stack>
          {data.length > 5 && (
            <Box sx={{ fontSize: '12px', color: '#676767' }}>
              +{data.length - 5}
            </Box>
          )}
          <Icon as={BsChevronDown} />
        </Stack>
      );
    };

    const memoizedMenuItem = (key: number) => {
      return (
        <Stack spacing="10px" key={`tr-ul-${key}`}>
          {data?.map((val: any) => {
            return (
              <Flex gap="16px" alignItems="center">
                <Image
                  borderRadius="full"
                  boxSize="40px"
                  placeSelf="center"
                  src={val?.recruiter?.profile_picture || User}
                  sx={{ border: '1px solid #E7E9ED' }}
                />
                <Text
                  sx={{
                    fontSize: '12px',
                    color: '#000',
                  }}
                >
                  {[val?.recruiter?.first_name, val?.recruiter?.last_name].join(
                    ' '
                  )}
                </Text>
              </Flex>
            );
          })}
        </Stack>
      );
    };

    if (data.length > 0) {
      return (
        <Menu>
          <MenuButton
            sx={{
              button: {
                boxShadow: '0px 2px 8px 0px #0000000A',
                border: '1px solid #BABABA66',
                borderRadius: '6px',
                color: '#2B2D42',
              },
            }}
          >
            {memoizedMenuButton(5, true)}
          </MenuButton>
          <MenuList
            sx={{
              p: '10px',
              borderRadius: '20px',
              border: '1px solid #6930CA',
            }}
          >
            {memoizedMenuItem(1)}
          </MenuList>
        </Menu>
      );
    }
    return memoizedMenuButton(-1);
  };
  return userPopulate(recruiters);
};
