import {
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';

import MdWand from '@/assets/images/ai-generator/MdWand.svg';
import MdClose from '@/assets/images/ai-generator/MdClose.svg';
import MdMaximize from '@/assets/images/ai-generator/MdMaximize.svg';
import MdDiamond from '@/assets/images/ai-generator/MdDiamond.svg';
import MdGenerate from '@/assets/images/ai-generator/MdGenerate.svg';

import { POPULAR_PROMPTS } from './constants';
import { useDispatch } from 'react-redux';
import { updateAI } from '@/store/ai.slice';
import { AI_LIST } from '../Rewrite/constants';
import { MdMinimize } from 'react-icons/md';

interface AIGeneratorComposeProps {
  hasPrompt: string;
  setHasPrompt: any;
  setHasTone: any;
  setHasAIGenerate: any;
  setIsGenerate: any;
  handleGenerate: () => void;
  isMaximize: boolean;
  setIsMaximize: any;
}

export const AIGeneratorCompose = ({
  hasPrompt,
  setHasPrompt,
  setHasTone,
  setHasAIGenerate,
  setIsGenerate,
  handleGenerate,
  isMaximize,
  setIsMaximize,
}: AIGeneratorComposeProps) => {
  const dispatch = useDispatch();
  return (
    <Stack
      id="ai"
      sx={{
        width: !isMaximize ? '35%' : '100%',
        height: !isMaximize ? 'auto' : '70vh',
        bgColor: '#fff',
        borderRadius: '6px',
        m: '8px 16px',
        ml: !isMaximize && 0,
        p: '8px 10px',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          height: '100%',
          p: '8px',
        }}
      >
        <Stack spacing="8px">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap="10px">
              <Image src={MdWand} mt="2px" />
              <Text
                sx={{ fontSize: '14px', fontWeight: 600, color: '#6930CA' }}
              >
                Popular prompts
              </Text>
            </Flex>
            <Flex gap="10px">
              <Image
                src={!isMaximize ? MdMaximize : MdMinimize}
                sx={{ cursor: 'pointer' }}
                onClick={() => setIsMaximize(!isMaximize)}
              />
              <Image
                src={MdClose}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setHasAIGenerate('');
                  setHasPrompt('');
                  setIsGenerate(false);
                  dispatch(updateAI({ aiList: AI_LIST }));
                }}
              />
            </Flex>
          </Flex>

          <Grid templateColumns="repeat(2, 5fr)" gap={2}>
            {POPULAR_PROMPTS.map((val, item) => {
              return (
                <GridItem
                  key={item}
                  w="100%"
                  sx={{
                    border: '1px solid #E7EDF4',
                    borderRadius: '4px',
                    p: '8px',
                    cursor: 'pointer',
                  }}
                  _hover={{
                    bgColor: '#F9F4FF',
                    border: '1px solid #6930CA',
                  }}
                  onClick={() => {
                    setHasPrompt(val.prompt);
                    setHasTone(null);
                    handleGenerate();
                  }}
                >
                  <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>
                    {val.title}
                  </Text>
                  <Text sx={{ fontSize: '14px', color: '#6B6C7B' }}>
                    {val.prompt}
                  </Text>
                </GridItem>
              );
            })}
          </Grid>
        </Stack>

        <InputGroup>
          <InputLeftElement>
            <Image src={MdDiamond} />
          </InputLeftElement>
          <Input
            value={hasPrompt}
            placeholder="Write a prompt"
            onChange={(e) => {
              setHasPrompt(e.target.value);
              e.preventDefault();
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                handleGenerate();
              }
            }}
            sx={{
              border: '1px solid #E7EDF4',
              borderRadius: '4px',
              boxShadow: '0px 2px 8px 0px #0000000A',
              color: '#718096',
            }}
            _hover={{
              border: '1px solid #E7EDF4',
              borderRadius: '4px',
              boxShadow: '0px 2px 8px 0px #0000000A',
            }}
            _focus={{
              border: '1px solid #E7EDF4',
              borderRadius: '4px',
              boxShadow: '0px 2px 8px 0px #0000000A',
            }}
          />
          <InputRightElement
            onClick={() => {
              setHasTone(null);
              handleGenerate();
            }}
          >
            <Image src={MdGenerate} />
          </InputRightElement>
        </InputGroup>
      </Stack>
    </Stack>
  );
};
