import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  Stack,
  Text,
  Flex,
  Image,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Formik, FormikValues } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCreateSMTPMutation } from '@/services//engage/engage.mutation';
import { useGetEmailConnectionListMutation } from '@/store/outreach.slice';
import createSMTPValidation, { createSMTPInputs } from './form-validation';

interface CreateSMTPProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export default function CreateSMTP({
  isOpen,
  onClose,
  onCancel,
}: CreateSMTPProps) {
  const initialValues: createSMTPInputs = {
    username: '',
    password: '',
    smtp_host: '',
    smtp_port: '',
    imap_host: '',
    imap_port: '',
  };
  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [req, res] = useCreateSMTPMutation();
  const { emailConnectionPgBtn } = useSelector((state: any) => state.outreach);
  const toast = useToast({ isClosable: true, duration: 3000, position: 'top' });

  async function handleSubmit(data: createSMTPInputs) {
    req({
      smtp_username: data.username,
      smtp_pass: data.password,
      imap_host: data.imap_host,
      imap_port: parseInt(data.imap_port),
      smtp_host: data.smtp_host,
      smtp_port: parseInt(data.smtp_port),
    })
      .unwrap()
      .then(() => {
        reqGetEmailConnectionList(emailConnectionPgBtn);
        toast({ status: 'success', title: 'Created IMAP/SMTP!' });
        onClose();
      })
      .catch((err) => {
        toast({ status: 'error', title: 'Something went wrong!' });
      });
  }
  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        background="default.white.800"
      >
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 16px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          IMAP / SMTP
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={createSMTPValidation}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, handleSubmit, values, touched }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody borderRadius="0 0 4px 4px" p="8px 16px">
                  <Stack
                    spacing="16px"
                    p="16px"
                    sx={{
                      borderRadius: '6px',
                      p: '16px',
                      bgColor: 'white',
                    }}
                  >
                    <Flex columnGap={'8px'}>
                      <FormControl
                        isInvalid={Boolean(errors.username && touched.username)}
                      >
                        <FormLabel aria-label="username" htmlFor="username">
                          Username
                        </FormLabel>
                        <Field
                          as={Input}
                          placeholder="Username"
                          name="username"
                        />
                        <ErrorMessage
                          name="username"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(errors.password && touched.password)}
                      >
                        <FormLabel aria-label="password" htmlFor="password">
                          Password
                        </FormLabel>
                        <Field
                          as={Input}
                          placeholder="Password"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                    </Flex>
                    <Grid templateColumns="repeat(12, 1fr)" columnGap={'8px'}>
                      <GridItem colSpan={8}>
                        <FormControl
                          isInvalid={Boolean(
                            errors.imap_host && touched.imap_host
                          )}
                        >
                          <FormLabel aria-label="imap_host" htmlFor="imap_host">
                            IMAP Host
                          </FormLabel>
                          <Field
                            as={Input}
                            placeholder="  IMAP Host"
                            name="imap_host"
                          />
                          <ErrorMessage
                            name="imap_host"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={4}>
                        <FormControl
                          isInvalid={Boolean(
                            errors.imap_port && touched.imap_port
                          )}
                        >
                          <FormLabel aria-label="imap_port" htmlFor="imap_port">
                            IMAP Post
                          </FormLabel>
                          <Field
                            as={Input}
                            placeholder="Post"
                            name="imap_port"
                          />
                          <ErrorMessage
                            name="imap_port"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(12, 1fr)" columnGap={'8px'}>
                      <GridItem colSpan={8}>
                        <FormControl
                          isInvalid={Boolean(
                            errors.smtp_host && touched.smtp_host
                          )}
                        >
                          <FormLabel aria-label="smtp_host" htmlFor="smtp_host">
                            SMTP Host
                          </FormLabel>
                          <Field
                            as={Input}
                            placeholder="  SMTP Host"
                            name="smtp_host"
                          />
                          <ErrorMessage
                            name="smtp_host"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={4}>
                        <FormControl
                          isInvalid={Boolean(
                            errors.smtp_port && touched.smtp_port
                          )}
                        >
                          <FormLabel aria-label="smtp_port" htmlFor="smtp_port">
                            SMTP Post
                          </FormLabel>
                          <Field
                            as={Input}
                            placeholder="Post"
                            name="smtp_port"
                          />
                          <ErrorMessage
                            name="smtp_port"
                            component={FormErrorMessage}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Stack>
                </ModalBody>
                <ModalFooter
                  background="default.white.800"
                  p="9px 16px"
                  columnGap={'10px'}
                >
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button
                    type="submit"
                    variant={'solid'}
                    isLoading={res.isLoading}
                  >
                    Connect
                  </Button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
