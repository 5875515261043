import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateApp,
  usePostGenerateAssistantMutation,
  usePostGetThreadMutation,
} from '@/store/app.slice';

import { TALIE_LIST } from './constant';

export const useDrawerServices = () => {
  const dispatch = useDispatch();

  const { isTalieShow, talieList } = useSelector((state: any) => state.app);

  const [prompt, setPrompt] = useState('');
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [reqPostGetThread] = usePostGetThreadMutation();
  const [reqPostGeneratePrompt, resPostGeneratePrompt] =
    usePostGenerateAssistantMutation();

  const handleSubmit = () => {
    const payload = {
      message: prompt,
      thread_id: talieList?.thread?.id,
    };

    if (prompt && !resPostGeneratePrompt.isLoading) {
      dispatch(
        updateApp({
          talieList: {
            ...talieList,
            ...(talieList && talieList?.messages?.length > 0
              ? {
                  messages: [
                    ...talieList?.messages,
                    {
                      role: 'user',
                      content: [
                        {
                          text: {
                            value: payload?.message,
                          },
                        },
                      ],
                    },
                    {
                      role: 'assistant',
                      content: [
                        {
                          text: {
                            value: null,
                          },
                        },
                      ],
                    },
                  ],
                }
              : {
                  messages: [
                    {
                      role: 'user',
                      content: [
                        {
                          text: {
                            value: payload?.message,
                          },
                        },
                      ],
                    },
                    {
                      role: 'assistant',
                      content: [
                        {
                          text: {
                            value: null,
                          },
                        },
                      ],
                    },
                  ],
                }),
          },
        })
      );
      reqPostGeneratePrompt({ data: payload }).then(async (data: any) => {
        const reversedMessages = [...data?.data?.data?.messages].reverse();

        const payload = await {
          ...data?.data?.data,
          messages: reversedMessages,
        };

        dispatch(
          updateApp({
            talieList: payload,
          })
        );
      });

      setPrompt('');
    }
  };

  const handleChange = (event: any) => {
    setPrompt(event.target.value);
    event.preventDefault();
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      setPrompt(event.target.value);
      handleSubmit();
    }
  };

  const handleClose = () => {
    dispatch(updateApp({ isTalieShow: !isTalieShow }));
  };

  const handleNewChat = () => {
    const payload = {
      message: 'New chat',
    };
    reqPostGeneratePrompt({ data: payload });
    dispatch(updateApp({ talieList: TALIE_LIST }));
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [talieList?.messages]);

  useEffect(() => {
    if (isTalieShow && talieList.length === 0)
      reqPostGetThread({}).then(async (data: any) => {
        const reversedMessages = [...data?.data?.data?.messages].reverse();

        const payload = await {
          ...data?.data?.data,
          messages: reversedMessages,
        };

        dispatch(
          updateApp({
            talieList: payload,
          })
        );
      });
  }, [isTalieShow, talieList]);

  return {
    handleClose,
    isTalieShow,
    talieList: talieList?.messages,
    handleNewChat,
    chatContainerRef,
    prompt,
    handleChange,
    handleKeyDown,
    handleSubmit,
    isLoading: resPostGeneratePrompt.isLoading,
  };
};
