import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useLoginAuthMutation } from '@/store/auth.slice';

import * as Yup from 'yup';

import Granim from 'granim';

interface useLoginServicesProps {
  onClose: () => void;
  onOpen: () => void;
}

export const useLoginServices = ({
  onClose,
  onOpen,
}: useLoginServicesProps) => {
  const navigate = useNavigate();

  const [reqAuth, resAuth] = useLoginAuthMutation();

  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
      password: Yup.string().required('Password is required'),
    })
  );

  const onSubmit = async (data: any) => await reqAuth(data);

  useEffect(() => {
    if (resAuth.isSuccess) {
      onClose();
      navigate('/dashboard');
    } else if (resAuth.isError) {
      onOpen();
    }
  }, [navigate, onClose, onOpen, resAuth, resAuth.isSuccess]);

  useEffect(() => {
    const granimInstance = new Granim({
      element: '#granim-canvas',
      direction: 'left-right',
      isPausedWhenNotInView: true,
      states: {
        'default-state': {
          gradients: [
            ['#6930ca', '#b100ff'],
            ['#b100ff', '#6930ca'],
            ['#6930ca', '#b100ff'],
          ],
        },
      },
    });

    // Clean up on unmount
    return () => granimInstance.destroy();
  }, []);

  return {
    initialValues,
    validationSchema,
    onSubmit,
    show,
    handleClick,
    isLoading: resAuth.isLoading,
  };
};
