import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import Button from '@/Library/Button';
import { ActivityLogData } from '@/types';

const ClientsActivityLog = () => {
  const { clientData } = useSelector((state: any) => state.clients);

  const tableHeader: { label: string }[] = [
    { label: 'Time Of Activity' },
    { label: 'Activity Description' },
  ];

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex gap="16px" py={6}>
        <Button rightIcon="chevron-down" variant="basic">
          Last 7 Days
        </Button>
        <Button rightIcon="chevron-down" variant="basic">
          Filters
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {clientData?.activityLog?.map((activityLog: ActivityLogData) => (
                <Tr key={activityLog.id} cursor="pointer" onClick={() => {}}>
                  <Td>{activityLog.activityTime}</Td>
                  <Td>{activityLog.activityDescription}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ClientsActivityLog;
