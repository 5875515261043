import parse from 'html-react-parser';
import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react';

import TalieLogo from '@/assets/images/talie/talie-logo.svg';
import TalieIcon from '@/assets/images/talie/talie-icon-filled.svg';
import Close from '@/assets/images/talie/close.svg';
import Send from '@/assets/images/talie/send.svg';
import Wand from '@/assets/images/talie/wand.svg';
import Loading from '@/assets/images/talie/loading.gif';

import { useDrawerServices } from './useDrawerServices';
import TypingEffect from './Layout/TypingEffect';
import { AutoResizeTextarea } from '@/components/app/AutoResizeTextarea';

export const TalieDrawer = () => {
  const {
    handleClose,
    talieList,
    handleNewChat,
    chatContainerRef,
    prompt,
    handleChange,
    handleKeyDown,
    handleSubmit,
    isLoading,
  } = useDrawerServices();

  return (
    <Stack
      spacing={0}
      sx={{
        position: 'fixed',
        zIndex: 99999999,
        top: '70px',
        right: 0,
        height: '90vh',
        width: '30vw',
        border: '1px solid #E7EDF4',
        bgColor: '#FFF',
        boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
        fontFamily: 'NunitoSans Regular',

        '& #content::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          top: '-1px',
          left: '-49px',
          borderRadius: 'unset',
          h: '48px',
          w: '48px',
          bg: '#E6E9EE',
          color: 'default.secondarytext',
          cursor: 'pointer',
          boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
        }}
        _hover={{
          backgroundColor: 'none',
        }}
        onClick={handleClose}
      >
        <Image src={Close} height="24px" width="24px" />
      </Stack>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{
          h: '47px',
          bgColor: '#F9FAFB',
          borderBottom: '1px solid #E7EDF4',
          p: '12px 16px',
        }}
      >
        <Image src={TalieLogo} w="fit-content" />
        {talieList?.length > 1 && (
          <Button
            leftIcon={<Image src={Wand} />}
            sx={{ h: '34px', borderRadius: '40px', fontWeight: 700 }}
            onClick={handleNewChat}
          >
            New Chat
          </Button>
        )}
      </Flex>

      <Stack
        id="content"
        ref={chatContainerRef}
        spacing="10px"
        sx={{
          p: '18px 16px',
          height: '75vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {talieList?.length > 0 &&
          talieList?.map((val: any, index: any) => {
            return (
              <>
                {val.role === 'assistant' ? (
                  <Flex alignItems="center">
                    <Image src={TalieIcon} />

                    {val?.content[0]?.text?.value ? (
                      <Stack
                        alignItems="flex-start"
                        sx={{
                          maxW: '60%',
                          w: 'fit-content',
                          border: '1px solid #E7EDF4',
                          borderRadius: '4px',
                          p: '10px',
                        }}
                      >
                        <Box
                          whiteSpace="pre-wrap"
                          sx={{
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#2B2D42',
                          }}
                        >
                          {talieList.length - 1 === index ? (
                            <TypingEffect text={val?.content[0]?.text?.value} />
                          ) : (
                            parse(val?.content[0]?.text?.value)
                          )}
                        </Box>
                      </Stack>
                    ) : (
                      <Image src={Loading} height="12px" width="12px" />
                    )}
                  </Flex>
                ) : (
                  <Stack alignItems="flex-end">
                    <Stack
                      sx={{
                        maxW: '60%',
                        w: 'fit-content',
                        bgColor: '#F9F4FF',
                        border: '1px solid #6930CA',
                        borderRadius: '4px',
                        p: '10px',
                      }}
                    >
                      <Box
                        whiteSpace="pre-wrap"
                        sx={{
                          fontSize: '14px',
                          lineHeight: '18px',
                          color: '#2B2D42',
                        }}
                      >
                        {val?.content[0]?.text?.value}
                      </Box>
                    </Stack>
                  </Stack>
                )}
              </>
            );
          })}
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          bottom: 0,
          bgColor: '#FFF',
          width: '100%',
          p: '8px',
        }}
        spacing="10px"
      >
        <Flex
          gap="8px"
          alignItems="center"
          sx={{ border: '1px solid #E7EDF4', borderRadius: '4px', p: '8px' }}
        >
          <Stack
            spacing={1}
            w="100%"
            sx={{
              '& textarea::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <AutoResizeTextarea
              name="prompt"
              placeholder="Write a prompt"
              value={prompt}
              autoFocus={true}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              sx={{
                cursor: isLoading ? 'disabled' : 'auto',
                border: 'none',
                p: '5px',
                overflow: 'scroll',
                maxHeight: '150px',
                height: '25px',
                color: '#2B2D42',
                outline: 'none',
              }}
              _focus={{
                border: 'none',
              }}
            />
          </Stack>

          <Image
            src={Send}
            onClick={handleSubmit}
            sx={{ cursor: isLoading ? 'disabled' : 'pointer' }}
          />
        </Flex>
      </Stack>
    </Stack>
  );
};
