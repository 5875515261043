import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDisclosure } from '@chakra-ui/react';

import {
  listLeads,
  useGetStatusMutation,
  useGetTypesMutation,
  useListLeadsMutation,
} from '@/store/leads.slice';

import { statusInf } from './types';

export function useLeadsPageServices() {
  const dispatch = useDispatch();

  document.documentElement.dir = 'ltr';

  const { leadPgBtn, leadStatus } = useSelector((state: any) => state.leads);

  const [reqLeads, resLeads] = useListLeadsMutation();
  const [reqStatus] = useGetStatusMutation();
  const [reqTypes] = useGetTypesMutation();
  const [typeIsChecked, setTypeChecked] = useState([]);
  const [statusIsChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setTypeChecked(leadPgBtn.types);
    setStatusChecked(leadPgBtn.status);
  }, [leadPgBtn.status, leadPgBtn.types]);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const initialValue = {
    search: leadPgBtn.query,
    hot: false,
    qualified: false,
    status: [] as statusInf[],
    types: [] as statusInf[],
    unclaimed: leadPgBtn.unclaimed,
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchLeads = async (param: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqLeads({ data: param, signal: abortControllerRef.current.signal });
  };

  const submitForm = (params: any) => {
    localStorage.setItem('leadsFilter', JSON.stringify(params));
    dispatch(
      listLeads({
        leadPgBtn: params,
      })
    );
    fetchLeads(params);
  };

  const leadFilterData: string | null = localStorage.getItem('leadsFilter');
  const initFilter =
    leadFilterData == null ? leadPgBtn : JSON.parse(leadFilterData);

  const onSubmit = (data: any) => {
    let params = {
      ...leadPgBtn,
      page: 1,
      query: data.search,
      types: data.types,
    };
    submitForm(params);
  };

  const checkAll = (checked: any, unclaimed: boolean) => {
    setStatusChecked(checked);
    // console.log({ leadPgBtn });
    let params = {
      ...leadPgBtn,
      unclaimed,
      page: 1,
      status: checked,
    };
    submitForm(params);
  };

  const handleCheckStatus = useCallback(
    (e: any) => {
      let params = {
        ...leadPgBtn,
        page: 1,
        status: statusIsChecked,
        types: typeIsChecked,
      };

      const { id, checked } = e.target;
      let ID = parseInt(id);
      setStatusChecked([...statusIsChecked, ID]);
      params.status = [...statusIsChecked, ID];
      if (!checked) {
        const isChecked = statusIsChecked.filter((item: number) => item !== ID);
        setStatusChecked(isChecked);
        params.status = isChecked;
      }

      submitForm(params);
    },
    [
      leadPgBtn.query,
      leadPgBtn.take,
      statusIsChecked,
      submitForm,
      typeIsChecked,
    ]
  );

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        ...leadPgBtn,
        page: 1,
        query: '',
      };
      dispatch(
        listLeads({
          leadPgBtn: params,
        })
      );
      fetchLeads(params);
    }
  };

  const reloadLeadList = async () => {
    fetchLeads(leadPgBtn);
  };

  return {
    initialValue,
    leadStatus,
    isOpenModal,
    statusIsChecked,
    resLeads,
    initFilter,
    reqStatus,
    reqTypes,
    reqLeads,
    onOpenModal,
    onCloseModal,
    handleCheckStatus,
    clearSearch,
    onSubmit,
    setStatusChecked,
    checkAll,
    reloadLeadList,
  };
}
