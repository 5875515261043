import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Stack,
  Text,
  Icon,
  AccordionPanel,
  FormControl,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Input,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  CheckboxGroup,
} from '@chakra-ui/react';
import { SearchBar } from '@/components/app/Global/SearchBar';
import { debounce } from 'lodash';
import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import Tabs from './components/Tabs';
import Select from '@/components/ui/Select/Select';
import { MILES } from '@/constants/form-select-options';
import { AutoResizeTextarea } from '@/components/app/AutoResizeTextarea';
import { CheckboxLayout } from '@/components/app/Global/Checkbox';
import FilterAccordianItem from '@/components/ui/Accordian/FilterAccrodianItem';
import {
  useFetchAllConstantsQuery,
  useFetchSearchTagsQuery,
} from '@/services/common/constants.query';
import { getLabelValueFormat } from '@/utils/querySelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFilter,
  setBooleanSearch,
  setCandidateSearch,
  setCandidateSource,
  setCandidateSubSource,
  setIsResumePresent,
  setJobTitleInclude,
  setJobTitleSearch,
  setLocationSearch,
  setMiles,
  setResumeUpdatedOn,
  setTags,
  setTagsType,
  setWorkStatus,
  setZipCode,
} from '@/store/candidates.slice';
import {
  useFetchGeoLocationQuery,
  useFetchWildSearchGeoLocationQuery,
} from '@/services/common/geoLocation.query';

const RESUME_UPDATED_ON = [
  { label: 'All Time', value: 0 },
  { label: 'Last Week', value: 1 },
  { label: 'Last Month', value: 2 },
  { label: '0-3 months', value: 3 },
  { label: '3-6 months', value: 4 },
  { label: '6-12 months', value: 5 },
  { label: 'More than a year', value: 6 },
];
export default function Sidebar() {
  const dispatch = useDispatch();
  const filterValues = useSelector((state: any) => state.candidates.filter);
  const [inputQuery, setInputQuery] = useState({
    jobTitle: '',
    booleanSearch: '',
  });
  const [candidateQuery, setCandidateQuery] = useState('');
  const [selectQuery, setSelectQuery] = useState({
    location: '',
    zipCode: '',
    tag: '',
  });

  const count = useMemo(() => {
    let filterCount = [
      filterValues.search,
      filterValues?.locationSearch?.length,
      filterValues?.zipCode,
      filterValues?.miles,
      filterValues?.jobTitle,
      // filterValues?.include?.length,
      filterValues?.candidateSource,
      filterValues?.candidateSubSource,
      filterValues?.booleanSearch,
      filterValues?.resumeUpdateOn,
      filterValues?.isResumePresent,
      filterValues?.workStatus?.length,
      filterValues?.tags?.length,
    ].filter((item) => item).length;

    return filterCount;
  }, [filterValues]);

  const { data: tagList, isFetching: isLoadingTags } = useFetchSearchTagsQuery(
    { is_private: filterValues.tagsType === '2', tag: selectQuery.tag },
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data ? getLabelValueFormat(data, 'id', 'tag_name') : [],
        ...other,
      }),
    }
  );
  const { data: constants } = useFetchAllConstantsQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: {
          candidateSource: data?.candidate_source
            ? data?.candidate_source?.map((source) => ({
                label: source.source,
                value: source.id,
                sub: getLabelValueFormat(source?.sub, 'id', 'sub_source'),
              }))
            : [],
          candidateWorkStatus: getLabelValueFormat(
            data?.candidate_work_status,
            'id',
            'status'
          ),
        },
      }),
    }
  );
  const { data: locationList, isLoading: isLocationListLoading } =
    useFetchWildSearchGeoLocationQuery(
      {
        currentName: '',
        search: selectQuery.location,
      },
      {
        selectFromResult: ({ data, ...other }) => ({
          ...other,
          data: data
            ? data.map((geoLocation) => ({
                label: `${geoLocation.city}, ${geoLocation.state}, ${geoLocation.country}`,
                value: geoLocation.id,
                city: geoLocation.city,
                country: geoLocation.country,
                country_code: geoLocation.country_code,
                postal_code: geoLocation.id,
                state: geoLocation.state,
              }))
            : [],
        }),
        skip: !Boolean(selectQuery.location),
      }
    );
  const { data: zipCodeList, isLoading: isZipCodeListLoading } =
    useFetchGeoLocationQuery(
      {
        search: selectQuery.zipCode,
        column: 'postal_code',
      },
      {
        selectFromResult: ({ data, ...other }) => ({
          ...other,
          data: data
            ? data.map((geoLocation) => ({
                label: `${geoLocation.postal_code}, ${geoLocation.city}, ${geoLocation.state}, ${geoLocation.country}`,
                value: geoLocation.postal_code,
                ...geoLocation,
              }))
            : [],
        }),
        skip: !Boolean(selectQuery.zipCode),
      }
    );

  //-------
  //to prevent API call on every keystroke i.e debounce used below
  const handleCandidateSearchChange = useRef(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      let search = e.target.value;
      dispatch(setCandidateSearch(search));
    }, 500)
  ).current;
  const handleJobTitleChange = useRef(
    debounce((search: string) => {
      dispatch(setJobTitleSearch(search));
    }, 500)
  ).current;
  const handleBooleanSearchChange = useRef(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      let search = e.target.value;
      dispatch(setBooleanSearch(search));
    }, 500)
  ).current;
  const handleSelectInputValue = useRef(
    debounce((value: string, key: 'location' | 'zipCode' | 'tag') => {
      setSelectQuery((state) => ({ ...state, [key]: value }));
    }, 500)
  ).current;
  //-------

  function handleTagTypeChange(e: ChangeEvent<HTMLInputElement>) {
    dispatch(setTagsType(e.target.value));
  }
  function handleResetFilter() {
    setCandidateQuery('');
    if (count) {
      dispatch(resetFilter());
      setInputQuery({ booleanSearch: '', jobTitle: '' });
    }
  }
  return (
    <Stack
      borderColor="default.borderlight"
      bg="#F7FAFC"
      overflowY="auto"
      width="300px"
      height="100%"
      position="relative"
      gap="8px"
    >
      <Tabs onTabSwitch={handleResetFilter}>
        <Box
          sx={{
            bgColor: '#fff',
            borderRadius: '10px',
            p: '16px',
          }}
        >
          <Flex
            gap="5px"
            sx={{
              borderBottom: '1px solid #E7EDF4',
              mb: '16px',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters{' '}
            {Boolean(count) && (
              <Text sx={{ color: 'primary.800' }}>({count})</Text>
            )}
          </Flex>

          <SearchBar
            search={candidateQuery}
            setSearch={setCandidateQuery}
            placeholder="Search by name, phone, email..."
            handleOnChange={handleCandidateSearchChange}
          />
        </Box>
        <Accordion allowMultiple defaultIndex={[]} p={0}>
          <Stack gap="8px">
            <FilterAccordianItem title="Location">
              <Select
                isMulti
                value={filterValues.locationSearch}
                options={locationList}
                isLoading={isLocationListLoading}
                placeholder="Location Search"
                onInputChange={(e) => {
                  handleSelectInputValue(e, 'location');
                }}
                onChange={(e: any) => {
                  dispatch(setLocationSearch(e));
                }}
                isClearable
                selectTheme="filter"
              />
              <Stack gap="4px" mt="12px">
                <Text
                  sx={{
                    w: '100%',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#2B2D42',
                  }}
                >
                  Zip Code
                </Text>
                <Grid templateColumns="1fr 1fr">
                  <GridItem colSpan={1}>
                    <Select
                      value={filterValues.zipCode}
                      options={zipCodeList}
                      isLoading={isZipCodeListLoading}
                      placeholder="Zip Code"
                      onInputChange={(e) => {
                        handleSelectInputValue(e, 'zipCode');
                      }}
                      onChange={(e: any) => {
                        dispatch(setZipCode(e));
                      }}
                      isClearable
                      selectTheme="filter"
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Select
                      value={filterValues.miles}
                      options={MILES}
                      placeholder="Miles"
                      onChange={(e: any) => {
                        dispatch(setMiles(e));
                      }}
                      selectTheme="filter"
                    />
                  </GridItem>
                </Grid>
              </Stack>
            </FilterAccordianItem>

            <FilterAccordianItem title="Job Title">
              <InputGroup
                bg="#fff"
                color="primary.800"
                sx={{
                  '.chakra-textarea': {
                    fontWeight: 600,
                  },
                  '.chakra-textarea::placeholder': {
                    fontWeight: 'normal',
                  },
                }}
              >
                <InputRightElement cursor="pointer" />
                <AutoResizeTextarea
                  value={inputQuery.jobTitle}
                  placeholder="Search Job Title"
                  onChange={(e) => {
                    setInputQuery((state) => ({
                      ...state,
                      jobTitle: e.target.value,
                    }));
                  }}
                  _focus={{
                    boxShadow: 'none',
                    border: '1px solid #E2E8F0',
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevent new line
                      handleJobTitleChange(e?.currentTarget?.value);
                    }
                  }}
                />
              </InputGroup>
              <Stack
                spacing="8px"
                sx={{
                  mt: '8px',
                }}
              >
                <Box color="default.secondarytext" fontSize="14px">
                  Include:
                </Box>
                <Stack>
                  <CheckboxGroup
                    value={filterValues?.include}
                    onChange={(e) => {
                      dispatch(setJobTitleInclude(e));
                    }}
                  >
                    <Checkbox value={'Current'} size="md">
                      Current
                    </Checkbox>
                    <Checkbox value={'Past'} size="md">
                      Past
                    </Checkbox>
                  </CheckboxGroup>
                </Stack>
              </Stack>
            </FilterAccordianItem>
            <FilterAccordianItem title="Candidate Source">
              <Stack gap="8px">
                <Select
                  options={constants?.candidateSource}
                  selectTheme="filter"
                  value={filterValues.candidateSource}
                  onChange={(e: any) => dispatch(setCandidateSource(e))}
                />
                {Boolean(filterValues.candidateSource?.sub?.length) ? (
                  <Stack gap="4px">
                    <Text
                      sx={{ color: 'default.primarytext', fontSize: '14px' }}
                    >
                      Sub Source
                    </Text>
                    <Select
                      options={filterValues.candidateSource?.sub}
                      selectTheme="filter"
                      value={filterValues.candidateSubSource}
                      onChange={(e: any) => dispatch(setCandidateSubSource(e))}
                    />
                  </Stack>
                ) : null}
              </Stack>
            </FilterAccordianItem>
            <FilterAccordianItem title="Boolean Search">
              <Input
                value={inputQuery.booleanSearch}
                placeholder="Search..."
                color="primary.800"
                onChange={(e) => {
                  setInputQuery((state) => ({
                    ...state,
                    booleanSearch: e.target.value,
                  }));
                  handleBooleanSearchChange(e);
                }}
              />
            </FilterAccordianItem>
            <FilterAccordianItem title="Resume Updated On">
              <Stack gap="4px" mt="4px">
                <Select
                  options={RESUME_UPDATED_ON}
                  selectTheme="filter"
                  value={filterValues.resumeUpdatedOn}
                  onChange={(e: any) => dispatch(setResumeUpdatedOn(e))}
                />
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  onChange={() => dispatch(setIsResumePresent())}
                  isChecked={filterValues.isResumePresent}
                >
                  Is Resume Present
                </Checkbox>
              </Stack>
            </FilterAccordianItem>
            <FilterAccordianItem title="Work Status">
              <Stack gap="4px" mt="4px">
                <Select
                  isMulti
                  isClearable
                  options={constants.candidateWorkStatus}
                  selectTheme="filter"
                  value={filterValues.workStatus}
                  onChange={(e: any) => dispatch(setWorkStatus(e))}
                />
              </Stack>
            </FilterAccordianItem>
            <FilterAccordianItem title="Tags">
              <Stack gap="4px" mt="4px">
                <RadioGroup value={filterValues.tagsType}>
                  <Stack py={2} spacing={5} direction="row">
                    <Radio value="1" onChange={handleTagTypeChange}>
                      All Tags
                    </Radio>
                    <Radio value="2" onChange={handleTagTypeChange}>
                      Private
                    </Radio>
                  </Stack>
                </RadioGroup>
                <Select
                  options={tagList}
                  isLoading={isLoadingTags}
                  isMulti
                  isClearable
                  selectTheme="filter"
                  onInputChange={(e) => {
                    handleSelectInputValue(e, 'tag');
                  }}
                  value={filterValues.tags}
                  onChange={(e: any) => dispatch(setTags(e))}
                />
              </Stack>
            </FilterAccordianItem>
          </Stack>
        </Accordion>
      </Tabs>
      {count !== 0 && (
        <Flex
          sx={{
            position: 'sticky',
            bottom: 0,
            bg: '#fff',
            w: '100%',
            borderTop: '1px solid #E6E9EE',
            p: '16px 24px',
            pl: '30px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '14px', fontWeight: 600, color: '#718096' }}>
            {count} item(s) selected
          </Box>
          <Button variant="solid" size="md" onClick={handleResetFilter}>
            Clear
          </Button>
        </Flex>
      )}
    </Stack>
  );
}
