/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@/Library/Button';

import SequencesModal from '../Modal';
import SequencesListTable from './SequencesListTable';

export const MainPage = () => {
  const {
    isOpen: isOpenSequencesModal,
    onOpen: onOpenSequencesModal,
    onClose: onCloseSequencesModal,
  } = useDisclosure({ defaultIsOpen: false });

  return (
    <Box fontFamily="NunitoSans Regular" overflow="hidden">
      <Flex justifyContent="flex-end" mb="15px" px="8px">
        <Flex gap={5}>
          <Button
            leftIcon="plus"
            variant="solid"
            onClick={onOpenSequencesModal}
          >
            Add Sequence
          </Button>
        </Flex>
      </Flex>

      <SequencesListTable />

      {isOpenSequencesModal && (
        <SequencesModal
          isOpen={isOpenSequencesModal}
          onClose={onCloseSequencesModal}
        />
      )}
    </Box>
  );
};
