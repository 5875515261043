import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  useDisclosure,
  VStack,
  Button,
  useToast,
  Text,
} from '@chakra-ui/react';

import FAIcon from '@/components/lib/FAIcon';
import { useListJobsMutation } from '@/store/jobs.slice';
import { Formik } from 'formik';
import { authStorage } from '@/utils/localStorage';
import {
  listCandidates,
  useAssociateCandidatesMutation,
  useBulkAssociateCandidatesMutation,
  useListCandidatesMutation,
} from '@/store/candidates.slice';
import LoadingPage from '@/components/app/Loading';
import ScrollToFieldError from '@/components/app/ScrollError';

interface CandidatesModalProps {
  isOpen: any;
  onClose: any;
  id: any;
  idList?: number[];
  assoc_jobs: any;
  isBulkAssociateJob?: boolean;
  onSuccess: () => void;
}

const CandidatesAssociateJobModal = ({
  isOpen,
  onClose,
  id,
  idList,
  assoc_jobs,
  isBulkAssociateJob = false,
  onSuccess,
}: CandidatesModalProps) => {
  const toast = useToast();

  // console.log('assoc_jobs', assoc_jobs);
  // console.log('idList', idList);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const dispatch = useDispatch();

  const { jobs } = useSelector((state: any) => state.jobs);
  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const [checkedBox, setChecked] = useState([]);

  const initialValue = {
    search: '',
  };

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqList, resJobs] = useListJobsMutation();
  const [reqAssocJob, resAssocJob] = useAssociateCandidatesMutation();
  const [reqBulkAssocJob, resBulkAssocJob] =
    useBulkAssociateCandidatesMutation();

  const handleCheck = (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setChecked([...checkedBox, ID]);
    if (!checked) {
      setChecked(checkedBox.filter((item: number) => item !== ID));
    }
  };

  useEffect(() => {
    console.log('isBulkAssociateJob ', isBulkAssociateJob);
  }, [isBulkAssociateJob]);

  const jobParams = {
    all: true,
    closed: false,
    draft: false,
    full: false,
    job_ads: true,
    job_assigned: true,
    open: true,
    page: 1,
    query: '',
    quickJobs: true,
    take: 100,
  };

  const abortControllerJobRef = useRef<AbortController | null>(null);

  const fetchJobs = async (params: object) => {
    if (abortControllerJobRef.current) {
      abortControllerJobRef.current.abort();
    }

    abortControllerJobRef.current = new AbortController();
    reqList({ data: params, signal: abortControllerJobRef.current.signal });
  };

  useEffect(() => {
    fetchJobs(jobParams);
  }, [reqList]);

  const abortControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    if (resAssocJob.isSuccess || resBulkAssocJob.isSuccess) {
      toast({
        title: 'Success',
        description: 'Candidate has been associated to job/s',
        status: 'success',
        isClosable: true,
        duration: 2000,
        position: 'top',
      });
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      reqCandidates({
        data: candidatePgBtn,
        signal: abortControllerRef.current.signal,
      });
      onSuccess();
      onClose();
    }
  }, [resAssocJob.isSuccess, resBulkAssocJob.isSuccess]);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      fetchJobs(jobParams);
    }
  };

  const onSubmit = (data: any) => {
    const newParam = {
      ...jobParams,
      query: data?.search,
    };
    fetchJobs(jobParams);
  };

  const submit = () => {
    const user = authStorage();
    const data = {
      job_id: checkedBox,
      recruiter_id: user.id, //temporary
      ...(isBulkAssociateJob && { idList }),
    };
    if (checkedBox.length > 0) {
      if (isBulkAssociateJob) {
        reqBulkAssocJob({ data });
      } else {
        reqAssocJob({ data, id });
      }
    } else {
      onOpen();
      setErrorMessage('Please select at least one job');
    }
  };

  const isDisable = (id: any) => {
    const find = assoc_jobs.find((job: any) => {
      return job === id;
    });

    return find ? true : false;
  };

  const locationParse = (location: any) => {
    try {
      const loc = [
        location?.address,
        location?.city,
        location?.state,
        location?.zip_code,
        location?.country?.country,
      ]
        .filter(Boolean)
        .join(', ');
      if (loc !== ', ') {
        return loc;
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Associate To Job
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Box
              overflow="automatic"
              p="30px"
              background="#fff"
              borderRadius="8px"
            >
              <FormControl mb="16px">
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <InputGroup background="#fff">
                          <InputRightElement
                            cursor="pointer"
                            children={<FAIcon iconName="search" />}
                          />
                          <Input
                            type="search"
                            placeholder="Search Jobs"
                            id="search"
                            onChange={(e) => {
                              handleChange(e);
                              clearSearch(e);
                            }}
                            value={values.search}
                          />
                        </InputGroup>
                      </form>
                    </>
                  )}
                </Formik>
              </FormControl>
            </Box>
            <Box>
              {resJobs.isLoading && <LoadingPage />}
              <VStack
                spacing={2}
                align="stretch"
                divider={<StackDivider borderColor="gray.200" />}
              >
                {jobs.length > 0 &&
                  jobs.map((job: any) => {
                    return (
                      <Box key={`associate-modal-${job.id}`}>
                        <Flex>
                          <Center w="50px">
                            <Checkbox
                              id={job.id}
                              onChange={handleCheck}
                              isChecked={checkedBox.includes(job.id)}
                              isDisabled={isDisable(job.id)}
                            />
                          </Center>
                          <Box>
                            <VStack align="stretch" spacing={0}>
                              {isDisable(job.id) ? (
                                <Box as="s">{job.title}</Box>
                              ) : (
                                <Box>{job.title}</Box>
                              )}

                              <Box>
                                {job.client?.name} - {job.serial_number}
                              </Box>
                              <Box>
                                <Text fontSize="sm">
                                  {locationParse(job.location)}
                                </Text>
                              </Box>
                            </VStack>
                          </Box>
                        </Flex>
                      </Box>
                    );
                  })}
              </VStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            {assoc_jobs.length > 0 && (
              <Box w="100%" as="i" fontSize="12" color="red.300">
                All disabled jobs from the list are already been associated to
                this candidate.
              </Box>
            )}

            <Button
              variant="solid"
              onClick={submit}
              loadingText="Submitting"
              isDisabled={isOpenAlert}
              isLoading={resAssocJob.isLoading || resBulkAssocJob.isLoading}
            >
              Associate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidatesAssociateJobModal;
