import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ApprovalsDetails from './Details';
import ApprovalsJobs from './Jobs';

import { getApproval } from '@/store/approval.slice';

const ApprovalsTabs = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { approvals, approvalData } = useSelector(
    (state: any) => state.approvals
  );

  const tabIndex: any = {
    details: 0,
    jobs: 1,
  };

  const stateTab: any = params.approvalsTab;
  const approvalID = params.approvalsId;

  useEffect(() => {
    if (approvalID) {
      const getApprovalData = approvals.filter(
        (approval: any) => approval.id === Number(approvalID)
      )[0];
      dispatch(getApproval({ approvalData: getApprovalData }));
    }
  }, [dispatch, approvalID, approvals]);

  const handleRoute = (route: string) => {
    navigate(`/approvals/${approvalData.id}/${route}`);
  };

  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('details')}
        >
          Details
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('jobs')}
        >
          Jobs
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <ApprovalsDetails />
        </TabPanel>
        <TabPanel>
          <ApprovalsJobs />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ApprovalsTabs;
