/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
} from '@/store/outreach.slice';
import { TAB_TYPE } from '../constant';

export const useEngagePageServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { emailConnectionList, emailConnectionPgBtn, hasAlert } = useSelector(
    (state: any) => state.outreach
  );

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();

  const [tab, setTab] = useState<string>('recruitment');

  const isEmailConnectionTab =
    location.pathname ===
    `/engage/settings/${params.settingTab}/email-connection`;

  useEffect(() => {
    const hasDisconnected =
      emailConnectionList.find((connection: any) => connection.is_default === 1)
        ?.is_disconnect === 1;

    if (hasDisconnected) {
      dispatch(updateOutreach({ hasAlert: true }));
    } else {
      dispatch(updateOutreach({ hasAlert: false }));
    }
  }, [emailConnectionList, location.pathname, params.settingTab]);

  useEffect(() => {
    if (location.pathname === '/engage')
      reqGetEmailConnectionList(emailConnectionPgBtn);
  }, [emailConnectionPgBtn, location.pathname]);

  useEffect(() => {
    const {
      settingNav,
      settingType = '',
      settingTypeId = '',
      settingSubType = '',
      settingSubId = '',
    } = params;

    const navigatePath = `/engage/${settingNav}/${tab}${
      settingType && `/${settingType}`
    }${settingTypeId && `/${settingTypeId}`}${
      settingSubType && `/${settingSubType}`
    }${settingSubId && `/${settingSubId}`}`;
    const bdPath = '/engage/sequences/business';

    const hashFragment = location.hash || location.search;

    navigate(
      tab === TAB_TYPE.recruitment ? navigatePath + hashFragment : bdPath
    );
  }, [tab]);

  return { tab, setTab, hasAlert, isEmailConnectionTab };
};
