// Formik for form handling
import { Formik } from 'formik';

// Chakra UI components
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

// Custom components
import ScrollToFieldError from '@/components/app/ScrollError';
import AtsSelect3 from '@/components/app/AtsSelect3';

// Custom hooks
import { useRejectServices } from './useRejectServices';

interface RejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  idList?: number[];
  rejectType?: string;
  jobsId?: number;
  isBulkReject?: boolean;
  callback?: () => void;
}

export default function RejectModal({
  isOpen,
  onClose,
  id,
  idList,
  rejectType = null,
  jobsId = null,
  isBulkReject = false,
  callback = null,
}: RejectModalProps) {
  const {
    isOpenAlert,
    onCloseAlert,
    initialValues,
    handleSubmit,
    validationSchema,
    isLoading,
    status,
  } = useRejectServices({
    rejectType,
    id,
    isBulkReject,
    idList,
    jobsId,
    onClose,
    callback,
  });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Reject Candidate
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>
                  There was an error processing your request. Change a few
                  things up and try again.
                </AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px">
                  <Box mb="34px">
                    <FormControl
                      isInvalid={Boolean(!!errors.reason && touched.reason)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Please select a reject reason
                      </FormLabel>
                      <AtsSelect3
                        id="reason"
                        name="reason"
                        placeholder="Select"
                        variant="outline"
                        value={values.reason}
                        onChange={(e: any) => setFieldValue('reason', e)}
                        options={status}
                      />
                      <FormErrorMessage>
                        {String(errors.reason)}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button mr={3} onClick={() => onClose()} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
