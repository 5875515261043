import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import FAIcon from '../../lib/FAIcon';

import ApprovalsTable from './Table';

import {
  useApprovalStatusMutation,
  useListAppprovalsMutation,
  listApprovals,
} from '@/store/approval.slice';
import { useGetTypesMutation } from '@/store/leads.slice';
import ScrollToFieldError from '../ScrollError';
import TrLoading from '../TrLoading';

interface statusInf {
  id: number;
}

export default function ApprovalsPage() {
  const dispatch = useDispatch();

  document.documentElement.dir = 'ltr';

  const { appovalStatus, approvalPgBtn } = useSelector(
    (state: any) => state.approvals
  );
  const { leadTypes } = useSelector((state: any) => state.leads);

  const [reqApprovals, resApprovals] = useListAppprovalsMutation();
  const [reqStatus] = useApprovalStatusMutation();
  const [reqTypes] = useGetTypesMutation();
  const [typeIsChecked, setTypeChecked] = useState([]);
  const [statusIsChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setTypeChecked(approvalPgBtn.types);
    setStatusChecked(approvalPgBtn.status);
  }, [approvalPgBtn.status, approvalPgBtn.types]);

  const initialValue = {
    search: '',
    hot: false,
    qualified: false,
    status: [] as statusInf[],
    types: [] as statusInf[],
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchApprovals = async (param: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqApprovals({ data: param, signal: abortControllerRef.current.signal });
  };

  const submitForm = (params: any) => {
    dispatch(
      listApprovals({
        approvalPgBtn: params,
      })
    );
    fetchApprovals(params);
  };

  useEffect(() => {
    const approvalsFilterData: string | null =
      localStorage.getItem('approvalsFilter');
    const initFilter =
      approvalsFilterData == null
        ? approvalPgBtn
        : JSON.parse(approvalsFilterData);

    if (initFilter) {
      dispatch(
        listApprovals({
          approvalPgBtn: initFilter,
        })
      );
      fetchApprovals(initFilter);
    }
  }, [dispatch, reqApprovals]);

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: approvalPgBtn.take,
      query: data.search,
      status: data.status,
      types: data.types,
    };
    submitForm(params);
  };

  const handleCheckTypes = useCallback(
    (e: any) => {
      let params = {
        page: 1,
        take: approvalPgBtn.take,
        query: approvalPgBtn.query,
        status: statusIsChecked,
        types: typeIsChecked,
      };

      const { id, checked } = e.target;
      let ID = parseInt(id);
      setTypeChecked([...typeIsChecked, ID]);
      params.types = [...typeIsChecked, ID];

      if (!checked) {
        const isChecked = typeIsChecked.filter((item: number) => item !== ID);
        setTypeChecked(isChecked);
        params.types = isChecked;
      }
      submitForm(params);
      localStorage.setItem('approvalsFilter', JSON.stringify(params));
    },
    [
      approvalPgBtn.query,
      approvalPgBtn.take,
      statusIsChecked,
      submitForm,
      typeIsChecked,
    ]
  );

  const handleCheckStatus = useCallback(
    (e: any) => {
      let params = {
        page: 1,
        take: approvalPgBtn.take,
        query: approvalPgBtn.query,
        status: statusIsChecked,
        types: typeIsChecked,
      };

      const { id, checked } = e.target;
      let ID = parseInt(id);
      setStatusChecked([...statusIsChecked, ID]);
      params.status = [...statusIsChecked, ID];
      if (!checked) {
        const isChecked = statusIsChecked.filter((item: number) => item !== ID);
        setStatusChecked(isChecked);
        params.status = isChecked;
      }

      submitForm(params);
      localStorage.setItem('approvalsFilter', JSON.stringify(params));
    },
    [
      approvalPgBtn.query,
      approvalPgBtn.take,
      statusIsChecked,
      submitForm,
      typeIsChecked,
    ]
  );

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: approvalPgBtn.take,
        query: '',
        status: approvalPgBtn.status,
        types: approvalPgBtn.types,
      };
      dispatch(
        listApprovals({
          approvalPgBtn: params,
        })
      );
      fetchApprovals(params);
    }
  };

  useEffect(() => {
    reqStatus({});
    reqTypes({});
  }, [reqStatus, reqTypes]);

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" mb="16px" px={6}>
        <Flex justifyContent="space-between" maxWidth="40%" alignItems="center">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search a lead name"
                      value={values.search}
                      id="search"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            )}
          </Formik>

          <Flex px={2} justifyContent="flex-start">
            {Object.keys(leadTypes).length > 0 &&
              Object.values(leadTypes).map((data: any, key: number) => {
                return (
                  <Checkbox
                    key={key}
                    colorScheme="purple"
                    color="default.secondarytext"
                    mx={1}
                    className="filterCheckBox"
                    onChange={handleCheckTypes}
                    isChecked={typeIsChecked.includes(data.id)}
                    id={data.id}
                  >
                    {data.type}
                  </Checkbox>
                );
              })}
          </Flex>
        </Flex>

        <Flex px={2} justifyContent="flex-start">
          {Object.keys(appovalStatus).length > 0 &&
            Object.values(appovalStatus).map((data: any, key: number) => {
              return (
                <Checkbox
                  key={key}
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  onChange={handleCheckStatus}
                  isChecked={statusIsChecked.includes(data.id)}
                  id={data.id}
                >
                  {data.status}
                </Checkbox>
              );
            })}
        </Flex>
      </Flex>

      <Box>
        {resApprovals.isLoading ? (
          <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
              boxSizing="border-box"
              borderX="1px solid"
              borderTop="1px solid"
              borderColor="default.white.400"
              borderRadius="md"
              minHeight="70vh"
            >
              <Table>
                <Thead height="40.5px">
                  <Tr bg="default.white.600">
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody
                  boxSizing="border-box"
                  background="default.white.100"
                  borderBottom="1px solid"
                  borderColor="default.white.400"
                >
                  <TrLoading rows={7} columns={10} />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <ApprovalsTable />
        )}
      </Box>
    </Box>
  );
}
