import React, { lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router';
import ConfettiExplosion from 'react-confetti-explosion';

import { Box, Image, Skeleton, Spinner, Text } from '@chakra-ui/react';

import confetti from '@/assets/images/confetti.svg';
import confetti1 from '@/assets/images/confetti1.svg';

import '../../theme/fonts.css';

import Loader from '@/Library/Loader';

import LoginAppNavBar from '@/components/app/AppNavbar';
import ApprovalsPage from '@/components/app/Approvals/Page';
import CandidatesPage from '@/components/app/Candidates/Page';
import CandidatesResume from '@/components/app/Candidates/Resume';
import ClientsPage from '@/components/app/Clients/Page';
import Dashboard from '@/components/app/DashBoard';
import FooterNotif from '@/components/app/FooterNotif';
import MsGraph from '@/components/app/MsGraph';
import MsGraphMe from '@/components/app/MsGraph/me';
import MsGraphOuth from '@/components/app/MsGraph/oauth';
import MyProfile from '@/components/app/MyProfile';
import ParseQueue from '@/components/app/ParseQueue';
import Settings from '@/components/app/Settings';
import EmailSignatures from '@/components/app/Settings/EmailSignatures';
import EmailTemplateTab from '@/components/app/Settings/EmailTemplates';
import RingCentralAuth from '@/components/app/Settings/RingCentral/auth';
import ScheduleInterview from '@/components/app/Settings/ScheduleInterview';
import Users from '@/components/app/Settings/Users';
import SwitchAccount from '@/components/app/SwitchAccount';

import NotFoundPage from '@/pages/404';

import { TalieDrawer } from '@/components/app/TalieAI/Drawer';

import { Inboxes } from '@/pages/Inboxes';
import { Jobs } from '@/pages/Jobs';
import NewProspects from '@/pages/Leads/NewProspect';
import ExistingClientProspects from '@/pages/Leads/ExistingClientProspects';
import MyLeads from '@/pages/Leads/MyLeads';
import UnclaimedLeads from '@/pages/Leads/UnclaimedLeads';
import { LeadLayout, LeadsDrawerDetail } from '@/modules/Leads';
import { Engage } from '@/pages/Engage';

import { updateApp } from '@/store/app.slice';
import { useMainPageServices } from './useMainPageServices';
import RouterErrorBoundary from '@/components/error-boundary/RouterErrorBoundary';
import SentryErrorBoundry from '@/components/error-boundary/SentryErrorboundary';
import CandidateLayout from '@/modules/Candidates/components/layouts';
import ModalLoader from '@/components/ui/Loaders/ModalLoader';

const CandidateSearch = lazy(() => import('@/pages/Candidates/Search'));
const CandidateViews = lazy(() => import('@/pages/Candidates/Views'));
const CandidateDrawer = lazy(
  () => import('@/components/app/Candidates/Drawer')
);

const MainPage = () => {
  const dispatch = useDispatch();
  const {
    config,
    isNavBar,
    handleOpenParseQueue,
    handleOpenFooterNotif,
    handleSwitchAccount,
    isOpenParseQueue,
    onCloseParseQueue,
    isDashboard,
    isEngage,
    isCongrats,
    first_name,
    setIsNavBar,
    isOpenFooterNotif,
    onCloseFooterNotif,
    handleNotification,
    hasSwitchedAccount,
    constants,
    callViewProfile,
    profile,
    // teamMembers,
    auth,
    isTalieShow,
    isBackDrop,
  } = useMainPageServices();
  return (
    <Box
      bg="default.white.800"
      m="0"
      className="mainpage-container"
      h="100vh"
      boxSize="border-box"
      border={hasSwitchedAccount !== null ? '3px solid' : 'none'}
      borderColor="primary.800"
    >
      {hasSwitchedAccount !== null && (
        <Box
          position="relative"
          w="100vw"
          zIndex="99999999"
          className="switch account container"
        >
          <SwitchAccount
            member={hasSwitchedAccount}
            handleSwitchAccount={handleSwitchAccount}
          />
        </Box>
      )}
      <Box pb={6} position="relative" w="100%">
        {isNavBar && (
          <LoginAppNavBar
            handleOpenParseQueue={handleOpenParseQueue}
            handleOpenFooterNotif={handleOpenFooterNotif}
            handleSwitchAccount={handleSwitchAccount}
            handleNotification={handleNotification}
            constants={constants}
            callViewProfile={callViewProfile}
            profile={profile}
            // teamMembers={teamMembers}
          />
        )}
      </Box>
      {isOpenParseQueue && (
        <ParseQueue isOpen={isOpenParseQueue} onClose={onCloseParseQueue} />
      )}

      {isBackDrop && (
        <Box
          sx={{
            zIndex: 998,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bgColor: 'black',
            opacity: '30%',
            height: '100%',
          }}
        />
      )}

      <Box
        sx={{
          px: !isDashboard && !isEngage && 4,
          pt: !isDashboard && !isEngage ? 20 : 10,
        }}
        zIndex="1"
        onClick={() => dispatch(updateApp({ isCongrats: false }))}
      >
        {isCongrats && (
          <Box
            sx={{
              zIndex: 999999999999,
              position: 'absolute',
              top: '30vh',
              left: 0,
              right: 0,
              bgColor: '#FAF5FF',
              width: '80%',
              border: '0.5px solid #6930ca1f',
              borderRadius: '20px',
              margin: '0 auto',
              py: '20px',
              display: 'flex',
              flexDir: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '40px',
              fontWeight: 700,
              color: 'primary.800',
              boxShadow: '-7px 5px 8px 2px rgba(0, 0, 0, 0.05)',
            }}
          >
            <ConfettiExplosion {...config} />
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti} alt="logo" />
            </Box>
            <Box>Woohoooo!! Congrats, {first_name}!</Box>
            <Box> You’re Awesome!</Box>
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti1} alt="logo" />
            </Box>
          </Box>
        )}

        <Box position="relative">{isTalieShow && <TalieDrawer />}</Box>

        <Box sx={{ filter: isCongrats && 'blur(2px)', height: '100%' }}>
          <SentryErrorBoundry>
            <React.Suspense fallback={<Loader />}>
              <Routes>
                <Route
                  path={'/'}
                  element={<Dashboard />}
                  errorElement={<RouterErrorBoundary />}
                />

                <Route
                  path={'/inbox'}
                  element={<Inboxes />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":settingTab"
                    element={<Inboxes />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":settingTabId"
                      element={<Inboxes />}
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                </Route>

                <Route
                  path={'/dashboard'}
                  element={<Dashboard />}
                  errorElement={<RouterErrorBoundary />}
                />

                <Route
                  path="/jobs"
                  element={<Jobs />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":jobId"
                    element={<Jobs />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":jobsTab"
                      element={<Jobs />}
                      errorElement={<RouterErrorBoundary />}
                    >
                      <Route
                        path=":jobsTabId"
                        element={<Jobs />}
                        errorElement={<RouterErrorBoundary />}
                      >
                        <Route
                          path=":jobsTabNav"
                          element={<Jobs />}
                          errorElement={<RouterErrorBoundary />}
                        >
                          <Route
                            path=":candidateId"
                            element={<Jobs />}
                            errorElement={<RouterErrorBoundary />}
                          >
                            <Route
                              path=":tab"
                              element={<Jobs />}
                              errorElement={<RouterErrorBoundary />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="/candidates"
                  element={<CandidateLayout />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path="search"
                    element={
                      <Suspense fallback={<Skeleton w="100%" h="100%" />}>
                        <CandidateSearch />
                      </Suspense>
                    }
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":candidateId/:tab"
                      element={
                        <Suspense fallback={<ModalLoader />}>
                          <CandidateDrawer />
                        </Suspense>
                      }
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                  <Route
                    path="views"
                    element={
                      <Suspense fallback={<Skeleton w="100%" h="100%" />}>
                        <CandidateViews />
                      </Suspense>
                    }
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":candidateId/:tab"
                      element={
                        <Suspense fallback={<ModalLoader />}>
                          <CandidateDrawer />
                        </Suspense>
                      }
                      errorElement={<RouterErrorBoundary />}
                    />
                    <Route
                      path=":jobId/:candidateId/:tab"
                      element={
                        <Suspense fallback={<ModalLoader />}>
                          <CandidateDrawer />
                        </Suspense>
                      }
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                </Route>

                {/* <Route
                  path="/candidates"
                  element={<CandidatesPage />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":candidatesTabState"
                    element={<CandidatesPage />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":candidatesPageNum"
                      element={<CandidatesPage />}
                      errorElement={<RouterErrorBoundary />}
                    >
                      <Route
                        path=":candidatesId"
                        element={<CandidatesPage />}
                        errorElement={<RouterErrorBoundary />}
                      >
                        <Route
                          path=":candidatesTab"
                          element={<CandidatesPage />}
                          errorElement={<RouterErrorBoundary />}
                        >
                          <Route
                            path=":candidatesTabId"
                            element={<CandidatesPage />}
                            errorElement={<RouterErrorBoundary />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route> */}

                <Route
                  path="/clients"
                  element={<ClientsPage />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":clientsTab"
                    element={<ClientsPage />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":clientsId"
                      element={<ClientsPage />}
                      errorElement={<RouterErrorBoundary />}
                    >
                      <Route
                        path=":clientsSubTab"
                        element={<ClientsPage />}
                        errorElement={<RouterErrorBoundary />}
                      >
                        <Route
                          path=":clientsTabId"
                          element={<ClientsPage />}
                          errorElement={<RouterErrorBoundary />}
                        >
                          <Route
                            path=":clientSubSubTab"
                            element={<ClientsPage />}
                            errorElement={<RouterErrorBoundary />}
                          >
                            <Route
                              path=":clientLeadTab"
                              element={<ClientsPage />}
                              errorElement={<RouterErrorBoundary />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="/leads"
                  element={<LeadLayout />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route index element={<Navigate to="my-leads" replace />} />
                  <Route
                    path="new-prospects"
                    element={<NewProspects />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":leadsId/:leadsTab"
                      element={<LeadsDrawerDetail />}
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                  <Route
                    path="existing-client-prospects"
                    element={<ExistingClientProspects />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":leadsId/:leadsTab"
                      element={<LeadsDrawerDetail />}
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                  <Route
                    path="my-leads"
                    element={<MyLeads />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":leadsId/:leadsTab"
                      element={<LeadsDrawerDetail />}
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>
                  <Route
                    path="unclaimed-leads"
                    element={<UnclaimedLeads />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":leadsId/:leadsTab"
                      element={<LeadsDrawerDetail />}
                      errorElement={<RouterErrorBoundary />}
                    />
                  </Route>

                  {/* <Route path=":leadsId" element={<MyLeads />}>
                  <Route path=":leadsTab" element={<MyLeads />}>
                    <Route path=":leadsTabId" element={<MyLeads />} />
                  </Route>
                </Route> */}
                </Route>

                <Route
                  path="/approvals"
                  element={<ApprovalsPage />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":approvalsId"
                    element={<ApprovalsPage />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":approvalsTab"
                      element={<ApprovalsPage />}
                      errorElement={<RouterErrorBoundary />}
                    >
                      <Route
                        path=":approvalsTabId"
                        element={<ApprovalsPage />}
                        errorElement={<RouterErrorBoundary />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="/profile/:profileID"
                  element={<MyProfile />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>

                <Route
                  path="/settings"
                  element={<Settings />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="settings/users"
                  element={<Users />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/settings/ringcentral/auth"
                  element={<RingCentralAuth />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="*"
                  element={<NotFoundPage setIsNavBar={setIsNavBar} />}
                />
                <Route
                  path="/msgraph"
                  element={<MsGraph />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/msgraph/oauth"
                  element={<MsGraphOuth />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/msgraph/me"
                  element={<MsGraphMe />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/email-template"
                  element={<EmailTemplateTab />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":templateTab"
                    element={<EmailTemplateTab />}
                    errorElement={<RouterErrorBoundary />}
                  ></Route>
                </Route>
                <Route
                  path="/email-signature"
                  element={<EmailSignatures />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":signatureTab"
                    element={<EmailSignatures />}
                    errorElement={<RouterErrorBoundary />}
                  ></Route>
                </Route>
                <Route
                  path="/schedule-interview"
                  element={<ScheduleInterview />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/resume/:resumeId"
                  element={<CandidatesResume />}
                  errorElement={<RouterErrorBoundary />}
                ></Route>
                <Route
                  path="/engage"
                  element={<Engage />}
                  errorElement={<RouterErrorBoundary />}
                >
                  <Route
                    path=":settingNav"
                    element={<Engage />}
                    errorElement={<RouterErrorBoundary />}
                  >
                    <Route
                      path=":settingTab"
                      element={<Engage />}
                      errorElement={<RouterErrorBoundary />}
                    >
                      <Route
                        path=":settingType"
                        element={<Engage />}
                        errorElement={<RouterErrorBoundary />}
                      >
                        <Route
                          path=":settingTypeId"
                          element={<Engage />}
                          errorElement={<RouterErrorBoundary />}
                        >
                          <Route
                            path=":settingSubType"
                            element={<Engage />}
                            errorElement={<RouterErrorBoundary />}
                          >
                            <Route
                              path=":settingSubId"
                              element={<Engage />}
                              errorElement={<RouterErrorBoundary />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </React.Suspense>
          </SentryErrorBoundry>
        </Box>
      </Box>
      {isOpenFooterNotif && (
        <FooterNotif isOpen={isOpenFooterNotif} onClose={onCloseFooterNotif} />
      )}
    </Box>
  );
};

export default MainPage;
